import '../AdminForm.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
//import Griddle from 'griddle-react';
import { Table } from '../../table/Table'
import classNames from 'classnames';
import ActionsComponent from '../../dashboard/gridview/components/actions';
import TextField from '../../fields/text/index';
import SelectField from '../../fields/select/index';
import CountrySelect from '../../fields/select/country';
import RegionSelect from '../../fields/select/RegionSelect';
import CurrencySelect from '../../fields/select/currency';
import MonthSelect from '../../fields/select/months';
import { clone } from '../../../../utils';
import RequiredLabel from '../../RequiredLabel/index';
import {FinancialPeriodsTable} from '../../grids/financialPeriods/view';
import { FinancialPeriodsEditor } from '../../grids/financialPeriods/edit/FinancialPeriods';


const reportingScales = [
    { value: 1, label: 'x 1' },
    { value: 100, label: 'x 100' },
    { value: 1000, label: 'x 1.000' },
    { value: 10000, label: 'x 10.000' },
    { value: 100000, label: 'x 100.000' },
    { value: 1000000, label: 'x 1.000.000' },
    { value: 10000000, label: 'x 10.000.000' }
];

const clientTypes = [
    { value: 'MFI', label: 'Mfi' },
    { value: 'BANK', label: 'Bank' },
	{ value: 'AGRO', label: 'Agro' }
];

const statuses = [
	{ value: 'active', label: 'Active' },
	{ value: 'inactive', label: 'Inactive'},
	{ value: 'workout', label: 'Workout'}
];

const institutionalForms = [
    {
        value: "Bank",
        label: "Bank"
    },
    {
        value: "NBFI",
        label: "NBFI (Non Bank Financial Intermediary)"
    },
    {
        value: "NGO",
        label: "NGO"
    },
    {
        value: "Credit Union / SACCO",
        label: "Credit Union / SACCO"
    },
    {
        value: "Other",
        label:  "Other"
    }
];

const profitProfiles = [
    {
        value: "profit",
        label:  "For Profit"
    },
    {
        value: "nonprofit",
        label:  "Not For Profit"
    }	
];


class ClientForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			values: clone(this.props.values, true) || {},
			MODE:'',
			valid: true,
			contactsValid: true,
			contactsCreating: false,
			contactsEditing: false,
			contactsSaving: false,
			rowIndex: null,
			selectedContact: {}
		};

		//2 validation maps = 1 for the general form, 1 for the contact part of the general form
		this._validations = new Map();
		this._contactValidations = new Map();

		this.addContact = this.addContact.bind(this);
		this.saveContact = this.saveContact.bind(this);
		this.cancel = this.cancel.bind(this);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onValidationChange = this.onValidationChange.bind(this);
		this.editPeriodsClick = this.editPeriodsClick.bind(this);
		this.onClosePeriods = this.onClosePeriods.bind(this);
	}

	editPeriodsClick() {
		this.setState({MODE:"PERIODS"});
		if(this.props.showButtons) {
			this.props.showButtons(false);
		}
	}

	onClosePeriods(periods) {
		
		if(periods) {
			this.setState({
				values: {
					...this.state.values,
					financialPeriods:[...periods]
				}
			})
		}
		this.setState({MODE:""});
		if(this.props.showButtons) {
			this.props.showButtons(true);
		}
	}

	//set new value in received valueTree based on prop identifier
	iterateObject(values, value, identifier) {
		let foundValue = false;
		Object.keys(values).forEach((k) => {
			if (values[k] !== null && typeof values[k] === 'object') {
				foundValue = !foundValue ? this.iterateObject(values[k], value, identifier) : foundValue;
			}
			if (k === identifier) {
				values[k] = value;
				foundValue = true;
			}
		});

		return foundValue;
	}

	//method hooked up to all the fields --> detect changes
	//if a field of the contact form is adjusted, immediately adjust value in state.selectedContact
	onFieldChange(field, value, previousValue, valid) {
		if(field.props.identifier.split('.')[0] === 'contact') {
			this.state.selectedContact[field.props.identifier.split('.')[1]] = value;
		} else {
			let values = this.state.values;
			let foundValue = this.iterateObject(values, value, field.props.identifier);
			if(!foundValue) values[field.props.identifier] = value;

			this.setState({values: values});
		}
	}

	//method hookup to all the fields --> detect validation changes (valid/invalid)
	//if invalid --> store identifier in map, if map.length > 0 => form is invalid
	//if a field of the contact form is changed, store it in a different map
	onValidationChange(field, valid, value) {
		let identifier = field.props.identifier.split('.');
		let validationMapToUse = identifier[0] === 'contact' ? this._contactValidations : this._validations;
		if(validationMapToUse.has(field.props.identifier)) validationMapToUse.delete(field.props.identifier);
		if(!valid) {
			validationMapToUse.set(field.props.identifier, valid)
		}
		let myValid = validationMapToUse.size === 0;

		if(identifier[0] === 'contact') {
			this.setState({ contactsValid: myValid });
		}
		else this.setState({valid: myValid});
	}

	//user clicked on "add new contact" --> show contact form with empty values
	addContact() {
		this.setState({
			contactsCreating: true,
			selectedContact: {
				name: null,
				role: null,
				email: null
			}
		});
	}

	//user clicked on "save contact" --> save new/existing contact to the state (no call needed to the db, will happen in the higher component)
	saveContact() {
		this.setState({ contactsSaving: true });
		if(this.state.contactsValid) {
			let values = this.state.values;
			if(this.state.contactsEditing) {
				values.contacts[this.state.rowIndex] = this.state.selectedContact;
			} else if(this.state.contactsCreating) {
				if(!values.contacts) values.contacts = [];
				values.contacts.push(this.state.selectedContact);
			}

			this.setState({
				values: values,
				contactsEditing: false,
				contactsSaving: false,
				contactsCreating: false,
				rowIndex: null,
				selectedContact: {},
				contactsValid: true
			});
		}
	}

	//user clicked on one of the action buttons in the contacts GridView (edit/delete contact --> no call needed to the db, will happen in the higher component)
	onAction(action, rowData) {
		if(action === 'edit') {
			this.setState({
				contactsEditing: true,
				rowIndex: rowData.__index,
				selectedContact: {
					name: rowData.name,
					role: rowData.role,
					email: rowData.email
				}
			});
		} else if(action === 'delete') {
			let values = this.state.values;
			values.contacts.splice(rowData.__index, 1);
			this.setState({values: values});
		}
	}

	//user clicked on cancel in the "contacts" part of the form --> show contacts GridView
	cancel() {
		this.setState({contactsEditing: false, contactsCreating: false, contactsSaving: false, rowIndex: null, selectedContact: {}});
	}

	//separate method to render a GridView with contacts in it (specific columns & data)
	renderContacts(contacts) {
		let values = contacts && contacts.length > 0 ? contacts.map((dataEntry, idx) => {
			return {
				role: dataEntry.role,
				name: dataEntry.name,
				email: dataEntry.email,
				__actions: ['edit', 'delete'],
				__index: idx
			};
		}) : [];

		const columnsToShow = ['name', 'role', 'email', '__actions'];
		const columnMeta = [
			{ columnName: 'name', displayName: <FormattedMessage id="admin.form.client.contacts.name" /> },
			{ columnName: 'role', displayName: <FormattedMessage id="admin.form.client.contacts.role" /> },
			{ columnName: 'email', displayName: <FormattedMessage id="admin.form.client.contacts.email"/> },
			{
				columnName: '__actions',
				displayName: <FormattedMessage id="grid.column.actions" />,
				customComponent: ActionsComponent,
				customComponentProps: {
					onAction: this.onAction.bind(this)
				}
			},
			{ columnName: '__index', visible: true }
		];

		let gridClassName = classNames(
			'o-gridView',
		);

		let tableClassName = classNames(
			'o-gridView__table',
			'o-gridView__table-no-margin-top',
			'o-gridView__table--sortable',
			'o-gridView__table--paged',
		);

		return (
			<div className="grid-container">
				<Table data={values} columns={columnsToShow} columnMetadata={columnMeta} useGriddleStyles={false}
								 noDataMessage={'No data could be found.'} gridClassName={gridClassName}
								 tableClassName={tableClassName}/>
			</div>
		);
	}

	//render method (render a form --> NOT using FormGenerator here, only available field components)
	render() {
		const {
			values
		} = this.state;

		if(this.state.MODE=="PERIODS") {
			
			return <div><FinancialPeriodsEditor onClose={this.onClosePeriods} clientId={values._id} data={values.financialPeriods}
			defaultSettings={values.settings.financialPeriodEnd}>

			</FinancialPeriodsEditor>
			</div>
		}

		const standardBindings = {
			validations: {required: true},
			activePageIsSubmitted: this.props.saving,
			onChange: this.onFieldChange,
			onValidationChange: this.onValidationChange
		};

		const nonRequiredBindings = Object.assign({}, standardBindings, {validations: {}});

		let contactStandardBindings = {...standardBindings};
		contactStandardBindings.activePageIsSubmitted = this.state.contactsSaving;

		return (
			<div className="admin-form">
				<div className="required-fields-label">
					<FormattedMessage id="required-fields.label"/>
				</div>

				<div className="form-group readOnly">
					<label>
						<FormattedMessage id="admin.form.client.key"/>
					</label>
					<TextField value={values['key']} identifier={'key'} readOnly={true} {...standardBindings}/>
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.client.name"/>
						<RequiredLabel />
					</label>
					<TextField value={values['name']} identifier={'name'} {...standardBindings} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.client.clientType"/>
						<RequiredLabel />
					</label>
					<SelectField value={values['clientType']} identifier={'clientType'} options={clientTypes} {...standardBindings} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.client.institutionalForm"/>
						<RequiredLabel />
					</label>
					<SelectField value={values['institutionalForm']} identifier={'institutionalForm'} options={institutionalForms} {...standardBindings} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.client.profitProfile"/>
						<RequiredLabel />
					</label>
					<SelectField value={values['profitProfile']} identifier={'profitProfile'} options={profitProfiles} {...standardBindings} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.client.status"/>
						<RequiredLabel />
					</label>
					<SelectField value={values['status']} identifier={'status'} options={statuses} {...standardBindings} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.client.officialName"/>
						<RequiredLabel />
					</label>
					<TextField value={values['officialName']} identifier={'officialName'} {...standardBindings} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.client.region"/>
						<RequiredLabel />
					</label>
					<RegionSelect value={values['worldRegion']} identifier={'worldRegion'}
											 {...standardBindings} placeholder={'Select region(s) ... '} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.client.yearsOfOperationalStart"/>
						<RequiredLabel />
					</label>
					<TextField value={values['yearOfOperationalStart']} identifier={'yearOfOperationalStart'} {...standardBindings} />
				</div>

				<div className="form-group">
					<div className="block">
						<label className="top-label">
							<FormattedMessage id="admin.form.client.address"/>
						</label>

						<div className="block-children">
							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.client.address.country"/>
									<RequiredLabel />
								</label>
								<CountrySelect value={values['address']['country']} identifier={'country'}  {...standardBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.client.address.city"/>
									<RequiredLabel />
								</label>
								<TextField value={values['address']['city']} identifier={'city'} {...standardBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.client.address.postalCode"/>
									<RequiredLabel />
								</label>
								<TextField value={values['address']['postalcode']} identifier={'postalcode'} {...standardBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.client.address.street"/>
									<RequiredLabel />
								</label>
								<TextField value={values['address']['street']} identifier={'street'} {...standardBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.client.address.nr"/>
									<RequiredLabel />
								</label>
								<TextField value={values['address']['nr']} identifier={'nr'} {...standardBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.client.address.box"/>
								</label>
								<TextField value={values['address']['box']} identifier={'box'} {...nonRequiredBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.client.address.additional"/>
								</label>
								<TextField value={values['address']['additional']} identifier={'additional'} {...nonRequiredBindings} />
							</div>
						</div>
					</div>
				</div>

				<div className="form-group">
					<div className="block">
						<label className="top-label">
							<FormattedMessage id="admin.form.client.settings"/>
						</label>

						<div className="block-children">
							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.client.settings.financialPeriodEnd"/>
								</label>
								<MonthSelect value={values['settings']['financialPeriodEnd']} identifier={'financialPeriodEnd'}
																 {...standardBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.client.settings.currency"/>
									<RequiredLabel />
								</label>
								<CurrencySelect value={values['settings']['currency']} identifier={'currency'} {...standardBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.client.settings.reportingScale"/>
									<RequiredLabel />
								</label>
								<SelectField value={values['settings']['reportingScale']} identifier={'reportingScale'} options={reportingScales} {...standardBindings} />
							</div>

							<div className="form-group">
							<label>
									Financial Periods
								</label>
								<div>
								<FinancialPeriodsTable clientId={values.id} data={this.state.values['financialPeriods'] || []} />
								<button style={{float:"none"}} onClick={this.editPeriodsClick}>EDIT PERIODS</button>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div className="form-group">
					<div className="block">
						<label className="top-label">
							<FormattedMessage id="admin.form.client.contacts"/>
						</label>

						{
							!this.state.contactsCreating && !this.state.contactsEditing ?
								this.renderContacts(this.state.values.contacts) :
								<div className="block-children">
									<div className="form-group">
										<label>
											<FormattedMessage id="admin.form.client.contacts.name"/>
											<RequiredLabel />
										</label>
										<TextField value={this.state.selectedContact['name']} identifier={'contact.name'} {...contactStandardBindings} />
									</div>

									<div className="form-group">
										<label>
											<FormattedMessage id="admin.form.client.contacts.role"/>
											<RequiredLabel />
										</label>
										<TextField value={this.state.selectedContact['role']} identifier={'contact.role'} {...contactStandardBindings} />
									</div>

									<div className="form-group">
										<label>
											<FormattedMessage id="admin.form.client.contacts.email"/>
											<RequiredLabel />
										</label>
										<TextField value={this.state.selectedContact['email']} identifier={'contact.email'} {...contactStandardBindings} />
									</div>
								</div>
						}

						{
							!this.state.contactsCreating && !this.state.contactsEditing ?
								<button onClick={this.addContact}>
									<FormattedMessage id="app.buttons.add-new-contact"/>
								</button> :
								<div className="form-actions">
									<button onClick={this.saveContact}>
										<FormattedMessage id="app.buttons.save-contact"/>
									</button>
									<button onClick={this.cancel} className="cancel">
										<FormattedMessage id="app.buttons.cancel"/>
									</button>
								</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default ClientForm;