import React from 'react';
import { FormattedMessage } from 'react-intl';
import GridView from '../dashboard/gridview/gridview.jsx';
import {Table} from '../table/Table';


const DefaultProps = {
    itemsPerPage: 5
};

class ClientGridSelector extends React.Component {
    constructor(props) {
        super(props);
    }

    //bind the onClick event on a row to the parent onRowClick property (f.ex. navigate to another route)
    onSelectedRowClick(row) {
        if (this.props.onSelectedRowClick) {
            this.props.onSelectedRowClick(row.props.data);
        }
    }
    //bind the onClick event on a row to the parent onRowClick property (f.ex. navigate to another route)
    onUnselectedRowClick(row) {
        if (this.props.onUnselectedRowClick) {
            this.props.onUnselectedRowClick(row.props.data);
        }
    }

    //render method
    render() {
        const selected = this.props.selected || [];
        const unselected = this.props.unselected || [];

        let selectedData = selected.map((client) => {
            return {
                _id:client._id,
                key: client.key,
                officialName: client.officialName,
                status: client.status
            };
        });

        let unselectedData = unselected.map((client) => {
            return {
                _id:client._id,
                key: client.key,
                officialName: client.officialName,
                status: client.status
            };
        });

        const allClientsMeta = [
            { columnName: 'officialName', displayName: <FormattedMessage id="export.client-grid.exclude"/>}
        ];

        const selectedClientsMeta = [
            { columnName: 'officialName', displayName: <FormattedMessage id="export.client-grid.include"/>}
        ];

        return (
					<div className="clients-grid-select">
						<Table data={unselectedData}  showFilter={true} compact={true}
                          scroll={{y:"auto"}}
											columnMetadata={allClientsMeta}
											resultsPerPage={this.props.itemsPerPage}
											onRowClick={this.onUnselectedRowClick.bind(this)} />

						<Table data={selectedData}  showFilter={true} compact={true}
											columnMetadata={selectedClientsMeta}
											resultsPerPage={this.props.itemsPerPage}
                                            scroll={{y:"auto"}}
											onRowClick={this.onSelectedRowClick.bind(this)} />
					</div>
        );
    }
}

export default ClientGridSelector;