import { useContext, useMemo } from "react"
import { AppContext } from "./AppContext"


export const useApp = ()=> {
    const appCtx =  useContext(AppContext);
    return appCtx;
}

export const useConfigCache = ()=> {

    const { configMap } = useContext(AppContext);

    const result = useMemo(()=> {
        return {
            color:(configKey)=> {
                return configMap.get(configKey).color;
            },
            getFieldDefinition(configKey,path) {
                let cfg = configMap.get(configKey);
                if(!cfg) return null;
                let parts = path.split(".");
                let obj = cfg.fields.find(o=>o.internalName==parts[0]);
                parts.shift()
                
                while(parts.length>0 && obj) {
                    let key = parts.shift();
                    if(obj.children) {
                        obj = obj.children.find(o=>o.internalName==key)
                    } else {
                        break;
                    }
                }
                return obj;
            },
            translator: {
            config:(configKey)=> {
                let c = configMap.get(configKey);
                return c?.name?.value || configKey;
            },
            field:(configKey,path)=> {
               
                let c = configMap.get(configKey);
                if(!c) return path;
                let arr = Array.isArray(path) ? [...path] : path.split(".");
                if(arr[arr.length-1]==="value") arr.pop();
                return arr.map((a,idx)=> {
                    
                    return c.translations.get(arr.slice(0,idx+1).join(".")) || a;
                })
            }}
        };

    },[configMap])

   
    
    return result
}