//import 'react-select/dist/react-select.css';
import React from 'react';
import {Async as Select } from 'react-select';
import { BaseField } from '../base';
import {apolloClient} from '../../../../redux/store';
import {covenantListQuery} from '../../../../graph/queries/admin/covenants';



class SelectCovenant extends BaseField {

    constructor(props) {
        super(props);
        this.loadOptions=this.loadOptions.bind(this);
    }

    componentDidMount() {
        this.validate(this.getValue());
    }

    loadOptions(input) {
       
        if(this.loadTimer) clearTimeout(this.loadTimer);
        return new Promise((resolve,reject)=> {
            this.loadTimer = setTimeout(()=> {
                
                apolloClient.query({
                    query: covenantListQuery,
                    variables:{
                        filter:input,
                        clientId: this.props.clientId, // feature/issue-126
                        limit:30,
                        language:'en'
                    },
                    forceFetch: true
                }).then((result) => {
                    resolve({options:result.data.covenantList, complete:true});
                });
            },300);
        });
    }

    onChange(val) {
        super.onChange(val ? val["value"] ? val.value : val : null);
    }

    onComponentChange(val) {
        const value = val;
        const previousValue = this.state.value;
        this.setState({value});
        this.onChange(value,previousValue);
    }

    render() {
        const {
            multi,
            readOnly,
            placeholder,
            activePageIsSubmitted
        } = this.props;

        const classNames = !this.state.valid && activePageIsSubmitted ? 'invalid' : null;

        return(
            <Select name="form-field-covenant" className={classNames} disabled={readOnly} value={this.state.value}
                    loadOptions={this.loadOptions}
                    backspaceRemoves={false}
                   onChange={this.onComponentChange.bind(this)} placeholder={placeholder} onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)} />
        );
    }
}

export default SelectCovenant;
