import {
    CLIENT_LOADED, CLIENT_LOADING,LOAD_ERROR , CLIENT_CLEAR,
		CLIENT_SET_PERIOD_SELECTOR
} from '../actions/client/actions';
import moment from 'moment';

const initialState = {
    loading:false,
    _id:null,
    key:null,
    name: null,
    contacts:[],
    currency: null,
    recent: [],
    settings: null,
		status: null,
    error: null,
    bottomDate: null,
    topDate: null,
		periodType: null,
		periods: []
};

export const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_ERROR:
            return {
                ...state,
                loading:false,
                _id:null,
                key:null,
                name:null,
                settings:null,
                contacts:[],
								status: null,
                error:action.payload
            };
        case CLIENT_CLEAR:
            return {
                ...state,
                loading:false,
                _id:null,
                key:null,
                name:null,
                settings:null,
                contacts:[],
								status: null,
                error:null
            };
        case CLIENT_LOADING: {
            return {
                ...state,
                loading:true,
                error:null
            };
        }
        case CLIENT_LOADED:
            const client = action.client;
            let recent = [{_id:client._id,name:client.officialName}, ...state.recent.filter(r=>r._id!=client._id)];

            if(recent.length > 5) {
                recent.pop();
            }

            let currentYear = parseInt(`${moment().year()}00`);
            let bottomDate = client.bottomDate ? parseInt(client.bottomDate) : currentYear;
            let topDate = client.topDate ? parseInt(client.topDate) : currentYear;
            let periods = [];
 						let diffDates = topDate - bottomDate;
            let int = 3;
            if(diffDates < 300) {
            	int = diffDates/100 + 1;
						}

						for(let i = 0; i < int; i++) {
            	periods.push(topDate - (i * 100));
						}

            var clientConfigType = client.clientConfigType;
           

            if(!clientConfigType) {
              clientConfigType = "MFI";
              if(client.clientType!="BANK") {
                clientConfigType =client.clientType;
              }

            }
            
            


            return {
                loading:false,
                _id: client._id,
                key: client.key,
                name: client.officialName,
                clientType: client.clientType,
                clientConfigType,
                contacts: client.contacts,
                currency: client.settings.currency,
                settings: client.settings,
								status: client.status,
                recent,
                error: null,
								bottomDate,
								topDate,
								periods,
								periodType: 'F'
            };
			case CLIENT_SET_PERIOD_SELECTOR:
				return {
					...state,
					periodType: action.payload && action.payload.periodType ? action.payload.periodType : 'F',
					periods: action.payload && action.payload.periods ? action.payload.periods : []
				};
    }
    return state;
};
