import React from 'react';
import { BaseField} from '../base';

class TextField extends BaseField {
	componentDidMount() {
		this.validate(this.state.value);
	}

	componentWillUnmount() {
		this.onValidationChange(true);
	}

	render() {
		const {
			placeholder,
			readOnly,
			activePageIsSubmitted
		} = this.props;

		let classNames = !this.state.valid && activePageIsSubmitted ? 'invalid' : '';

		const fieldProps = {
			type: this.props.type || 'text',
			placeholder: placeholder,
			className: classNames,
			value: this.state.value || '',
			readOnly: readOnly,
			cols: this.props.cols,
			rows: this.props.rows ? this.props.rows :
				(this.props.validations && this.props.validations && this.props.validations.format && this.props.validations.format.rows ? this.props.validations.format.rows : null),
			...this.domBindings(this)
		};

		return(
			<div className="text-field">
				{
					this.props.validations && this.props.validations.format && this.props.validations.format.multiLine ?
						<textarea {...fieldProps} /> : <input {...fieldProps}/>
				}
			</div>
		);
	}
}


export default TextField;