import './dashboard.scss';

import React from 'react';
import classNames from 'classnames';

/**
 * DESCRIPTION: dashboard wrapper/container
 *   => className differences from client - incofin dashboard
 */


class DashboardContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    //render method
    render() {
        return (
            <div className={classNames('c-dashboard', this.props.className)}>
                {this.props.children}
            </div>
        );
    }
}

export default DashboardContainer;