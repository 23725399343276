import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from '@apollo/client/react/hoc';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import Panel from '../../components/dashboard/panel/panel.jsx';
import { DashboardContainer, SmartLayout } from '../../components/dashboard/index';
import ExportCovenantsForm from '../../components/ExportCovenantsForm/index';
import { createExportCovenants } from '../../../graph/mutations/export';

class AdminExportCovenantsPage extends React.Component {
	constructor(props) {
		super(props);

		this.exportCovenantsForm = null;
		this.createCovenantsExport = this.createCovenantsExport.bind(this);
	}

	createCovenantsExport() {
		let values = this.exportCovenantsForm.getValues();

		this.props.createExportCovenants({
			variables: {
				fieldType: values.fieldType
			}
		}).then(({data}) => {
			if (data.createExportCovenants && !data.createExportCovenants.error) {
				let a = document.createElement("a");
				document.body.appendChild(a);
				a.href = '/download/' + data.createExportCovenants;
				a.click();
				document.body.removeChild(a);
			} else if (this.refs.container && data.createExportCovenants.error) {
				this.refs.container.error(data.createExportCovenants.error.message);
			}
		}).catch((error) => {
			if (this.refs.container) this.refs.container.error(error.message);
		});
	}

	//render method
	render() {
		
		let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

		return (
			<DashboardContainer>
				<SmartLayout>
					<Panel title={<FormattedMessage id="export-covenants.title"/>}>
						<ToastContainer ref="container" toastMessageFactory={ToastMessageFactory} className="toast-top-right"/>

						<ExportCovenantsForm values={{fieldType: null}} ref={(form) => this.exportCovenantsForm = form} />

						<div style={{height: '3em', marginTop: '2em'}}>
							<button onClick={this.createCovenantsExport}>
								<FormattedMessage id="export.labels.export-to-excel" />
							</button>
						</div>
					</Panel>
				</SmartLayout>
			</DashboardContainer>
		);
	}
}


export default graphql(createExportCovenants, {name: 'createExportCovenants'})(AdminExportCovenantsPage)