import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import NumericField from '../fields/numeric/index';
import SelectCovenant from '../fields/select/covenant';
import SelectField from '../fields/select/index';
import DateField from '../fields/date/index';
import { clone } from '../../../utils';
import RequiredLabel from '../RequiredLabel/index';


const waiverTypeOptions = [
	{value: 'DISABLE', label: 'DISABLE'},
	{value: 'REPLACE', label: 'REPLACE'},
	{value: 'ALTER', label: 'ALTER'}
];

class WaiverForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			values: clone(this.props.values, true) || {},
			valid: true,
		};

		this._validations = new Map();

		this.onFieldChange = this.onFieldChange.bind(this);
		this.onValidationChange = this.onValidationChange.bind(this);
	}

	//set new value in received valueTree based on prop identifier
	iterateObject(values, value, identifier) {
		let foundValue = false;
		Object.keys(values).forEach((k) => {
			if (values[k] !== null && typeof values[k] === 'object' && k) {
				foundValue = !foundValue ? this.iterateObject(values[k], value, identifier) : foundValue;
			}
			if (k === identifier) {
				values[k] = value;
				foundValue = true;
			}
		});

		return foundValue;
	}

	onFieldChange(field, value, previousValue, valid) {
		let values = this.state.values;
		let foundValue = this.iterateObject(values, value, field.props.identifier);
		if(!foundValue) values[field.props.identifier] = value;

		let changeValidForm = false;

		if(field.props.identifier === 'waiverType') {
			if(value === 'DISABLE') {
				values.centralCovenant = null;
				values.alertThreshold = null;
				values.range = {min:null, max:null};

				changeValidForm = true;
				if(this._validations.has('centralCovenant')) this._validations.delete('centralCovenant');
				if(this._validations.has('min')) this._validations.delete('min');
				if(this._validations.has('max')) this._validations.delete('max');
				if(this._validations.has('alertThreshold')) this._validations.delete('alertThreshold');
			}

			if(value === 'ALTER') {
				values.centralCovenant = null;

				changeValidForm = true;
				if(this._validations.has('centralCovenant')) this._validations.delete('centralCovenant');
			}
		}

		if(changeValidForm) {
			this.setState(() => ({
				valid: this._validations.size === 0
			}));
		}

		this.setState(() => ({
			values: values
		}));
	}

	onValidationChange(field, valid, value) {
		if(this._validations.has(field.props.identifier)) this._validations.delete(field.props.identifier);
		if(!valid) this._validations.set(field.props.identifier, valid);
		let myValid = this._validations.size === 0;
		this.setState(() => ({
			valid: myValid
		}));
	}

	render() {
		const {
			values
		} = this.state;

		const standardBindings = {
			validations: {required: true},
			activePageIsSubmitted: this.props.saving,
			onChange: this.onFieldChange,
			onValidationChange: this.onValidationChange
		};

		return (
			<div className="admin-form">
				<div className="required-fields-label">
					<FormattedMessage id="required-fields.label"/>
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="form.waiver.waiverType"/>
						<RequiredLabel/>
					</label>
					<SelectField value={values['waiverType']} identifier={'waiverType'} options={waiverTypeOptions} {...standardBindings} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="form.waiver.startDate"/>
						<RequiredLabel/>
					</label>
					<DateField value={values['startDate']} identifier={'startDate'} {...standardBindings}
										 maxDate={values['endDate'] ? moment(values['endDate'], 'DD-MM-YYYY') : null} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="form.waiver.endDate"/>
						<RequiredLabel/>
					</label>
					<DateField value={values['endDate']} identifier={'endDate'} {...standardBindings}
										 minDate={values['startDate'] ? moment(values['startDate'], 'DD-MM-YYYY') : null}/>
				</div>

				{
					values['waiverType'] && values['waiverType'] === 'REPLACE' ?
						<div className="form-group">
							<label>
								<FormattedMessage id="form.waiver.covenant"/>
								<RequiredLabel/>
							</label>
							<SelectCovenant value={values['centralCovenant']} identifier={'centralCovenant'} {...standardBindings} />
						</div> : null
				}

				{
					values['waiverType'] && (values['waiverType'] === 'REPLACE' || values['waiverType'] === 'ALTER') ?
						<div>
							<div className="form-group">
								<div className="block">
									<label className="top-label">
										<FormattedMessage id="admin.form.covenant.range"/>
									</label>

									<div className="block-children">
										<div className="form-group">
											<label>
												<FormattedMessage id="admin.form.covenant.range.min"/>
												<RequiredLabel/>
											</label>
											<NumericField value={values['range']['min']} max={values['range']['max']} identifier={'min'} {...standardBindings} precision={2} />
										</div>

										<div className="form-group">
											<label>
												<FormattedMessage id="admin.form.covenant.range.max"/>
												<RequiredLabel/>
											</label>
											<NumericField value={values['range']['max']} min={values['range']['min']} identifier={'max'} {...standardBindings} precision={2} />
										</div>
									</div>
								</div>
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.covenant.alertThreshold"/>
									<RequiredLabel/>
								</label>
								<NumericField allowNegative={false} value={values['alertThreshold']} identifier={'alertThreshold'} {...standardBindings} precision={2} />
							</div>
						</div> : null
				}
			</div>
		);
	}
}

export default WaiverForm;