import './translation-item.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import TranslationItem from './translation-item';
import _ from 'underscore';

let languageOptions = [
	{value: 'en', label: 'English'},
 	{value: 'es', label: 'Spanish'},
 	{value: 'fr', label: 'French'},
 	{value: 'pt', label: 'Portugese'},
 	{value: 'ru', label: 'Russian'}
];

class TranslationContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			translations: props.translations,
			creating: false,
			valid: !props.required,
			requiredError: false
		};

		this.onChange = this.onChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.addTranslation = this.addTranslation.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return this.state.requiredError !== nextState.requiredError || this.state.valid !== nextState.valid || this.state.creating !== nextState.creating
			|| this.props.submitted !== nextProps.submitted || this.props.required !== nextProps.required || !_.isEqual(this.state.translations, nextState.translations);
	}

	componentDidMount() {
		if(this.props.onTranslationsChange) {
			//this.props.onTranslationsChange(this.state.translations, this.validate(this.state.translations));
		}
	}

	addTranslation() {
		this.setState(() => ({
			creating: true
		}));
	}

	validate(translations) {
		let valid = true;
		if((!translations || Object.keys(translations).length === 0) && this.props.required) {
			valid = false;
			this.setState(() => ({
				requiredError: true
			}));
		} else if(translations && this.props.requiredLanguages && this.props.requiredLanguages.length > 0 && Array.isArray(this.props.requiredLanguages)) {
			this.props.requiredLanguages.forEach((language) => {
				if(!translations.hasOwnProperty(language)) {
					valid = false;
					this.setState(() => ({
						requiredError: true
					}));
					return;
				}
			});
		}

		if(valid) {
			this.setState(() => ({
				requiredError: false
			}));
		}

		return valid;
	}

	onDelete(languageKey) {
		if(languageKey === 'new') {
			this.setState(() => ({
				creating: false
			}));
		} else {
			let translations = {...this.state.translations};

			if(translations[languageKey]) {
				delete translations[languageKey];
			}

			let valid = this.validate(translations);

			if(this.props.onTranslationsChange) this.props.onTranslationsChange(translations, valid);

			this.setState(() => ({
				translations: translations,
				valid: valid
			}));
		}
	}

	onChange(language, value, newTranslation) {
		let translations = {...this.state.translations};
		translations[language] = value;
		let valid = this.validate(translations);

		if(this.props.onTranslationsChange) this.props.onTranslationsChange(translations, valid);

		this.setState((state) => ({
			translations: translations,
			valid: valid,
			creating: newTranslation ? false : state.creating
		}));
	}

	renderRequiredLabel(languages) {
		return (
			<div className={this.state.requiredError ? 'error required-wrapper' : 'required-wrapper'}>
				<span>(* required languages: </span>

				{
					languages.map((language, idx) => {
						const languageOption = _.findWhere(languageOptions, {value: language});
						return (
							<span key={language}>
								{languageOption.label + (idx !== languages.length - 1 ? ', ' : '')}
							</span>
						)
					})
				}
				)
			</div>
		);
	}

	render() {
		let translationItems = [];

		const {
			multiline,
			submitted,
			required,
			requiredLanguages
		} = this.props;

		const {
			translations
		} = this.state;

		let usedLanguages = [];

		for(let languageKey in translations) {
			usedLanguages.push(languageKey);
			translationItems.push(
				<TranslationItem key={languageKey} language={languageKey} onChange={this.onChange} onDelete={this.onDelete} submitted={required ? submitted : null}
												 value={translations[languageKey]} multiline={multiline} languageOptions={languageOptions} required={required} />
			);
		}

		let remainingOptions = languageOptions.filter((language) => {
			return usedLanguages.indexOf(language.value) === -1;
		});

		if((this.state.creating || !translations || Object.keys(translations).length === 0) && remainingOptions.length > 0) {
			translationItems.push(
				<TranslationItem key={'unknown'} onChange={this.onChange} onDelete={this.onDelete} submitted={required ? submitted : null}
												 multiline={multiline} languageOptions={remainingOptions} newTranslation={true} required={required} />
			);
		}

		return (
			<div className="translation-wrapper">
				<div className="wrapper-actions">
					<button onClick={this.addTranslation}>
						<FormattedMessage id="app.buttons.add" />
					</button>
				</div>

				{
					requiredLanguages && requiredLanguages.length > 0 && Array.isArray(requiredLanguages) ?
						this.renderRequiredLabel(requiredLanguages)
						: null
				}

				{translationItems}
			</div>
		);
	}
}

export default TranslationContainer;