import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import Panel from '../../components/dashboard/panel/panel.jsx';
import { DashboardContainer, SmartLayout } from '../../components/dashboard/index';
import ExportInvestmentsForm from '../../components/ExportInvestmentsForm/index';
import { createExportInvestments } from '../../../graph/mutations/export';


class AdminExportInvestmentsPage extends React.Component {
	constructor(props) {
		super(props);



		this.exportInvestmentsForm = null;
		this.createInvestmentsExport = this.createInvestmentsExport.bind(this);
	}

	createInvestmentsExport() {
		let values = this.exportInvestmentsForm.getWrappedInstance().getWrappedInstance().getWrappedInstance().getValues();

		if(values) {
			this.props.createExportInvestments({
				variables: {
					periods: values.periods,
					funds: values.funds,
					clients: values.clients,
					regions: values.regions,
					investmentManagers: values.investmentManagers,
					reportingFrequency: values.reportingFrequency,
				}
			}).then(({data}) => {
				if(data.createExportInvestments && !data.createExportInvestments.error) {
					let a = document.createElement("a");
					document.body.appendChild(a);
					a.href = '/download/' + data.createExportInvestments;
					a.click();
					document.body.removeChild(a);
				} else if(this.refs.container && data.createExportInvestments.error) {
					this.refs.container.error(data.createExportInvestments.error.message);
				}
			}).catch((error) => {
				if(this.refs.container) this.refs.container.error(error.message);
			});
		}
	}

	//render method
	render() {
		const {
			clients,
			client,
			regions
		} = this.props;

		
		let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

		return (
			<DashboardContainer>
				<SmartLayout>
					<Panel title={<FormattedMessage id="export-investments.title"/>}>
						<ToastContainer ref="container" toastMessageFactory={ToastMessageFactory} className="toast-top-right"/>

						<ExportInvestmentsForm ref={(form) => this.exportInvestmentsForm = form}
																	 clients={clients} regions={regions} client={client} />

						<div style={{height: '3em', marginTop: '2em'}}>
							<button onClick={this.createInvestmentsExport}>
								<FormattedMessage id="export.labels.export-to-excel" />
							</button>
						</div>
					</Panel>
				</SmartLayout>
			</DashboardContainer>
		);
	}
}

export default connect((state) => {
	return {
		clients: state.session.clients,
		regions: state.session.regions,
		client: state.client
	};
})(
graphql(createExportInvestments, {name: 'createExportInvestments'})(AdminExportInvestmentsPage))