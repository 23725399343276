import { UserAuthWrapper } from 'redux-auth-wrapper'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'
import { connect } from 'react-redux'
import _ from 'underscore';

// Global security --> if a user is not authenticated, redirect to '/' or '/login'
export const UserIsAuthenticated = connectedAuthWrapper({
	
	authenticatedSelector: (state) => state.session.authenticated,
	//redirectAction: routerActions.replace,
	wrapperDisplayName: 'UserIsAuthenticated',
	predicate: loginState => loginState,
	failureRedirectPath: '/'
});

// Incofin routes security --> redirects non-incofin members to '/login' (if logged in, redirect to dashboard client => happens automatically)
/*export const UserIsIncofinMember = connectedAuthWrapper({
	//authenticatedSelector: state => state.session,
	//redirectAction: routerActions.replace,
	wrapperDisplayName: 'UserIsIncofinMember',
	authenticatedSelector: loginState => loginState.authenticated ? (loginState.user.roles.indexOf('Incofin') > -1 || loginState.user.roles.indexOf('Incofin-admin') > -1) : false,
	failureRedirectPath: '/',
	allowRedirectBack: false
});*/

function mapStateToProps(state) {
    return {
        authenticated:state.session.authenticated,
        authenticating:state.session.authenticating,
        userLoading:state.session.loading,
        user:state.session.user,
        dataLoaded:state.data.loaded

    };
}

export const UserIsIncofinMember = connect(mapStateToProps)(({authenticated,user,children})=> {

	console.log('UserIsIncofinMember',authenticated,user.roles)
	if(authenticated && (user.roles.indexOf('Incofin') > -1 || user.roles.indexOf('Incofin-admin') > -1) ) {
		return children;
	}
	return <div>redirect</div>
});


// Client routes security --> if a user isn't linked to a certain client, redirect to his client dashboard (if client member) or to the incofin dashboard (if Incofin member)
// todo: replace with server answer, if client portal recieves null => redirect to user dashboard.
export const UserCanAccessClient = connectedAuthWrapper({
	authenticatedSelector: (state, ownProps) => {
		return {
			Clients: state.login && state.login.profile ? state.login.profile.clients : [],
			clientId: ownProps.match.params.clientId
		};
	},
	authenticatedSelector: authData => !!_.findWhere(authData.Clients, { key: authData.clientId }),
	failureRedirectPath: (state) => state.login.authenticated ? (state.login && state.login.user && state.login.user.roles.indexOf('Incofin') > -1 ? '/incofin' : `/client/${state.login.profile.clients[0].key}/`) : '/',
	wrapperDisplayName: 'UserCanAccessClient',
	allowRedirectBack: false
});

export const UserIsAdmin = connect(mapStateToProps)(({authenticated,user,children})=> {

	if(authenticated && (user.roles.indexOf('Incofin-admin') > -1) ) {
		return children;
	}
	return <div>redirect</div>
});


//AuthWrapper for the admin routes, used down below
// const UserIsAdmin = connectedAuthWrapper({
// 	//redirectAction: routerActions.replace,
// 	authenticatedSelector: state => state.session.user,
// 	predicate: user => user.roles.indexOf('Incofin-admin') > -1,
// 	failureRedirectPath: (state) => state.session.authenticated ? (state.session && state.session.user && state.session.user.roles.indexOf('Incofin') > -1 ? '/incofin' : `/`) : '/',
// 	wrapperDisplayName: 'UserIsAdmin',
// 	allowRedirectBack: false
// });

// Admin routes security --> redirects non-admin users to '/incofin' or their client page
//export const IsAdmin = UserIsAdmin((props) => props.children);

//If user is an Incofin-admin or admin, render a different data container/layout (menu)
export const RenderAdminComponent = (NonAdminComponent, AdminComponent) => connectedAuthWrapper({
	authenticatedSelector: state => state.session.user,
	//redirectAction: routerActions.replace,
	wrapperDisplayName: 'RenderAdminComponent',
	predicate: loginState => loginState.authenticated ? (loginState.roles.indexOf('Incofin-admin') > -1 || loginState.roles.indexOf('admin') > -1) : false,
	failureRedirectPath: '/',
	allowRedirectBack: false,
	FailureComponent: NonAdminComponent
})(AdminComponent);