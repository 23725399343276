import { createContext, useContext } from "react";

const getAtPath = function(obj,path) {
	let parts = path.split(".");
	parts.shift(); // config
	let res = obj;
	while(typeof(res)==="object" && res!=null && parts.length>0) {
		res=res[parts.shift()];
	} 
	return res;
}

export const FormValuesContext= createContext({values:{}})


export const useFormField = (path)=> {
	const {config, data} = useContext(FormValuesContext);
//debugger;
	const obj = getAtPath(data.values,path);
	
	
	return [obj,{data,config,numeric:data?.context?.numeric}];
}