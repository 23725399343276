import React from 'react';
import moment from 'moment';
import WaiverList from './waiver-list';
import { clone } from '../../../utils';
import classNames from 'classnames';

class CovenantItem extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentSituation: this.calculateSituation(props.waivers)
		};

		this.deleteCovenant = this.deleteCovenant.bind(this);
		this.editCovenant = this.editCovenant.bind(this);
		this.deleteWaiver = this.deleteWaiver.bind(this);
		this.saveWaiver = this.saveWaiver.bind(this);
	}

	calculateSituation(waivers) {
		const nowNumeric = parseInt(moment().format('YYYYMMDD'));
		let currentWaiverInEffect = {};

		if(waivers && waivers.length > 0 && Array.isArray(waivers)) {
			let sortedWaivers = clone(waivers).sort((a, b) => a.startDate - b.startDate);

			for(let i = 0; i < sortedWaivers.length; i++) {
				if(sortedWaivers[i].startDate <= nowNumeric && nowNumeric <= sortedWaivers[i].endDate) {
					currentWaiverInEffect = {
						index: i,
						...sortedWaivers[i]
					};

					break;
				}
			}
		}

		return currentWaiverInEffect;
	}

	componentWillReceiveProps(nextProps) {
		this.setState(() => ({
			currentSituation: this.calculateSituation(nextProps.waivers)
		}));
	}

	editCovenant() {
		if(this.props.editCovenant && this.props._id) this.props.editCovenant(this.props._id);
	}

	deleteCovenant() {
		if(this.props.deleteCovenant && this.props._id) this.props.deleteCovenant(this.props._id, this.props.centralCovenantId._id);
	}

	saveWaiver(waiver, addOrEdit) {
		if(this.props.saveWaiver && this.props._id) this.props.saveWaiver(this.props._id, waiver, addOrEdit);
	}

	deleteWaiver(waiverId) {
		if(this.props.deleteWaiver && this.props._id) this.props.deleteWaiver(this.props._id, waiverId);
	}

	render() {
		const {
			intl,
			range: {
				min,
				max
			},
			alertThreshold,
			centralCovenantId: {
				name,
				guide,
				archived
			},
			waivers,
			permissions
		} = this.props;

		const {
			currentSituation
		} = this.state;

		let tooltipBindings = guide && guide.length > 0 ?
			{
				'data-tip': '<div>' + guide + '</div>',
				'data-for': 'tooltip',
				'data-place': 'right',
				'data-effect': 'solid'
			} : {};

		let classes = classNames("covenant-list-item",{
			"covenant-archived":!!archived && (permissions.canEditCovenants || permissions.canDeleteCovenants)
		})

		return (
			<div className={classes}>
				{
					archived && (permissions.canEditCovenants || permissions.canDeleteCovenants)? <div className="covenant-archived-warning">Warning: Archived as from {moment(archived).format("DD/MM/YYYY")}
					<br/>
					<span>Still active for client, but no longer available in new investments.</span></div> : null
				}
				{
					permissions.canEditCovenants || permissions.canDeleteCovenants ?
						<div className="covenant-actions">
							{
								permissions.canEditCovenants ?
									<span className="edit" onClick={this.editCovenant} /> : null
							}

							{
								permissions.canDeleteCovenants ?
									<span className="delete" onClick={this.deleteCovenant} /> : null
							}
						</div>
						: null
				}

				<div className="covenant-title">
					<div className={
						currentSituation && Object.keys(currentSituation).length > 0
						&& (currentSituation.waiverType === 'DISABLE' || currentSituation.waiverType === 'REPLACE') ?
							'covenant-name line-through' : 'covenant-name'}>
						{ guide && Object.keys(tooltipBindings).length > 0 ?
							<span className="covenant-info-guide" {...tooltipBindings} /> : null }
						{name}
					</div>

					<div className={
						currentSituation && Object.keys(currentSituation).length > 0
						&& (currentSituation.waiverType === 'DISABLE' || currentSituation.waiverType === 'REPLACE' || currentSituation.waiverType === 'ALTER') ?
							'covenant-details line-through' : 'covenant-details'}>
						<span className="covenant-details-range">
							{intl.formatNumber(min/100)} &#060; &#062; {intl.formatNumber(max/100)}
						</span>
						<span className="covenant-details-alert-threshold">
							{intl.formatNumber(alertThreshold/100)}
						</span>
					</div>
				</div>

				{
					currentSituation && Object.keys(currentSituation).length > 0 ?
						<div className="covenant-waiver-in-effect">
							<div className="covenant-waiver-in-effect-title">
								<span className="waiver-icon"/>
								{moment(currentSituation.startDate, 'YYYYMMDD').format('DD/MMM/YYYY')}
								&#160;&#045;&#160;
								{moment(currentSituation.endDate, 'YYYYMMDD').format('DD/MMM/YYYY')}

								<span>&#058;</span>
								{currentSituation.waiverType}
							</div>

							{
								currentSituation.waiverType === 'REPLACE' ?
									<div className="covenant-waiver-in-effect-replace-covenant">
										{currentSituation.centralCovenant.name}
									</div>
									: null
							}

							{
								currentSituation.waiverType === 'ALTER' || currentSituation.waiverType === 'REPLACE' ?
									<div className="covenant-waiver-in-effect-details">
										<span className="covenant-details-range">
											{intl.formatNumber(currentSituation.range.min/100)} &#060; &#062; {intl.formatNumber(currentSituation.range.max/100)}
										</span>
										<span className="covenant-details-alert-threshold">
											{intl.formatNumber(currentSituation.alertThreshold/100)}
										</span>
									</div>
									: null
							}
						</div>
						: null
				}

				<div className="covenant-waiver-list">
					
					<WaiverList waivers={clone(waivers).sort((a, b) => a.startDate - b.startDate)} intl={intl} permissions={permissions}
											deleteWaiver={this.deleteWaiver} saveWaiver={this.saveWaiver} currentWaiver={currentSituation}/>
				</div>
			</div>
		);
	}
}

export default CovenantItem;
