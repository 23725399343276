import { gql } from '@apollo/client';
import { formConfigFields, getFieldFragment } from './form/form';

const essentials = gql`query { 
    formConfigs {
        _id
        key
        name {
          value
        }
        ${formConfigFields}
        translations {
            key
            value
        }
    }
    reportConfigs {
        _id
        key
        name {
          value
        }
        scheduleType
        contextTarget
        clientTypes
       
    }
    categorisedForms {
        clientType
        menu {
            key
            name
            forms {
                name
                key
                settings
                context {
                    target
                }

            }
        }
    }
  }  
  ${getFieldFragment(true)}
`;

export default essentials;
