import React from 'react';
import {BaseField} from '../base';
import validator from 'validator';
import {injectIntl, intlShape} from 'react-intl';


class NumericField extends BaseField {
	constructor(props) {
		super(props);
		this.setConfig(props.precision, props.format, props.allowNegative);
		this.state.modelValue =  props.value; //this.state.value;
		this.state.inputValue = props.value / 100;
		this.state.viewValue = this.convertViewValue(props.value / 100);

		this.min = props.min;
		this.max = props.max;
	}

	buildRegex() {
		let re = '[0-9]';
		if (this.allowNegative) {
			re += '|(\-)'
		}
		if (this.precision > 0) {
			re += '|(\\.)|(\,)'
		}
		re = new RegExp(re + '+', 'g');

		this.keysRe = re;
	}

	setConfig(precision = 0, format = true, allowNegative = true) {
		this.precision = precision;
		this.precisionPow = Math.pow(10, precision);
		this.format = format;
		this.allowNegative = allowNegative;
		this.buildRegex();
	}

	componentDidMount() {
		this.validate(this.state.value);
		this.setConfig(this.props.precision, this.props.format);
		this.state.viewValue = this.convertViewValue(this.state.value/100);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.value !== this.props.value) {
			this.resetState(nextProps.value);
		}

		if (nextProps.readOnly !== this.props.readOnly) {
			this.validate(this.state.value, nextProps.readOnly);
		}

		if (nextProps.precision !== this.precision || nextProps.format !== this.format) {
			this.setConfig(nextProps.precision, nextProps.format);
		}

		if(nextProps.max !== this.props.max) {
			this.max = nextProps.max;
			this.validate(this.state.value, nextProps.readOnly);
		}

		if(nextProps.min !== this.props.min) {
			this.min = nextProps.min;
			this.validate(this.state.value, nextProps.readOnly);
		}
	}

	resetState(value) {
		var inputVal = value === null ? null : value / 100;
		this.setState({
			previousValue: this.state.value,
			value: value,
			blurValue: value,
			inputValue: inputVal,
			viewValue: this.convertViewValue(inputVal)
		});
	}

	convertViewValue(rawValue) {
		let val = null;

		if (rawValue != null && rawValue != undefined) {
			val = rawValue;
			if (this.format != false) {
				val = this.props.intl.formatNumber(rawValue, {
					minimumFractionDigits: this.precision,
					maximumFractionDigits: this.precision,
					style: this.props.type === 'percent' ? 'percent' : 'decimal'

				});
			}
		}

		return val;
	}

	onDomLiveChange(event, val) {
		val = val != null ? val : event.target.value;
		//console.log('inputval', event, val);
		if (val == null || val == "") {
			this.setState({
				value: null,
				inputValue: null,
				viewValue: null
			});
		}

		let mval = '' + val;
		let numval = null;
		let point = mval.lastIndexOf(".");
		let comma = mval.lastIndexOf(",");
		if (point != comma) { // there is at least one
			if (point > comma) {
				mval = mval.replace(',', '');
				mval = mval.substring(0, point).replace('.', '') + mval.substring(point);
				val = mval;
			} else {
				mval = mval.replace('.', '');
				mval = mval.substring(0, comma).replace(',', '') + mval.substring(comma);
				val = mval;
				mval = mval.replace(',', '.');
			}
		}

		console.log('mval', mval);
		numval = mval == '' ? null : parseFloat(mval);
		var pow = Math.pow(10, this.precision);
		numval = numval != null ? (Math.round(numval * pow) / pow) * 100 : numval;
		console.log('num', numval);
		console.log('num view', this.convertViewValue(numval));
		console.log('inputval', val);
		this.setState({
			value: numval,
			inputValue: val,
			viewValue: this.convertViewValue(numval/100)
		});
	}

	onBlur() {
		this.setState({focus: false, inputValue: this.state.value/100});
		if (this.props.onBlur) this.props.onBlur(this);

		this.onChange(this.getValue());
	}

	getValue() {
		// EMPTY ==== NULL
		return this.state.value === null || (validator.isEmpty('' + this.state.value) ) ? this.props.notNullable ? 0 : null : this.state.value;
	}

	onKeyPress(e) {
		const re = /[0-9]|(\-)|(\.)|(\,)+/g;
		if (!re.test(e.key)) {
			console.log('key not allowed, ',e.key)
			//!this.keysRe.test(e.key)) {
			e.preventDefault();
		}
	}

	render() {
		const {
			postText,
			placeholder,
			precision,
			negative,
			readOnly,
			activePageIsSubmitted,
			...other
		} = this.props;

		// TODO move to base ?
		let classNames = 'form-control';
		classNames += !this.state.valid && activePageIsSubmitted ? ' invalid' : '';
		classNames += this.getValue() < 0 ? ' negative' : '';

		let fieldProps = {
			type: 'text',
			placeholder: placeholder,
			className: classNames,
			value: this.state.viewValue === null ? "" : this.state.viewValue,
			readOnly: readOnly,
			onFocus: readOnly ? null : this.onFocus.bind(this),
			onChange:()=>{}
		};

		if (this.state.focus && !this.readOnly) {
			// render input
			fieldProps = Object.assign({}, fieldProps, {
				onKeyPress: this.onKeyPress,
				value: this.state.inputValue === null ? '' : this.state.inputValue,
				...this.domBindings(this)
			});
		}

		return (
			<div className="numeric-field">
				<input {...fieldProps}/>
				{ postText && postText !== null && postText.length > 0 ?
					<span className="field-post-text">{postText}</span> : null}
			</div>
		);
	}
}


export default injectIntl(NumericField);