import './investment-grid-item.scss';
import moment from 'moment';
import React, {Component} from 'react';

class InvestmentGridItem extends Component {
	render() {
		const {
			metadata, rowData, data
		} = this.props;
		let displayText = data;

		let classNames = '';

		if (rowData.endedAt) {
			classNames += ' investment-past-date';
		}
		if (metadata.columnName === 'reportingPeriodicity' && !data) {
			classNames += ' missing-reporting-periodicity';
			if (!rowData.endedAt) {
				displayText = 'missing';
			}
		}
		if (metadata.columnName === 'covenantsNr' && !data) {
			classNames += ' missing-reporting-periodicity';
			
		}
		if (metadata.columnName === 'finalMaturityDate' && !rowData.endedAt && rowData.finalMaturityDate && moment().isAfter(moment(rowData.finalMaturityDate, 'DD/MMM/YYYY'))) {
			classNames += ' investment-should-be-finalized';
		}

		return <span className={classNames}>{displayText}</span>;
	}
}

export default InvestmentGridItem;
