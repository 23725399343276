//import 'react-select/dist/react-select.css';
import React from 'react';
import Select from 'react-select';
import { BaseField } from '../base';
import _ from 'underscore';


const options = [
	{value: 'active', label: 'ACTIVE'},
	{value: 'workout', label: 'WORKOUT'},
	{value: 'inactive', label: 'INACTIVE'}
];

class SelectClientStatus extends BaseField {
	constructor(props) {
		super(props);

		this.state = {
			value: ['active', 'workout']
		};
	}

	componentDidMount() {
		this.validate(this.getValue());
	}

	onChange(val) {
		super.onChange(val ? val["value"] ? val.value : val : null);
	}

	onComponentChange(val) {
		const value = _.pluck(val, 'value');
		const previousValue = this.state.value;
		this.setState({value});
		this.onChange(value,previousValue);
	}

	render() {
		const {
			multi,
			readOnly,
			placeholder,
			activePageIsSubmitted
		} = this.props;

		const classNames = !this.state.valid && activePageIsSubmitted ? 'invalid' : null;

		return(
			<Select name="form-field-name" className={classNames} multi={multi} disabled={readOnly} value={this.state.value} backspaceRemoves={false}
							options={options} onChange={this.onComponentChange.bind(this)} placeholder={placeholder} onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)} />
		);
	}
}

export default SelectClientStatus;