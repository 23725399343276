import { clientSearchSelectQuery } from "../../../graph/queries/clients/search";
import { GraphSearchSelect } from "../GraphSearchSelect";




const graphOptions = {
    query:clientSearchSelectQuery,
    resultField:"searchClients",
    valueField:"_id",
    labelField:"name",
    mapVariables:(value)=> ({text:value})
}


export const ClientSelect = ({...props})=> {
   
    return <GraphSearchSelect
        graphOptions={graphOptions}
        {...props}
  />
}