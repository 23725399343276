export const WIZARD_CLEAR = 'WIZARD/CLEAR';
export const WIZARD_LOAD = 'WIZARD/LOAD';
export const WIZARD_LOADING = 'WIZARD/LOADING';
export const WIZARD_LOADED = 'WIZARD/LOADED';

export const WIZARD_STEPS_SET = 'WIZARD/STEPS/SET';
export const WIZARD_STEPS_LOAD = 'WIZARD/STEPS/LOAD';
export const WIZARD_STEP_SETTINGS_CHANGE = 'WIZARD/STEPS/SETTINGS/CHANGE';
export const WIZARD_STEPS_VALIDATE = 'WIZARD/STEPS/VALIDATE';
export const WIZARD_DATA_LOAD = 'WIZARD/DATA/LOAD';
export const WIZARD_DATA_CHANGE = 'WIZARD/DATA/CHANGE';
export const WIZARD_DATA_SETVALIDATION = 'WIZARD/DATA/SET-VALIDATION';
export const WIZARD_DATA_SET_FIELD_VALIDATION = 'WIZARD/DATA/SET-FIELD-VALIDATION';
export const WIZARD_DATA_REMOVE = 'WIZARD/DATA/REMOVE';

export const WIZARD_FIELDGRID_ADD = 'WIZARD/FIELDGRID/ADD';
export const WIZARD_FIELDGRID_SAVE = 'WIZARD/FIELDGRID/SAVE';
export const WIZARD_FIELDGRID_CANCEL = 'WIZARD/FIELDGRID/CANCEL';
export const WIZARD_FIELDGRID_REMOVE = 'WIZARD/FIELDGRID/REMOVE';

/*
export const WIZARD_STEPS_SET = 'WIZARD/STEPS/SET';
export const WIZARD_FOCUS_FIELDS_SET = 'WIZARD/FOCUS/FIELDS/SET';
export const WIZARD_CLEAR_STATE = 'WIZARD/CLEAR/STATE';
export const WIZARD_SET_ELEMENT_IN_VIEW = 'WIZARD/SET_ELEMENT_IN_VIEW';
    */

export default {
     WIZARD_CLEAR,WIZARD_LOAD ,WIZARD_LOADING ,WIZARD_LOADED
     ,WIZARD_STEPS_SET,WIZARD_STEPS_LOAD,WIZARD_STEP_SETTINGS_CHANGE
     ,WIZARD_STEPS_VALIDATE,WIZARD_DATA_LOAD 
     ,WIZARD_DATA_CHANGE,WIZARD_DATA_SETVALIDATION ,WIZARD_DATA_SET_FIELD_VALIDATION
     ,WIZARD_DATA_REMOVE,WIZARD_FIELDGRID_ADD ,WIZARD_FIELDGRID_SAVE
     ,WIZARD_FIELDGRID_CANCEL,WIZARD_FIELDGRID_REMOVE 
}