import '../../../pages/login/forgotpassword/resetpassword.scss';
import React from 'react';

import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import classNames from 'classnames';
import LoginField from '../field/index';

import { graphql } from '@apollo/client/react/hoc';
import { updateUserPassword } from '../../../../graph/mutations/user';
import { connect } from 'react-redux';
import { push } from '../../../../router';
import _ from 'underscore';

function mapDispatchToProps(dispatch) {
    return {
        navigateTo: (action) => {
            dispatch(push(action));
        }
    }
}


class NewPasswordForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            passwordsMatch: true,
            valid: true,
            passwordChanged: false
        };

        this.values = {};
        this._validations = new Map();

        this.gridClassName = classNames(
            'o-gridView',
        );

        this.tableClassName = classNames(
            'o-gridView__table',
            'o-gridView__table-no-margin-top',
            'o-gridView__table--sortable',
            'o-gridView__table--paged',
        );

        this.submitForm = this.submitForm.bind(this);
        this.navigateToLogin = this.navigateToLogin.bind(this);
    }

    iterateObject(values, value, identifier) {
        let foundValue = false;
        Object.keys(values).forEach((k) => {
            if(value instanceof Array && k === identifier) {
                values[k] = _.pluck(value, 'value');
                foundValue = true;
                //return;
            } else if (values[k] !== null && typeof values[k] === 'object') {
                foundValue = !foundValue ? this.iterateObject(values[k], value, identifier) : foundValue;
                //return;
            } else if(k === identifier) {
                values[k] = value;
                foundValue = true;
            }
        });

        return foundValue;
    }

    submitForm() {
        this.setState({saving: true});

        if(this.refs.password1.getValue() === this.refs.password2.getValue()) {
            this.setState({passwordsMatch: true});
            if (this.state.valid && this.refs.password1.validate() && this.refs.password2.validate()) {
                this.values.password = this.refs.password1.getValue().trim();

                this.props.updateUserPassword({
                    variables: {
                        user: this.props.user,
                        password: this.values.password
                    }
                }).then(({data}) => {
                    if (data.updateUserPassword.success && this.refs.container) {
                        this.setState({saving: false, passwordChanged: true});
                        this.refs.container.success('Success');
                    } else if(data.updateUserPassword.error && this.refs.container) this.refs.container.error(data.updateUserPassword.error.code, data.updateUserPassword.error.message);
                }).catch((error) => {
                   console.log('query failed...');
                    if (this.refs.container) this.refs.container.error(error.message);
                });

            } else {
                this.refs.container.error('Please fill in all required fields');
            }
        } else {
            this.setState({passwordsMatch: false});
            this.refs.container.error('Passwords don\'t match');
        }
    }

    getValues() {
        return this.values;
    }

    //method hookup to all the fields --> detect validation changes (valid/invalid)
    //if invalid --> store identifier in map, if map.length > 0 => specified form is invalid
    //if a field of the communication/background form is changed, store it in a different map
    onValidationChange(field, valid, value) {
        let validationMapToUse = field.props.identifier.split('.')[0] === 'communication' ? this._communicationValidations : this._validations;

        if(validationMapToUse.has(field.props.identifier)) validationMapToUse.delete(field.props.identifier);
        if(!valid) validationMapToUse.set(field.props.identifier, valid);
        let myValid = validationMapToUse.size === 0;

        if(field.props.identifier.split('.')[0] === 'communication') this.setState({communicationValid: myValid});
        else this.setState({valid: myValid});
    }

    navigateToLogin() {
        this.props.navigateTo('/login');
    }

    renderForm() {
        let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

        return <div className="resetpassword-form register-form admin-form">
            <ToastContainer ref="container" toastMessageFactory={ToastMessageFactory} className="toast-top-right" />

            <div className="form-group">
                <label>Password</label>
                <LoginField value={''} inputType={'password'} identifier={'password1'}
                            ref="password1"
                            placeHolder="type your password here"
                            validations={
                                {
                                    required: true,
                                    length: {min: 6},
                                    strength: {min: 2}
                                }
                            }
                            passwordsMatch={this.state.passwordsMatch}
                />
            </div>
            <div className="form-group">
                <label>Re-type password</label>
                <LoginField value={''} inputType={'password'} identifier={'password2'}
                            ref="password2"
                            placeHolder="type your password again"
                            validations={
                                {
                                    required: true,
                                    length: {min: 6},
                                    strength: {min: 2}
                                }
                            }
                            passwordsMatch={this.state.passwordsMatch}
                />
            </div>

            <button className="confirm" onClick={this.submitForm}>Update your password</button>
        </div>
    }

    renderCompleted() {
        return <div className="resetpassword-form register-form admin-form">
            <p>Your password has been changed. You can now log in with your new password.</p>
            <button className="login" onClick={this.navigateToLogin.bind(this)}>Log In</button>
        </div>
    }

    render() {
        const {
            user
        } = this.props;

        if (!user.userAccountId && user.status === 'INVITED') {
            return (
                <div className="resetpassword-form register-form admin-form">
                    <p>Your account is currently inactive. Please activate your account in order to log in.</p>
                </div>
            )
        }
        return (
            (!!user) ?
                (!this.state.passwordChanged) ?
                    this.renderForm() :
                    this.renderCompleted()
                 :
                <div className="resetpassword-form register-form admin-form">
                    <p>No user found.</p>
                </div>
        )
    }
}

export default graphql(updateUserPassword, { name: 'updateUserPassword' })(
    connect(null, mapDispatchToProps)(NewPasswordForm))