import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {BaseField} from '../base';



class DateField extends BaseField {
	constructor(props) {
		super(props);
		let value = props.isNullable ? null : (props.value && props.value !== '' ? moment(props.value, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD']) : moment());
		let initValue = props.isNullable ? null : (props.initValue && props.initValue !== '' ? moment(props.initValue, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD']) : moment());

		this.state = {
			focus: false,
			valid: true,
			initValue: initValue || value,
			previousValue: value,
			value: value,
			blurValue: value
		};
		}

	componentDidMount() {
		this.handleChange(this.state.value);
	}

	componentWillReceiveProps(props) {
		////console.log('DateFieldWillReceiveProps', props);
	}

	onChange(value) {
	
		try {
		let previousValue = this.state.blurValue;
		if (value !== previousValue) {
			let valid = this.validate(value);
			this.setState({previousValue: previousValue, blurValue: value});
			if (this.props.onChange) this.props.onChange(this, value ? moment(value).format(this.props.format ? this.props.format :'DD-MM-YYYY') : null, previousValue ? moment(previousValue).format(this.props.format ? this.props.format :'DD-MM-YYYY') : null, previousValue);
		}
		} catch(e) {
			alert(e.message);
		//	alert(value);
		} 
	}

	handleChange(date) {
	
		let valid = true;
		if ((!date || date === null) && this.props.validations.required && !this.props.readOnly) valid = false;
		this.setState({value: date, invalid: !valid});
		if (this.props.onChange) this.props.onChange(this, date ? date.format(this.props.format ? this.props.format :'DD-MM-YYYY') : null, date ? date.format(this.props.format ? this.props.format :'DD-MM-YYYY') : null, date);
	}

	onDomLiveChange(date) {
		
		const value = date;
		this.setState({value});
		this.onChange(value);
	}

	render() {
		const {
			readOnly,
			required,
			minDate,
			maxDate
		} = this.props;

		const classNames = !this.state.valid ? 'invalid' : null;

		const selected = this.state.value ? moment.isMoment(this.state.value) ? this.state.value.toDate() : this.state.value : null;
		
		return (
			<div className="date-field">
				<DatePicker selected={selected} className={classNames} disabled={readOnly} required={required}
										{...this.domBindings(this)} minDate={minDate} maxDate={maxDate} />
			</div>
		);
	}
}


export default DateField;