import React from 'react';
import classNames from 'classnames';

import GridLayout from './gridLayout.jsx';
import GridItem from './gridItem.jsx';


const DefaultProps = {
};

class SmartLayout extends React.Component {
    constructor(props) {
        super(props);
    }

	shouldComponentUpdate(nextProps, nextState) {
		////console.log('SMART LAYOUT', nextProps, nextState);
		return true;
	}

    render() {
        const items = React.Children.toArray(this.props.children).filter(x => !!x);
        const itemsCount = items.length;

        if (itemsCount === 0) return false;

        const xs = 12;
        let sm = itemsCount > 1 ? 6 : xs;
        let md = itemsCount > 1 ? itemsCount > 2 ? 4 : 6 : xs;
        let lg = itemsCount > 1 ? itemsCount > 2 ? itemsCount > 3 ? 3 : 4 : 6 : xs;
        if (lg === md) lg = null;
        if (md === sm) md = null;
        if (sm === xs) sm = null;

        return (
            <GridLayout className={classNames(this.props.className)}>
                {React.Children.map(this.props.children, (item, idx) => <GridItem key={`gi-${idx}`} xs={xs} sm={sm} md={md} lg={lg}>{item}</GridItem>)}
            </GridLayout>
        );
    }
}

export default SmartLayout;
