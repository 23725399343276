
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import Panel from '../dashboard/panel/panel.jsx';
import GridView,{GridviewPaged} from '../dashboard/gridview/gridview.jsx';

import {dashboardClientsQuery} from '../../../graph/queries/incofin/dashboard';

import { GraphTableLocal } from '../table/GraphTable'
import { Table } from '../table/Table'
/**
 * DESCRIPTION: show all clients on the "/incofin/clients" route
 *   => displays only the clients for which you were granted access
 */


const DefaultProps = {
    itemsPerPage: 5
};


const gridDataMapper = (clients)=>{return clients.map((client) => {
    return {
        id: client._id,
        _id: client._id,
        importedId: client.importedId,
                        key: client.key,
        name: client.name,
        officialName:client.officialName,
                        clientType: client.clientType,
        country:client.address ? client.address.countryName : null,
        worldRegion: client.worldRegion,
                        status: client.status
    };
}); };

const columnMeta = [
    {"columnName": 'importedId', "displayName": "TM1 ID"},
    {"columnName": 'name', "displayName": <FormattedMessage id="grid.clients.columns.name"/>},
    {"columnName": 'officialName', "displayName": <FormattedMessage id="grid.clients.columns.officialName"/>},
    {"columnName": 'clientType', "displayName": <FormattedMessage id="grid.clients.columns.type"/>},
    {"columnName": 'country', "displayName": <FormattedMessage id="grid.clients.columns.country"/>},
    {"columnName": 'worldRegion', "displayName": <FormattedMessage id="grid.clients.columns.region"/>},
                {"columnName": 'status', "displayName": <FormattedMessage id="grid.reports.columns.status"/>}
];

const ClientsGridView =({
	onRowClick,
	showPager,
	enableSort,
	showFilter,
	itemsPerPage=DefaultProps.itemsPerPage,
	reports,
	onFilterChange,
	fullMode,
	showCustomFilter

})=> {

        const onRowClickCb = useCallback((row)=>{
            onRowClick(row.props.data);
        },[onRowClick]);
        

       

        return (
            <Panel title={<FormattedMessage id="grid.clients.title"/>}>
                <GraphTableLocal qry={dashboardClientsQuery} 
                    options={{
                        fetchPolicy: "no-cache",
                    }}
                    tableDataMapper={gridDataMapper}
                    dataSelector={(d)=>d && d.clients ? d.clients : []}
                    columnMetadata={columnMeta} showFilter={true} showPager={true} 
                    resultsPerPage={itemsPerPage}
                                            onRowClick={onRowClickCb} 
                    enableSort={enableSort}
                    />
               
            </Panel>
        );
    }


export default ClientsGridView;
