import { useCallback } from 'react'
import './bar.scss'
import classNames from 'classnames'


const TabButton = ({
    children,
    itemKey,
    active,
    index,
    onItemSelect
})=> {

    const onSelect = useCallback(()=> {
        onItemSelect(itemKey,index);
    },[itemKey,onItemSelect])
   
    return  <div data-node-key={itemKey} onClick={onSelect} className={ classNames("tabs-tab",{"tabs-tab-active":active})}>
    <div role="tab" aria-selected="true" className="tabs-tab-btn" tabindex={index} >{children}</div>
</div>
}

export const TabBar = ({ tabs=[],active, onChange}) => {


    const onTab = useCallback((key,idx)=> {
        if(onChange) {
            onChange({key,idx});
        }
    },[onChange])

    return <div className="inco-tabs" >
            <div role="tablist" className="tabs-nav">
                <div className="tabs-nav-wrap">
                    <div className="tabs-nav-list">
                        {
                            tabs.map((t,idx)=><TabButton key={idx} onItemSelect={onTab}  index={idx} active={active==idx || active===t.itemKey} {...t}>{t.label}</TabButton>)
                        }
                       
                        
                    </div>
                </div>
            </div>
            <div className="tab-nav-line"></div>
        </div>
}
//<div class="ink-bar ink-bar-animated" style="left: 16.7227px; transform: translateX(-50%); width: 33.4453px;"></div>