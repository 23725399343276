import { CheckCircleOutlined, CheckOutlined, EyeOutlined, LoadingOutlined, SecurityScanOutlined, SendOutlined, StopOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Alert, Button, Form, Input, Result,Space,Spin } from "antd";
import { approveMutation, rejectMutation, submitMutation } from "../../../graph/mutations/wizard";
import { useCallback, useContext, useState } from "react";
import { ReportingWizardContext } from "../hooks/context";
import { useApp } from "../../../hooks/useApp";


// TODO: add ability to leave a note
/*
 <Input.TextArea>
    </Input.TextArea>
*/

export const AuditResponse = ({onBack,onDone=()=>{}})=> {
    
    const { info } = useContext(ReportingWizardContext)
    const { message } = useApp();
    const [error,setError] = useState(null);
    const [sendApproval,{loading:approving, data:approved}] = useMutation(approveMutation, {
        variables:{
            reportId:info.id
        }
    })
    const [sendRejection,{loading:rejecting, data:rejected}] = useMutation(rejectMutation, {
        variables:{
            reportId:info.id
        }
    })

    const approve = useCallback(()=> {
        setError(null);
        sendApproval().then(async ({error})=> {
            if(error) {
                debugger;
            } else {
                message.success("Report approved.");
                onDone(true)
            }
        }).catch((e)=>{
            setError(e.message)
            debugger;
        })
    },[onDone,sendApproval])

    const reject = useCallback(()=> {
        setError(null);
        sendRejection().then(async ({error})=> {
            if(error) {
                debugger;
            } else {
                message.success("Report rejected.");
                onDone(true)
            }
        }).catch((e)=>{
            setError(e.message)
            debugger;
        })
    },[onDone,sendRejection])

    const loading = approving || rejecting;
    const data = approved || rejected;
    //<SendOutlined/>

    return <Result status={error ? "error" : approved ? "success" : undefined}
    icon={error ? undefined : <SecurityScanOutlined style={{color:rejected ? "red" : null}}/> }
    title="Ready to share your audit result!"
    extra={<>
    <div style={{marginBottom:"1em"}}>
    <Space><Button loading={loading} icon={<CheckCircleOutlined />} type="primary" onClick={approve}>Approve</Button>
        <Button loading={loading} icon={<StopOutlined />} type="primary" danger onClick={reject}>Reject</Button>
    </Space></div>
    <Button disabled={loading || !!data} icon={<EyeOutlined/>} type="dashed" onClick={onBack} >Take one more look</Button>
    
    </>
  }
  >
   {error ? <Alert  message="Failed to perform"
      description={error}
       type="error" showIcon/> : null}
  </Result>

}