import { useQuery } from "@apollo/client";
import React,{ createContext, useCallback, useContext, useMemo, useState } from "react"
import { useHistory } from "react-router-dom";
import { formConfigQuery } from "../../../graph/queries/form/form";
import { ClientContext } from "../../containers/client";
import { FormMultiView } from "../../../features/Form/FormMultiView";
import { FormDataEntry } from "../../../features/Form/FormDataEntry";
import { useApp } from "../../../hooks/useApp";
import { ReportingWizard } from "../../../features/NewWizard";

export const FormPage = ({
	match,history
}) => {


	return <><FormMultiView configKey={match.params.key}></FormMultiView>
	<FormDataEntry configKey={match.params.key}></FormDataEntry>
	</>


}


