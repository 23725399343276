import { Empty } from "antd";


export const FormTraverser = (config,{
        renderItem,
        renderGroup,
        renderGroupHeader
        
 }) => {

    const { fields=[], key:configKey } = config || {};
    
    
    let compFn = ({configKey,field,parentPath,depth=1})=> {
        let path = `${parentPath}.${field.internalName}`
        let fieldType = field?.settings?.fieldType || field?.fieldType;

        if(field.children && field.children.length>0 && field.fieldType!="COVENANT") {
            
            let items = field.children.map((fld)=>compFn({
                configKey,
                field:fld,
                parentPath:path,
                depth:depth+1
        
            }))

            let groupHeader = renderGroupHeader({
                configKey,
                field,
                path,
                fieldType,
                depth
            });


            return renderGroup({
                configKey,
                field,
                path,
                fieldType,
                depth,
                header:groupHeader,
                children:items
            });
        } else {
            let levelDepth = depth < 4 && depth > 1 ? "last" : depth;
            return renderItem({
                configKey,
                field,
                path,
                fieldType,
                depth:levelDepth
            });
        }

    }

    if(fields.length==0) {
        let name = `No data for ${config?.name?.value}`
        return <Empty description={name} />
    }

    return fields.map((fld)=>compFn({
        configKey,
        field:fld,
        parentPath:configKey

    }))
}