import { Button, Form, Modal, Result,Select,Typography } from "antd"
import { EyeOutlined, SearchOutlined, SecurityScanOutlined, SettingOutlined,WarningOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useEffect, useState } from "react"
import { ReportingWizardContext } from "../hooks/context";
import { IconReport } from "../icons/IconReport";
import currencies, { currencyMap } from "../../../data/currencies";
import { useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import { startReviewMutation } from "../../../graph/mutations/wizard/index";

const { Paragraph,Text } = Typography;



export const EntryStartAudit = ({onStart=()=>{}})=> {
  
    const {
        info,currency,scale,steps,changeSettings,client
      } = useContext(ReportingWizardContext);
  
      const [startAuditing,{loading}] = useMutation(startReviewMutation)
  
      const {
        title,clientName,contextText
      } = info;

      const onStartAudit = useCallback(()=> {
        startAuditing({
          variables:{
            reportId:info.id
          }
        }).then(({data,errors})=> {

          console.log("ERRORS",errors);

        })
    },[startAuditing,info]);

    const onRead = useCallback(()=> {

    },[])
    
    return <Result
    
    icon={<SecurityScanOutlined />}
    title={<>Start Auditing {title}<br/> <Text mark>{contextText}</Text></>}
    subTitle={<>Report submitted by <Text mark>{info?.reportedBy?.fullName}</Text> is ready to be audited {title}<br/></>}
    extra={<><Button loading={loading} type="primary" onClick={onStartAudit} >Start Auditing</Button>
    <Button type="text"  icon={<EyeOutlined/>}  onClick={onStart} >View, without audit</Button>
    </>}
  ></Result>
}