import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DashboardContainer, SmartLayout } from '../../../../components/dashboard';
import Panel from '../../../../components/dashboard/panel/panel.jsx';
import AdminCovenantsGridView from '../../../../components/grids/admin-covenants';
import _ from 'underscore';
import { TabBar } from '../../../../components/Tabs/Bar';
import { Titles } from './titles';




const CovenantsN = ({match,history})=> {
    console.log("COVENANTS LIST")
	const [archived,setArchived] = useState(false);

    const type = match.params.type;


	const onCovenantClicked = useCallback((data)=> {
       
        let url = match.url;
        if(url[url.length-1]=="/") {
            url = url.substr(0,url.length-1)
        }
		history.push(`${url}/${data._id}`);
	},[history,match]);

    const onAddCovenant = useCallback(()=> {
        let url = match.url;
        if(url[url.length-1]=="/") {
            url = url.substr(0,url.length-1)
        }
		history.push(`${url}/add`);
    },[history]);

	const onTabChange= useCallback(({key,index})=> {
        setArchived(key==="ARCHIVED");
    },[setArchived]);

    let title = <span><FormattedMessage id="grid.covenants.title" /> : {Titles[type]}</span>

	return <DashboardContainer>
	<SmartLayout>
	<Panel title={title}>
	<TabBar active={archived ? "ARCHIVED" : "OPEN"} onChange={onTabChange} tabs={[
                { label:"Active",
                itemKey:"OPEN"
                   },
                   { label:"Archived",
                   itemKey:"ARCHIVED"
                   }
            ]}>
				</TabBar>
				<div>
                {!archived ? <div style={{position:"absolute",right:'35px',height: '3em', }}>
											
                                            <button onClick={onAddCovenant}>
                                                <FormattedMessage id="app.buttons.add"/>
                                            </button>
                                    
                                </div> : null }
					<div style={{display: 'block'}}>
					<AdminCovenantsGridView itemsPerPage={15} 
                        type={type}
                        archived={archived}
						onRowClick={onCovenantClicked} />

						
						
					</div>
                    {!archived ? <div style={{height: '3em', marginTop: '2em'}}>
											
                                            <button onClick={onAddCovenant}>
                                                <FormattedMessage id="app.buttons.add"/>
                                            </button>
                                    
                                </div> : null }
                    
					
				</div>
	</Panel>
	</SmartLayout>
</DashboardContainer>

}
export default CovenantsN;