

import { gql } from '@apollo/client';

export const clientSearchSelectQuery = gql`query client($text: String) { 
    searchClients(text:$text) {
        _id
        name
        clientType
    }
  }
`;
