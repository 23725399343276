import React, { useCallback, useEffect, useRef, useState } from "react";
import { covenantConfigQuery } from "../../../../../graph/queries/admin/covenants";
import { useMutation, useQuery } from "@apollo/client";

import AdminCovenantForm from '../../../../components/AdminForms/CovenantForm/CovenantForm';
import { createCovenantConfig, deleteCovenantConfig } from "../../../../../graph/mutations/covenants";
import { ToastContainer, ToastMessageAnimated } from "react-toastr";

import { DashboardContainer, SmartLayout } from '../../../../components/dashboard';
import Panel from "../../../../components/dashboard/panel/panel";
import { FormattedMessage } from "react-intl";
import { Titles } from './titles';


const default_data ={
    name: {},
    guide: {},
    links: [],
    fieldType: null,
    alertThreshold: null,
    range: {
        min: null,
        max: null
    },
    // feature/issue-124
    clientType: null,
    formula: null
};

export const CovenantConfigAdd = ({match,history})=> {

    const toastRef= useRef();
    const type = match.params.type;

    const title = Titles[type];   
   
    const [_saveCovenant,{loading:saving}] = useMutation(createCovenantConfig,{});
   
    const [data,setData] = useState({...default_data,clientType:type.toUpperCase()});

    useEffect(()=> {
        if(data.clientType!=type) {
            setData({...data,clientType:type.toUpperCase()})
        }
    },[type]);

    const onCancel = useCallback(()=>{
        
        
        history.push(match.url.split("/").slice(0,-1).join("/"))
    },[history,match]);

    const onSave = useCallback((values,valid)=> {
        if(valid) {
            //debugger
            _saveCovenant({
                variables: {
                    covenant: {...values,clientType:type.toUpperCase()}
                }
            }).then((result)=> {
                toastRef.current.success(`Covenant '${values.name}' was saved succesfully.`);
                onCancel();
            }).catch((error)=> {
                toastRef.current.error(`Failed to save covenant: ${error.message}`);
                    
                console.error(error);
            });
        } else {
            toastRef.current.error('Invalid values, review form');
        }
    },[_saveCovenant,onCancel]);

    const onArchive = useCallback(()=> {
        
    },[])


	let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

// language={language} 

    return <DashboardContainer>
        <SmartLayout>
        
            <Panel title={<span><FormattedMessage id="grid.covenants.title"/> : {title}</span>}>
            <ToastContainer ref={toastRef} toastMessageFactory={ToastMessageFactory} className="toast-top-right" />
	
                <AdminCovenantForm saving={saving} values={data}
                 onSave={onSave}
                 clientType={type}
                 onCancel={onCancel} onDelete={onArchive} />
 
           
            </Panel>
        </SmartLayout>
    </DashboardContainer>
}