import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import Panel from '../../components/dashboard/panel/panel.jsx';
import { DashboardContainer, SmartLayout } from '../../components/dashboard/index';
import ExportReportsForm from '../../components/AdminForms/ExportReportsForm/ExportReportsForm';
import { createReportsExport } from '../../../graph/mutations/export';


class AdminExportReportsPage extends React.Component {
	constructor(props) {
		super(props);

		this.exportReportsForm = null;

		this.createReportsExport = this.createReportsExport.bind(this);
	}

	createReportsExport() {
		let values = this.exportReportsForm.getValues();

		this.props.createReportsExport({
			variables: {
				periods: values.periods,
				reportStatus: values.reportStatus,
				report: values.report,
				clients: values.clients,
				countries: values.countries,
				regions: values.regions,
				dueDateRange: values.dueDateRange,
				clientStatus: values.clientStatus,
        overdue: values.overdue, // issue-42
			}
		}).then(({data}) => {
			if(data.createReportsExport && !data.createReportsExport.error) {
				let a = document.createElement("a");
				document.body.appendChild(a);
				a.href = '/download/' + data.createReportsExport;
				a.click();
				document.body.removeChild(a);
			} else if(this.refs.container && data.createReportsExport.error) {
				this.refs.container.error(data.createReportsExport.error.message);
			}
		}).catch((error) => {
			if(this.refs.container) this.refs.container.error(error.message);
		});
	}

	//render method
	render() {
		const {
			reportConfigs,
			language,
			clients,
			client,
			regions
		} = this.props;

		
		let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

		return (
			<DashboardContainer>
				<SmartLayout>
					<Panel title={<FormattedMessage id="export-reports.title"/>}>
						<ToastContainer ref="container" toastMessageFactory={ToastMessageFactory} className="toast-top-right"/>

						<ExportReportsForm language={language} reportConfigs={reportConfigs} clients={clients} regions={regions}
															 values={{report: null, clients: null, reportStatus: null, regions:null, countries:null, clientStatus: ['active', 'workout'], overdue: null}} // issue-42
															 ref={(exportForm) => this.exportReportsForm = exportForm} client={client}/>

						<div style={{height: '3em', marginTop: '2em'}}>
							<button onClick={this.createReportsExport}>
								<FormattedMessage id="export.labels.export-to-excel" />
							</button>
						</div>
					</Panel>
				</SmartLayout>
			</DashboardContainer>
		);
	}
}

export default connect((state) => {
	return {
		clients: state.session.clients,
		client: state.client,
		regions: state.session.regions,
		reportConfigs: state.data.reportConfigs,
		language: state.session.user.language
	};
})(
graphql(createReportsExport, {name: 'createReportsExport'})(AdminExportReportsPage))