import 'react-select/dist/react-select.css';
import './login.scss';
import React from 'react';
import Select from 'react-select';
import {asOptions as options} from './languages';
import {userSettings} from '../../../localForage'

/**
 * DESCRIPTION: this language selector is used in the login page
 *   => less functionality and other css as the language selector used in header
 */

export default class LoginSelect extends React.Component {
	constructor(props) {
		super(props);



		//if user already selected a language in previous sessions (localForage), show this value in this component
		userSettings.getItem("language").then((lang) => {
			if (lang) this.setState({value: lang});
		});
	}

	//user selected a new language --> set in localForage & do a window.reload to apply changes everywhere in the application
	onChange(value,meta) {
		console.log('CHANGED',value,meta)
		if(this.props.action) {
			this.props.action(value.value);
		}
	}

	//render method
	render() {
		const {
			fieldName,
			label,
			value,
			disabled=false
		} = this.props;

		let classNames = 'field-wrapper';

	//	let optValue = value ? options.find((f)=>f.value===value) : null

		return (
			<div className={classNames}>
				<div className="field-container">
					<label htmlFor={fieldName}>{label}</label>
					<Select isDisabled={disabled} clearable={false} searchable={false} className="login-select" classNamePrefix="Select"
									onChange={this.onChange.bind(this)} options={options} value={value}/>
				</div>
			</div>
		);
	}
}
