//import 'react-select/dist/react-select.css';
import React from 'react';
import GraphSelect from './graphSelect'
import Select from 'react-select';
import { BaseField } from '../base';


class SelectField extends BaseField {
	componentDidMount() {
		this.validate(this.getValue());
	}

	/*
	onChange(val) {
		let valid = true;
		//console.log('changed');
		if(!val && this.props.required && !this.props.readOnly) valid = false;
        let changed = val != this.state.value || !valid != this.state.invalid;
        let prevValue = this.state.previousValue;
        this.setState({previousValue: this.state.value, value: val, invalid: !valid});
		if(this.props.onChange && changed) this.props.onChange(this,val,prevValue,valid);
	}
	*/

	onChange(val) {
		super.onChange(val ? val["value"] ? val.value : val : null);
	}

	onComponentChange(val) {
			const value = val;
			const previousValue = this.state.value;
			////console.log('LIVECHANGE',previousValue,value);
			this.setState({value});
			this.onChange(value,previousValue);
	}

	render() {
		const {
			multi,
			options,
			readOnly,
			placeholder,
			activePageIsSubmitted,
			query, optionMapping
		} = this.props;

		const classNames = !this.state.valid && activePageIsSubmitted ? 'invalid' : null;

		if(query) {
			return(
				<GraphSelect optionMapping={optionMapping} query={query} name="form-field-name" className={classNames} multi={multi} disabled={readOnly} value={this.state.value} backspaceRemoves={false}
									onChange={this.onComponentChange.bind(this)} placeholder={placeholder} onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)} />
			);
		}
		return(
			<Select name="form-field-name" className={classNames} multi={multi} disabled={readOnly} value={this.state.value} backspaceRemoves={false}
								options={options} onChange={this.onComponentChange.bind(this)} placeholder={placeholder} onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)} />
		);

	}
}

export default SelectField;