import { mean,sum,default as MathJs } from "mathjs";


const ignore = (a)=>a===null || a===undefined || isNaN(a);
// || a===MathJs.Infinity;


/**
 * itemExists (Math.exists)
 * @param {any[]} args Mathjs node style arguments
 * @param {object} math MathJs math object
 * @param {object} scope scope applied
 * @returns {boolean} true if scope item exists, false if it doesn't
 */
 export function itemExists(args, math, scope) {
	let path = [];
	if(args.length==0) return false;

    // to change, since new scope handles it better
    try {
		
        let val = args[0].evaluate(scope);
        return val!=null && val!=undefined; 
    } catch(e) {
        return false;
    }

}
itemExists.rawArgs = true;

//((current.agroBalanceSheet.values.liabilities.currentLiabilities.shortTermBorrowedFunds.value + current.agroBalanceSheet.values.liabilities.longTermLiabilities.longTermBorrowedFunds.value + current.agroBalanceSheet.values.liabilities.longTermLiabilities.shareholderLoans.value + current.agroBalanceSheet.values.liabilities.longTermLiabilities.subordinatedLoans.value) / meanLoose(_1M.agroProfitLoss.values.ebitda.value, _2M.agroProfitLoss.values.ebitda.value, _3M.agroProfitLoss.values.ebitda.value, _4M.agroProfitLoss.values.ebitda.value, _5M.agroProfitLoss.values.ebitda.value, _6M.agroProfitLoss.values.ebitda.value, _7M.agroProfitLoss.values.ebitda.value, _8M.agroProfitLoss.values.ebitda.value, _9M.agroProfitLoss.values.ebitda.value, _10M.agroProfitLoss.values.ebitda.value, _11M.agroProfitLoss.values.ebitda.value, _12M.agroProfitLoss.values.ebitda.value))
//
export function multiPeriod(args,math,scope) {
	//args[]	
}
multiPeriod.rawArgs=true;


export function sumLoose(...args) {
	let arr = args.filter(a=>!ignore(a));
	if(arr.length==0) return 0;
	return sum(...arr);
}


export function meanLoose(...args) {
	let arr = args.filter(a=>!ignore(a));
	if(arr.length==0) return 0;
	return mean(...arr);
}
