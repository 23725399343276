import { apolloClient, store } from '../../store';
import investmentsQuery from '../../../graph/queries/incofin/investments';
import { updateInvestmentPeriodicity, createCovenant, deleteCovenant, updateCovenant } from '../../../graph/mutations/investment.covenants';
import { createWaiver, deleteWaiver, updateWaiver } from '../../../graph/mutations/waiver';
import Meteor from '../../../Meteor';

export const INVESTMENTS_LOADING = 'INVESTMENTS/LOADING';
export const INVESTMENTS_LOADED = 'INVESTMENTS/LOADED';
export const INVESTMENTS_LOAD_ERROR = 'INVESTMENTS/ERROR';
export const INVESTMENTS_CLEAR_ERROR = 'INVESTMENTS/ERROR/CLEAR';
export const INVESTMENTS_PERIODICITY_UPDATE = 'INVESTMENTS/PERIODICITY/UPDATE';
export const INVESTMENTS_MUTATION_ERROR = 'INVESTMENTS/MUTATION/ERROR';
export const INVESTMENTS_COVENANTS_ADD = 'INVESTMENTS/COVENANTS/ADD';
export const INVESTMENTS_COVENANTS_DELETE = 'INVESTMENTS/COVENANTS/DELETE';
export const INVESTMENTS_COVENANTS_UPDATE = 'INVESTMENTS/COVENANTS/UPDATE';
export const INVESTMENTS_COVENANTS_WAIVERS_ADD = 'INVESTMENTS/COVENANTS/WAIVERS/ADD';
export const INVESTMENTS_COVENANTS_WAIVERS_DELETE = 'INVESTMENTS/COVENANTS/WAIVERS/DELETE';
export const INVESTMENTS_COVENANTS_WAIVERS_UPDATE = 'INVESTMENTS/COVENANTS/WAIVERS/UPDATE';

function clearError(dispatch) {
	dispatch({
		type: INVESTMENTS_CLEAR_ERROR
	});
}

export function loadInvestments() {
	return function (dispatch) {
		dispatch({
			type: INVESTMENTS_LOADING
		});

		const state = store.getState();

		apolloClient.query({
			query: investmentsQuery,
			variables: {
				clientId: state.client._id,
				language: state.session.language
			},
			forceFetch:true
		}).then((result) => {
			if(result && result.data && result.data.investments) {
				dispatch({
					type: INVESTMENTS_LOADED,
					investments: result.data.investments
				});
			} else {
				dispatch({
					type: INVESTMENTS_LOAD_ERROR,
					payload: "No investments"
				});
			}
		}).catch((error)=> {
			// handle error
			dispatch({
				type: INVESTMENTS_LOAD_ERROR,
				payload: error.message
			});
		});
	};
}

export function changeInvestmentPeriodicity(investmentId, periodicity) {
	return function(dispatch) {
		clearError(dispatch);

		apolloClient.mutate({
			mutation: updateInvestmentPeriodicity,
			variables: {
				investmentId,
				periodicity
			}
		}).then((result) => {
			let response = result.data.updateInvestmentPeriodicity;
			if(response && response.success) {
				dispatch({
					type: INVESTMENTS_PERIODICITY_UPDATE,
					investmentId: investmentId,
					reportingPeriodicity: periodicity
				});
			} else if(response && response.error) {
				dispatch({
					type: INVESTMENTS_MUTATION_ERROR,
					payload: response.error.message
				});
			}
		}).catch((error) => {
			// handle error
			dispatch({
				type: INVESTMENTS_LOAD_ERROR,
				payload: error.message
			});
		});
	}
}

export function addCovenant(investmentId, covenantId) {
	return function(dispatch) {
		clearError(dispatch);

		apolloClient.mutate({
			mutation: createCovenant,
			variables: {
				investmentId,
				covenantId
			}
		}).then((result) => {
			let response = result.data.createCovenant.response;
			if(response && response.success) {
				dispatch({
					type: INVESTMENTS_COVENANTS_ADD,
					investmentId: investmentId,
					covenants: result.data.createCovenant.covenants
				});
			} else if(response && response.error) {
				dispatch({
					type: INVESTMENTS_MUTATION_ERROR,
					payload: response.error.message
				});
			}
		}).catch((error) => {
			// handle error
			dispatch({
				type: INVESTMENTS_LOAD_ERROR,
				payload: error.message
			});
		});
	}
}

export function removeCovenant(investmentId, investmentCovenantId, centralCovenantId) {
	return function(dispatch) {
		clearError(dispatch);

		apolloClient.mutate({
			mutation: deleteCovenant,
			variables: {
				investmentId,
				covenantId: investmentCovenantId,
				centralCovenantId
			}
		}).then((result) => {
			let response = result.data.deleteCovenant;
			if(response && response.success) {
				dispatch({
					type: INVESTMENTS_COVENANTS_DELETE,
					investmentId: investmentId,
					covenantId: investmentCovenantId
				});
			} else if(response && response.error) {
				dispatch({
					type: INVESTMENTS_MUTATION_ERROR,
					payload: response.error.message
				});
			}
		}).catch((error) => {
			// handle error
			dispatch({
				type: INVESTMENTS_LOAD_ERROR,
				payload: error.message
			});
		});
	}
}


export function saveCovenant(investmentId, covenant) {
	return function(dispatch) {
		clearError(dispatch);

		apolloClient.mutate({
			mutation: updateCovenant,
			variables: {
				investmentId,
				covenant,
				covenantId: covenant._id
			}
		}).then((result) => {
			let response = result.data.updateCovenant;
			if(response && response.success) {
				dispatch({
					type: INVESTMENTS_COVENANTS_UPDATE,
					investmentId: investmentId,
					covenant
				});
			} else if(response && response.error) {
				dispatch({
					type: INVESTMENTS_MUTATION_ERROR,
					payload: response.error.message
				});
			}
		}).catch((error) => {
			// handle error
			dispatch({
				type: INVESTMENTS_LOAD_ERROR,
				payload: error.message
			});
		});
	}
}


export function addWaiver(investmentId, covenantId, waiver) {
	return function(dispatch) {
		clearError(dispatch);

		apolloClient.mutate({
			mutation: createWaiver,
			variables: {
				investmentId,
				covenantId,
				waiver
			}
		}).then((result) => {
			let response = result.data.createWaiver.response;
			if(response && response.success) {
				dispatch({
					type: INVESTMENTS_COVENANTS_WAIVERS_ADD,
					investmentId,
					covenantId,
					waivers: result.data.createWaiver.waivers
				});
			} else if(response && response.error) {
				dispatch({
					type: INVESTMENTS_MUTATION_ERROR,
					payload: response.error.message
				});
			}
		}).catch((error) => {
			// handle error
			dispatch({
				type: INVESTMENTS_LOAD_ERROR,
				payload: error.message
			});
		});
	}
}

export function removeWaiver(investmentId, covenantId, waiverId) {
	return function(dispatch) {
		clearError(dispatch);

		apolloClient.mutate({
			mutation: deleteWaiver,
			variables: {
				investmentId,
				covenantId,
				waiverId
			}
		}).then((result) => {
			let response = result.data.deleteWaiver;
			if(response && response.success) {
				dispatch({
					type: INVESTMENTS_COVENANTS_WAIVERS_DELETE,
					investmentId,
					covenantId,
					waiverId
				});
			} else if(response && response.error) {
				dispatch({
					type: INVESTMENTS_MUTATION_ERROR,
					payload: response.error.message
				});
			}
		}).catch((error) => {
			// handle error
			dispatch({
				type: INVESTMENTS_LOAD_ERROR,
				payload: error.message
			});
		});
	}
}

export function saveWaiver(investmentId, covenantId, waiver) {
	return function(dispatch) {
		clearError(dispatch);

		apolloClient.mutate({
			mutation: updateWaiver,
			variables: {
				investmentId,
				covenantId,
				waiver,
				waiverId: waiver._id
			}
		}).then((result) => {
			let response = result.data.updateWaiver.response;
			if(response && response.success) {
				dispatch({
					type: INVESTMENTS_COVENANTS_WAIVERS_UPDATE,
					investmentId,
					covenantId,
					waiver,
					waiverCovenant: waiver.waiverType === 'REPLACE' ? result.data.updateWaiver.waiver.centralCovenant : null
				});
			} else if(response && response.error) {
				dispatch({
					type: INVESTMENTS_MUTATION_ERROR,
					payload: response.error.message
				});
			}
		}).catch((error) => {
			// handle error
			dispatch({
				type: INVESTMENTS_LOAD_ERROR,
				payload: error.message
			});
		});
	}
}
