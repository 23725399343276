import { gql, useApolloClient } from "@apollo/client";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useConfigCache } from "../../../hooks/useApp";
import { useCurrency } from "../../../hooks/useCurrency";
import dayjs from "dayjs";
import { ClientContext } from "../../../ui/containers/client";
import { FormulaScope } from "../scope/FormulaScope";
import { FormulaContext } from "./formulaContext";
import moment from "moment";

const qry = gql`query fieldScope($clientId: ID!,$numeric:Int!,$fields:[String],$currency:String) {
    fieldScope(clientId: $clientId, numeric:$numeric,fields:$fields,currency:$currency)
}
`

function destructTargetString(str) {
    let parts = str.split("|")
    let result =  {
       
        config:parts[0],
        path:parts[2] || parts[1] || null
    }
    result.definitionId=`${result.config}|${result.path || ""}`;
    return result;
}




export const FormulaViewerProvider = ({children})=> {
    const client = useContext(ClientContext);
    const apolloClient = useApolloClient();
    const configCache = useConfigCache();
    
    const currency = useCurrency();
    const [loading,setLoading] = useState(false);

    const [settings,setSettings] = useState(null);
    const currentId = useRef(null);
    const currentShowing = useRef(null);
  
    const show = useCallback(({
        id,
        formula,
        target,
        scope,
        numeric,
        includeResults=true
    })=> {


        if(!formula || currentId.current==id) {
            setSettings(null);
            currentId.current=null;
            currentShowing=null;
            return;
        }
        currentShowing.current={
            id,
            formula,
            target,
            scope,
            numeric,
            includeResults
        };
        let newSettings = {
            id,
            error:null,
            numeric,
            contextDate:numeric ? dayjs(numeric.toString(),"YYYYMMDD") : null,
            formula,
            includeResults,
            target,
            config:null,
            fields:new Map(),
            scope
        }
    
        let fld = null;
        if( newSettings.target) {
            if(typeof  newSettings.target ==="string") {
                newSettings.target = destructTargetString(target);
                // "{configKey}|{path}"
            }
            if(!newSettings.target.definitionId) {
                newSettings.target.definitionId=`${newSettings.target.config}|${newSettings.target.path || ""}`;
            }
            newSettings.target.dataId = `${newSettings.target.config}|current|${newSettings.target.path || ""}`
            fld = configCache.getFieldDefinition(newSettings.target.config,newSettings.target.path);
            if(fld) {
                newSettings.fields.set(newSettings.target.definitionId,fld);
            }
        }
        if(formula?.dependencies) {
            newSettings.fields = formula?.dependencies.reduce((m,d)=>{
                let destructed = destructTargetString(d);
                let t = configCache.getFieldDefinition(destructed.config,destructed.path);
                if(t) m.set(destructed.definitionId,t);
                return m;
            },newSettings.fields);
            
        }

        let curMom = newSettings?.numeric ? ` - ${moment(newSettings.numeric.toString()).format("MMM YYYY")}` : "";
        let title = fld?.name?.value;
        if(title && curMom) {
            title = `${title} (${curMom})`
        }
        newSettings.title=title;
        // resolve target field
        // resolve dependencies config
        
        if(client && !scope && includeResults && numeric) {
            setLoading(true);
           // console.log("GET FIELDS ",currency)
            apolloClient.query({
                query:qry,
               
                variables: {
                    clientId:client._id,
                    numeric,
                    currency,
                    fields:[newSettings.target.dataId ,...formula.dependencies]
                }
            }).then(({data:result,error})=> {
                newSettings.scope = new FormulaScope(numeric,result?.fieldScope,1)
            }).catch((e)=> {
                setSettings(null);
                newSettings.error=e;

            }).finally(()=> {
                setLoading(false)
            });

        } 
        if(!newSettings.scope) {
            newSettings.includeResults=false;
        }
        setSettings(newSettings);
        currentId.current=id;
    },[client,apolloClient,currency]);

    const value = useMemo(()=>{
        return {
            show,
            loading,
            settings,
            id:currentId.current,
            clear:()=>{
                setSettings(null);
                currentId.current=null;
                currentShowing.current=null;
            }
        }
    },[show,loading,settings,currentId,currency])

    useEffect(()=> {
        if(currentShowing.current) {
            currentId.current=null;
            show(currentShowing.current)
        }
    },[currency])
    
    return <FormulaContext.Provider value={value}>{children}</FormulaContext.Provider>

}

export const useFormulaViewer = ()=> {
    const viewer = useContext(FormulaContext);
    return viewer;
}

export const useFormulaViewerShow = ()=> {

    const {id } = useContext(FormulaContext);
    return id;
}

export const useFormulaViewerAction = ()=> {

    const {show } = useContext(FormulaContext);
    return show;
}
