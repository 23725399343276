import {
	FORM_CHANGE_VALUE,
	FORM_IS_VALID,
	FORM_VALIDATION_TREE,
	FORM_SET_VALUES,
	FORM_CLEAR_STATE
} from './form.actions.js';

const initialState = {

};

/*function deepFind(obj, data) {
	let current = obj, i;

	for (i = 0; i < data.path.length; ++i) {
		if (current[data.path[i]] == undefined) {
			return undefined;
		} else {
			current = current[data.path[i]];
		}
	}
	return current;
}


function adjustValueInTree(values, data) {
	let result = deepFind(values, data);
	if(result) result.value = data.value;

	return values;
}

function adjustValidationTree(tree, data) {
	var newValue = true;
	tree = tree.map((treeItem) => {
		if(Object.keys(treeItem).indexOf(data.internalName) > -1) {
			newValue = false;
			treeItem[data.internalName] = data.invalid;
		}

		return treeItem;
	});

	if(newValue) tree.push({[data.internalName]: data.invalid});
	return tree;
}*/

export const formReducer = (state = initialState, action) => {
	switch(action.type) {
		/*case FORM_SET_VALUES:
			let ste =  Object.assign({}, state);
			ste[action.key] = {
				valueTree: JSON.parse(JSON.stringify(action.values)),
				validationTree: [],
				formIsValid: false
			};
			return ste;
		case FORM_CHANGE_VALUE:
			let steChangeValueTree = Object.assign({}, state);
			steChangeValueTree[action.payload.key].valueTree = adjustValueInTree(state[action.payload.key].valueTree, action.payload);
			return steChangeValueTree;
		case FORM_VALIDATION_TREE:
			let steChangeValidationTree = Object.assign({}, state);
			steChangeValidationTree[action.payload.key].validationTree = adjustValidationTree(state[action.payload.key].validationTree, action.payload);
			return steChangeValidationTree;
		case FORM_IS_VALID:
			let formIsValid = true;

			state[action.key].validationTree.forEach((treeItem) => {
				for(let prop in treeItem) {
					if(treeItem[prop]) formIsValid = false;
				}
				if(!formIsValid) return;
			});

			let steIsValid = Object.assign({}, state);
			steIsValid[action.key].formIsValid = formIsValid;
			return steIsValid;*/
		case FORM_CLEAR_STATE:
			state = {};
			return state;
		default:
			return state;
	}
};