import { gql } from '@apollo/client';

const dashBoardQuery = gql`query dashboard($clientId: String!, $language: String, $currency: String) { 
    
    clientDashCharts(clientId: $clientId,language:$language,currency:$currency) {
        id
        title
        series
        legend
    } 
    clientSummaries(clientId: $clientId,language:$language,currency:$currency) {
        id
        icon
        title
        subtitle
        value
        valueIcon
        type
    }
  }
`;

export default dashBoardQuery;