import { gql } from '@apollo/client';

export const createClient = gql`mutation createClient($client: Object!) {
	createClient(client: $client) {
		success
		error {
			code
			message
		}
	}
}
`;

export const updateClient = gql`mutation updateClient($clientId: String!, $client: Object!) {
	updateClient(clientId: $clientId, client: $client) {
		success
		error {
			code
			message
		}
	}
}
`;

export const updateFinancialPeriods = gql`mutation updateFinancialPeriods($clientId: String!, $financialPeriods: [InputFinancialPeriod]!) {
	updateFinancialPeriods(clientId: $clientId, financialPeriods: $financialPeriods) {
		financialYear
		financialYearId
		start
		end
		months
	}
}
`;


export const removeClient = gql`mutation removeClient($clientId: String!, $clientName: String!) {
	removeClient(clientId: $clientId, clientName: $clientName) {
		success
		error {
			code
			message
		}
	}
}
`;