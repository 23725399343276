//import { syncHistoryWithStore, routerReducer } from 'react-router-redux';
import { sessionReducer } from './session';
import { clientReducer } from './client';
import { dataReducer } from './data';
import { navigationReducer } from './navigation';
import { formReducer } from '../../features/FormGenerator/redux/form.reducer';
import { formConfigReducer } from './formConfig';
import { covenantReducer } from './covenants';
import { investmentReducer } from './investments';
import { reportReducer } from './reports';
import { wizardReducer, wizardStepsReducer,wizardDataReducer, wizardNotesReducer } from '../../features/ReportingWizard/redux/wizard.reducer';
import {reducer as toastrReducer} from 'react-redux-toastr';
import { reducer as tooltipReducer } from 'redux-tooltip';


//export const routing = routerReducer;
export const session = sessionReducer;
export const client = clientReducer;
export const data = dataReducer;
export const navigation = navigationReducer;
export const form = formReducer;
export const formConfig = formConfigReducer;
export const wizard = wizardReducer;
export const wizardSteps = wizardStepsReducer;
export const wizardData = wizardDataReducer;
export const toastr = toastrReducer;
export const tooltip = tooltipReducer;
export const covenants = covenantReducer;
export const investments = investmentReducer;
export const reports = reportReducer;
