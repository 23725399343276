import './chart.scss';
import React from 'react';
import { ResponsiveContainer, Bar, XAxis, YAxis, Tooltip, Legend, ComposedChart, Line } from 'recharts';
import CustomTick from './custom/tick';
import CustomLabel from './custom/label';
import _ from 'underscore';

export default class Chart extends React.Component {
	constructor(props) {
		super(props);

		this.formatTooltipValues = this.formatTooltipValues.bind(this);
	}

	formatTooltipValues(value) {
		return this.props.intl.formatNumber(value, {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
	}

	_calculateLabel(type) {
		switch (type) {
			case 'percentual':
				return '%';
				break;
			case 'numeric':
				return '#';
				break;
			case 'currency':
				return this.props.currency;
				break;
		}
	}

	//render method using recharts (values & additional data will be passed through props)
	render() {
		const {
			series,
			legend,
			colors
		} = this.props;

		let BarLines = legend && legend.length > 0 && Array.isArray(legend) ?
			legend.map((legendItem, idx) => {
				switch(legendItem.chartType) {
					case 'bar':
						return (
							<Bar key={`${legendItem.label}-${idx}`} stackId="a" isAnimationActive={false}
									 dataKey={legendItem.label} fill={colors[idx]} yAxisId={'bar'} />
						);
						break;
					case 'line':
						return (
							<Line key={`${legendItem}-${idx}`} type="linear" isAnimationActive={false}
										dataKey={legendItem.label} dot={{strokeWidth: 4 }} yAxisId={'line'} />
						);
						break;
				}
			}) : null;

		let barLabel = this._calculateLabel(_.findWhere(legend, {chartType: 'bar'}).typeOfValues);
		let lineLabel = this._calculateLabel(_.findWhere(legend, {chartType: 'line'}).typeOfValues);

		return (
			<div className="chart-container">
				<ResponsiveContainer width="100%" height="100%">
					<ComposedChart data={series}>
						<XAxis dataKey="name" type="category"/>
						<YAxis yAxisId="bar" orientation={'left'} tick={<CustomTick />} label={<CustomLabel label={barLabel} />} />
						<YAxis yAxisId="line" orientation={'right'} tick={<CustomTick />} label={<CustomLabel label={lineLabel} />} />

						<Tooltip animationDuration={200} formatter={this.formatTooltipValues} />
						<Legend />

						{ BarLines }
					</ComposedChart>
				</ResponsiveContainer>
			</div>
		);
	}
}