import { useContext } from "react";
import { useApp } from "../../../hooks/useApp";
import { EntryStartSubmission } from "./startSubmission";
import { ReportingWizardContext } from "../hooks/context";
import { EntryStartAudit } from "./startAudit";




export const ReportingWizardEntry = ({onStart, onPreview})=> {

    const { user } = useApp();
    const { status,reportedBy,auditedBy } = useContext(ReportingWizardContext)

    if(status==="in_progress" && user?._id ===reportedBy?._id) {
        onStart();
    }
    //onPreview

    if(user.isIncofin) {
        switch(status) {
            case "created":
            case "overdue":
            case "rejected":
                return <EntryStartSubmission onStart={onStart}/>
                
         /*   case "in_progress":
                return <EntrySubmissionProgress onStart={onStart}/>
            case "rejected":
                return <EntrySubmissionProgress onStart={onStart}/>
    */
            case "submitted": {
                return <EntryStartAudit onStart={onStart} />
            }
            case "in_review": {
               // return <EntryInReviewOther />
            }
        }
    } else {
        switch(status) {
            case "created":
            case "overdue":
            case "rejected":
                return <EntryStartSubmission onStart={onStart}/>
    
        }
    }
    

    return <span>--nothing- {status} </span>

}