import { gql } from '@apollo/client';

export const covenantConfigBody = `
_id
name
guide
links {
    uri
    name
    guide 
}
fieldType
range {
    min
    max
}
alertThreshold
internalName
archived
clientType
formula

`

export const covenantQuery = gql`query covenantQuery($id: String, $language: String) {
    covenant(id: $id) {
        _id
        name
        guide
        links {
        	uri
        	name
        	guide 
        }
        fieldType
        range {
        	min
        	max
        }
        alertThreshold
        internalName
        archived
        clientType
        formula
    }
}
`;

export const covenantsQuery = gql`query covenantsQuery($language: String) {
    covenants(language: $language) {
        _id
        name
        guide
        links {
        	uri
        	name
        	guide 
        }
        fieldType
        range {
        	min
        	max
        }
        alertThreshold
        internalName
        archived
        clientType
        formula
    }
}
`;

export const covenantConfigQuery = gql`query covenantConfigQuery($id: String) {
    covenantConfig(id: $id) {
        ${covenantConfigBody}
    }
}
`;

export const covenantsConfigQuery = gql`query covenantsConfigQuery($skip:Int, $limit:Int, $language: String, $filter: String,$clientType:String, $archived:Boolean, $sorting:[InputSorting]) {
    covenantsConfig(skip:$skip, limit:$limit, language: $language, filter: $filter,clientType:$clientType,archived:$archived,sorting:$sorting) {
        results {
            _id
            name
            guide 
            links {
                uri
                name
                guide 
            }
            fieldType
            range {
                min
                max
            }
            alertThreshold
            internalName
            statistics {
                count
            }
            archived
            clientType
            formula
        }
        pageInfo {
			total
			limit
			offset
		  }
    }
  	
}
`;

export const covenantListQuery = gql`query covenantListQuery($filter: String, $clientId: String, $language: String, $limit: Int) {
    covenantList(filter: $filter, clientId: $clientId, language: $language, limit: $limit) {
        value
        label
    }
}`;
