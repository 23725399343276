import './fields.scss';
import React, { useCallback } from 'react';
import validator from 'validator';


function chooseIf(...args) {

    for(let i=0;i<args.length;i++) {
        if(typeof(args[i]) != 'undefined' && args[i]!=null) {
            return args[i];
        }
    }
    return null;
}

const useFieldValidator = (validations,readOnly,type,allowNegative)=> {

   // const [validations,setValidations] = useS
    const runValidation = useCallback((refValue, readonly)=> {
        let valid = true;
        if(validations && !readOnly) {
            let vlds = validations;

            if(vlds.required) {
                if(refValue === null || validator.isEmpty('' + refValue)) {
                    valid = false;
                }
            }

            if(vlds.length) {
                if(refValue === null || !validator.isLength(refValue.toString(), vlds.length)) valid = false;
            }

            if(vlds.format && type && !vlds.format.multiLine) {
                switch(type) {
                    case 'text':
                        if(vlds.format.pattern) {
                            if(!validator.matches(refValue, vlds.format.pattern)) valid = false;
                        }
                        break;
                    case 'email':
                        if(!validator.isEmail(refValue)) valid = false;
                        break;
                    case 'tel':
                        if(!validator.matches(refValue, /([0|\+[0-9]{1,5})?([0-9]{10})/)) valid = false;
                        break;
                    case 'number':
                        if(!validator.isDecimal(refValue) && !validator.isNumeric(refValue)) valid = false;
                        break;
                    case 'date':
                        if(!validator.isDate(refValue)) valid = false;
                        break;
                    case 'url':
                        if(!validator.isURL(refValue)) valid = false;
                        break;
                }
            }

            if(type) {
                switch(type.toLowerCase()) {
                    case 'number':
                        break;
                    case 'date':
                        break;
                    case 'text':
                        break;

                }
            }
        }
    },[]);
}



// handle form reset at form level
// though field must know when to reset its state
export class BaseField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            focus: false,
            valid: true,
            initValue: chooseIf(this.props.initValue,(this.props.value)),
            previousValue: chooseIf(this.props.value),
            value: chooseIf(this.props.value,''),
            blurValue: chooseIf(this.props.value)
        };
        this.refMyfield = React.createRef();

    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.value !== this.props.value) {
            this.resetState(nextProps.value);
        }

        if(nextProps.readOnly !== this.props.readOnly) {
 					this.validate(this.state.value, nextProps.readOnly);
				}
    }

    resetState(value) {
        this.setState({
            previousValue: this.state.value,
            value: value,
            blurValue: value
        });
    }

    validate(value, readOnly) {
        const valid = this.runValidation(value, readOnly);

        if(this.state.valid !== valid) {
            this.setState({valid: valid});
            this.onValidationChange(valid);
        }
        return valid;
    }

    runValidation(refValue, readonly) {
        let valid = true;

        let {
            validations,
            readOnly,
            type,
						allowNegative
        } = this.props;

			  readOnly = readonly !== undefined && readonly !== null ? readonly : readOnly;

			  if(typeof allowNegative !== "undefined" && !allowNegative && refValue < 0) valid = false;
			  if(this.min && refValue < this.min) valid = false;
			  if(this.max && refValue > this.max) valid = false;

        if(validations && !readOnly) {
            let vlds = validations;

            if(vlds.required) {
                if(refValue === null || validator.isEmpty('' + refValue)) {
                    valid = false;
                }
            }

            if(vlds.length) {
                if(refValue === null || !validator.isLength(refValue.toString(), vlds.length)) valid = false;
            }

            if(vlds.format && type && !vlds.format.multiLine) {
                switch(type) {
                    case 'text':
                        if(vlds.format.pattern) {
                            if(!validator.matches(refValue, vlds.format.pattern)) valid = false;
                        }
                        break;
                    case 'email':
                        if(!validator.isEmail(refValue)) valid = false;
                        break;
                    case 'tel':
                        if(!validator.matches(refValue, /([0|\+[0-9]{1,5})?([0-9]{10})/)) valid = false;
                        break;
                    case 'number':
                        if(!validator.isDecimal(refValue) && !validator.isNumeric(refValue)) valid = false;
                        break;
                    case 'date':
                        if(!validator.isDate(refValue)) valid = false;
                        break;
                    case 'url':
                        if(!validator.isURL(refValue)) valid = false;
                        break;
                }
            }

            if(type) {
                switch(type.toLowerCase()) {
                    case 'number':
                        break;
                    case 'date':
                        break;
                    case 'text':
                        break;

                }
            }
        }

        return valid;
    }

    onFocus(event) {
        this.setState({focus: true});
        if(this.props.onFocus) this.props.onFocus(this);
        setTimeout(()=>{if(this.refMyfield.current && this.refMyfield.current.select) this.refMyfield.current.select();},100);
    }

    onBlur() {
        this.setState({focus: false});
        if(this.props.onBlur) this.props.onBlur(this);

        this.onChange(this.getValue());
    }

    onDomLiveChange(event,val) {
        const value = chooseIf(val,event.target.value);
        const previousValue = this.state.value;
        this.setState({value});
        this.onLiveChange(value, previousValue);
    }

    onChange(value) {
        let previousValue = this.state.blurValue;
        if(value !== previousValue) {
            let valid = this.validate(value);

            this.setState({previousValue: previousValue, blurValue: value, valid: valid});
            if (this.props.onChange) {
                this.props.onChange(this, value, previousValue, valid);
            }
        }
    }

    onLiveChange(value,previousValue) {
        if(value!== previousValue) {
            if (this.props.onLiveChange) this.props.onLiveChange(this, value, previousValue);
        }
    }

    onClick() {
        if(this.props.onClick) this.props.onClick(this);
    }

    // better to use valid instead of invalid ?
    onValidationChange(valid) {
        if(this.props.onValidationChange) this.props.onValidationChange(this, valid, this.getValue());
    }

    getValue() {
        // EMPTY ==== NULL
        return this.state.value == null || (validator.isEmpty(''+this.state.value) )? null : this.state.value;
    }

    domBindings(instance) {
			let standardBindings = {
				onBlur: instance.onBlur.bind(instance),
				onFocus: instance.onFocus.bind(instance),
				onClick: instance.onClick.bind(instance),
				onChange: instance.onDomLiveChange.bind(instance)
			};

			let tooltipBindings = {
				'data-tip': instance.props['data-tip'],
				'data-for': instance.props['data-for'],
				'data-event': 'focus',
				'data-event-off': 'blur',
				'data-place': 'right',
				'data-effect': 'solid'
			};

			return {
			    ref:this.refMyfield,
				...standardBindings,
				...tooltipBindings
			};
		}
}