import React, { useCallback } from 'react';
import {connect} from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import {bindActionCreators} from 'redux';

import Loader from '../../components/loader/spinner';
import ReportsGridView from '../../components/grids/reports/incofin.jsx';
import ClientsGridView from '../../components/grids/clients';
import {DashboardContainer, SmartLayout} from '../../components/dashboard';

import dashBoardQuery from '../../../graph/queries/incofin/dashboard';
import { useHistory } from 'react-router-dom';

/**
 * DESCRIPTION: incofin dasbhoard overview of all report instances and an overview of all the clients under the user his/her supervision
 * ROUTE(S):
 *   => /incofin
 *   => /incofin/dashboard
 * ACCESSIBLE BY:  Incofin-RD, Incofin-IM, Incofin-admin & admin
 * DATA: dashboardQuery (other query than the client dashboard query)
 *          => all report instances
 *          => clients
 */


function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({}, dispatch),
		};
}


const Dashboard = ({data})=> {

	const history = useHistory();

	const onClientClick = useCallback((client)=> {
		history.push(`/client/${client.key}/`)
	},[history])
	const onReportClick = useCallback((report)=> {
		history.push(`/client/${report.clientKey}/reports/${report.key}/${report.period}`)
	},[history])

	

	return (
		<DashboardContainer className="c-dashboard--incofin">
			<SmartLayout>
				<ReportsGridView onRowClick={onReportClick} showCustomFilter={false}
												 fullMode={false} showFilter={false} enableSort={false} showPager={false} />
			</SmartLayout>
			<SmartLayout>
				<ClientsGridView onRowClick={onClientClick}/>
			</SmartLayout>
		</DashboardContainer>
	);
}


export default connect(null, mapDispatchToProps)(Dashboard)