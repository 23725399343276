import React,{ Suspense,useEffect, useMemo, useState }  from 'react'
import logo from './logo.svg';
import './App.css';
import {  useLazyQuery, useQuery } from '@apollo/client';
import {authCheckQuery} from './graph/queries/profileQuery'
import { Router,Route,Switch,Redirect,useLocation, useHistory } from 'react-router-dom';
import essentialsQuery from './graph/queries/essentials';

import {ClientRoute} from './ui/pages/clients';
import {IncofinRoute} from './ui/pages/incofin';
import LoginPage from './ui/pages/login/index.jsx';
import ForgotPasswordPage from './ui/pages/login/forgotpassword/index'
import RegistrationPage from './ui/pages/registration/index'

import { connect, useDispatch,useSelector } from 'react-redux';

import {setLogin} from './redux/actions/session';
import { PrivateRoute,useAuth } from './auth/PrivateRoutes'
import { NotFound } from './ui/components/400/NotFound';
import Loader from './ui/components/loader/spinner';
import { App, ConfigProvider, Modal, Spin, message, notification } from 'antd';
import { AntdTheme } from './antdTheme';
import { AppContext } from './hooks/AppContext';
import uniqolor from 'uniqolor';


class DebugRouter extends Router {
  constructor(props){
    super(props);
    console.log('initial history is: ', JSON.stringify(props.history, null,2))
    props.history.listen((location, action)=>{
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      )
      console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
    });
  }
}

function mapDispatchToProps(dispatch) {
	return {
		login:(userId)=>{
			dispatch(setLogin(userId));
		}
	};
}
function mapStateToProps(state) {
	return {
		user: state.session.user
	};
}

const AuthWrap = ({children,login,user})=> {
  const [checkLogin,{ called,loading, error, data }]= useLazyQuery(authCheckQuery);
  const history = useHistory();

 

  useEffect(()=> {
    console.log('AUTHWRAP STATE', user,loading,data)
    if(!loading && data && data.whoAmI && data.whoAmI._id) {
      console.log('AUTHENTICATED', data);
      login(data.whoAmI._id)
    }
    if(!loading && !user && error ) {
      console.log('AUTHWRAP STATE - error',error);
      history.replace("/login");
    }
    if(!loading && !user && data && data.whoAmI===null) {
      console.log('AUTHWRAP STATE - login required');
      history.replace("/login");
    }
    if(!loading && !data && !error && !user && !called) {
      console.log('AUTHWRAP STATE',"checking login");
      checkLogin();
    }
   
  },[loading,data,error,user,called])

  

  if (loading || !user) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  //return <div>ok</div>

  if (user) {
    return  <Switch>
  
      <Route path="/incofin" component={IncofinRoute}/>
      <Route path="/client/:clientId/" component={ClientRoute}></Route>
    
    </Switch>;
    }
}

const ConnectedAuthWrap = connect(mapStateToProps,mapDispatchToProps)(AuthWrap)

function AppPublic() {
  

  return   <Switch>
       <Route exact path="/login" component={LoginPage}/>
       <Route exact path="/login/" component={LoginPage}/>
       <Route exact path="/login/resetpassword" component={ForgotPasswordPage}/>
       <Route exact path="/registration/:inviteId" component={RegistrationPage}/>
				<Route exact path="/registration/:inviteId/:verifyToken" component={RegistrationPage}/>
				
       <Route>
      
         <AppPrivate />
      </Route>
 </Switch>
}

function useEssentials(user) {
  const [getEssentials,{loading,data}] = useLazyQuery(essentialsQuery);
  const [waiting,setWaiting] = useState(!!user);
  const dispatch = useDispatch();
  const state = useSelector(s=>s.data);

  useEffect(()=> {
    if(user) {
      setWaiting(false);
      getEssentials();

    } else {
      setWaiting(true);
    }
  },[user,getEssentials])
  
  useEffect(()=> {
    if(data && state && state.loaded===-1 ) {
      dispatch({
        type:'ESSENTIALS/LOADED',
        payload:{
            reportConfigs:data.reportConfigs,
            categorisedForms:data.categorisedForms
        }
    });
    } else if(state && state.loaded===1) {

    }
  },[data,state]);

  return {
    loading:waiting || loading || (state && state.loaded===-1),
    essentials:data  
  };
  
}

//const OtherComponent = React.lazy(() => import('./OtherComponent'));

function AppPrivate() {

  const [notify_api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderM] = message.useMessage();
  const [modal, modalContext] = Modal.useModal();
  
  
  const location = useLocation();
//  const history = useHistory();
  const [authenticating,user,authError] = useAuth();

  const {loading,essentials }=useEssentials(user);

  const configMap = useMemo(()=> {
    let dta = JSON.parse(JSON.stringify(essentials?.formConfigs || []))
    dta.forEach(d=> {
      d.color = uniqolor(d._id,{
        saturation: [35, 70],
        lightness: [25-60],
      });
      d.translations = new Map(d.translations.map((t)=> 
      [t.key,t.value]
    ));
  })

    return new Map(dta.map(d=>[d.key,d]))
  },[essentials?.formConfigs])

//console.log('authenticating',authenticating,user);
  if(authenticating) return <></>;
  if(authError) {
    return <Redirect
        to={{
          pathname: "/login",
          state: { from: location }
        }}
      />
  }
  
  if(!user) {
    return <Redirect
        to={{
          pathname: "/login",
          state: { from: location }
        }}
      />
  }

  if(loading) {
    return <Spin fullscreen></Spin>
  }
 

  return (
  <ConfigProvider theme={AntdTheme}>
    <AppContext.Provider value={{
      user,notify:notify_api,
      message:messageApi,
      modal,
      configMap,
      categorisedForms:essentials?.categorisedForms
      }}>
      {contextHolder}{contextHolderM}{modalContext}
  <Switch>
    <Route exact path="/">
      {user && user.home ? <Redirect to={user.home}/> : <Redirect to='/login'/>}
    </Route>
    <PrivateRoute path="/incofin" component={IncofinRoute}/>
    <PrivateRoute path="/client/:clientId/" component={ClientRoute}></PrivateRoute>
    <Route>
      <NotFound/>
    </Route>
</Switch></AppContext.Provider></ConfigProvider>



);
}

export default AppPublic;

/*
<Route component={Secured}>
					<Route path="/incofin"  component={AuthWrapper.UserIsIncofinMember(DataContainers.Incofin)}>
						<Route component={PortalLayout}>
						
						</Route>
					</Route>

					<Route path="client/:clientId" component={DataContainers.Client}>
						<Route component={PortalLayout}>
							<Route exact path="/" component={ClientPages.Dashboard}/>
							<Route path="dashboard" component={ClientPages.Dashboard}/>
							<Route path="investments" component={InvestmentsPage}/>
							<Route path="organisation/:key" component={FormPage}/>
							<Route path="financial/:key" component={FormPage}/>
							<Route path="social/:key" component={FormPage}/>
							<Route path="statistics/:key" component={FormPage}/>
							<Route path="export/factsheet" component={ClientPages.ExportFactsheet}/>
							<Route path="export/reports" component={ClientPages.ExportReports}/>
							<Route path="export/investments" component={ClientPages.ExportInvestments} />
							<Route path="reports" component={ClientPages.Reports}>
								<Route path=":reportId" component={ClientPages.Reports}/>
							</Route>
						</Route>

						<Route path="reports/:reportId/:period" component={ReportPage}/>
					</Route>
				</Route>

    <Router history={history}>
       <Route path="/" >
         <LoginPage/>
      <Secured>
      <PortalLayout>
       
      
       <Switch>
       <Route exact path="/"> <div>Main entry</div></Route>
       <Route exact path="/client/:clientId">
            <ClientPart/>
        </Route>
        <Route path="/"> <div>Main entry</div></Route>
       </Switch>
         
   
      </PortalLayout>
      </Secured>
      </Route>
      </Router>
*/
