import React from 'react';
import classNames from 'classnames';
import './textBlock.scss';
/**
 * DESCRIPTION: display some text together with an appointed icon
 *    => used in the SummaryBlock component
 */



class TextBlock extends React.Component {
	constructor(props) {
		super(props);
	}

	shouldComponentUpdate(nextProps) {
		return nextProps.icon !== this.props.icon || nextProps.text !== this.props.text || nextProps.postText !== this.props.postText;
	}

	//render method
	render() {
		const {
			icon,
			text,
			postText
		} = this.props;

		const renderIcon = icon && icon !== '';

		const blockClass = classNames({
			'text-block': true,
			'text-block--with-icon': renderIcon
		});

		const iconClass = classNames({
			[`text-block__icon fa fa-${icon}`]: renderIcon
		});

		return (
			<div className={blockClass}>
				{ renderIcon && <i className={iconClass} /> }

				<span className="text-block__text">{text} {postText}</span>
			</div>
		);
	}
}

export default TextBlock;