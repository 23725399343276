import { apolloClient, store } from '../../store';
import { covenantsConfigQuery } from '../../../graph/queries/admin/covenants';
import Meteor from '../../../Meteor';
export const COVENANTS_LOADING = 'COVENANTS/LOADING';
export const COVENANTS_LOADED = 'COVENANTS/LOADED';
export const COVENANTS_LOAD_ERROR = 'COVENANTS/ERROR';
export const COVENANTS_SET_CURRENT = 'COVENANTS/SET/CURRENT';
export const COVENANTS_SET_PAGE = 'COVENANTS/SET/PAGE';
export const COVENANTS_SET_FILTER = 'COVENANTS/SET/FILTER';

export function loadCovenants(skip, limit, filter, keepPreviousResult) {
	return function (dispatch) {
		dispatch({
			type: COVENANTS_LOADING
		});

		const state = store.getState();
		const intFilter = parseInt(filter);

		apolloClient.query({
			query: covenantsConfigQuery,
			variables: {
				skip,
				limit,
				language: state.session.language,
				name: filter
				/*,
				rangeMin: isNaN(intFilter) ? null : filter,
				rangeMax: isNaN(intFilter) ? null : filter,
				fieldType: filter,
				guide: filter*/
			},
			forceFetch:true
		}).then((result) => {
			if(result && result.data && result.data.covenantsConfig) {
				dispatch({
					type: COVENANTS_LOADED,
					covenants:result.data.covenantsConfig,
					totalCovenants: result.data.totalCovenantConfigs,
					keepPreviousResult: keepPreviousResult
				});
			} else {
				dispatch({
					type: COVENANTS_LOAD_ERROR,
					payload: "No covenants"
				});
			}
		}).catch((error)=> {
			// handle error
			dispatch({
				type: COVENANTS_LOAD_ERROR,
				payload: error.message
			});
		});
	};
}

export function setCurrentCovenant(id) {
	return {
		type: COVENANTS_SET_CURRENT,
		payload: {
			covenantId: id
		}
	};
}

export function setCurrentPage(curPage, refetch, skip, limit, filter, keepPreviousResult) {
	return function(dispatch) {
		if(refetch) {
			dispatch(loadCovenants(skip, limit, filter, keepPreviousResult));
		}

		dispatch({
			type: COVENANTS_SET_PAGE,
			payload: {
				curPage
			}
		});
	};
}

export function setFilter(filter, limit) {
	return function(dispatch) {
		dispatch(loadCovenants(0, limit, filter, false));

		dispatch({
			type: COVENANTS_SET_FILTER,
			payload: {
				filter
			}
		});
	};
}