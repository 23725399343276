import { SendOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { useContext } from "react";
import { ReportingWizardContext } from "../hooks/context";
import { FinalSubmit } from "./submit";
import { AuditResponse } from "./audit";




export const ReportingWizardFinal = ({onBack,onDone=()=>{}})=> {
    
    const { status } = useContext(ReportingWizardContext)

    switch(status) {
      case "in_progress": {
        return <FinalSubmit onDone={onDone} onBack={onBack}/>
      }
      case "in_review": {
        return <AuditResponse onDone={onDone} onBack={onBack}/>
      }
    }
    return <Result
    icon={<SendOutlined />}
    title={status}
   
  
  />

}