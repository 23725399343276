import React, { useCallback,useRef, useState } from 'react';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { FormattedMessage } from 'react-intl';
import { DashboardContainer, SmartLayout } from '../../components/dashboard';
import Panel from '../../components/dashboard/panel/panel';
import AdminReportsGridView from '../../components/grids/admin-reports';
import Loader from '../../components/loader/spinner';
import Pagination from '../../components/grids/custom/pagination';
import Filter from '../../components/grids/custom/filter';
import ReportInstanceForm from '../../components/ReportInstanceForm/index';
import adminReportsQuery from '../../../graph/queries/admin/reports';
import { useQuery, useMutation } from '@apollo/client';
import { deleteReportInstance, reopenReportInstance,insertReportInstance } from '../../../graph/mutations/report';
import { ReportingWizardDrawer, ReportingWizardModal } from '../../../features/NewWizard/ReportingWizard';
import { Button, Modal } from 'antd';
import { useApp } from '../../../hooks/useApp';



const AdminReportPage = ({history,match})=> {

	//const containerRef = useRef()

	const { message, modal} = useApp();
	const [creating,setCreating] = useState(false);
	const [counter,setCounter] = useState(0);	
	

	const [_deleteReport,{loading:deleting}] = useMutation(deleteReportInstance, {
    });

	const [_reopenReport,{loading:reopening}] = useMutation(reopenReportInstance, {
      
    });

	const [showReport,setShowReport] = useState(null);

	const onConfirmDelete = useCallback(()=> {

	})

	const onRowClick=useCallback((report)=> {
		
		setShowReport({
			clientId:report.clientId,
			reportKey:report.key,
			periodKey:report.period
		});
		//history.push(`/client/${report.clientKey}/reports/${report.key}/${report.period}`);
	},[history]);

	const addReportInstance = useCallback(()=> {
		setCreating(true);
	});

	const toastRef= useRef();

	//onFilter={this.onFilterChange}
	// onAction={this.onRowAction}
	const onAction = useCallback(async (action,rowData)=> {
		console.log("ACTION",action,rowData);
		let variables= {
			instanceId: rowData.id
			/*,
			reportConfigId: rowData.reportId,
			reportName: rowData.category,
			periodName: rowData.periodName,
			clientName: rowData.client,
			numericContext: rowData.numericContext,
			clientId: rowData.clientId*/
		};
		let reportText = `${rowData.category}:${rowData.periodName} of ${rowData.client}`
		
		switch(action) {
			case "delete": {
				modal.confirm({
					title: 'Confirm delete',
					okButtonProps: {
						danger:true
					},
					okText:"Delete Report",
					content: `Are you sure you want to delete report ${reportText}`
				  }).then((res)=> {
					if(res) {
						let prom = null;
						
						modal.confirm({
							title: 'Confirm delete: adjoining data.',
							okButtonProps: {
								danger:true
							},
							okText:"Delete All",
							cancelText:"Delete Report Only",
							cancelButtonProps: {
								danger:true
							},
							content: `Do you whish to keep the adjoining data or delete as well?`
						  }).then((res)=> {
							variables.includeForms = res;
							let msgAddition = res ? ",and adjoining data" : "";
							_deleteReport({variables}).then(()=> {
								message.success("Successfully deleted report " + reportText+msgAddition);
							}).catch((e)=> {
								message.error("Failed to delete report " + reportText + msgAddition + ": " + e.message);
							});
						  })
					}
				  })
				
				//prom = _deleteReport({variables});
				break;
			}
			case "reopen":{
				_reopenReport({variables:{
					instanceId:rowData.id,
					clientId:rowData.clientId,
					numeric:rowData.numericContext
				}}).then(()=> {
					message.success("Successfully reopened report " + reportText);
				}).catch((e)=> {
					message.error("Failed to reopen report " + reportText + ": " + e.message);
				});
				break;
			}
		}

	},[_deleteReport,_reopenReport]);

	const onReadyReportCreate = useCallback(()=> {
		setCounter((s)=>s+1);
		setCreating(false);
	})
	
	let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

	return <DashboardContainer>
	<SmartLayout>
		<Panel title={<FormattedMessage id="grid.admin-reports.title" />}>
			<ToastContainer ref={toastRef} toastMessageFactory={ToastMessageFactory} className="toast-top-right" />
			
				<AdminReportsGridView fullMode={true} onAction={onAction}
					showFilter={true} itemsPerPage={25}
					onRowClick={onRowClick}
					counter={counter}  />
					  
				  <div style={{height: '3em', marginTop: '2em'}}>
					  <button onClick={addReportInstance}>
								  <FormattedMessage id="app.buttons.add" />
					  </button>
				  </div>
				  { showReport ? <ReportingWizardDrawer open={!!showReport} {...showReport} onClose={()=> setShowReport(null)}></ReportingWizardDrawer> : null }
		
			{creating ? <ReportInstanceForm onDone={onReadyReportCreate} onCancel={onReadyReportCreate} open={creating} client={null} config={null}/> : null}
		</Panel>
	</SmartLayout>
</DashboardContainer>;
}

//	<ReportingWizardModal open={!!showReport} {...showReport} onClose={()=> setShowReport(null)}></ReportingWizardModal>
		


AdminReportPage.defaultProps = {
	itemsPerPage: 25
};

export default connect((state) => ({
	language: state.session.user.language,
	clients: state.session.clients,
	client: state.client,
	reportConfigs: state.data.reportConfigs
}), null)(
graphql(deleteReportInstance, {name: 'deleteReportInstance'})(
graphql(reopenReportInstance, {name: 'reopenReportInstance'})(
graphql(insertReportInstance, {name: 'insertReportInstance'})(AdminReportPage))));
