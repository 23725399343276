import {apolloClient, store} from '../../store';
import { formConfigQuery } from '../../../graph/queries/form/form';
import Meteor from '../../../Meteor';

export const FORMCONFIG_CLEAR = 'FORMCONFIG/CLEAR';
export const FORMCONFIG_LOADING = 'FORMCONFIG/LOADING';
export const FORMCONFIG_LOADED = 'FORMCONFIG/LOADED';
export const FORMCONFIG_LOAD_ERROR = 'FORMCONFIG/ERROR';



export function loadConfig(key,language) {
    return function (dispatch) {
        dispatch({
            type:FORMCONFIG_LOADING,
            payload:{key,language}
        });

        apolloClient.query({
            query: formConfigQuery,
         //   forceFetch: true,
            variables:{
                key, language
            }
        }).then((result) => {
            if(result && result.data && result.data.formConfig) {

                dispatch({
                    type:FORMCONFIG_LOADED,
                    payload:result.data.formConfig
                });
            }
            else {
                dispatch({
                    type:FORMCONFIG_LOAD_ERROR,
                    payload:"No config"
                });
            }
        }).catch((error)=> {
            // handle error;
            dispatch({
                type:FORMCONFIG_LOAD_ERROR,
                payload:error.message
            });
            console.log("ERROR FORM CONFIG LOAD",error);
        });
    };
}