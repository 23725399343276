import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

export default class SpanStatusComponent extends React.Component {
	constructor(props) {
		super(props);
	}

	//render method => give the status of each report instance a corresponding color (used in GridViews)
	render() {
		const cssClasses = classNames(
			'o-label',
			`o-label--${this.props.data}`
		);

		return (
			<span className={cssClasses}>
				<FormattedMessage id={`grid.reports.status.${this.props.data}`} defaultMessage={this.props.data}/>
			</span>
		);
	}
}