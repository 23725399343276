import {math} from "../mathjs"
import { transformMultiPeriod } from "./multiPeriod";



export function transformDefinitionToExec(node) {


    function transformer(child,path,parent) {
        if(child instanceof math.FunctionNode) {
            switch(child.name) {
                case "multiPeriod":{
                    
                    return transformMultiPeriod(child);
                    break;   
                }
               
            }
        }
        return child;
    }

    return node.transform(transformer);

}