import { CheckOutlined, EyeOutlined, LoadingOutlined, SendOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Alert, Button, Form, Input, Result,Spin } from "antd";
import { submitMutation } from "../../../graph/mutations/wizard";
import { useCallback, useContext, useState } from "react";
import { ReportingWizardContext } from "../hooks/context";
import { useApp } from "../../../hooks/useApp";


// TODO: add ability to leave a note
/*
 <Input.TextArea>
    </Input.TextArea>
*/

export const FinalSubmit = ({onBack,onDone=()=>{}})=> {
    
    const { info } = useContext(ReportingWizardContext)
    const { message } = useApp();
    const [error,setError] = useState(null);
    const [form] = Form.useForm();
    const [submitForApproval,{loading,data}] = useMutation(submitMutation, {
        variables:{
            reportId:info.id
        }
    })

    const sendIt = useCallback(()=> {
        setError(null);
        submitForApproval().then(async ({error})=> {
            if(error) {
                debugger;
            } else {
                message.success("Report send for approval");
                onDone(true)
            }
        }).catch((e)=>{
            setError(e.message)
            debugger;
        })
    },[onDone,submitForApproval])

    //<SendOutlined/>

    return <Result status={error ? "error" : data ? "success" : undefined}
    icon={error ? undefined : <SendOutlined/> }
    title="Ready to submit for approval!"
    extra={<>
    <div style={{marginBottom:"1em"}}><Button loading={loading} icon={<SendOutlined/>} type="primary" onClick={sendIt}>Submit for approval</Button>
    </div>
    <Button disabled={loading || !!data} icon={<EyeOutlined/>} type="dashed" onClick={onBack} >Take one more look</Button>
    <Button disabled={loading || !!data} type="text" danger >Save and submit later</Button>
    </>
  }
  >
   {error ? <Alert  message="Failed to submit"
      description={error}
       type="error" showIcon/> : null}
  </Result>

}