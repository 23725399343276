import React, { useCallback, useMemo } from 'react';
import {FormattedMessage} from 'react-intl';
import Panel from '../../dashboard/panel/panel.jsx';
import SpanStatusComponent from '../../dashboard/gridview/components/span-status.jsx';
import moment from 'moment';
import {connect} from 'react-redux';
import {push} from '../../../../router';
import Filter from '../../../components/grids/custom/filter';
import {contextToCalendar} from "../../periodToText";
import { useHistory,useRouteMatch } from 'react-router-dom';
import reportsQuery from '../../../../graph/queries/clients/dashboard/reports';
import { GraphTableServer } from '../../../components/table/GraphTable'
/**
 * DESCRIPTION: show report instances on the client's dashboard
 *   => different than the Incofin report instances GridView because of different columns & data
 */

 const gridDataMapper = (reports)=>{return reports ? reports.map((report) => {
	let overdue = moment().isAfter(moment(report.schedule.due));

			return {
				id: report._id,
				_id: report._id,
				key: report.config?.key,
				configId: report.configId,
				clientKey: report.client?.key,
				status: report.status === 'created' && overdue ? 'overdue' : report.status,
				category: report.config?.name?.value,
				periodName: contextToCalendar(report.context),
				period:report.context.text,
				dueDate: moment(report.schedule.due).format('dddd MMMM DD YYYY'),
				overdue,
				reporter: report.reportedBy?.fullName,
				auditor: report.auditedBy?.fullName,
				lastUpdated:moment(report.lastUpdated).format('DD/MM/YYYY hh:mm:ss')
			};
}) : []; };
const columnMeta = [
	{"columnName": 'status', "displayName": <FormattedMessage id="grid.reports.columns.status"/>, "customComponent": SpanStatusComponent},
	{"columnName": 'category', "displayName": <FormattedMessage id="grid.reports.columns.category"/>},
	{"columnName": 'periodName', "displayName": <FormattedMessage id="grid.reports.columns.period"/>},
	{"columnName": 'dueDate', "displayName": <FormattedMessage id="grid.reports.columns.due-date"/>},
	{"columnName": 'reporter', "displayName": <FormattedMessage id="grid.reports.columns.reporter"/>},
	{"columnName": 'auditor', "displayName": <FormattedMessage id="grid.reports.columns.auditor"/>},
	
	{"columnName": 'lastUpdated', "displayName": <FormattedMessage id="grid.column.lastUpdated"/>},
];

const rowMetadata = {
	'bodyCssClassName': function (rowData) {
		if (rowData.status === 'rejected' || (rowData.overdue && rowData.status !== 'approved')) return 'error';
	}
};

const fetchOptions = {
	fetchPolicy: "no-cache",
}

const dataSelector=(d)=>d && d.reports ? d.reports.results : []
const totalSelector=(d)=>d && d.reports && d.reports.pageInfo ? d.reports.pageInfo.total :0


const ClientReportsGridView  = ({
	counter,
		onRowClick,
		showPager,
		enableSort,
		showFilter,
		itemsPerPage,
		reports,
		onFilterChange,
		fullMode,
		showCustomFilter,
		clientId,
		reportId,
		actions=[]
	
	}) => {
		const onRowClickCb = useCallback((row)=>{
			//console.log('ON ROW CLICK',row)
			onRowClick(row.props.data);
		},[onRowClick]);
		const history = useHistory();
		const match = useRouteMatch();
		const goToReports = useCallback(()=>{
			console.log(history);
			console.log(`${match.url}`);

			const url = !match.url.endsWith('/') ? `${match.url}/` : match.url

			history.push(`${url}reports`)
		},[history]);

		const vars = useMemo(()=> {
			return {clientId,reportId,sort:[
				{field:"context.numeric", sort:"DESC"},
				{field:"schedule.due", sort:"DESC"},
				{field:"report.name", sort:"ASC"}
			]}
		},[clientId,reportId,counter])
		
		console.log("ClientReportsGridView","RENDER")

		return (
			<Panel actions={actions} title={<FormattedMessage id="grid.reports.title"/>} extraClassNames={'reports-panel'}>
		 <GraphTableServer qry={reportsQuery} 
        options={fetchOptions}
		counter={counter}
		vars={vars}
        tableDataMapper={gridDataMapper}
        dataSelector={dataSelector}
        totalSelector={totalSelector}
         columnMetadata={columnMeta} showFilter={true} showPager={fullMode || showPager} 
		 resultsPerPage={itemsPerPage}
								onRowClick={onRowClickCb} 
		 enableSort={enableSort}
        />
				
			{
				(!fullMode) ?
					<button className={'more-reports-button'} onClick={goToReports}>More</button> : null
			}
		</Panel>
		)
	}	

export default ClientReportsGridView;
