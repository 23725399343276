import React from 'react';



function renderStepHeader(steps, activeStep,canEdit,onClick) {
    if(!steps) return null;
    
    return steps.length > 0 ?
        steps.map((step, index) => {
            let classNames = 'step';
            if(!canEdit) {
                classNames += ' clickable';
            }
            if(index === activeStep) classNames += ' active';
            if(index < activeStep) classNames += ' completed';
            if(step.invalid) {
                classNames += ' error';
            }


            return (
                <div className={classNames} key={index} onClick={()=>{onClick(index)}}>
                    {step?.config?.name?.value}
                </div>
            );
        }) : null;
};

export const StepHeader = ({steps,activeStep,setStep}) => {
    return (<section className="step-indicator">
        { renderStepHeader(steps, activeStep,true,setStep) }
    </section>);
};
