import React from 'react';
import TimelineBlock from './Block';
import _ from 'underscore';

export default class TimeslicerBody extends React.Component {
	constructor(props) {
		super(props);

		this.blocks = [];
	}

	shouldComponentUpdate(nextProps, nextState){
		return nextProps.type !== this.props.type || nextProps.viewable !== this.props.viewable;
	}

	componentWillUpdate(nextProps, nextState){
		if(nextProps.type !== this.props.type) {
			this.blocks = [];
		}
	}

	componentDidMount() {
		if(this.props.scrollLastYearIntoView) this.props.scrollLastYearIntoView();
	}

	componentDidUpdate() {
		if(this.props.scrollLastYearIntoView) this.props.scrollLastYearIntoView();
	}

	renderBlocks() {
		const {
			from,
			to,
			filter,
			type,
			onTimeblockSelect
		} = this.props;

		const toYear = to ? to : (new Date()).getFullYear();
		//let blocks = [];

		for(let i = from; i <= toYear; i++) {
			let defaultSelected =
				this.props.default && this.props.default.periods && this.props.default.periods.length > 0 && Array.isArray(this.props.default.periods) ?
					_.filter(this.props.default.periods, (defaultValue) => {
						return parseInt(defaultValue.toString().substr(0, 4)) === i;
					}) : [];

			let filterValues =
				filter && filter.length > 0 && Array.isArray(filter) ?
					_.filter(filter, (filterValue) => {
						return parseInt(filterValue.toString().substr(0, 4)) === i;
					}) : false;

			this.blocks.push(
				<TimelineBlock key={i} year={i} type={type} filter={filterValues}
											 onTimeblockSelect={onTimeblockSelect} defaultSelected={defaultSelected} />
			);
		}
	}

	render() {
		const {
			viewable
		} = this.props;

		if(this.blocks && this.blocks.length === 0) this.renderBlocks();

		return (
			<div className="timeslicer-body">
				<div className="timeslicer-body-scroll">
				{viewable ? this.blocks : null}
				</div>
			</div>
		);
	}
}