import '../AdminForm.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import NumericField from '../../fields/numeric/index';
import SelectField from '../../fields/select/index';
import TextField from '../../fields/text/index';
import { clone } from '../../../../utils';
import TranslationContainer from '../../TranslationItem/container';

import { Table } from '../../table/Table'
import classNames from 'classnames';
import ActionsComponent from '../../dashboard/gridview/components/actions';
import RequiredLabel from '../../RequiredLabel/index';
import { FormulaViewer } from '../../../../features/NewFormula/components/Viewer';


const fieldTypeOptions = [
	{ value: 'currency', label: 'Currency' },
	{ value: 'percentual', label: 'Percentual'},
	{ value: 'numeric', label: 'Numeric'}
];

const iterateObject = (values, value, identifier)=> {
	let foundValue = false;
	Object.keys(values).forEach((k) => {
		if (values[k] !== null && typeof values[k] === 'object') {
			foundValue = !foundValue ? iterateObject(values[k], value, identifier) : foundValue;
		}
		if (k === identifier) {
			values[k] = value;
			foundValue = true;
		}
	});

	return foundValue;
}


const CovenantForm = ({
	values,saving,language,
	onDelete,onCancel,onSave,clientType
})=> {

	const [localValues,setLocalValues] = useState(values);
	const [valid,setValid] = useState(true);
	const [linkStatus,setLinkStatus] = useState(null);
	const [linkState,setLinkState] = useState({
			linkValid: true
	});
	const [rowIndex,setRowIndex] = useState(null);
	const [selectedLink,setSelectedLink] = useState({});

	const [validations,setValidations] = useState(new Map());
	const [linkValidations,setLinkValidations] = useState(new Map());

	console.log("VALIDATIONS", validations)


	const onFieldChange = useCallback((field, value, previousValue, valid)=> {
		console.log('CF> onFieldChange')
		if(field.props.identifier.split('.')[0] === 'link') {
			let updSelectedLink = {...selectedLink};
			updSelectedLink[field.props.identifier.split('.')[1]] = value;
			setSelectedLink(updSelectedLink);
		} else {
			let values = {...localValues};
			let foundValue = iterateObject(values, value, field.props.identifier);
			if (!foundValue) values[field.props.identifier] = value;
			setLocalValues(values);
		}
	},[localValues,selectedLink]);

	const onValidationChange = useCallback((field, valid, value)=> {
		console.log('CF> onValidationChange')
		let identifier = field.props.identifier.split('.');
		let validationMapToUse = identifier[0] === 'link' ? linkValidations : validations;
		if(validationMapToUse.has(field.props.identifier)) validationMapToUse.delete(field.props.identifier);
		if(!valid) {
			validationMapToUse.set(field.props.identifier, valid)
		}
		let myValid = validationMapToUse.size === 0;

		if(identifier[0] === 'link') {
			setLinkValidations(validationMapToUse);
			setLinkState({...linkState,linkValid:myValid});
		}
		else {
			setValidations(validationMapToUse);
			setValid(myValid);
		}
	},[linkValidations,validations,linkState]);

	const onTranslationsChange = useCallback((translations, identifier, valid) => {
		console.log('CF> onTranslationsChange')
		let values = {...localValues};
		values[identifier] = translations;

		if(validations.has(identifier)) validations.delete(identifier);
		if(!valid) {
			validations.set(identifier, valid);
		}

		let formValid = validations.size === 0;
		setValidations(validations);
		setValid(formValid);
		setLocalValues(values);
	},[localValues,validations]);

	const onLinkTranslationsChange = useCallback((translations, identifier, valid) => {
		
		console.log('CF> onLinkTranslationsChange')
		let _selectedLink = {...selectedLink};
		_selectedLink[identifier] = translations;

		if(linkValidations.has(identifier)) linkValidations.delete(identifier);
		if(!valid) {
			linkValidations.set(identifier, valid);
		}

		let linkValid = linkValidations.size === 0;

		setSelectedLink(_selectedLink);
		setLinkValidations(linkValidations);
		setLinkState({...linkState,linkValid});

	},[selectedLink,linkValidations,linkState]);

	const addLink = useCallback(()=> {
		console.log('CF> addLink')
		setLinkStatus("CREATING");
	
		setSelectedLink({
			name: {},
			guide: {},
			uri: null
		});
		
	},[linkStatus,selectedLink]);

	
	const saveLink = useCallback(() => {
		console.log('CF> saveLink')
		

		if(linkState.linkValid) {
			let values = {...localValues};
			if(linkStatus === "EDITING") {
				values.links = [...values.links]
				values.links[rowIndex] = selectedLink;
			} else if(linkStatus === "CREATING") {
				if(!values.links) values.links = [];
				values.links = [...values.links, selectedLink];
			}

			setLocalValues(values);
			setLinkStatus(null);
			setLinkState({
			
				linkValid: true});
			setSelectedLink({});
			setRowIndex(null);

		}
	},[localValues,linkState,linkStatus,rowIndex,selectedLink]);


	const onAction = useCallback((action, rowData) => {
		console.log('CF> onAction')
		if(action === 'edit') {
			setRowIndex(rowData.__index);
			setSelectedLink({
				uri: rowData.uri,
				name: rowData.name,
				guide: rowData.guide
			});
			setLinkStatus("EDITING")
		
			
		} else if(action === 'delete') {
			let values = {...localValues};
			values.links.splice(rowData.__index, 1);
			setLocalValues(values);
		}
	},[linkStatus,localValues]);

	//user clicked on cancel in the "contacts" part of the form --> show contacts GridView
	const cancelLink = useCallback(() => {
		console.log('CF> cancelLink')
		setLinkStatus(null);
		setLinkState({
			linkValid: true
		})
		setRowIndex(null);
		setSelectedLink({});
	},[]);

	const onLocalSave = useCallback(()=> {
		if(onSave) {
			onSave(localValues,valid);
		}

	},[onSave,localValues,valid]);

	const onLocalCancel = useCallback(()=> {
		if(onCancel) onCancel();
	},[onCancel]);

	const onLocalDelete = useCallback(()=> {
		if(onDelete) onDelete(localValues);
	},[onDelete,localValues])

	useEffect(()=> {
		setLocalValues(values);
	},[values]);

	//separate method to render a GridView with links in it (specific columns & data)
	function renderLinks(links) {
		console.log("RENDER LINKS",links,localValues)
		let values = links && links.length > 0 ? links.map((dataEntry, idx) => {
			return {
				uri: dataEntry.uri,
				nameTranslated: dataEntry.name ? (dataEntry.name[language] ? dataEntry.name[language] : dataEntry.name['en']) : null,
				name: dataEntry.name,
				guide: dataEntry.guide,
				__actions: ['edit', 'delete'],
				__index: idx
			};
		}) : [];

		const columnsToShow = ['uri', 'nameTranslated', '__actions'];
		const columnMeta = [
			{ columnName: 'uri', displayName: <FormattedMessage id="admin.form.covenant.links_uri"/> },
			{ columnName: 'nameTranslated', displayName: <FormattedMessage id="admin.form.covenant.name" /> },
			{
				columnName: '__actions',
				displayName: <FormattedMessage id="grid.column.actions" />,
				customComponent: ActionsComponent,
				customComponentProps: {
					onAction: onAction
				}
			}
		];

		let gridClassName = classNames(
			'o-gridView',
		);

		let tableClassName = classNames(
			'o-gridView__table',
			'o-gridView__table-no-margin-top',
			'o-gridView__table--sortable',
			'o-gridView__table--paged',
		);

		return (
			<div className="grid-container">
				<Table data={values} columns={columnsToShow} columnMetadata={columnMeta} useGriddleStyles={false}
								 noDataMessage={'No data could be found.'} gridClassName={gridClassName}
								 tableClassName={tableClassName} />
			</div>
		);
	}


	const standardBindings = {
		validations: {required: true},
		activePageIsSubmitted: saving,
		onChange: onFieldChange,
		onValidationChange: onValidationChange
	};

	let nonRequiredBindings = {...standardBindings};
	delete nonRequiredBindings.validations;

	let linkStandardBindings = {...standardBindings};
	//linkStandardBindings.activePageIsSubmitted = linkState.linkSaving;

	console.log('COV VALUES',values)
	if(!localValues || !localValues.range) return <div></div>;


	const { linkValid,
		
		 } = linkState;


	return (<div>
		<div className="admin-form">
			<div className="required-fields-label">
				<FormattedMessage id="required-fields.label"/>
			</div>

			<div className="form-group">
				<label>
					<FormattedMessage id="admin.form.covenant.name"/>
					<RequiredLabel />
				</label>

				<TranslationContainer translations={localValues['name']} multiline={false} required={true} submitted={saving} requiredLanguages={['en']}
															onTranslationsChange={(translations, valid) => onTranslationsChange(translations, 'name', valid)} />
			</div>

			<div className="form-group">
				<label>
					<FormattedMessage id="admin.form.covenant.guide"/>
				</label>

				<TranslationContainer translations={localValues['guide']} multiline={true} required={false} submitted={saving}
															onTranslationsChange={(translations, valid) => onTranslationsChange(translations, 'guide', valid)} />
			</div>

			<div className="form-group">
				<div className="block">
					<label className="top-label">
					<FormattedMessage id="admin.form.covenant.links"/>
					</label>

					{
						linkStatus === null ?
							renderLinks(localValues ? localValues.links : []) :
							<div className="block-children">
								<div className="form-group">
									<label>
										<FormattedMessage id="admin.form.covenant.links_uri"/>
										<RequiredLabel />
									</label>
									<TextField value={selectedLink['uri']} identifier={'link.uri'} {...linkStandardBindings}
														 validations={{format: {}}} type="url" />
								</div>

								<div className="form-group">
									<label>
										<FormattedMessage id="admin.form.covenant.name" />
									</label>
									<TranslationContainer translations={selectedLink['name']} multiline={false} required={false} 
																				onTranslationsChange={(translations, valid) => onLinkTranslationsChange(translations, 'name', valid)} />
								</div>

								<div className="form-group">
									<label>
										<FormattedMessage id="admin.form.covenant.guide" />
									</label>
									<TranslationContainer translations={selectedLink['guide']} multiline={true} required={false} 
																				onTranslationsChange={(translations, valid) => onLinkTranslationsChange(translations, 'guide', valid)} />
								</div>
							</div>
					}

					{
						linkStatus===null ?
							<button onClick={addLink}>
								<FormattedMessage id="app.buttons.add-new-link"/>
							</button> :
							<div className="form-actions">
								<button onClick={saveLink}>
									<FormattedMessage id="app.buttons.save-link"/>
								</button>
								<button onClick={cancelLink} className="cancel">
									<FormattedMessage id="app.buttons.cancel"/>
								</button>
							</div>
					}
				</div>
			</div>

			<div className="form-group">
				<label>
					<FormattedMessage id="admin.form.covenant.fieldType"/>
					<RequiredLabel />
				</label>
				<SelectField value={localValues['fieldType']} identifier={'fieldType'} options={fieldTypeOptions} {...standardBindings} />
			</div>

			<div className="form-group">
				<div className="block">
					<label className="top-label">
						<FormattedMessage id="admin.form.covenant.range"/>
					</label>

					<div className="block-children">
						<div className="form-group">
							<label>
								<FormattedMessage id="admin.form.covenant.range_min"/>
							</label>
							<NumericField value={localValues['range']['min']} max={localValues['range']['max']} identifier={'min'} {...nonRequiredBindings} precision={2} />
						</div>

						<div className="form-group">
							<label>
								<FormattedMessage id="admin.form.covenant.range_max"/>
							</label>
							<NumericField value={localValues['range']['max']} min={localValues['range']['min']} identifier={'max'} {...nonRequiredBindings} precision={2} />
						</div>
					</div>
				</div>
			</div>

			<div className="form-group">
				<label>
					<FormattedMessage id="admin.form.covenant.alertThreshold"/>
					<RequiredLabel />
				</label>
				<NumericField allowNegative={false} value={localValues['alertThreshold']} identifier={'alertThreshold'} {...standardBindings} precision={2} />
			</div>
      {/* feature/issue-124 */}
      <div className="form-group readOnly">
        <label>
          <FormattedMessage id="admin.form.client.clientType"/>
					<RequiredLabel />
        </label>
        <SelectField readOnly={!!clientType} displayName value={localValues['clientType']} identifier={'clientType'} multi={false} options={[{value: 'AGRO', label: 'AGRO'}, {value: 'MFI', label: 'MFI/BANK'}]}
                    placeholder={'Select client type ... '}  {...standardBindings}/>
      </div>
	  { localValues['formula'] ? 
      <div className="form-group readOnly">
        <label>
          Formula
        </label>
		<div style={{
			border:"1px dashed #000",
			padding:"1em",
			margin:"0.3em"
		}}>
		<FormulaViewer formula={localValues['formula'].formula}
       />
	   </div>
      </div> : null}
		</div>
		<div style={{height: '3em', marginTop: '2em'}}>
	
				<div className="form-actions">
					<button onClick={onLocalSave}>
						<FormattedMessage id="app.buttons.save"/>
					</button>
					
					<button onClick={onLocalCancel} className="cancel" style={{margin: '0 1em'}}>
						<FormattedMessage id="app.buttons.cancel"/>
					</button>
				</div>
			</div>
	
	</div>);

	
}


export default CovenantForm;


/*
<button onClick={onLocalDelete} style={{margin: '0 1em'}}>
						<FormattedMessage id="app.buttons.delete"/>
					</button>
*/