import { useContext } from "react";
import { ClientContext } from "../../containers/client";
import { ReportingWizard } from "../../../features/NewWizard";
import { Layout } from "antd";



export const ReportPage = ({match})=> {

	const { params }= match;
	
	const clientContext = useContext(ClientContext);

	

	
	return  <div className="app-wrapper">
	<div className="app-content app-full-screen"><ReportingWizard client={clientContext}
				
				reportKey={params.reportId}
				periodKey={params.period}
				numeric={null}
	></ReportingWizard></div></div>
}