import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from '../table/Table'
import moment from 'moment';
/**
 * DESCRIPTION: show ALL exchange rates on the "/incofin/admin/exchangrates" route
 *   => only accessible by the Incofin-admin or admin user
 */


const DefaultProps = {
    itemsPerPage: 5
};

const execFilter = (data,filter)=> {
    return data.map((d)=>d.search = moment(d.numericDate.toString(),"YYYYMMDD").format("DD MMM YYY"));
}



class AdminRatesGridView extends React.Component {
    constructor(props) {
        super(props);
    }

    //bind the onClick event on a row to the parent onRowClick property (f.ex. navigate to another route)
    onRowClick(row) {
        if (this.props.onRowClick) {
            this.props.onRowClick(row.props.data);
        }
    }

    //render method
    render() {
        const rates = this.props.rates || [];
        let gridData = rates.map((rate) => {
            return {
                _id: rate._id,
                numericDate: moment(rate.numericDate, 'YYYYMMDD').format('DD MMM YYYY'),
                source: rate.source
            };
        });

        const columnMeta = [
            { columnName: 'numericDate', displayName: <FormattedMessage id="grid.audits.columns.date"/> },
            { columnName: 'source', displayName: <FormattedMessage id="grid.exchange-rates.columns.source"/> }
        ];

        console.log("render rates")

        return (
            <div className="rates-grid">
               
                <Table data={gridData} 
                          columnMetadata={columnMeta}
                          resultsPerPage={this.props.itemsPerPage}
                          showFilter={true}
                          onFilter={this.props.onFilter}
                          showPager={true}
                          onRowClick={this.onRowClick.bind(this)}

                />
            </div>
        );
    }
}

export default AdminRatesGridView;
