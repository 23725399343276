import './gridLayout.scss';

import React from 'react';
import classNames from 'classnames';

import GridItem from './gridItem.jsx';

export default class GridLayout extends React.Component {
    constructor(props) {
        super(props);
    }

		shouldComponentUpdate(nextProps, nextState) {
		//console.log('GRID LAYOUT', nextProps, nextState);
		return true;
	}

    render() {
        const {
        } = this.props;

        const items = React.Children
            .toArray(this.props.children)
            .filter(x => x.type === GridItem);

        if (items.length === 0) return false;

        return (
            <div className={classNames('o-grid', this.props.className)}>
                {items}
            </div>
        );
    }
}
