

import { useEffect, useRef, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useFormulaViewerShow } from '../hooks/useFormulaViewer';
import { FormulaCard } from './FormulaCard';


export const FormulaPanel = ({pinnable,panelGroupId,children,overflow=true})=> {

    const [panelState,setPanelState] = useState({
        direction:"vertical"
    });
    const ref = useRef(null);

    const viewerId = useFormulaViewerShow();

    useEffect(()=> {
        if(ref.current) {
            if(!viewerId) {
                ref.current.collapse();
            } else {
                ref.current.expand(10);
                if(ref.current.isCollapsed()) {
                    ref.current.expand(10);
                }
            }
        }
    },[viewerId])

    return  <PanelGroup direction={panelState.direction} autoSaveId={panelGroupId}>
        <Panel style={{overflow: overflow ? "auto" : "hidden"}}>
            {children}
        </Panel>


        <PanelResizeHandle />
        <Panel maxSize={75} minSize={10} collapsible ref={ref}>
            <FormulaCard pinnable={pinnable}/>
        </Panel>
    </PanelGroup>
}