import React from 'react';
import { updateDeclineReason } from '../../../graph/mutations/user';
import { graphql } from '@apollo/client/react/hoc';
import TextAreaAuto from '../../components/TextAreaAuto';

/**
 * DESCRIPTION: registration page
 * ROUTE: /register OR /
 * ACCESSIBLE BY: invitation link (everyone)
 * DATA: N/A
 */

class DeclineInvite extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reasonInputValue: '',
            submitted: false,
            busy: false
        }

       
        this.onReasonSubmit = this.onReasonSubmit.bind(this);
    }

    onInputChange(event) {
        this.setState({reasonInputValue: event.target.value});
    }

    onReasonSubmit() {
        var reason = this.state.reasonInputValue || "No reason provided";
        //if(this.state.reasonInputValue) {
            this.setState({busy:true});
            this.props.updateDeclineReason({
                variables: {
                    inviteId: this.props.inviteId,
                    bounceReason: reason
                }
            }).then(({data}) => {
                if (data.updateDeclineReason.success) {
                    this.setState({submitted: true});
                } else if(data.updateDeclineReason.error && this.refs.container) {
                    this.setState({busy:false});
                    this.refs.container.error(data.updateDeclineReason.error.code, data.updateDeclineReason.error.message);
                }
            }).catch((error) => {
                this.setState({busy:false});
                if (this.refs.container) this.refs.container.error(error.message);
            });
            this.setState({busy:false});
      //  }
    }

    render() {
        if (!this.state.submitted) {
            return <div className="invite-declined">
                <p>Would you like to inform us why you declined to enroll on to Incofin Reporting?</p>
                <p>
                <TextAreaAuto  value={this.state.reasonInputValue} onChange={this.onInputChange.bind(this)}></TextAreaAuto>
                </p>
                <div className='button-list'>
                <button className='confirm accept' onClick={this.props.onChangedMind}>Go Back</button>
                <button className='confirm accept' onClick={this.onReasonSubmit.bind(this)}>Submit</button>
                </div>
            </div>
        } else {
            return <div>
                <p>Thank you for your feedback.</p>
            </div>
        }
    }
}


export default graphql(updateDeclineReason, {name: 'updateDeclineReason'})(DeclineInvite)