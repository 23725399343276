import React from 'react';
import { FormattedMessage } from 'react-intl';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import moment from 'moment';
import WaiverItem from './waiver-item';
import WaiverForm from '../InvestmentForm/WaiverForm';
import _ from 'underscore';

class WaiverList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showWaivers: false,
			creating: false,
			saving: false,
			editing: false,
			waiver: {}
		};

		this.waiverForm = null;

		this.toggleWaivers = this.toggleWaivers.bind(this);
		this.addWaiver = this.addWaiver.bind(this);
		this.editWaiver = this.editWaiver.bind(this);
		this.saveWaiver = this.saveWaiver.bind(this);
		this.cancel = this.cancel.bind(this);
	}

	addWaiver() {
		this.setState(() => ({
			creating: true,
			waiver: {
				waiverType: null,
				startDate: null,
				endDate: null,
				range: {min:null, max:null},
				alertThreshold: null,
				centralCovenant: null
			}
		}));
	}

	editWaiver(waiverId) {
		let waiver = _.findWhere(this.props.waivers, {_id: waiverId});
		this.setState(() => ({
			editing: true,
			waiver: {
				_id: waiver._id,
				waiverType: waiver.waiverType,
				centralCovenant: waiver.centralCovenant ? waiver.centralCovenant._id : null,
				range: waiver.range,
				alertThreshold: waiver.alertThreshold,
				startDate: waiver.startDate,
				endDate: waiver.endDate
			}
		}));
	}

	saveWaiver() {
		this.setState(() => ({
			saving: true
		}));

		if(this.waiverForm.state.valid && this.props.saveWaiver) {
			let values = this.waiverForm.state.values;

			if(values.startDate) values.startDate = parseInt(moment(values.startDate, 'DD-MM-YYYY').format('YYYYMMDD'));
			if(values.endDate) values.endDate = parseInt(moment(values.endDate, 'DD-MM-YYYY').format('YYYYMMDD'));

			this.props.saveWaiver(values, this.state.creating ? 'ADD' : 'EDIT');

			this.setState(() => ({
				showWaivers: true,
				editing: false,
				creating: false,
				saving: false,
				waiver: {}
			}));
		} else if(this.refs.container){
			this.refs.container.error('Invalid waiver form! Please fill in all the required values!');
		}
	}

	cancel() {
		this.setState(() => ({
			showWaivers: true,
			creating: false,
			saving: false,
			editing: false,
			waiver: {}
		}));
	}

	toggleWaivers() {
		this.setState((state) => ({
			showWaivers: !state.showWaivers
		}));
	}

	renderWaiverForm() {
		const {
			waiver,
			saving
		} = this.state;

		return (
			<div className="covenant-waiver-form">
				<WaiverForm values={waiver} saving={saving} ref={(waiverForm) => this.waiverForm = waiverForm}/>

				<div style={{height: '3em', margin: '2em 0'}}>
					<div className="form-actions">
						<button onClick={this.saveWaiver}>
							<FormattedMessage id="app.buttons.save"/>
						</button>
						<button onClick={this.cancel} className="cancel" style={{margin: '0 1em'}}>
							<FormattedMessage id="app.buttons.cancel"/>
						</button>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const {
			waivers,
			intl,
			permissions,
			deleteWaiver,
			currentWaiver
		} = this.props;

		const {
			showWaivers,
			creating,
			editing
		} = this.state;

		let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

		if(!waivers || (waivers && waivers.length === 0)) {
			return (
				<div>
					<ToastContainer ref="container" toastMessageFactory={ToastMessageFactory} className="toast-top-right" />

					{
						creating ?
							this.renderWaiverForm() :
							<div className="covenant-waiver-list-header empty">
								<span>
									<FormattedMessage id="form.waiver.no-waivers"/>
								</span>
								{
									permissions.canAddWaivers ?
										<span onClick={this.addWaiver}>
											<i className="fa fa-plus-circle"/>
										</span>
										: null
								}
							</div>
					}
				</div>
			);
		}

		return (
			<div>
				<ToastContainer ref="container" toastMessageFactory={ToastMessageFactory} className="toast-top-right" />

				{
					creating || editing ?
						this.renderWaiverForm()
						:
						<div>
							<div className="covenant-waiver-list-header">
								<span className={showWaivers ? 'rotate' : null} onClick={this.toggleWaivers}>
									({waivers.length}) <FormattedMessage id="form.waiver.waivers"/>
									<i className="fa fa-chevron-right"/>
								</span>

								{
									permissions.canAddWaivers ?
										<span onClick={this.addWaiver}>
											<i className="fa fa-plus-circle"/>
										</span>
										: null
								}
							</div>

							<div className={showWaivers ? 'covenant-waiver-list-items' : 'covenant-waiver-list-items collapsed'}>
								{
									showWaivers && permissions.canReadWaivers ?
										waivers.map((waiver, idx) => {
											return (
												<WaiverItem key={idx} {...waiver} intl={intl} permissions={permissions}
																		waiverInEffect={currentWaiver._id === waiver._id}
																		editWaiver={this.editWaiver} deleteWaiver={deleteWaiver} />
											);
										})
										: null
								}
							</div>
						</div>
				}
			</div>
		);
	}
}

export default WaiverList;