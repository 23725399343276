import { gql } from '@apollo/client';

const financialPeriodsQuery = gql`query financialPeriods($clientId: String!) { 
    financialPeriods(clientId:$clientId) {
        financialYear
        financialYearId
        start
        end
        months
    }
  }
`;

export default financialPeriodsQuery;