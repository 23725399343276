import { gql } from '@apollo/client';


const investmentResultBody = `
		_id
		importedId
		country
		client
		fund {
			_id 
			name
		}
		product {
			_id
			name
		}
		currency
		outstandingAmount
		firstDisbursementDate
		lastReportedMonth
		finalMaturityDate
		endedAt
		investmentManagersIds
		investmentManagers {
			_id
		name
		startAt
		endedAt
		}
		reportingPeriodicity
		covenants {
			_id
			centralCovenantId {
				_id
				name
				guide
				links {
					uri
					name
					guide
				}
				fieldType
				range {
					min
					max
				}
				alertThreshold 
				archived
			}
			range {
				min
				max
			}
			alertThreshold
			waivers {
				_id
				waiverType
				centralCovenant {
					_id
					name
					guide
					links {
						uri
						name
						guide
					}
					fieldType
					range {
						min
						max
					}
					alertThreshold 
				}
				range {
					min
					max
				}
				alertThreshold
				startDate
				endDate
			}
}
`

const investmentsQuery = gql`query investmentsQuery($clientId: String, $language:String) {
    investments(clientId: $clientId, language:$language) {
       ${investmentResultBody}
    }
}
`;


export const investmentsOverviewQuery = gql`query investmentsQuery($filter:String,$state:OpenOrClosed, $clientId: String,$clientType:String,$sorting:[InputSorting], $skip:Int, $limit:Int ) {
    investmentsPaged(filter:$filter, state:$state, clientId: $clientId,clientType:$clientType,sorting:$sorting, skip:$skip, limit:$limit) {
       results {
	   	${investmentResultBody}
	   }
	   pageInfo {
        total
        limit
        offset
      }
    }
}
`;

export const investmentQuery = gql`query investementQuery($id:ID!) {
	investment(id:$id) {
		_id
		importedId
		country
		client
		fund {
			_id 
			name
		}
		product {
			_id
			name
		}
		currency
		outstandingAmount
		firstDisbursementDate
		lastReportedMonth
		finalMaturityDate
		endedAt
		investmentManagersIds
		investmentManagers {
			_id
			name
			startAt
			endedAt
		}
		reportingPeriodicity
		covenants {
			_id
			centralCovenantId {
				_id
				name
				guide
				links {
					uri
					name
					guide
				}
				fieldType
				range {
					min
					max
				}
				alertThreshold 
				archived
			}
			range {
				min
				max
			}
			alertThreshold
			waivers {
				_id
				waiverType
				centralCovenant {
					_id
					name
					guide
					links {
						uri
						name
						guide
					}
					fieldType
					range {
						min
						max
					}
					alertThreshold 
				}
				range {
					min
					max
				}
				alertThreshold
				startDate
				endDate
			}
		}
	}
}`;

export default investmentsQuery;
