import { useCallback, useContext, useMemo, useState } from "react";
import { useApp } from "../../../hooks/useApp";
import { FormReportFlat } from "../form/FormReportFlat";
import { FormattedMessage } from "react-intl";
import { ReportingWizardContext } from "../hooks/context";
import { StepHeader } from "../stepHeader";
import { ReportForm } from "../form/ReportForm";
import { duration } from "moment";
import { Alert, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";

const SettingsHeader = ()=> {
    const {currency,scale} = useContext(ReportingWizardContext);

    return  <Alert showIcon={true} type="info" message={<>Reporting in {currency}, scale 1/{scale}</>}/>
  
}

export const ReportingWizardSteps = ({onEnd})=> {

    const [step,setStep] = useState(0);

    const { readOnly,editMode,status,calculate, scope ,info,validateForm,steps,saveChangesOfStep} = useContext(ReportingWizardContext);
    const { notify,message } = useApp()

    const activeStep = steps[step];

    const validateAndSave = useCallback(async ()=> {
     
        if(activeStep?.config?.key) {
            let isValid = validateForm(activeStep.config.key)
         
            if(!isValid) {
                message.warning("Invalid fields, please review.")
                return false;
            }
            if(!readOnly) {
                calculate(); // double check
            }
            let changeSet = scope.getServerChangesetOfConfig(activeStep.config.key);
            let k = "save_" + activeStep.config.key;
            if(changeSet && changeSet.changes.length>0) {
               
                
                message.loading({content:`Saving changes to ${activeStep.config?.name?.value}`,
                    key:k
                });

                try {
                const {error} = await saveChangesOfStep({variables:{
                    reportId:info.id,
                    changeSet
                }})

                    message.destroy(k);
                    if(error) {
                        
                        notify.error({
                            message:`Failed to save changes to ${activeStep.config?.name?.value}`
                            ,description:error.message
                            ,duration:4
                        })
                    }
                    else {
                        message.success(`Saved changes to ${activeStep.config?.name?.value}`)
                    }
                    return true
                } catch(e) {
                    message.destroy(k);
                    notify.error({
                        message:`Failed to save changes to ${activeStep.config?.name?.value}`
                        ,description:e.message
                        ,duration:4
                    })
                    return false;
                   
                }
               
                
            } else {
                return true;
            }
        }
        return false;
    },[step,activeStep,scope])

    const next = useCallback(async ()=> {
        
        
        // validate
        // save
       // debugger;
        if(activeStep?.config?.key) {
           let ok = await validateAndSave();
          
           if(!ok) return;
        }
        if(step==steps.length-1 ) {
            onEnd();
        } else {
            setStep(step+1);
        }
    },[validateAndSave,onEnd])
    const previous = useCallback(()=> {
        setStep(step-1);
    },[step])

    //<FormReportEntry key={activeStep.config.key} renderField={FormReportFieldEntry} config={activeStep.config} />
  

    return <div className="wizard-wrapper">
        <StepHeader setStep={setStep} steps={steps} activeStep={step}/>
        <SettingsHeader/>
       {status =="in_review" ? <div className="wizard-enable-edit"><Button onClick={editMode} icon={<EditOutlined />} ghost>Edit Contents</Button></div> : null }
        <section className="body-wrapper">
            {activeStep ? 
                <ReportForm key={activeStep.config.key} config={activeStep.config} /> 
           : null}
        </section>
       
      
        <section className="wizard-actions">
            {step>0 ?  <button className="takeAction" onClick={previous}>
                        <FormattedMessage id="app.buttons.previous"/>
                    </button> : <div></div> }

                    <div className="action-wrapper">
            {step<steps.length-1 ?  <button className="takeAction" onClick={next}>
                <FormattedMessage id="app.buttons.next"/>
            </button> : null }

            {step==steps.length-1 ?  <button className="takeAction" onClick={next}>
                        <FormattedMessage id="app.buttons.finish"/>
                    </button> : null }
                    </div>
            </section>

        </div>

}
