import { gql } from '@apollo/client';

const usersQuery = gql`query {
	users {
		_id
		created
		firstName
		lastName
		fullName
		email
		lastLogin
		address {
			country
			countryName
			state
			city
			postalcode
			street
			nr
			box
			additional
		}
		communication {
			type
			value
		}
		userAccountId
		language
		status
		statusMessage
		changeLog {
			action
			actionMessage
			timestamp
			actionBy
		}
		roles
		invitation {
			mailId
			lastSendOfInvite
			expirationDate
			fulfilled
			validationToken
			creator
			sents
			bounced
		}
		clients
	}
	regions {
		_id
    region
	}
	countries {
		_id
		name
	}
}
`;

export default usersQuery;