import { gql } from '@apollo/client';

const investmentMgrQuery = gql`query investmentManagers($clientId:String){ 
    investmentManagers(clientId:$clientId) {
        _id
        fullName
    }
  }  
`;

export default investmentMgrQuery;
