import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { ReportingWizardContext } from "./context"

/*
export const useCovenantItem = (configKey,item,path,isEntryField=false,period="current") => {

    const {
        value,
        validationState,
        currency,
        disabled,
        setValue
    } = useWizardField(configKey,item,path,isEntryField,period);

    const [inBreach,setBreach] = useState();
    const [inAlert,setAlert] = useState();

    const breachPath = scope.pathToParts(path + ".breached.value",period);
    const alertPath = scope.pathToParts(path + ".alerted.value",period);
    

    useEffect(()=> {
        let listener = scope.subscribeField(path,()=>{
            let breach = scope.pathToParts(path + ".breached.value",period);
            let alert = scope.pathToParts(path + ".breached.value",period);
            let v = scope.getFieldValue(path,fieldType,period)
            console.log("CHANGE",path,v);
            setLocalValue(v)
        })
        return ()=> scope.unsubscribe(listener)
    },[scope,path,fieldType,period])

   

 
    return {
        value,
        validationState,
        currency,
        disabled,
        setValue,
        inBreach,
        inAlert
    }

}*/

export const useWizardValue = (configKey,fieldType,path,period) => {
    const {  scope } = useContext(ReportingWizardContext);
   
    const [localValue,setLocalValue] = useState(scope.getFieldValue(path,fieldType,period))
    useEffect(()=> {
        let listener = scope.subscribeField(path,()=>{
            let v = scope.getFieldValue(path,fieldType,period);
            setLocalValue(v)
        })
        return ()=> scope.unsubscribe(listener)
    },[scope,path,fieldType,period])
    
    return {
        value:localValue
    }
}

export const useWizardField = (configKey,item,path,isEntryField=false,period="current") => {

    const { readOnly, scope,validateForm,calculate,getFieldStatus,hasValidationErrors,currency } = useContext(ReportingWizardContext);

    let fieldType = item?.settings?.fieldType || item?.fieldType;

    const [validationState,setValidationState] = useState(getFieldStatus(path));
   // if(path=="agroBalanceSheet.assets" && period=="1FY") {
       // debugger
   // }
    const [localValue,setLocalValue] = useState(scope.getFieldValue(path,fieldType,period));
    

    useEffect(()=> {
        setValidationState(getFieldStatus(path,fieldType,period))
    },[getFieldStatus,fieldType,path,period]);

    const setValue = useCallback((value)=> {
        
        let revalidate = hasValidationErrors(configKey);
        scope.reportValue(path,value,fieldType);
        calculate(scope);
        if(revalidate) {
            validateForm(configKey)
        }
    },[scope,calculate,hasValidationErrors,validateForm,configKey,path,period,fieldType]);

    
    useEffect(()=> {
        let listener = scope.subscribeField(path,()=>{
            let v = scope.getFieldValue(path,fieldType,period)
            console.log("CHANGE",path,v);
            setLocalValue(v)
        })
        return ()=> scope.unsubscribe(listener)
    },[scope,path,fieldType,period])
   

    return {
        value:localValue,
        validationState,
        currency,
        disabled:readOnly,
        setValue:isEntryField && !readOnly ? setValue : ()=>{ }
    }

}