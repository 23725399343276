import {default as Dashboard }from './dashboard';
import {default as Clients} from './clients';
import {default as Reports} from './reports';
import {default as ExportFactsheet} from '../export/index';
import {default as ExportReports} from '../export/exportReports';
import {default as ExportInvestments} from '../export/exportInvestments';
import {default as ExportCovenants} from '../export/exportCovenants';
import Secured from '../../containers/Secured';
import PortalLayout from '../../layouts/portal';
import { UserIsIncofinMember, IsAdmin} from '../../../auth/AuthWrappers';
import IncofinDataContainer from '../../containers/incofin';
import {InvestmentTiles, Investments as InvestmentsPage } from '../investments/tabs';
import { Router,Route,Switch,useRouteMatch,Redirect } from 'react-router-dom';
import AuthHelper from '../../../auth/AuthHelper';
import { AdminRoute } from '../admin'
import { InvestmentDetail } from '../investments/investment';
import { useApp } from '../../../hooks/useApp';

export {default as Dashboard }from './dashboard';
export {default as Clients} from './clients';
export {default as Reports} from './reports';
export {default as ExportFactsheet} from '../export/index';
export {default as ExportReports} from '../export/exportReports';
export {default as ExportInvestments} from '../export/exportInvestments';
export {default as ExportCovenants} from '../export/exportCovenants';




export const IncofinRoute = ({  })=> {

    const match = useRouteMatch()
    const { user } = useApp();
    //const IncofinMember= UserIsIncofinMember(IncofinDataContainer);
    if(!user.isIncofin) {
        return <Redirect to='/'/>
    }

    return <IncofinDataContainer>
            <PortalLayout>
            <Switch>
            <Route path={`${match.path}`} exact component={Dashboard}/>
            <Route path={`${match.path}/`} exact component={Dashboard}/>
            <Route path={`${match.path}/dashboard`} exact component={Dashboard}/>

            <Route path={`${match.path}/investments`} exact component={InvestmentTiles}/>
            <Route path={`${match.path}/investments/:clientType`} exact component={InvestmentsPage}/>
            <Route path={`${match.path}/investments/:clientType/:investId`} exact component={InvestmentDetail}/>
            <Route path={`${match.path}/clients`} exact component={Clients}/>
            <Route path={`${match.path}/reports`} exact component={Reports}/>
            <Route path={`${match.path}/reports/:reportId`} exact component={Reports}/>
           <Route path={`${match.path}/export/factsheet`} exact component={ExportFactsheet}/>
            <Route path={`${match.path}/export/reports`} exact component={ExportReports}/>
            <Route path={`${match.path}/export/investments`} exact component={ExportInvestments}/>
            <Route path={`${match.path}/export/covenants`} exact component={ExportCovenants}/>
            <Route path={`${match.path}/admin`} component={AdminRoute}/>
            {
                AuthHelper.isGranted()
            }
            </Switch>
            </PortalLayout>
            </IncofinDataContainer>;

}

export default {
    Dashboard,Clients,Reports,
    ExportCovenants,ExportFactsheet,ExportReports,ExportInvestments
}
/*
   <Switch>
            <Route path={`${match.path}`} exact component={Dashboard}/>
            <Route path={`${match.path}/`} exact component={Dashboard}/>
            <Route path={`${match.path}/dashboard`} exact component={Dashboard}/>
            <Route path={`${match.path}/investments`} exact component={InvestmentsPage}/>
            <Route path={`${match.path}/clients`} exact component={Clients}/>
            <Route path={`${match.path}/reports`} exact component={Reports}/>
            <Route path={`${match.path}/reports/:reportId`} exact component={Reports}/>
            <Route path={`${match.path}/export/reports`} exact component={ExportReports}/>
            <Route path={`${match.path}/export/investments`} exact component={ExportInvestments}/>
            <Route path={`${match.path}/export/covenants`} exact component={ExportCovenants}/>
            </Switch>
*/
/*

							<Route path="admin" component={AuthWrapper.IsAdmin}>
							
							</Route>
*/