import React from 'react';
import { FormattedMessage } from 'react-intl';

export default class ActionsComponent extends React.Component {
	constructor(props) {
		super(props);
	}

	// pass action to parent components when clicked on button
	onActionClicked(action) {
		this.props.metadata.customComponentProps.onAction(action, this.props.rowData, this.props.metadata.customComponentProps.gridNumber);
	}

	//render method
	render() {
		return (
			<div>
				{
					this.props.data.map((action, idx) => {
						const fn = (e) => {
							//debugger;
							e.preventDefault();
							e.stopPropagation();
							this.onActionClicked(action);
						};

						return (
							<button onClick={fn.bind(this)} key={idx}>
								<FormattedMessage id={`app.buttons.${action}-lowercase`}/>
							</button>
						);
					}, this)
				}
			</div>
		);
	}
}