import { gql } from '@apollo/client';

const clientQuery = gql`query client($clientId: String) { 
    client(id:$clientId) {
        _id
        name
        officialName
        key
        status
        contacts {
            name
            role
            email
        }
        settings {
            financialPeriodEnd {
                day
                month
            }
            currency
            reportingScale
        }
        clientType
       	bottomDate
       	topDate
        financialPeriods {
            financialYear
            financialYearId
            start
            end
            months
            __typename

        }
    }
  }
`;

export default clientQuery;