import { Alert, Button, Form, List, Modal, Result,Select,Typography } from "antd"
import { EyeOutlined, SearchOutlined, SettingOutlined,WarningOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useEffect, useState } from "react"
import { ReportingWizardContext } from "../hooks/context";
import { IconReport } from "../icons/IconReport";
import currencies, { currencyMap } from "../../../data/currencies";
import { useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import { startSubmissionMutation } from "../../../graph/mutations/wizard/index";
import { contextToCalendar } from "../../../ui/components/periodToText";

const { Paragraph,Text } = Typography;


const ReportSettingsForm = ({onFormReady})=> {
  const [form] = Form.useForm();
  const {
    currency,scale
  } = useContext(ReportingWizardContext);

  useEffect(() => {
    onFormReady(form);
  }, []);

  return  <Form layout="vertical" form={form} name="report_settings" initialValues={{
    currency,
    scale
  }}>
      <Form.Item label="Currency"
      name="currency"
      rules={[
        {
          required: true,
          message: 'Please select a currency!',
        }
      ]}>
        <Select options={currencies}/>
      </Form.Item>
      <Form.Item label="Scale"
      name="scale"
      rules={[
        {
          required: true,
          message: 'Please select a currency!',
        }
      ]}>
       <Select placeholder="Select a scale to report in">
        <Select.Option value={1}>1/1</Select.Option>
        <Select.Option value={10}>1/10</Select.Option>
        <Select.Option value={100}>1/100</Select.Option>
        <Select.Option value={1000}>1/1000</Select.Option>
        <Select.Option value={10000}>1/10000</Select.Option>
        <Select.Option value={100000}>1/100000</Select.Option>
      </Select>
      </Form.Item>
  </Form>
}

/*
 <List.Item.Meta
         title={}
          description="Ant Design, a design language for background applications, is refined by Ant UED Team"
        />
*/

const ReportList = ({data})=> {
  return <List
  size="small"
    style={{maxHeight:"120px",overflowY:"scroll"}}
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item, index) => (
      <List.Item key={index}>
        {item.config?.name?.value} - {contextToCalendar(item.context)}
      </List.Item>
    )}
  />
    
 
}

export const EntryStartSubmission = ({onStart=()=>{}})=> {
  
   const onPreview =()=>{
    onStart()
   }
   const intl = useIntl();
    const {
      info,currency,scale,steps,changeSettings,client,refreshScope
      ,previousOpenReports
    } = useContext(ReportingWizardContext);

    const [startSubmission,{loading}] = useMutation(startSubmissionMutation)

    const [showSettingsForm,setShowSettingsForm] = useState(false);

    const [formInstance, setFormInstance] = useState();

    const {
      title,clientName,contextText
    } = info
    
    const onStartSubmission = useCallback(()=> {
        startSubmission({
          variables:{
            reportId:info.id,
            settings:{
              reportingScale:scale,
              currency
            }

          }
        }).then(({data,errors})=> {

        //  console.log("ERRORS",errors);
          
          refreshScope();
          onStart();

        })
    },[startSubmission,scale,currency,info,client,onStart]);

    let OpenReportList = null;
    if(previousOpenReports && previousOpenReports.length>0) {

      OpenReportList =  <Alert
      message={`${previousOpenReports.length} previous reports are still open`}
      type="warning"
      description={<ReportList data={previousOpenReports}></ReportList>}
      showIcon
     
      closable
    />
      
      

    }

    return <Result
    
    icon={<IconReport />}
    title={<>Submission of {title}<br/> <Text mark>{contextText}</Text></>}
    subTitle={<>Review the reporting sessions and press start to start the submission process of {title}<br/></>}
    extra={<><Button loading={loading} type="primary" onClick={onStartSubmission} >Start Submission</Button>
    <Button type="text"  icon={<EyeOutlined/>}  onClick={onPreview} >preview</Button>
    </>}
  >
    <div>
        <Paragraph>
        <Text
          strong
          style={{
            fontSize: 16,
          }}
        >
         <WarningOutlined/> Report all currencies in <Text mark> {currencyMap.get(currency)} ({currency}) </Text>{scale>1 ? <> in a <Text mark>1/{scale} scale</Text><Text type="secondary" style={{fontSize:'0.8em'}}> ({intl.formatNumber(scale,{ style:"currency",currency})} to be reported as {intl.formatNumber(1,{ style:"currency",currency})})</Text></> : null}. <Button onClick={()=>setShowSettingsForm(true)} type="text" size="small" icon={<SettingOutlined/>}>Change settings</Button>
        </Text>
        </Paragraph>
        <Paragraph>
        <Text
          strong
          style={{
            fontSize: 16,
          }}
        >Steps to report:</Text>
        <ul>
          {steps.map((s,idx)=><li key={idx}>{s?.config?.name?.value}</li>)}
        </ul>
      </Paragraph>
      {OpenReportList}
      <Modal open={showSettingsForm} destroyOnClose
      onCancel={()=>setShowSettingsForm(false)}
        onOk={async () => {
          try {
            
            const values = await formInstance?.validateFields();
            formInstance?.resetFields();
            changeSettings(values);
            setShowSettingsForm(false)
          } catch (error) {
            console.log('Failed:', error);
          }
        }}
      ><ReportSettingsForm onFormReady={(instance) => {
        setFormInstance(instance);
      }} /> </Modal>
    </div>
    
  </Result>
}