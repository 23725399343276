
import * as React from "react";

function IconMathFunction(props) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M14 10h1c1 0 1 1 2.016 3.527C18 16 18 17 19 17h1" />
      <path d="M13 17c1.5 0 3-2 4-3.5s2.5-3.5 4-3.5M3 19c0 1.5.5 2 2 2s2-4 3-9 1.5-9 3-9 2 .5 2 2M5 12h6" />
    </svg>
  );
}

export default IconMathFunction;
