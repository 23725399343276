import { gql } from '@apollo/client';

const adminReportsQuery = gql`query adminReports($skip: Int, $limit: Int, $filter: String,$sort:[InputSorting], $language: String) {
	adminReports(skip: $skip, limit: $limit, filter: $filter, sort:$sort, language: $language) {
		results {
			_id
			client {
					_id
					officialName
					name
					key
			}
			config {
					_id
					key
					name {
						value
					}
			}
			configId
			schedule {
					due
			}
			settings {
					currency
					reportingScale
			}
			context {
					category
					text
					numeric
					period {
						month
						quarter
						year
						financialYear
						financialYearPeriod
					  }
			}
			status
			overdue
			reportedBy { 
				_id
				fullName 
			}
			auditedBy { 
				_id
				fullName 
			}
			lastUpdated
		}
		pageInfo {
			total
			limit
			offset
		  }
	}
	
}
`;

export default adminReportsQuery;

