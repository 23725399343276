import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import Loader from '../../components/loader/index.jsx';
import RegisterForm from '../../components/RegisterForms/index';
import validationQuery from '../../../graph/queries/registration/validation';
import { createValidationToken } from '../../../graph/mutations/user';

/**
 * DESCRIPTION: registration page
 * ROUTE: /register OR /
 * ACCESSIBLE BY: invitation link (everyone)
 * DATA: N/A
 */

class UpdateInvitation extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        //console.log(this.props);
       

        if (this.props.user) {
            return (<div className="registration-form">
                        <p>Please fill in or update the following data.</p>
                        <RegisterForm user={this.props.user} />
                    </div>
            );
        } else {
            return <div>Wrong token.</div>
        }
    }
}

/*
export default graphql(validationQuery, {
    options: ({inviteId,verifyToken}) => ({
        variables: {
            inviteToken: inviteId,
            verifyToken: verifyToken
        },
        forceFetch: true
    })
}, {name: 'validationQuery'})(UpdateInvitation)*/
export default UpdateInvitation;