import React from 'react';
import { FormattedMessage } from 'react-intl';
import SelectField from '../../fields/select/index';
import CountrySelect from '../../fields/select/country';
import SelectClientStatus from '../../fields/select/client-status';
import { clone } from '../../../../utils';
import moment from 'moment';
import Timeline from '../../../../features/TimeSlicer/index';
import DateRange from '../../../components/grids/custom/range-date';
import AuthHelper from '../../../../auth/AuthHelper';
import _ from 'underscore';

const statusOptions = [
	{label: 'Created', value: 'created'},
	{label: 'In Progress', value: 'in_progress'},
	{label: 'In Review', value: 'in_review'},
	{label: 'Submitted', value: 'submitted'},
	{label: 'Rejected', value: 'rejected'},
	{label: 'Approved', value: 'approved'},
	// {label: 'Overdue', value: 'overdue'}
];
// issue-42
const overdueOptions = [
	{label: 'Yes', value: 'yes'},
	{label: 'No', value: 'no'},
];


class ExportReportsForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			values: clone(props.values, true),
			range: {},
      clients: this.props.clients,
		};

		this.periodSelector = null;
		this.isIncofin = AuthHelper.isGranted('level', 'INCOFIN');

		this.onFieldChange = this.onFieldChange.bind(this);
		this.onRangeChange = this.onRangeChange.bind(this);
		this.onClientTypeChange = this.onClientTypeChange.bind(this);
		this.filterClients = this.filterClients.bind(this);
	}

	//set new value in received valueTree based on prop identifier
	iterateObject(values, value, identifier) {
		let foundValue = false;
		Object.keys(values).forEach((k) => {
			if (values[k] !== null && typeof values[k] === 'object') {
				foundValue = !foundValue ? this.iterateObject(values[k], value, identifier) : foundValue;
			}
			if (k === identifier) {
				values[k] = value;
				foundValue = true;
			}
		});

		return foundValue;
	}

	//method hooked up to all the fields --> detect changes
	onFieldChange(field, value, previousValue, valid) {
		let values = this.state.values;
		let foundValue = this.iterateObject(values, value, field.props.identifier);
		if(!foundValue) values[field.props.identifier] = value;

		this.setState(() => ({
			values: values
		}));
	}

  // issue-65
  onClientTypeChange(field, val) {
		let filteredClients = this.filterClients(this.props.clients, ['active', 'workout'], val);
    console.log('filteredClients', filteredClients)
		this.setState(() => ({
			clients: filteredClients
		}));
	}
  filterClients(clients, status, type) {
		return clients.filter((client) => {
			return ((type=="AGRO" && client.clientType=="AGRO") || (type!="AGRO" && client.clientType!="AGRO")) &&  status.indexOf(client.status) > -1;
		});
	}

	onRangeChange(rangeType, dateField, value) {
		
		let range = this.state.range;
		if(value) {
			range[rangeType] = value;
		} else if(range[rangeType] && value === null) {
			delete range[rangeType];
		}

		this.setState(() => ({
			range: range
		}));
	}

	getValues() {
		let periodSelectorValues = this.periodSelector.getValue();

		return {
			report: this.state.values.report,
			reportStatus: _.pluck(this.state.values.reportStatus, 'value'),
			clients: this.isIncofin && this.props.client && !this.props.client._id ? _.pluck(this.state.values.clients, 'value') : [this.props.client._id],
			regions: _.pluck(this.state.values.regions, 'value'),
			countries: _.pluck(this.state.values.countries, 'value'),
			dueDateRange: this.state.range,
			periods: periodSelectorValues ? periodSelectorValues.periods : {},
			clientStatus: this.state.values.clientStatus,
      overdue: this.state.values.overdue, // issue-42
		};
	}

	render() {
		const {
			reportConfigs,
			clients,
			language,
			regions,
			client
		} = this.props;

		const {
			values,
			range
		} = this.state;

		const standardBindings = {
			onChange: this.onFieldChange
		};

		const clientOptions = [];
		let clientValue = client && client._id ? {
			value: client._id,
			label: client.name
		} : null;

		if (this.isIncofin && clients && clients.length > 0) {
      if(this.state.clients) {
        this.state.clients.forEach((client) => {
          clientOptions.push({
            value: client._id,
            label: client.name
          });
        });
      } else {
        this.props.clients.forEach((client) => {
          clientOptions.push({
            value: client._id,
            label: client.name
          });
        });
      }
		} else if(this.props.client) {
			clientOptions.push({
				value: this.props.client._id,
				label: this.props.client.name
			});
		}

		const reportOptions = [];
		
		if(reportConfigs && reportConfigs.length > 0 && Array.isArray(reportConfigs)) {
			reportConfigs.forEach((reportConfig) => {
				reportOptions.push({
					value: reportConfig._id,
					label: reportConfig.name?.value || reportConfig.key
				});
			});
		}

		const regionOptions = [];
		if(regions && regions.length > 0 && Array.isArray(regions)) {
			regions.forEach((region) => {
				regionOptions.push({
					value: region.region,
					label: region.region
				});
			});
		}

		return (
			<div className="admin-form">
				<div className="form-group">
					<label>
						<FormattedMessage id="export.labels.period"/>
					</label>
					<div style={{width:'75%'}}>
						<Timeline ref={(c) => this.periodSelector = c} from={2000} to={moment().year()}
											withOnChangeTimeout={false} onlyCalendarType={true} />
					</div>
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="export.labels.report-status"/>
					</label>
					<SelectField multi={true} options={statusOptions} placeholder={'Select status ... '}
											 {...standardBindings} identifier={'reportStatus'} value={values['reportStatus']} />
				</div>
        {/* issue-42 */}
        <div className="form-group">
					<label>
						<FormattedMessage id="export.labels.overdue"/>
					</label>
					<SelectField multi={false} options={overdueOptions} placeholder={'Select overdue status ... '}
											 {...standardBindings} identifier={'overdue'} value={values['overdue']} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="form.reports.type-of-reporting"/>
					</label>
					<SelectField multi={false} options={reportOptions} placeholder={'Select report ... '}
											 {...standardBindings} identifier={'report'} value={values['report']} />
				</div>

				{
					this.isIncofin && client && !client._id ?
						<div className="form-group">
							<label>
								<FormattedMessage id="export.labels.regions"/>
							</label>
							<SelectField multi={true} options={regionOptions} placeholder={'Select region ... '}
													 {...standardBindings} identifier={'regions'} value={values['regions']} />
						</div>
						: null
				}

				{
					this.isIncofin && client && !client._id ?
						<div className="form-group">
							<label>
								<FormattedMessage id="export.labels.countries"/>
							</label>
							<CountrySelect value={values['countries']} identifier={'countries'}  {...standardBindings}
														 multi={true} placeholder={'Select country ...'} />
						</div>
						: null
				}
        {/* issue-65 */}
        {
					this.isIncofin && client && !client._id ?
            <div className="form-group">
							<label>
								<FormattedMessage id="admin.form.client.clientType"/>
							</label>
							<SelectField multi={false} options={[{value: 'AGRO', label: 'AGRO'},{value: 'MFI', label: 'MFI/BANK'}]} readOnly={false}
														placeholder={'Select clienttype ... '} onChange={this.onClientTypeChange}/>
						</div>
						: null
				}

				<div className="form-group">
					<label>
						<FormattedMessage id="export.labels.clients"/>
					</label>
					{
						this.isIncofin && client && !client._id ?
							<SelectField identifier={'clients'} multi={true} options={clientOptions}
													 placeholder={'Select client(s) ... '} {...standardBindings}
													 readOnly={false} value={values['clients']} />
							: <SelectField identifier={'clients'} multi={false} options={clientOptions} value={clientValue}
														 placeholder={'Select client ... '} readOnly={true} />
					}
				</div>

				{
					this.isIncofin && client && !client._id ?
						<div className="form-group">
							<label>
								<FormattedMessage id="export.labels.client-status"/>
							</label>
							<SelectClientStatus identifier={'clientStatus'} multi={true} readOnly={false} {...standardBindings}
																	placeholder={'Select client status ...'} value={values['clientStatus']} />
						</div>
						: null
				}


				<div className="form-group">
					<label>
						<FormattedMessage id="grid.reports.columns.due-date"/>
					</label>
					<DateRange range={range} onRangeChange={this.onRangeChange} />
				</div>
			</div>
		);
	}
}

export default ExportReportsForm;