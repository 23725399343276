import React from 'react';
import {FormattedMessage} from 'react-intl';
import SubNavigationItem from './subnavigation-item.jsx';


/* DISPLAY ROUTE WITH ITS OPTIONAL CHILDREN (subNavigationItem) */
class NavigationItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapsible :  props.children && props.children.length > 0,
			open:props.currentpath.indexOf(props.path)>-1
		};
	}

	componentWillReceiveProps(props) {
		
		var collapsible = props.children && props.children.length > 0;
		var open = collapsible ? false : true;
		if(!open
			&& props.currentpath.startsWith(props.path)
		) {
			open=true;
		} 
		//console.log("will receive props",props.path,open)
		this.setState((state,props)=> {
				return {
					open,
					collapsible
				};
			});
		


	}
	/*shouldComponentUpdate(nextProps, nextState) {
		return this.state.closed !== nextState.closed || this.props.currentPath !== nextProps.currentPath
			|| this.props.collapsed !== nextProps.collapsed;
	}*/

	//bubble click event to the navigation.jsx & show/hide children based on state
	onItemClicked() {
		
		if(this.state.collapsible) this.setState({open:!this.state.open});
		if(this.props.action && this.props.onClick) {
			if(!this.state.collapsible || this.state.open || this.props.collapsed) {
                this.props.onClick(this.props.action);
            }
        }

		//if(this.props.onClick) this.props.onClick(this.props.path, this.props.action, this.props.children);
	}

	//bubble click event to the navigation.jsx
	onSubItemClicked(path, action, children) {
		
		if(action && this.props.onClick) this.props.onClick(action);
	}

	render(){
		const {
			path,
            currentpath,
			children,
			iconName,
			title,
			messageId,
			intl,
            navId
		} = this.props;

		const {
			open
		} = this.state;

		let activePath = currentpath === path;
		let subActivePath= !activePath && (children && children.length>0 && currentpath.startsWith(path) )
		

		let titleText = title;

		if(title && typeof(title) === "object") {
			// get from messages
			titleText = title[intl.locale] ? title[intl.locale] : title["en"];
            //	title =
		}



		//setup classNames
		const classNames = 'navigation-item'
											+ (iconName ? ' ' + iconName : ' icon-dash')
											+ (children && children.length > 0 ? ' more' : '')
											+ (activePath ? ' active' : '')
											+ (subActivePath ? ' sub-active' : '')
											+ (!open ? ' closed' : '');

		const classNamesSub = 'sub-navigation' + (!open ? ' closed': '');

		//render children (subnavigation-item.jsx)
		const subNavigation = children && children.length > 0 && children instanceof Array ?
			children.map((item, idx) => {
				return (
					<SubNavigationItem key={`${navId}-${idx}`}  intl={intl} {...item} currentpath={currentpath} parent={path}
														 onSubNavigationItemClicked={this.onSubItemClicked.bind(this)} />
				)
			}) : null;

		return(
			<div>
				<div className={classNames} onClick={this.onItemClicked.bind(this)}>
					{messageId && !title ? <span><FormattedMessage id={messageId}/></span> : <span>{titleText}</span> }
					
				</div>
				{ children && children.length > 0 && children instanceof Array ?
					<ul className={classNamesSub}>
						{subNavigation}
					</ul> : null
				}
			</div>
		);
	}
}

export default NavigationItem;
