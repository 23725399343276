import localForage from 'localforage';



export default class Store {
    constructor(storeName) {
        this.store = localForage.createInstance({
            name: 'incofin',
            version: 1.0,
            storeName: storeName,
            description: 'Incofin - Reporting portal localForage'

        });
        console.log('DB config'); 
    }

    static create(storeName) {
       // var lf = 
        var str = new Store(storeName);
        return str.store.ready(()=>{ console.log('READY DB ' + storeName)},(e)=>console.error(e)).then(()=> str);
    }

    
    async setItem(key, value) {
        return await this.store.setItem(key, value);
    }

    getItem(key) {
        return this.store.getItem(key);
    }

    async getItemAsync(key) {
        return await this.store.getItem(key);
    }


    removeItem(key) {
        return this.store.removeItem(key);
    }

    getKeys() {
        return this.store.keys();
    }

    getLast(count=1) {
        return this.getKeys().then((keys) => {
            const last = keys.slice(-count);

            return Promise.all(last.map((key)=> {return this.getItem(key)}));

        });
    }

    getAll() {
        return this.getKeys().then((keys) => {
            console.log('GOT KEYS',keys);
            return Promise.all(keys.map((key)=> { return this.getItem(key)}));

        });
    }

}