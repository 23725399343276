//import {Meteor} from 'meteor/meteor';
import {isEmptyObject, clone} from '../utils';

let instances = new WeakMap();

let privateProp = function (object) {
    if (!instances.has(object)) {
        instances.set(object, {});
    }

    return instances.get(object);
};

export default class PermissionsCache {
    constructor(permissions) {
        if (!isEmptyObject(permissions)) privateProp(this).permissions = clone(permissions);
        else privateProp(this).permissions = {};
    }

    isGranted(context, action, userId) {
        if (context === undefined || action === undefined) return false;

        const userPermissions = privateProp(this).permissions[userId];

        return (
            userPermissions
            && userPermissions[context]
            && userPermissions[context][action]
            ) || false;
    }

    setUserPermissions(userPermissions = undefined, userId) {
        if (isEmptyObject(userPermissions)) delete privateProp(this).permissions[userId];
        else privateProp(this).permissions[userId] = clone(userPermissions);
    }

    clear() {
        privateProp(this).permissions = {};
    }

    getKeys() {
        return Object.keys(privateProp(this).permissions)
            .filter(key => !isEmptyObject(privateProp(this).permissions[key]));
    }
}
