import React from 'react';
import {FormattedMessage} from 'react-intl';

/* LOWEST COMPONENT LEVEL IN THE NAVIGATION MENU */
class SubNavigationItem extends React.Component {
	constructor(props) {
		super(props);
	}

	shouldComponentUpdate(nextProps) {
		return (nextProps.path == nextProps.currentpath && this.props.path!=this.props.currentpath)
				|| (nextProps.path != nextProps.currentpath && this.props.path==this.props.currentpath)
				|| nextProps.currentpath != this.props.currentpath
				|| nextProps.intl.locale != this.props.intl.locale
				|| nextProps.action != this.props.action
				|| nextProps.messageId != this.props.messageId
				|| nextProps.title != this.props.title;
	}

	//bubble click event to navigation-item.jsx (eventually to the navigation.jsx)
	onSubItemClicked() {
		//console.log("CLICK SUB",this.props.path, this.props.action, this.props.children)
		this.props.onSubNavigationItemClicked(this.props.path, this.props.action, this.props.children);
	}

	render() {
		const {
			path,
			title,
			messageId,
            currentpath,
            intl,
			children
		} = this.props;


        let titleText = title;

        if(title && typeof(title) === "object") {
            // get from messages
            titleText = title[intl.locale] ? title[intl.locale] : title["en"];
            //	title =
        }

		let activePath = path == currentpath;
		let subActivePath= !activePath && (children && children.length>0 && currentpath.startsWith(path) )
		const classNames = 'navigation-item' + (activePath ? ' active' : '')  + (subActivePath ? ' sub-active' : '');

		//console.log("NAV",path,activePath,subActivePath)
		const classNamesSub = 'sub-navigation' ;//+ (!open ? ' closed': '');

		const subNavigation = children && children.length > 0 && children instanceof Array ?
			children.map((child, idx) => {
				return (
					<SubNavigationItem key={`${path}-${idx}`}  intl={intl} {...child} currentpath={currentpath} parent={path}
														 onSubNavigationItemClicked={this.props.onSubNavigationItemClicked.bind(this)} />
				)
			}) : null;

			//console.log("SubNavigationItem",path)

		return [
			<li key={`${path}-a`} className={classNames} >
                <span onClick={this.onSubItemClicked.bind(this)}>{messageId && !title ? <FormattedMessage id={messageId}/> : <span>{titleText}</span> }</span>
				
			</li>,
			 children && children.length>0 ? <li key={`${path}-b`}><ul className={classNamesSub}>{subNavigation}
			</ul></li> : null 
		];
	}
}

export default SubNavigationItem;

