import { gql } from '@apollo/client';

const financeQuery = gql`query financeQuery($formConfigId: String!, $clientId: String!, $range: [Int], $periodType:String, $currency:String) {
 financeQuery(formConfigId:$formConfigId, clientId:$clientId, range:$range, periodType:$periodType, currency:$currency) {
    _id
    client
    context {
        contextRef
        period {
            month
            quarter
            year
            financialYear
            financialYearPeriod
          }
    	periodType
    	text
    	numeric
    }
    settings {
      currency
      reportingScale
    }
    status
    values 
 }
}
`;

export default financeQuery