import { gql } from '@apollo/client';
import { covenantConfigBody } from '../queries/admin/covenants';

export const createCovenantConfig = gql`mutation createCovenantConfig($covenant: Object!) {
	createCovenantConfig(covenant: $covenant) {
		${covenantConfigBody}
	}
}
`;

export const updateCovenantConfig = gql`mutation updateCovenantConfig($covenantId: String!, $covenant: Object!) {
	updateCovenantConfig(covenantId: $covenantId, covenant: $covenant) {
		${covenantConfigBody}
	}
}
`;

export const deleteCovenantConfig = gql`mutation archiveCovenantConfig($covenantId: String!,$asFrom:Date) {
	archiveCovenantConfig(covenantId: $covenantId,asFrom:$asFrom) {
		_id
		archived
	}
}
`;
