import {
	INVESTMENTS_LOAD_ERROR,
	INVESTMENTS_MUTATION_ERROR,
	INVESTMENTS_CLEAR_ERROR,
	INVESTMENTS_LOADED,
	INVESTMENTS_LOADING,
	INVESTMENTS_COVENANTS_ADD,
	INVESTMENTS_COVENANTS_DELETE,
	INVESTMENTS_COVENANTS_UPDATE,
	INVESTMENTS_COVENANTS_WAIVERS_ADD,
	INVESTMENTS_COVENANTS_WAIVERS_UPDATE,
	INVESTMENTS_COVENANTS_WAIVERS_DELETE, INVESTMENTS_PERIODICITY_UPDATE
} from '../actions/investments';

const initialState = {
	loading: false,
	error: null,
	data: []
};

export const investmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case INVESTMENTS_LOAD_ERROR:
		case INVESTMENTS_MUTATION_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		case INVESTMENTS_LOADING:
			return {
				...state,
				loading: true,
				error: null
			};
		case INVESTMENTS_LOADED:
			return {
				...state,
				loading: false,
				error: null,
				data: action.investments
			};
		case INVESTMENTS_CLEAR_ERROR:
			return {
				...state,
				error: null
			};
		case INVESTMENTS_PERIODICITY_UPDATE:
			return Object.assign({}, state, {
				data: state.data.map((dataEntry) => {
					if(dataEntry._id === action.investmentId) {
						return Object.assign({}, dataEntry, {
							reportingPeriodicity: action.reportingPeriodicity
						});
					}
					return dataEntry;
				}),
				error: null
			});
		case INVESTMENTS_COVENANTS_ADD:
			return Object.assign({}, state, {
				data: state.data.map((dataEntry) => {
					if(dataEntry._id === action.investmentId) {
						return Object.assign({}, dataEntry, {
							covenants: action.covenants
						});
					}

					return dataEntry;
				}),
				error: null
			});
		case INVESTMENTS_COVENANTS_DELETE:
			return Object.assign({}, state, {
				data: state.data.map((dataEntry) => {
					if(dataEntry._id === action.investmentId) {
						return Object.assign({}, dataEntry, {
							covenants: [...dataEntry.covenants.filter((covenant) => {
								return covenant._id !== action.covenantId;
							})]
						});
					}

					return dataEntry;
				}),
				error: null
			});
		case INVESTMENTS_COVENANTS_UPDATE:
			return Object.assign({}, state, {
				data: state.data.map((dataEntry) => {
					if(dataEntry._id === action.investmentId) {
						return Object.assign({}, dataEntry, {
							covenants: dataEntry.covenants.map((covenant) => {
								if(covenant._id === action.covenant._id) {
									return Object.assign({}, covenant, {
										range: action.covenant.range,
										alertThreshold: action.covenant.alertThreshold
									});
								}

								return covenant;
							})
						});
					}

					return dataEntry;
				}),
				error: null
			});
		case INVESTMENTS_COVENANTS_WAIVERS_ADD:
			return Object.assign({}, state, {
				data: state.data.map((dataEntry) => {
					if(dataEntry._id === action.investmentId) {
						return Object.assign({}, dataEntry, {
							covenants: dataEntry.covenants.map((covenant) => {
								if(covenant._id === action.covenantId) {
									return Object.assign({}, covenant, {
										waivers: action.waivers
									});
								}

								return covenant;
							})
						});
					}

					return dataEntry;
				}),
				error: null
			});
		case INVESTMENTS_COVENANTS_WAIVERS_UPDATE:
			return Object.assign({}, state, {
				data: state.data.map((dataEntry) => {
					if(dataEntry._id === action.investmentId) {
						return Object.assign({}, dataEntry, {
							covenants: dataEntry.covenants.map((covenant) => {
								if(covenant._id === action.covenantId) {
									return Object.assign({}, covenant, {
										waivers: covenant.waivers.map((waiver) => {
											if(waiver._id === action.waiver._id) {
												return Object.assign({}, waiver, {
													waiverType: action.waiver.waiverType,
													alertThreshold: action.waiver.alertThreshold,
													range: action.waiver.range,
													startDate: action.waiver.startDate,
													endDate: action.waiver.endDate,
													centralCovenant: action.waiverCovenant
												});
											}

											return waiver;
										})
									});
								}

								return covenant;
							})
						});
					}

					return dataEntry;
				}),
				error: null
			});
		case INVESTMENTS_COVENANTS_WAIVERS_DELETE:
			return Object.assign({}, state, {
				data: state.data.map((dataEntry) => {
					if(dataEntry._id === action.investmentId) {
						return Object.assign({}, dataEntry, {
							covenants: dataEntry.covenants.map((covenant) => {
								if(covenant._id === action.covenantId) {
									return Object.assign({}, covenant, {
										waivers: [
											...covenant.waivers.filter((waiver) => {
												return waiver._id !== action.waiverId;
											})
										]
									});
								}

								return covenant;
							})
						});
					}

					return dataEntry;
				}),
				error: null
			});
	}

	return state;
};
