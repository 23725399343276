//import {Meteor} from 'meteor/meteor';
import PermissionsCache from './PermissionsCache';
import reduceRoles from './reduceRoles';
import { encrypt, decrypt } from '../utils';
import Meteor from '../Meteor'

class AuthorizationHelper {
    constructor() {
        this.cache = new PermissionsCache();
        this.__token = Symbol('permissions::token');
    }

    reduceAndSave(roles, userId= Meteor.userId()) {
        let permSet = reduceRoles(roles);
        this.cache.setUserPermissions(permSet, userId);
    }

    reduceAndEncrypt(roles, userId= Meteor.userId()) {
        let permSet = reduceRoles(roles);
        this.cache.setUserPermissions(permSet, userId);
        return encrypt(permSet, userId);
    }

    decryptAndSave(data, userId= Meteor.userId() ) {
        let permSet = decrypt(data, userId);
        this.cache.setUserPermissions(permSet, userId);
    }

    isGranted(context, action, userId= Meteor.userId()) {
        return this.cache.isGranted(context, action, userId);
    }
}

const AuthHelper = new AuthorizationHelper();

export default AuthHelper;