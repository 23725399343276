import React from "react"
import { FormTreeContent } from "../FormTree"
import classNames from "classnames"
import { FormulaIconLink, FormulaLink } from "../View/FieldLabel"
import { FormFieldDisplayRender } from "../View/FieldValue"

import { InputNumber} from "antd"

const FormFieldEntry = (item,path)=> {

	if(!!item.formula) {
		return FormFieldDisplayRender(item,path);
	}

	return <div className="field-entry">
		<InputNumber
      defaultValue={1000}
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
	  addonAfter="USD"
	  variant="filled"
	  stringMode
	  changeOnWheel={false}
	  controls={false}
    />
	</div>
}


export const FormFieldColumn = React.memo(({config,enableToggle=false,renderField=FormFieldEntry})=> {
	
	return <div className="form-tree-column form-tree-field-column">
			{FormTreeContent({config,enableToggle,renderField})}
		</div>
})