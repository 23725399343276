import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import classNames from 'classnames';

export default class TimeslicerSummary extends React.Component {
	constructor(props) {
		super(props);
	}

	renderSummary(years) {
		let summary = [];
		let objectLength = Object.keys(years).length;
		let index = 0;

		for(let year in years) {
			if(years[year] && years[year].length > 0 && Array.isArray(years[year])) {
				let yearLength = years[year].length;
				years[year].forEach((period, idx) => {
					let periodString = period.toString();
					let formatted = '';

					switch (periodString.length) {
						case 6:
							formatted = periodString.substr(4) === '00' ? `FY ${periodString.substr(0,4)}` : `${periodString.substr(0,4)}-${periodString.substr(4, 6)}`;
							break;
						case 8:
							formatted = moment(periodString,'YYYYMMDD').format('DD/MM/YYYY');
							break;
						default:
							break;
					}

					summary.push(
						<span key={year + period}>
							{formatted}
							{index === (objectLength-1) && idx === (yearLength-1) ? null : ', '}
						</span>
					);
				});
			}

			index++;
		}

		return summary;
	}

	render() {
		const {
			periods,
			collapsedBody
		} = this.props;

		let chevronClassNames = classNames(
			'chevron',
			collapsedBody ? null : 'rotate'
		);

		return (
			<div className="timeslicer-title timeslicer-summary" onClick={this.props.onClick ? () => this.props.onClick() : null}>
				<span className={chevronClassNames}>
					<i className="fa fa-chevron-right"/>
				</span>

				<FormattedMessage id="timeslicer.summary.filter" />: {this.renderSummary(periods)}
			</div>
		);
	}
}