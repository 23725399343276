import { gql } from '@apollo/client';

export const dashboardClientsQuery =gql`query dashboard($skip:Int, $limit:Int) { 
    clients(skip:$skip,limit: $limit) {
        _id
        importedId
        key
        name
        officialName
        key
        clientType
        address {
        	country
        	countryName
        }
        worldRegion
        status
    }
}
`

const dashBoardQuery = gql`query dashboard { 
    clients(skip:0,limit:3) {
        _id
        importedId
        key
        name
        officialName
        key
        clientType
        address {
        	country
        	countryName
        }
        worldRegion
    }
    reports(skip:0, limit:5) {
    	results {
        _id
        client {
            _id
            officialName
            key
        }
        report {
        		_id
            name
            key
        }
        reportId
        schedule {
            due
        }
        settings {
            currency
            reportingScale
        }
        context {
            category
            text
            numeric
            period {
                month
                quarter
                year
                financialYear
                financialYearPeriod
              }
        }
        status
        overdue
        reporter {
            fullName
        }
        lastUpdated
      }
      total
    }
  }
`;

export default dashBoardQuery;