import { gql } from '@apollo/client';

/**
 *
 * clientId,
 reportId,
 skip,
 limit,
 language: state.session.language,
 filter
 */

const reportsQuery = gql`query reports($clientId: String, $configId:String, $skip:Int, $limit:Int, $language:String, $filter:String,$sort:[InputSorting]) { 
   reports (clientId:$clientId, configId:$configId, skip:$skip, limit:$limit, language:$language, filter:$filter,sort:$sort) {
   		results {
        _id
        client {
            _id
            officialName
            key
        }
        config {
            _id
            key
            name {
              value
            }
        }
        configId
        schedule {
            due
        }
        settings {
            currency
            reportingScale
        }
        context {
            category
            text
            numeric
            period {
              month
              quarter
              year
              financialYear
              financialYearPeriod
            }
        }
        status
        reportedBy { 
            _id
        		fullName 
        }
        auditedBy { 
            _id
        		fullName 
        }
        lastUpdated
      }
      pageInfo {
        total
        limit
        offset
      }
    }
  }

`;

export default reportsQuery;