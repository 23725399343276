import React, { useCallback } from "react";
import { Tiles } from "../../../components/Tiles";


export const AdminsCovenantTypes = ({history,match})=> {

   

    let baseUrl = match.url;
     if(baseUrl.endsWith("/")) baseUrl = baseUrl.slice(0,-1);


    return <Tiles history={history} tiles={[
        { title:"MFI/Bank Central Covenants", url:`${baseUrl}/mfi`},
        { title:"Agro Central Covenants", url:`${baseUrl}/agro`}
    ]}/>
}