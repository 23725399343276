import React from 'react';
import { FormattedMessage } from 'react-intl';


class Pagination extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			curPage: this.props.curPage,
			maxPage: this.props.maxPage
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.curPage !== this.state.curPage || nextState.maxPage !== this.state.maxPage;
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.curPage !== this.props.curPage || nextProps.maxPage !== this.props.maxPage) {
			this.setState({curPage: nextProps.curPage, maxPage: nextProps.maxPage});
		}
	}

	pageChange(newPage, skip) {
		if(this.props.onPageChange) this.props.onPageChange(newPage, skip);
	}

	render() {
		let options = [];
		let startIndex = Math.max(this.state.curPage - 3, 0);
		let endIndex = Math.min(startIndex + 5, this.state.maxPage);

		if (this.state.maxPage >= 5 && (endIndex - startIndex) <= 4) {
			startIndex = endIndex - 5;
		}

		for(let i = startIndex; i < endIndex ; i++){
			let selected = this.state.curPage === (i + 1) ? 'page-selected' : '';
			options.push(<button key={i + 1} onClick={this.pageChange.bind(this, i + 1, (i+1) * this.props.itemsPerPage)} className={selected}>{i + 1}</button>);
		}

		return(
			<div className="pagination-container">
				<div className="previous-wrapper">
					{
						this.state.curPage > 1 ?
							<button type="button" onClick={this.pageChange.bind(this, this.state.curPage - 1)}>
								<FormattedMessage id="app.buttons.previous" />
							</button>
							: null
					}
				</div>

				<div className="pages-wrapper">
					{options}
				</div>

				<div className="next-wrapper">
					{
						this.state.curPage < this.state.maxPage ?
							<button type="button" onClick={this.pageChange.bind(this, this.state.curPage + 1)}>
								<FormattedMessage id="app.buttons.next" />
							</button>
							: null
					}
				</div>
			</div>
		);
	}
}

export default Pagination;