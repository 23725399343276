import { gql } from '@apollo/client';

export const updateInvestmentPeriodicity = gql`mutation updateInvestmentPeriodicity($investmentId: String!, $periodicity: String!) {
	updateInvestmentPeriodicity(investmentId:$investmentId,periodicity:$periodicity) {
		success
		error {
			code
			message
		}
	}
}
`;

export const createCovenant = gql`mutation createCovenant($investmentId: String!, $covenantId: String!) {
	createCovenant(investmentId:$investmentId, covenantId:$covenantId) {
		covenants {
			_id
			centralCovenantId {
				_id
				name
				guide
				links {
					uri
					name
					guide
				}
				fieldType
				range {
					min
					max
				}
				alertThreshold 
			}
			range {
				min
				max
			}
			alertThreshold
			waivers {
				_id
				waiverType
				centralCovenant {
					_id
					name
					guide
					links {
						uri
						name
						guide
					}
					fieldType
					range {
						min
						max
					}
					alertThreshold 
				}
				range {
					min
					max
				}
				alertThreshold
				startDate
				endDate
			}
		}
		response {
			error {
				code
				message
			}
			success
		}
	}
}
`;

export const updateCovenant = gql`mutation updateCovenant($investmentId: String!, $covenantId: String!, $covenant: Object!) {
	updateCovenant(investmentId:$investmentId, covenantId:$covenantId, covenant:$covenant) {
		success
		error {
			code
			message
		}
	}
}
`;

export const deleteCovenant = gql`mutation deleteCovenant($investmentId: String!, $covenantId: String!, $centralCovenantId: String!) {
	deleteCovenant(investmentId:$investmentId, covenantId: $covenantId, centralCovenantId:$centralCovenantId) {
		success
		error {
			code
			message
		}
	}
}
`;

export const validateCovenant = gql`mutation validateCovenant($investmentId: String!, $covenantId: String!) {
	validateCovenant(investmentId:$investmentId, covenantId:$covenantId) {
		success
		error {
			code
			message
		}
	}
}
`;
