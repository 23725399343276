import { gql } from '@apollo/client';

const fundsQuery = gql`query fundsQuery {
    funds {
        _id
        importedId
        name
        reportingPeriodicity
    }
}
`;

export default fundsQuery;
