import React from 'react';
import moment from 'moment';
import { contextToFull } from '../../ui/components/periodToText';


export const WizardHeader = ({clientName,clientStatus, title ,context,cancel}) => {
    return (<section className="header">
        <div className="header-content">
            <div className="report-header">
                <span>{clientName} {clientStatus!=="active" ? <b style={{color:'red'}}>{clientStatus}</b> : null} - </span>
                <span className="header-details">{title} {contextToFull(context)}</span>
                {cancel ? <span onClick={cancel} className="icon-close" /> : null}
            </div>
        </div>
    </section>);
};
