import {history} from '../../../router';
import {apolloClient, store} from '../../store';
import profileQuery from '../../../graph/queries/profileQuery';
import AuthHelper from '../../../auth/AuthHelper';
import moment from 'moment';
import Meteor from '../../../Meteor';
import { LOGIN_BUSY,METEOR_LOGGED_IN, LOADING_COMPLETE,LOADING_USER, LOGGED_OUT,SET_USER,SWITCH_LANGUAGE, SWITCH_CURRENCY
} from './actions';

const {replace, push} = history;


export function switchLanguage(language) {
    moment.locale(language);
    return {
        type: SWITCH_LANGUAGE,
        payload:language
    }
}

export function switchCurrency(currency) {
    return {
        type: SWITCH_CURRENCY,
        payload:currency
    }
}

export function loggingIn() {
    return {
        type: LOGIN_BUSY
    };
}

export function loggedIn() {
    return {
        type: LOADING_COMPLETE
    };
}

export function loggedOut() {
    return {
        type: LOGGED_OUT
    };
}

export function redirLogin() {
    console.log("redir login")
    push('/login');
}

export function logOut() {
    return function (dispatch) {
        console.log("logged out dispatch")
        dispatch(loggedOut());
        Meteor.logout(() => {
            console.log("post meteor logout")
            redirLogin();
        });


    };
}

function performLogin(username,password) {
    const data = new URLSearchParams();
    data.append('username', username);
    data.append('password', password);

  

    return fetch('/auth/login', {
        method: 'post',
        body: data,
    }).then(response => response.json());
}

export function login(username,password,push) {
    return function(dispatch,getState) {
        dispatch(loggingIn());
        performLogin(username,password).then((data)=>{
            console.log("LOGIN DATA",data);
            const { token } = data;
            localStorage.setItem('token',token);
           
            /*Meteor.setUserId(data.id)
            dispatch({
                type:METEOR_LOGGED_IN,
                payload:data.id
            });
           
            dispatch(fetchProfile(true,push));*/
        }).catch(e=> {
            const state = getState();
            dispatch({
                type:LOGGED_OUT
            });
            //startup=false;
        });
      
        //startup=false;
    }
   /* Meteor.autorun(() => {
        let userId = Meteor.userId();
        if(userId) {
           
            const state = store.getState();

            if(state.session.user ===null || state.session.user.userAccountId != userId) {
                store.dispatch(fetchProfile());
            }
            startup=false;
        } else {
            store.dispatch({
                type:LOGGED_OUT
            });
            startup=false;
        }

    });*/
};

export function setProfile(profile) {
    return {
        type:SET_USER,
        payload:profile
    }
}

export function setLogin(userId,redir,push) {
    return function (dispatch,getState) {
        Meteor.setUserId(userId);
        dispatch({
            type:METEOR_LOGGED_IN,
            payload:userId
        });
        const state = getState();

        if(state.session.user ===null || state.session.user.userAccountId != userId) {
            dispatch(fetchProfile(redir,push));
        }
    }
}


export function fetchProfile(redir,push) {
    return function (dispatch) {
        dispatch({
            type:LOADING_USER
        });

        apolloClient.query({
            query: profileQuery,
            forceFetch: true
        }).then((result) => {
          
            AuthHelper.decryptAndSave(result.data.whoAmI.permissions, Meteor.userId());

            const {
                __typename,
                ...user
            } = result.data.whoAmI.person;
            user.permissions = result.data.whoAmI.permissions;
            moment.locale(user.language);

            dispatch(setProfile({user,clients:result.data.clients, regions:result.data.regions}));

            
            if(redir && push) {
                if(AuthHelper.isGranted('level','INCOFIN',Meteor.userId())) {
                    push('/incofin/');

                } else if(AuthHelper.isGranted('level','CLIENT',Meteor.userId())) {
                    push('/client/' + result.data.whoAmI.clientKey + '/');

                }
            }
            dispatch({
                type:LOADING_COMPLETE
            });


        }).catch((error)=> {
            // handle error;
            console.log("ERROR PROFILE",error);
        });
    };
}
