import { Tag } from "antd";
import { FormattedMessage } from "react-intl";

const COLORS = {
    rejected:'#E62633',
    approved:'#93BB36',
    submitted:'#f19836',
    in_progress:'#535353',
    in_review:'#535353',
    created:'#12B6CF'
}


export const StatusTag=({status,...rest})=> {

    return <Tag style={{borderRadius:"4px"}} {...rest} color={COLORS[status]} >
        <FormattedMessage id={`grid.reports.status.${status}`} defaultMessage={"-"}/>
        </Tag>
}