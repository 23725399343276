import { 
    create, all
} from 'mathjs'

import { patchMathJsKeyScoping } from './patch';
import { itemExists, meanLoose, sumLoose } from './extensions';



let _math = patchMathJsKeyScoping(create(all,{
    absTol:0.01
}));


let mathImportConfig = {};

/** MATHJS EXTENSION **/

/** patch functions to handle ignores instead of throwing exceptions  **/

const _round = _math.round;

const ignore = (a)=>a===null || a===undefined || isNaN(a) || a===_math.Infinity;

mathImportConfig.add = (a, b) => ignore(a) || ignore(b) ? null : a + b
mathImportConfig.subtract = (a, b) =>  ignore(a) || ignore(b) ? null : a - b
mathImportConfig.multiply = (a, b) =>  ignore(a) || ignore(b) ? null : a * b
mathImportConfig.divide = (a, b) =>  ignore(a) || ignore(b) ? null : a / b
mathImportConfig.round = (a,b=0) =>  ignore(a) || ignore(b) ? null : _round(a,b)


/** add loose alternatives **/
/** loose = disregard unexisting **/


/** add utility functions **/
mathImportConfig.exists = itemExists;
mathImportConfig.sumLoose = sumLoose;
mathImportConfig.meanLoose = meanLoose;



_math.import(mathImportConfig,{override:true});

/** @constant
    @type {Partial<math.MathJsStatic>}
*/
export const math = _math;