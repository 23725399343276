

export const AntdTheme = {
    "token": {
      "controlHeight": 28,
      "colorPrimary": "#09415D",
      "colorSecondary":"#535353",
      "colorTertiary": "#93c11f",
      "borderRadius": 1
    },
    "components": {
      "InputNumber": {
        "activeBg": "#fff19a",
       "colorFillTertiary": "#fff19a",

        "colorFillSecondary": "#fff19a",
        "colorBgContainer": "#fff19a",
     // "colorBgContainer": "rgb(114, 46, 209)"
      }
    }
  }