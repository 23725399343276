import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import Loader from '../loader/index.jsx';
import SelectField from '../fields/select/index';
import AuthHelper from '../../../auth/AuthHelper';
import fundsQuery from '../../../graph/queries/incofin/funds';
import countryQuery from '../../../graph/queries/countryQuery';
import investmentMgrQuery from '../../../graph/queries/investmentMgrQuery';
import Timeline from '../../../features/TimeSlicer/index';
import ClientsGridSelector from '../clientsGridSelector/index';
import RequiredLabel from '../RequiredLabel/index';
import _ from 'underscore';
const reportingFrequencyOptions = [
	{value: 'monthly', label: 'MONTHLY'},
	{value: 'quarterly', label: 'QUARTERLY'}
];


 class ExportInvestmentsForm extends React.Component {
	constructor(props) {
		super(props);

		this.isIncofin = AuthHelper.isGranted('level', 'INCOFIN');
		this.isClient = AuthHelper.isGranted('level', 'CLIENT') && !this.isIncofin;

		this.state = {
			chosenRegions: [],
			countryList: [],
			chosenCountries: [],
			chosenClients: this.isClient || (this.props.client && this.props.client._id) ? [this.props.client._id] : [],
			currency: [],
			// clients: this.filterClients(props.clients, ['active', 'workout']),
			clients: this.filterClients(props.clients, ['active', 'workout'], this.props.client ? this.props.client.clientType : "MFI"),
			selectedClients: [],
			unselectedClients: [],
			funds: [],
			reportingFrequency: null,
			investmentManagers: []
		};

		this.onSelectedClientRowClick = this.onSelectedClientRowClick.bind(this);
		this.onUnselectedClientRowClick = this.onUnselectedClientRowClick.bind(this);
		this.onRegionChange = this.onRegionChange.bind(this);
		this.onCountryChange = this.onCountryChange.bind(this);
		this.onFundsChange = this.onFundsChange.bind(this);
		this.onReportingFrequencyChange = this.onReportingFrequencyChange.bind(this);
		this.onInvestmentManagerChange = this.onInvestmentManagerChange.bind(this);
    this.onClientTypeChange = this.onClientTypeChange.bind(this);
	}
  // issue-65
	// filterClients(clients, status) {
	// 	return clients.filter((client) => {
	// 		return status.indexOf(client.status) > -1;
	// 	});
	// }
  filterClients(clients, status, type) {
		return clients.filter((client) => {
			return ((type=="AGRO" && client.clientType=="AGRO") || (type!="AGRO" && client.clientType!="AGRO")) &&  status.indexOf(client.status) > -1;
		});
	}

	componentWillReceiveProps(props) {
		this.isClient = AuthHelper.isGranted('level', 'CLIENT');
		this.isIncofin = AuthHelper.isGranted('level', 'INCOFIN');
		this.isClient = this.isClient && !this.isIncofin;
	}

	onSelectedClientRowClick(val) {
		const {
			selectedClients,
			unselectedClients
		} = this.state;

		let clientIdx = selectedClients.findIndex((c) => c._id === val._id); //  _.findWhere(this.state.selectedClients, {key: val.key});
		if (clientIdx > -1) {
			this.setState(() => {
				return {
					selectedClients: Array.from(new Set([...selectedClients.slice(0, clientIdx), ...selectedClients.slice(clientIdx + 1)])),
					unselectedClients: Array.from(new Set([...unselectedClients, selectedClients[clientIdx]])).sort((s, t) => s.officialName.toUpperCase() !== t.officialName.toUpperCase() ? s.officialName.toUpperCase() > t.officialName.toUpperCase() ? 1 : -1 : 0)
				}
			});
		}
	}

	onUnselectedClientRowClick(val) {
		const {
			selectedClients,
			unselectedClients
		} = this.state;

		let clientIdx = unselectedClients.findIndex((c) => c._id === val._id); //  _.findWhere(this.state.selectedClients, {key: val.key});
		if (clientIdx > -1) {
			this.setState(() => {
				return {
					unselectedClients: Array.from(new Set([...unselectedClients.slice(0, clientIdx), ...unselectedClients.slice(clientIdx + 1)])),
					selectedClients: Array.from(new Set([...selectedClients, unselectedClients[clientIdx]])).sort((s, t) => s.officialName.toUpperCase() !== t.officialName ? s.officialName.toUpperCase() > t.officialName.toUpperCase() ? 1 : -1 : 0)
				}
			});
		}
	}

	onRegionChange(field, val, previousValue) {
		if (!val) {
			return;
		}

		const {
			regions,
			countryQuery: data
		} = this.props;

		let regionValues = val;

		let countries = regions.reduce((arr, r) => {
			if (regionValues.some((rv) => rv.value === r._id)) {
				arr = [...arr, ...r.countries];
			}
			return arr;
		}, []);

		if (this.state.chosenCountries && this.state.chosenCountries.length > 0) {
			countries = [...countries, this.state.chosenCountries];
		}
		countries = [...(new Set(countries))]; // distinct values
		countries = data.countries.reduce((arr, c) => {
			if (countries.indexOf(c._id) > -1) {
				arr.push({value: c._id, label: c.name});
			}
			return arr;
		}, []);

		this.setState(() => {
			return {
				chosenRegions: regionValues,
				countryList: countries
			};
		});
		this.onCountryChange(null, countries);
	}

	onCountryChange(field, countries, previousValue) {
		let values = _.pluck(countries, 'value');
		this.setState(() => {
			return {
				chosenCountries: values
			};
		});

		this.calculateClients(values, this.props.clients, ['active', 'workout']);
	}
  // isssue-65
  onClientTypeChange(field, val) {
		let filteredClients = this.filterClients(this.props.clients, ['active', 'workout'], val);
		this.setState(() => ({
			// clientType: val,
			clients: filteredClients
		}));

		this.calculateClients(this.state.chosenCountries, filteredClients, ['active', 'workout']);
	}

	onFundsChange(field, val) {
		this.setState(() => ({
			funds: _.pluck(val, 'value')
		}));
	}

	onInvestmentManagerChange(field, val) {
		this.setState(() => ({
			investmentManagers: _.pluck(val, 'value')
		}));
	}

	onReportingFrequencyChange(field, val) {
		this.setState(() => ({
			reportingFrequency: val
		}));
	}

	calculateClients(countryList, clients, clientStatus) {
		let selectedClients = clients.filter((c) => c.address && countryList.indexOf(c.address.country) > -1 && clientStatus.indexOf(c.status) > -1);
		let unselectedClients = Array.from(new Set([...(this.state.unselectedClients ? this.state.unselectedClients : [])]));
		if (unselectedClients.length > 0) {
			selectedClients = selectedClients.filter((c) => !unselectedClients.some((uc) => uc._id === c._id));
		}

		this.setState(() => {
			return {
				unselectedClients,
				selectedClients: selectedClients.sort((s, t) => s.officialName.toUpperCase() !== t.officialName ? s.officialName.toUpperCase() > t.officialName.toUpperCase() ? 1 : -1 : 0)
			};
		});
	}

	isValid() {
		let periodValues = this.periodSelector.getValue();
		return !(!this.periodSelector || !periodValues || !periodValues.periodType || !periodValues.periods || periodValues.periods.length < 1);
	}

	getValues() {
		if (this.isValid()) {
			return {
				periods: this.periodSelector.getValue(),
				funds: this.state.funds,
				regions: this.state.chosenRegions.map((region) => region.value),
				clients: this.props.client && this.props.client._id ? [this.props.client._id] : this.state.selectedClients.map((client) => client._id),
				reportingFrequency: this.state.reportingFrequency,
				investmentManagers: this.state.investmentManagers
			};
		} else {
			this.container.error('Please fill in all required information (Period).');
		}
	}

	render() {
		if (this.props.countryQuery.loading || this.props.fundsQuery.loading || this.props.imQuery.loading) {
			return <Loader/>;
		}

		const {
			fundsQuery: {
				funds
			},
			countryQuery: {
				countries
			},
			imQuery: {
				investmentManagers
			},
			regions
		} = this.props;

		let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

		const clientOptions = [];
		let client = this.props.client ? {
			value: this.props.client._id,
			label: this.props.client.name
		} : null;

		if (this.isIncofin && this.state.clients && this.state.clients.length > 0) {
			this.state.clients.forEach((client) => {
				clientOptions.push({
					value: client._id,
					label: client.name
				});
			});
		} else if (this.props.client) {
			clientOptions.push({
				value: this.props.client._id,
				label: this.props.client.name
			});
		}

		const regionOptions = [];
		if (regions && regions.length > 0 && Array.isArray(regions)) {
			regions.forEach((region) => {
				regionOptions.push({
					value: region._id,
					label: region.region
				});
			});
		}

		const fundsOptions = [];
		if (funds && funds.length > 0 && Array.isArray(funds)) {
			funds.forEach((fund) => {
				fundsOptions.push({
					value: fund._id,
					label: fund.name
				});
			});
		}

		const investmentManagersOptions = [];
		if(investmentManagers && investmentManagers.length > 0 && Array.isArray(investmentManagers)) {
			investmentManagers.forEach((im) => {
				investmentManagersOptions.push({
					value: im._id,
					label: im.fullName
				});
			});
		}

		let countryOptions = [];
		if (this.state.countryList && this.state.countryList.length > 0 && Array.isArray(this.state.countryList)) {
			countryOptions = this.state.countryList;
		} else if (countries && countries.length > 0 && Array.isArray(countries)) {
			countries.forEach((country) => {
				countryOptions.push({
					value: country._id,
					label: country.name
				});
			});
		}

		return (
			<div className="export-form">
				<ToastContainer ref={(c) => this.container = c} toastMessageFactory={ToastMessageFactory} className="toast-top-right"/>

				<div className="required-fields-label">
					<FormattedMessage id="required-fields.label"/>
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="export.labels.period"/>
						<RequiredLabel/>
					</label>
					<div style={{width: '75%'}}>
						<Timeline ref={(c) => this.periodSelector = c} from={2000} to={moment().year()}
											withOnChangeTimeout={false} filter={[]} />
					</div>
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="export.labels.funds"/>
					</label>
					<SelectField multi={true} value={this.state.funds} options={fundsOptions}
											 placeholder={'Select fund(s) ... '} onChange={this.onFundsChange} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="export.labels.investment-managers"/>
					</label>
					<SelectField multi={true} value={this.state.investmentManagers} options={investmentManagersOptions}
											 placeholder={'Select investment manager(s) ... '} onChange={this.onInvestmentManagerChange} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="export.labels.reporting-frequency"/>
					</label>
					<SelectField multi={false} options={reportingFrequencyOptions}
											 placeholder={'Select reporting frequency ... '} onChange={this.onReportingFrequencyChange} />
				</div>

				{
					(this.isIncofin && this.props.client && this.props.client._id) || this.isClient?
						<div className="form-group">
							<label>
								<FormattedMessage id="export.labels.clients"/>
							</label>
							<SelectField multi={false} value={client} readOnly={true}
													 placeholder={'Select client(s) ... '} />
						</div>
						: null
				}

				{
					this.isIncofin && this.props.client && !this.props.client._id ?
						<div>
							<div className="form-group">
								<label>
									<FormattedMessage id="export.labels.regions"/>
								</label>
								<SelectField multi={true} value={this.state.chosenRegions} options={regionOptions}
														 placeholder={'Select region(s) ... '} onChange={this.onRegionChange} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="export.labels.countries"/>
								</label>
								<SelectField multi={true} value={this.state.chosenCountries} options={countryOptions}
														 placeholder={'Select country(s) ... '} onChange={this.onCountryChange} />
							</div>
              {/* issue-65 */}
              <div className="form-group">
                <label>
                  <FormattedMessage id="admin.form.client.clientType"/>
                </label>
                <SelectField multi={false} options={[{value: 'AGRO', label: 'AGRO'}, {value: 'MFI', label: 'MFI/BANK'}]} readOnly={false}
                              placeholder={'Select clienttype ... '} onChange={this.onClientTypeChange}/>
              </div>

							<div className="form-group">
								<label>
									<FormattedMessage id="export.labels.clients"/>
								</label>
								<ClientsGridSelector unselected={this.state.unselectedClients} selected={this.state.selectedClients}
																		 itemsPerPage={this.props.clients.length}
																		 onSelectedRowClick={this.onSelectedClientRowClick}
																		 onUnselectedRowClick={this.onUnselectedClientRowClick} />
							</div>
						</div>
						: null
				}
			</div>
		);
	}
}

export default graphql(fundsQuery, {name: 'fundsQuery', withRef: true})(
	graphql(countryQuery, {name: 'countryQuery', withRef: true})(
	graphql(investmentMgrQuery, {
		options: (props) => ({
			variables: {
				clientId: props.client && props.client._id ? props.client._id : null
			}
		}),
		name: 'imQuery', withRef: true
	})(ExportInvestmentsForm)))
