import './resetpassword.scss';
import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer,ToastMessageAnimated } from 'react-toastr';
import Loader from '../../../components/loader/index.jsx';
import { processEmailForReset, processTokenForReset } from '../../../../graph/mutations/user';
import { graphql } from '@apollo/client/react/hoc';
import NewPasswordForm from '../../../components/login/forgotPassword/newPasswordForm';

/**
 * DESCRIPTION: registration page
 * ROUTE: /register OR /
 * ACCESSIBLE BY: invitation link (everyone)
 * DATA: N/A
 */


class ForgotPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            busy:false,
            inputEmail:'',
            inputToken:'',
            mailSent: false,
            tokenValidated: false,
            validatedUser: null,
            invalidToken: false
        };

        this._validations = new Map();
    }

    componentWillReceiveProps(nextProps) {

    }

    onEmailInputChange(event) {
        this.setState({inputEmail: event.target.value})
    }

    onTokenInputChange(event) {
        this.setState({inputToken: event.target.value})
    }

    onPressSend() {
        if (!this.state.inputEmail) {
            this.refs.container.error('Please fill in all required fields');
            return;
        }
        this.setState({busy: true});
        this.props.processEmailForReset({
            variables: {
                userEmail: this.state.inputEmail
            }
        }).then(({data}) => {
            if (data.processEmailForReset.success) {
                this.setState({mailSent: true});
            } else if(data.processEmailForReset.error && this.refs.container) {
                this.setState({busy:false});
                this.refs.container.error(data.processEmailForReset.error.code, data.processEmailForReset.error.message);
            }
        }).catch((error) => {
            this.setState({busy:false});
            if (this.refs.container) this.refs.container.error(error.message);
        });
        return;
    }

    onPressValidate() {
        if (!this.state.inputToken) {
            this.refs.container.error('Please fill in the token');
            return;
        }
        this.setState({busy: true});
        this.props.processTokenForReset({
            variables: {
                userEmail: this.state.inputEmail,
                token: this.state.inputToken
            }
        }).then(({data}) => {
            if (data.processTokenForReset) {
                this.setState({tokenValidated: true, validatedUser: data.processTokenForReset});
            } else {
                this.setState({invalidToken: true, busy: false});
            }
        }).catch((error) => {
            this.setState({busy:false});
            if (this.refs.container) this.refs.container.error(error.message);
        });
        this.setState({busy: false});
        return;
    }

    onGoBackButton() {
        this.setState({invalidToken: false});
    }

    renderInvalidTokenInput() {
        return <div className="resetpassword-form-wrapper">
            <div className="resetpassword-form">
                <p>The code you entered is not valid. Please try again.</p>
                <button onClick={this.onGoBackButton.bind(this)}>Go Back</button>
            </div>
        </div>
    }

    renderEmailInput() {
        return <div className="resetpassword-form-wrapper">
            <div className="resetpassword-form">
                <p>Enter your account's email address to continue:</p>
                <input className="email-input" type="text" value={this.state.inputEmail} onChange={this.onEmailInputChange.bind(this)}/>
                <button className="confirm" onClick={this.onPressSend.bind(this)}>Send</button>
            </div>
        </div>;
    }

    renderTokenInput() {
        return <div className="resetpassword-form-wrapper">
            <div className="resetpassword-form">
                <p>Enter the code that has been sent to your email account.</p>
                <input className="token-input" type="text" value={this.state.inputToken} onChange={this.onTokenInputChange.bind(this)}/>
                <button className="confirm" onClick={this.onPressValidate.bind(this)}>Validate</button>
            </div>
        </div>
    }

    renderPasswordForm() {
        return <NewPasswordForm user={this.state.validatedUser} />
    }

    render() {
        if(this.props.data && this.props.data.loading) {
            return <Loader/>;
        }

        let ToastMessageFactory = React.createFactory(ToastMessageAnimated);
        let view = this.state.tokenValidated ? this.renderPasswordForm() : this.state.mailSent ? (this.state.invalidToken) ? this.renderInvalidTokenInput() : this.renderTokenInput() : this.renderEmailInput();
        let welcome = null;

        return (
            <div className="registration-wrapper">
                <ToastContainer ref="container" toastMessageFactory={ToastMessageFactory} className="toast-top-right" />

                <section className="registration fadeInUp">
                    <div className="logo">
                        <span>reporting</span>
                    </div>
                    {welcome}
                    {view}
                </section>
            </div>
        );
    }
}

export default graphql(processEmailForReset, { name: 'processEmailForReset' })(
    graphql(processTokenForReset, { name: 'processTokenForReset' })(ForgotPasswordPage))