//import 'react-select/dist/react-select.css';
import React from 'react';
import Select from 'react-select';
import { BaseField } from '../base';
import moment from 'moment';

const momentMonths = moment.months();
const months = momentMonths.map((month, idx) => {
    return {
        label: month,
        value: idx + 1
    };
});



class SelectMonths extends BaseField {
    componentDidMount() {
        this.validate(this.getValue());
    }

    onChange(val) {
        super.onChange(val ? val["value"] ? val.value : val : null);
    }

    onComponentChange(val) {
    	let value = null;

    	if(val) {
				const mom = moment([(new Date()).getFullYear(),val.value-1,1]).endOf('month');
				value = {
					day:mom.date(),
					month:mom.month()+1
				} ;
			}

			const previousValue = this.state.value;
			this.setState({value});
			this.onChange(value, previousValue);
    }

    render() {
    	const {
				valid,
				value
			} = this.state;

			const {
				multi,
				readOnly,
				placeholder,
				activePageIsSubmitted
			} = this.props;

			const classNames = !valid && activePageIsSubmitted ? 'invalid' : null;

			return(
					<Select name="form-field-name" className={classNames} multi={multi} disabled={readOnly} value={value ? value.month : value} backspaceRemoves={false}
									options={months} onChange={this.onComponentChange.bind(this)} placeholder={placeholder} onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)} />
			);
    }
}


export default SelectMonths;
