import { useIntl } from "react-intl";
import { useFormField } from "../hooks";
import classNames from "classnames";
import { FormulaLink } from "../../NewFormula/components/Links";
import { useSelector } from "react-redux";

function getPostValue(valueType,currency="USD") {
	switch(valueType) {
		case "percentual":
			return "%"
		case "currency":
			return currency;
	}
	return ""
}
/*
let intlEur = new Intl.NumberFormat("nl-BE",{
	minimumFractionDigits: 2,      
   maximumFractionDigits: 2,
})*/


export function formatReportedValueType(value,valueType,intl,currency="USD") {
	let dispValue = "-"
	let dispPost = getPostValue(valueType,currency);
	//debugger;
	if(valueType==="fieldset") return "";

	if(value!= null && value!=undefined) {

		switch(valueType) {
			case "numeric":
				dispValue=intl.formatNumber(value, {
					minimumFractionDigits: 0,
					maximumFractionDigits: 2
				});
				break;
			case "currency":
				
				dispValue=intl.formatNumber(value / 100, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				});
				break;
			case "percentual":
				dispValue=intl.formatNumber(value, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				});
				break;
			case 'date':
				dispValue = intl.formatDate(value, {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric'
					});
				break;
			default:
				dispValue=value;
		}
	
	}
	let res= dispValue + (dispPost ? ` ${dispPost}` : "");
	return res;
}

export function formatValueType(value,valueType,intl,currency="USD") {
	let dispValue = "-"
	let dispPost = getPostValue(valueType,currency);
	//debugger;
	if(valueType==="fieldset") return "";

	if(value!= null && value!=undefined) {

		switch(valueType) {
			case "numeric":
			case "currency":
				dispValue=intl.formatNumber(value / 100, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				});
				break;
			case "percentual":
				dispValue=intl.formatNumber(value / 100, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				});
				break;
			case 'date':
				dispValue = intl.formatDate(value, {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric'
					});
				break;
			default:
				dispValue=value;
		}
	
	}
	let res= dispValue + (dispPost ? ` ${dispPost}` : "");
	return res;
}
/*
{item.formula ? <FormulaLink name={item?.name?.value} formula={item.formula} scope={null} >
*/
const FormFieldDisplay = ({path,valueType,name,formula})=> {
	const currency = useSelector(s=>s?.session?.currency||"USD");
	const [valueField,{numeric,config}]= useFormField(path);
	const intl = useIntl();
	const vle = formatValueType(valueField?.value,valueType,intl,currency);
	//debugger;
	return <div className="field-value">
		{formula ? <FormulaLink name={name}  fullPath={path} formula={formula} numeric={numeric} >{vle}</FormulaLink> : vle}
	</div>;

}

export const FormFieldDisplayRender = (item,path,numeric)=> {
	
	const cls = classNames("field-display-value",{
		"field-has-formula":!!item.formula
	})

	let valueType = item.fieldType;
	if(item?.settings?.fieldType) valueType=item.settings.fieldType;

	return <div className="field-display-value"> 
		<FormFieldDisplay name={item?.name?.value} formula={item.formula} path={path} valueType={valueType}/>
		
	</div>
}

