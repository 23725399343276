import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { GraphTableLocal } from '../table/GraphTable';
import clientsQuery from '../../../graph/queries/incofin/clients';
import Panel from '../dashboard/panel/panel.jsx';

/**
 * DESCRIPTION: show ALL clients on the "/incofin/admin/clients" route
 *   => only accessible by the Incofin-admin or admin user
 */

const DefaultProps = {
	itemsPerPage: 5
};
/*
class AdminClientsGridView extends React.Component {
	constructor(props) {
		super(props);
	}

	//bind the onClick event on a row to the parent onRowClick property (f.ex. navigate to another route)
	onRowClick(row) {
		if (this.props.onRowClick && typeof this.props.onRowClick === 'function') {
			this.props.onRowClick(row.props.data);
		}
	}

	//render method
	render() {
		const clients = this.props.clients || [];

		let gridData = clients.map((client) => {
			return {
				...client,
				country : client.address ? client.address.countryName : ""
			};
		});

		

		return (
			<div>
				<GridView results={gridData}
									initialSort={'name'}
									columnMetadata={columnMeta}
									resultsPerPage={this.props.itemsPerPage}
									onRowClick={this.onRowClick.bind(this)} />
			</div>
		);
	}
}
*/



const gridDataMapper = (clients)=>{return clients.map((client) => {
    return {
        id: client._id,
        _id: client._id,
        importedId: client.importedId,
        key: client.key,
        name: client.name,
        officialName:client.officialName,
        clientType: client.clientType,
        country:client.address ? client.address.countryName : null,
        worldRegion: client.worldRegion,
        status: client.status,
		__original:client
    };
}); };

const columnMeta = [
	{"columnName": 'importedId', sortable:true, "displayName": "TM1 ID"},
	{"columnName": 'name', sortable:true,defaultSort:'ASC', "displayName": <FormattedMessage id="grid.clients.columns.name"/>},
	{"columnName": 'officialName', sortable:true, "displayName": <FormattedMessage id="grid.clients.columns.officialName"/>},
	{"columnName": 'clientType', sortable:true, "displayName": <FormattedMessage id="grid.clients.columns.type"/>},
	{"columnName": 'country', sortable:true, "displayName": <FormattedMessage id="grid.clients.columns.country"/>},
	{"columnName": 'worldRegion', sortable:true, "displayName": <FormattedMessage id="grid.clients.columns.region"/>},
	{"columnName": "status", sortable:true, "displayName": <FormattedMessage id="grid.reports.columns.status"/>}
];

const AdminClientsGridView = ({
	onRowClick,
	showPager,
	enableSort,
	showFilter,
	itemsPerPage=DefaultProps.itemsPerPage,
	reports,
	onFilterChange,
	fullMode,
	showCustomFilter

})=> {
	const onRowClickCb = useCallback((row,original)=>{
		onRowClick(original);
	},[onRowClick]);
	

	return (
		<GraphTableLocal qry={clientsQuery} 
				options={{
					fetchPolicy: "no-cache",
				}}
				tableDataMapper={gridDataMapper}
				dataSelector={(d)=>d && d.clients ? d.clients : []}
				columnMetadata={columnMeta} showFilter={true} showPager={true} 
				resultsPerPage={itemsPerPage}
										onRowClick={onRowClickCb} 
				enableSort={true}
				/>
		
	);
}

export default AdminClientsGridView;