import {  ESSENTIALS_LOADED, ESSENTIALS_LOADING, DATA_FAILURE} from '../actions/data'

const initialState = {
    loaded:-1,
    reportConfigs:null,
    categorisedForms:null,
    error:null
};


export const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case DATA_FAILURE:
            return {
                ...state,
                loaded:-1,
                error:action.payload
            };
        case ESSENTIALS_LOADING:
            return {
                ...state,
                loaded:0,
                error:null
            };
        case ESSENTIALS_LOADED:
            return {
                ...state,
                loaded:1,
                ...action.payload
            };
    }

    return state;
};



