import './registration.scss';
import React, { useRef } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
//import Loader from '../../components/loader/inlineLoader';
import Loader from '../../components/loader/spinner';
import LoginField from '../../components/login/field/index.jsx';
import LoginSelect from '../../components/languageSelector/login';
import invitationQuery from '../../../graph/queries/registration/invitation';
import validationQuery from '../../../graph/queries/registration/validation';
import { createValidationToken } from '../../../graph/mutations/user';
import UpdateInvitation from './updateInvite';
import DeclineInvite from './declineInvite';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { NotFound } from '../../components/400/NotFound';
import { Card } from '../../components/Card';
import Meteor from '../../../Meteor';
//import validationQuery from '../../../graph/queries/registration/validation';

let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

const Welcome = ({fullName})=> {
    return <p className="welcome-text">Welcome {fullName}</p>
}


const STATES = {
    INVALID:"INVALID",
    REGISTER:"REGISTER",
    ACCEPTED:"ACCEPTED",
    VALIDATIONTOKEN_SEND:"VALIDATIONTOKEN_SEND",
    VALIDATIONTOKEN_DECLINED:"VALIDATIONTOKEN_DECLINED",
    VALIDATIONTOKEN_VALIDATED:"VALIDATIONTOKEN_VALIDATED",
    DECLINED:"DECLINED",
    
};

const TokenValidation = ({inviteId, onValidated,createResult})=> {
    const { success, error } = createResult;

    const [verifyToken,{called,data,loading}] = useLazyQuery(validationQuery,{
        fetchPolicy:"network-only"
    });
    
    const inputRef = useRef();

    const onVerifyToken = useCallback(()=> {
     
        if(inputRef.current && inputRef.current.value) {
            verifyToken({variables:{
                inviteToken:inviteId,
                verifyToken:inputRef.current.value.trim()
            }});
        } else {
            onValidated(false);
        }
        
    },[verifyToken,inviteId,onValidated]);

    useEffect(()=> {
        if(!loading && called && data) {
            if(data.validateToken &&  data.validateToken.success) {
                onValidated(true,inputRef.current.value.trim(),data.validateToken);

            } else {
                onValidated(false);

            }
          
        
        }

    },[called,data,onValidated,loading])

    useEffect(()=> {
        if(inputRef.current) {
            inputRef.current.focus();
        }
    });

  

    return <div className="validation-form-wrapper">
    <p>Enter the validation token, which has been sent to your email address.</p>
    <p className="highlighted">This token will only be valid for 10 minutes!</p>
    
        { loading ? <div className="validation-form"><Loader/><p>Validating</p></div> : <div className="validation-form">
            <input ref={inputRef} maxLength="23" type="text" />
            <div className='button-list'>
            <button className="confirm" onClick={onVerifyToken}>Validate</button>
            </div>
        </div>
        }
       
    </div>;
}

const Wrapper = ({match})=> {
    if(!match || !match.params || !match.params.inviteId) {
        return <NotFound/>
    }
    Meteor.logout();
    return <RegistrationPage inviteId={match.params.inviteId}/>
}

const RegistrationPage = ({inviteId})=> {

   
    
    const {loading,data} = useQuery(invitationQuery,{
        variables:{
            inviteToken: inviteId,
        }, fetchPolicy:'no-cache'
        
    });
 
    const [createToken, { called, loading:sendingToken,data:tokenData }]= useMutation(createValidationToken,{
        fetchPolicy:"no-cache"
    });

    const [status,setStatus] = useState(STATES.REGISTER);

    const [verifyToken,setVerifyToken] = useState();
    

    useEffect(()=> {
        if(!loading && (!data || !data.invitedUser) ) {
           
            setStatus(STATES.INVALID);
        }
    },[data,loading]);

    const toastRef = useRef();

    const onAcceptance = useCallback((accepted)=> {
        if(!accepted) {
            setStatus(STATES.DECLINED);
        } else {
            const {invitation} = data.invitedUser;
            createToken({variables:{invitation}}).then(({data})=> {
                if(data) {
                    setStatus(STATES.ACCEPTED);
                }

            });

        }

    },[createToken,data]);

    const backToStart = useCallback(()=> {
        setStatus(STATES.REGISTER)
    });

   
    const onTokenValidated = useCallback((validated,token)=> {
        
        if(validated) {
            setVerifyToken(token);
            setStatus(STATES.VALIDATIONTOKEN_VALIDATED);
           
        } else {
            setVerifyToken(null);
            setStatus(STATES.VALIDATIONTOKEN_DECLINED);
        }
    });

   
   let title ="Welcome";
    let body = null;

    if(loading) {
        body = <Loader/>;
      
    } else if (sendingToken) {
        body = <div><Loader/><h3>Sending validation token.</h3></div>;
    }  else {
        if(!data || !data.invitedUser) {
            body=<div><p>This token is invalid.</p></div>;
        } else {
            switch(status) {
                case STATES.REGISTER: {
                    body = <div>
                        <p>
                            Welcome {data.invitedUser.fullName}, 
                            </p>
                        <p>
                        Before you can start using our online portal we need to verify your email address.
                        </p>
                        <p>
                            Do you accept to join the Incofin Reporting Portal?
                        </p>
                        <div className='button-list'>
                        <button className="decline confirm" onClick={()=>onAcceptance(false)}>Decline</button>
                
                    <button className="accept confirm" onClick={()=>onAcceptance(true)}>Accept</button>
                    </div>
                </div>
                
                ;
                    break;
                }
                case STATES.ACCEPTED: {
                    let createResult = tokenData && tokenData.createValidationToken ? tokenData.createValidationToken : null;
                    title="Email verification"
                    body = <TokenValidation inviteId={inviteId} createResult={createResult} onValidated={onTokenValidated} />;
                    break;
                }
                case STATES.DECLINED: {
                    title ="Invitation declined";
                    body = <DeclineInvite onChangedMind={backToStart} inviteId={inviteId}/>;
                    break;
                }
                case STATES.INVALID: {
                    body=<div><p>This token is invalid.</p></div>;
                    break;
                }
                case STATES.VALIDATIONTOKEN_VALIDATED: {
                    body=<UpdateInvitation verifyToken={verifyToken} user={data.invitedUser} inviteId={inviteId}/>
                    break;
                }
                case STATES.VALIDATIONTOKEN_DECLINED: {
                    body=<><p>The token you've provided is invalid.</p>
                    <div className='button-list'>
                <button className="confirm" onClick={backToStart}>Retry</button>
                </div>
                    </>;
                    break;
                }
                default: {
                    
                }

            }
        }
    }
/*
<header>
    <h1>Pure <strong>CSS</strong> Steps</h1>
    <p>... a sassy "Step By Step" process.</p>
  </header>
*/
    
    return <div className="registration"><div className="content-wrapper">
  <div className="logo">
                    <span>reporting</span>
                </div>
  <section className='wrapper'>
    <article>
        <div className='article-body'>
    <header>
              <h2>{title}</h2>
         
            </header>
            <div>
                {body}
            </div>
            </div>
            </article>
</section></div></div> ;


    return (
        <div className="registration-wrapper">
            <ToastContainer ref={toastRef} toastMessageFactory={ToastMessageFactory} className="toast-top-right" />

            <section className="registration fadeInUp">
                <div className="logo">
                    <span>reporting</span>
                </div>
                {body}
                

               
                
            </section>
        </div>
    );

}

export default Wrapper