import { useCallback } from "react";
import IconMathFunction from "../../Form/FunctionIcon";
import { useFormulaViewerAction, useFormulaViewerShow } from "../hooks/useFormulaViewer";
//import { useFormulaDrawer } from "../hooks/useFormulaDrawer";





export const FormulaIconLink = ({
    name,definition,formula,config,fullPath,path,numeric,scope
})=> {
    
    const show = useFormulaViewerAction();

    const onClick = useCallback(()=> {
        //debugger;
        let cfg=config;
        let p = path;
        let id = [
            config,
            fullPath, 
            path,
            numeric,
            scope ? "scope" : ""
        ].filter(f=>!!f).join(".");
       
        if(!config && fullPath) {
            let parts = fullPath.split(".");
            cfg = parts.shift();
            p = parts.join(".");
        
        }
        show({
            id,
            formula,
            numeric,
            scope,
            target:{
                config:cfg,
                path:p
            }
        })
    },[]);

    return <div className="formula-icon"><IconMathFunction onClick={onClick}/></div>;

}

const activeStyle = {
    display: "inline-block",
    background: "lightgoldenrodyellow",
    color: "black"
}

export const FormulaLink = ({name,definition,formula,config,fullPath,path,numeric,scope,children}) => {

    const show = useFormulaViewerAction();
    const viewerId = useFormulaViewerShow();
    let id = [
        config,
        fullPath, 
        path,
        numeric,
        scope ? "scope" : ""
    ].filter(f=>!!f).join(".");

    const onClick = useCallback(()=> {
        let cfg=config;
        let p = path;

        
       
        if(!config && fullPath) {
            let parts = fullPath.split(".");
            cfg = parts.shift();
            p = parts.join(".");
        
        }
       
        show({
            id,
            formula,
            numeric,
            scope,
            target:{
                config:cfg,
                path:p
            }
        })
    },[id,name,formula,config,path,fullPath,numeric,scope,definition]);

    return <span style={
        viewerId==id ? activeStyle : {}
    } onClick={onClick} className="formula-link">{children}</span>
}
