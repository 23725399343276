import './gridview.scss';
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactDataGrid from 'react-data-grid';
import Table from 'rc-table'
import Pagination from 'rc-pagination'
import "rc-pagination/assets/index.css";
import Loader from '../../loader/spinner';
/**
 * DESCRIPTION: used in several GridViews (incofin clients, report instances, ...)
 *   => clean way to define a griddle with standard props and bindings
 *   => only thing that is variable are the columns, the results, column/row metadata coming in
 *   => still very customizable
 */


const DefaultProps = {
	resultsPerPage: 5,
	initialSortAscending: true
};

class GridView extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const props = Object.assign({
			useGriddleStyles: this.props.useGriddleStyles !== undefined ? this.props.useGriddleStyles : false,
			showFilter: this.props.showFilter !== undefined ? this.props.showFilter : true,
			enableSort: this.props.enableSort !== undefined ? this.props.enableSort : true,
			showPager: this.props.showPager !== undefined ? this.props.showPager : this.props.results.length > this.props.resultsPerPage,
			noDataMessage: 'No data could be found',
			sortAscendingComponent: (<span className="fa fa-sort-asc"/>),
			sortDescendingComponent: (<span className="fa fa-sort-desc"/>),
		}, this.props);

		delete props.children;

		console.log("columnMetadata",this.props.columnMetadata)
		props.columns = this.props.columnMetadata.map(x => x["columnName"]);

		const rc_cols= this.props.columnMetadata.map(x => {
			let c= {
				dataIndex:x["columnName"],
				key:x.columnName,
				title:x.displayName
			};
			if(x.customComponent) {
				c.render = (value, row, index)=>React.createElement(x.customComponent,{data:value})
			}
			return c;
		}) ;
		

		props.gridClassName = classNames(
			'o-gridView',
			{
				'o-gridView--filterable': props.showFilter
			}
		);
		props.tableClassName = classNames(
			'o-gridView__table',
			{
				'o-gridView__table--clickable': typeof props.onRowClick === 'function',
				'o-gridView__table--sortable': props.enableSort,
				'o-gridView__table--paged': props.showPager
			}
		);
		var tableRcClassName = classNames(
			'rc-table-inco',
			{
				'rc-table-inco--clickable': typeof props.onRowClick === 'function',
				'rc-table-inco--sortable': props.enableSort,
				'rc-table-inco--paged': props.showPager
			}
		);

		var rcRowClick = typeof props.onRowClick === 'function' ? 
			(record,index)=>({onClick:()=> props.onRowClick({props:{data:record}}) }): null


		

		return (
			<div>
				<h2>ReactDataGrid</h2>
		
			<Table className={tableRcClassName} columns={rc_cols} data={props.results} onRow={rcRowClick} >

			</Table>
			
								 </div>
		);
	}
}

/*
<h2>Griddle</h2>
			<Griddle {...props} data={props.results} nextText={<FormattedMessage id="app.buttons.next"/>}
							 previousText={<FormattedMessage id="app.buttons.previous"/>}
							 filterPlaceholderText={this.props.intl.formatMessage({id:"grid.search-placeholder"})} />
		*/

const usePagination = (data=[],rowsPerPage=10)=> {

	const [page,setPage] = useState(1);
	const [pageSize,setPageSize] = useState(rowsPerPage);
	const [total,setTotal] = useState(data.length);
	const [activeData,setActiveData] = useState(data.slice(pageSize*page-pageSize,pageSize*page));

	useEffect(()=> {
		console.log('GridViewPaged Pagination update',data)
		setActiveData(data.slice(pageSize*page-pageSize,pageSize*page));

	},[page,pageSize,data,total,setActiveData]);

	const onPageChange= useCallback((current,pageSize)=> {
		//debugger;
		setPage(current);
		setPageSize(pageSize);
	},[setPage,setPageSize])


	return [activeData,{page,pageSize,total,onPageChange}];
}


export const GridviewNew = ({loading,columnMetadata,results,onRowClick,showFilter=false,showPager=false,enableSort=false})=> {

	var tableRcClassName = classNames(
		'rc-table-inco',
		{
			'rc-table-inco--clickable': typeof onRowClick === 'function',
			'rc-table-inco--sortable': enableSort
		}
	);


	const rc_cols= columnMetadata.map((x,idx) => {
		let c= {
			dataIndex:x["columnName"],
			key:x.columnName+idx,
			title:x.displayName
		};
		if(x.customComponent) {
			c.render = (value, row, index)=>React.createElement(x.customComponent,{metadata:c, rowData:row, data:value})
		}
		return c;
	}) ;
	console.log("rc_cols",rc_cols)

	var rowClick = useCallback((record,index)=> {
		return {
			onClick:()=>onRowClick({props:{data:record}})
		}
	},[onRowClick])


	return loading ? <Loader/> : <Table rowKey="_id" className={tableRcClassName} columns={rc_cols} data={results} onRow={rowClick} scroll={{ y: 300 }}>

	</Table>
}

//export default injectIntl(GridviewNew);

export default injectIntl((props)=>{
	if(props.showPager) {
		return <GridviewPaged {...props}/>
	}
	return <GridviewNew {...props}/>
})

export const GridviewPaged = ({loading,columnMetadata,results,onRowClick,showFilter=false,enableSort=false,resultsPerPage=10})=> {

	const [activeData,{page,pageSize,onPageChange,total},updateSettings] = usePagination(results,resultsPerPage);

	const gridProps= {loading,columnMetadata,results:activeData,onRowClick,showFilter,enableSort};

	

console.log("GridviewPaged",pageSize,total,page,results.length)
	return <div>
		<GridviewNew {...gridProps}/>
 <Pagination
				pageSize={pageSize}
				onChange={onPageChange}
				current={page}
				total={total}
			/>
	</div>
}

/*
{ props.showPager ? <Pagination
				pageSize={countPerPage}
				onChange={updatePage}
				current={currentPage}
				total={props.results.length}
			/>
	  : null}*/