import dayjs from "dayjs";
import { createContext, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import './periodSelector.scss'
import { Radio } from "antd";
import { useContext } from "react";
import { ClientContext } from "../../containers/client";
import { useSelector } from "react-redux";
import classNames from "classnames";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";


const PeriodSelectorContext = createContext({
    selection:[]
});

const PeriodSelectContext = createContext({selections:[],toggle:()=>{}})

const usePeriodSelection = (numeric)=> {
    const {selection,setSelection,statusMap} = useContext(PeriodSelectContext);
    const periodNum = useMemo(()=> {
        return parseInt(dayjs(numeric.toString(),"YYYYMM").endOf("month").format("YYYYMMDD"))
    },[numeric])

    const toggle = useCallback(()=> {
        setSelection((s)=> {
            if(s.includes(periodNum)) {
                return [...s.filter(v=>v!=periodNum)]
            } else {
                return [...s,periodNum]
            }
        })
    },[periodNum]);

    const status = useMemo(()=> {
        if(!statusMap) return "";
        return statusMap.has(periodNum) ? statusMap.get(periodNum) : "unselectable";
    },[periodNum,statusMap])

    return [selection.includes(periodNum),status,toggle];
}


const MonthPart = ({children,numeric})=> {
    const [isSelected,status,toggle] = usePeriodSelection(numeric);

    const cls = classNames("period-select-month",{
        "selected":isSelected
    },status || "no-status")

    return <abbr onClick={toggle} className={cls} data-numeric={numeric}>{children}</abbr>
}


const MonthsInner = ({year})=> {

    const lang = useSelector(s=>s.session.language);
    const months = useMemo(()=> {
        
        return dayjs.monthsShort();
    },[lang]);

 

    return months.map((m,idx)=> { 
        let num = `${year}${(idx+1).toString().padStart(2,"0")}`;
        return <MonthPart key={idx} numeric={num}>{m}</MonthPart>
        
        //<abbr key={idx} className="period-select-month" data-month={(idx+1).toString().padStart(2,"0")}>{m}</abbr>
    } )
    
}

const FinanciaPeriodBlock = ({period,isFirst=false})=> {

    const lang = useSelector(s=>s.session.language);
    const months = useMemo(()=> {
        return dayjs.monthsShort();
    },[lang]);

    const renderMonths = useMemo(()=> {
        let from = dayjs(period.start.toString(),"YYYYMMDD").startOf("month");
        const to = dayjs(period.end.toString(),"YYYYMMDD");
        let arr = [];

        if(isFirst && from.month()!=0) {
            let year = from.year();
            let key =from.endOf("month").format("YYYYMMDD") +"year"
            arr.push(<span key={key} data-year={year}>{year}</span>)
        }

        if(from.month()%3!=0) {
            let m = from.month()%3;
            for(let i=0; i<m;i++) {
            arr.push(<abbr className="period-select-spacer" key={"spacer-"+period.financialYearId.toString()+i}></abbr>)
            }
        }

        

        while(from.isBefore(to)) {
            let month = from.month();
            let year = from.year();
            let key =from.endOf("month").format("YYYYMMDD")
            if(month==0) {
                arr.push(<span key={key+"year"} data-year={year}>{year}</span>)
            }
            //
            
           // <abbr key={key} className="period-select-month" data-month={(month+1).toString().padStart(2,"0")}>{months[month]}</abbr>
            arr.push(<MonthPart key={key} numeric={from.format("YYYYMM")}>{months[month]}</MonthPart>)
            from = from.add(1,"month");
        }
        return arr;

    },[period,months]);

 
    return <div className="period-select-year" data-year={period.financialYear}>
    <div className="period-select-year-header">FY {period.financialYear}</div>
   <div className="period-select-year-body" >
   {renderMonths}
   </div>
  
   </div>
    
}
 

const MonthsGrid = memo(({year})=> {

    const months = useMemo(()=> {
        return <MonthsInner year={year}/>
    });



    return <div className="period-select-year" data-year={year}>
         <div className="period-select-year-header">{year}</div>
        <div className="period-select-year-body">
        {months}
        </div>
       
        </div>

//    return <div className="year-block"> {months.map(m=> <abbr>{m}</abbr> )}</div>

})


const PeriodSelectorBody = ({rangeStart=2012,rangeEnd=2024})=> {

    const months = dayjs.monthsShort();
    const years = Array.from({length:rangeEnd-rangeStart+1},(v,index)=>rangeStart+index)

    let calendarAreas = years.reduce((res,year)=> {
        
        months.forEach((m,idx)=> {
             let num = `${year}${(idx+1).toString().padStart(2,"0")}`
            if(idx % 4 == 0) {
                res[0].push(num)
            } else if(idx % 2 ==0) {
                res[1].push(num)
            } else {
                res[2].push(num)
            }
        })
        return res;
    },[[],[],[]]);

    calendarAreas = calendarAreas.map(c=>`"${c.join(" ")}"`).join(" ")



    return <div className="period-body">
        <div className="my-grid calendar" style={{
          
        }}>
        {years.map((y)=><MonthsGrid year={y}/>)}
        </div>
       
    </div>
}

/*
 <div className="my-grid financial">
        {years.map((y)=>months)}
        </div>
*/

const CalendarPeriodSelector = ({from,to})=> {

    
    const years = useMemo(()=> {
        if(!from || !to) {
            return [];
        }
        const yearFrom = dayjs(from.toString(),"YYYYMMDD").year();
        const yearTo = dayjs(to.toString(),"YYYYMMDD").year();

        return Array.from({length:yearTo-yearFrom+1},(v,index)=>yearFrom+index)

    },[from,to]) 
    const ref = useRef(null);

    useEffect(() => {
       if (ref.current){
          ref.current.scrollLeft = ref.current.scrollWidth; // px
       }
    }, []);

    //console.log("years",years);

    return <div className="period-select-body period-select-calendar" ref={ref}>
        {years.length==0 ? "No data" : years.map((y)=><MonthsGrid year={y} key={y}/>)}
    </div>
}



const FinancialPeriodSelector = ({periods})=> {

    //.sort((a,b)=>a?.start-b?.start)
    let sorted = [...periods].sort((a,b)=>a?.start-b?.start)
    const ref = useRef(null);

     useEffect(() => {
        if (ref.current){
            console.log("scroll financial")
           ref.current.scrollLeft = ref.current.scrollWidth; // px
        }
     }, []);
 


    return <div className="period-select-body period-select-financial" ref={ref}>
        {
            sorted.map((p,idx)=> <FinanciaPeriodBlock key={p.financialYearId} period={p} isFirst={idx==0}/>)
        }

    </div>
}


const PeriodViewTypes = {
    CALENDAR:"CALENDAR",
    FINANCIAL:"FINANCIAL"
}

const statusGql = gql`query formStatusList($key:String!,$clientId:ID!) {
    formStatusList(key:$key,clientId:$clientId){
        _id
        clientId
        numeric
        status
    }
}
`

export const ConfigPeriodSelector = ({defaultSelection=[],onChange,configKey})=> {
    const {_id } = useContext(ClientContext);
    const {data,loading,called} = useQuery(statusGql,{
        variables:{
            key:configKey,
            clientId:_id
        },
        fetchPolicy:"cache-and-network"
    })

    
    const statusMap = useMemo(()=> {
        if(data?.formStatusList) {
            return new Map(data.formStatusList.filter(f=>f.status!="created").map(f=>[f.numeric,f.status]))
        }
        return null;
    },[data?.formStatusList,_id])

    /*
    const statusMap = (()=> {
        if(data?.formStatusList) {
            return new Map(data.formStatusList.filter(f=>f.status!="created").map(f=>[f.numeric,f.status]))
        }
        return null;
    })();*/
   /* if(loading) {

    }*/
    
    

    return <PeriodSelector defaultSelection={defaultSelection} onChange={onChange}
    statusMap={statusMap}
    />
}

export const PeriodSelector = ({defaultSelection=[],onChange,statusMap=null})=> {

    const client = useContext(ClientContext)
    const [selection,setSelection] = useState(defaultSelection);
    const [viewType,setViewType] = useState(PeriodViewTypes.CALENDAR);





    const { financialPeriods = [] } = client;
    let startAt = Math.min(...financialPeriods.map(f=>f.start));
    let endAt = Math.max(...financialPeriods.map(f=>f.end));

    const refSelected = useRef(selection);
   
    useEffect(()=> {
       if(refSelected.current!=selection && onChange) {
        onChange(selection);
       }
    },[selection,onChange])


//  <div>{startAt} - {endAt}</div>

    return <div className={classNames("period-selector",{
        "period-selector-status":statusMap!=null
    })}>
        <Radio.Group buttonStyle="solid" onChange={(e)=>{setViewType(e.target.value) }} value={viewType} >
      <Radio.Button value={PeriodViewTypes.CALENDAR}>Calendar</Radio.Button>
      <Radio.Button value={PeriodViewTypes.FINANCIAL}>Financial</Radio.Button>
    </Radio.Group>
  
    <div style={{padding:"5px"}} >
        <PeriodSelectContext.Provider value={{
            selection,setSelection,
            statusMap:statusMap
        }}>
    { viewType==PeriodViewTypes.CALENDAR ? <CalendarPeriodSelector
    from={startAt} to={endAt}
    /> : <FinancialPeriodSelector periods={financialPeriods}
    />
    }
    </PeriodSelectContext.Provider>
    </div>
    </div>
    
   
}