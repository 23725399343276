import React from 'react';
import classNames from 'classnames';
import SelectField from '../fields/select/index';
import TextField from '../fields/text/index';



class TranslationItem extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			valid: props.valid
		};

		this.language = props.language;
		this.value = props.value;

		this.onLanguageChange = this.onLanguageChange.bind(this);
		this.onValueChange = this.onValueChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	componentDidMount() {
		this.validate();
	}

	validate() {
		let value = this.value;
		let language = this.language;
		let valid = true;

		if(!language || language === null || language === '' || language.length === 0) {
			valid = false;
		}

		if(!value || value === null || value === '' || value.length === 0) {
			valid = false;
		}

		this.setState(() => ({
			valid: valid
		}));

		if(valid) {
			this.onChange();
		}
	}

	onLanguageChange(field, val, previousValue) {
		this.language = val;

		this.validate();
	}

	onValueChange(field, val, previousValue) {
		this.value = val;

		this.validate();
	}

	onChange() {
		if(this.props.onChange) {
			this.props.onChange(this.language, this.value, this.props.newTranslation);
		}
	}

	onDelete() {
		if(this.props.onDelete) this.props.onDelete(this.props.newTranslation ? 'new' : this.language);
	}

	render() {
		const {
			multiline,
			languageOptions,
			submitted,
			required
		} = this.props;

		let containerClasses = classNames(
			'translation-item-container',
			multiline ? 'multi' : null
		);

		return(
			<div className={containerClasses}>
				<SelectField options={languageOptions} multi={false} onChange={this.onLanguageChange} placeholder={'Select language...'}
										 value={this.language} readOnly={this.language && this.language !== null}
										 activePageIsSubmitted={submitted} validations={{required}} />

				<TextField value={this.value} onChange={this.onValueChange} rows={5}
									 validations={{required, format: {multiLine: multiline}}} activePageIsSubmitted={submitted} />

				<button className="delete" onClick={this.onDelete} />
			</div>
		);
	}
}

export default TranslationItem;