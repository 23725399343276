import { ApolloClient,ApolloLink,from, InMemoryCache , createHttpLink} from "@apollo/client"
import { setContext } from '@apollo/client/link/context';
import { offsetLimitPagination } from "@apollo/client/utilities"
import { onError } from "@apollo/client/link/error";
import { history } from '../router'
//console.log('offsetLimitPagination()',offsetLimitPagination())

const httpLink = createHttpLink({
  uri: '/graphql',
  credentials: 'same-origin',

});

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    console.log("AFTERWARE LINK",response)
    const context = operation.getContext();
    const { response: { headers } } = context;

    if (headers) {
      const refreshToken = headers.get('refreshtoken');
      if(refreshToken) {
        //console.log("SET REFRESHTOKEN",refreshToken)
        localStorage.setItem('token',refreshToken);
      }
    }

    return response;
  });
});


const errorLink = onError(({response, graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path,extensions,...rest }) => {
      
      if(extensions && extensions.code==="UNAUTHENTICATED") {
        history.replace('/login',{ from: window.location.pathname })
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      ,rest)
    }
      
    );
  if (networkError) {
    console.log('[Network error]:',networkError)
  }
});

let apolloClient = null;

export const createApolloClient = (reduxStore)=> {
  if(apolloClient) return apolloClient;
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');

    let adaptedHeaders = {
      ...headers
    };

    const reduxState =reduxStore.getState();

    console.log("REDUX ST",reduxState)
    if(reduxState && reduxState.session && reduxState.session.language) {
      adaptedHeaders["Incofin-Set-Language"] =  reduxState.session.language;
    } else {
      adaptedHeaders["Incofin-Set-Language"] = "en";
    }

    // return the headers to the context so httpLink can read them
    if(token) {
      return {
        headers: {
          ...adaptedHeaders,
          authorization: token ? `Bearer ${token}` : "",
        }
      }
    } else {
      return adaptedHeaders;
    }
    
  });

  apolloClient = new ApolloClient({
    link: from([authLink,afterwareLink,errorLink,httpLink]),
      cache: new InMemoryCache({
        
      }),
      onError: ({ networkError, graphQLErrors }) => {
  
        console.log("graphQLErrors", graphQLErrors)
        console.log("networkError", networkError)
      },
      connectToDevTools: true
    });

    return apolloClient
}

/*
export const apploClient = new ApolloClient({
  link: from([authLink,afterwareLink,errorLink,httpLink]),
    cache: new InMemoryCache({
      
    }),
    onError: ({ networkError, graphQLErrors }) => {

      console.log("graphQLErrors", graphQLErrors)
      console.log("networkError", networkError)
    },
    connectToDevTools: true
  });*/
