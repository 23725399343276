import { gql } from '@apollo/client';

const defaultBody = `
	_id
        client {
            _id
            officialName
            key
        }
        config {
            _id
            key
            name {
              value
            }
        }
        configId
        schedule {
            due
        }
        settings {
            currency
            reportingScale
        }
        context {
            category
            text
            numeric
            period {
              month
              quarter
              year
              financialYear
              financialYearPeriod
            }
        }
        status
        reportedBy { 
            _id
        		fullName 
        }
        auditedBy { 
            _id
        		fullName 
        }
        lastUpdated
	`

export const updateReportingStatus = gql`mutation updateReportingStatus($instanceId: String!, $status: String!) { 
      updateReportingStatus(instanceId: $instanceId, status: $status) {
		_id
		status
	  }

}`;

export const deleteReportInstance = gql`mutation deleteReportInstance($instanceId: ID!,$includeForms:Boolean) {
	deleteReportInstance(instanceId: $instanceId,includeForms:$includeForms) {
		success
		error {
			code
			message
		}
	}
}`;

export const reopenReportInstance = gql`mutation reopenReportInstance($instanceId: ID!,$clientId:ID!,$numeric:Int!) {
	reopenReportInstance(instanceId:$instanceId,clientId:$clientId,numeric:$numeric) {
		_id
		status
	}
}`;

export const insertReportInstance = gql`mutation insertReportInstance($configId: String!, $clientId: String!, $numeric: Int!) {
	insertReportInstance(configId: $configId, clientId: $clientId, numeric: $numeric) {
		${defaultBody}
	}
}`;

export const covenantIsInBreach = gql`mutation covenantIsInBreach($clientId:String!, $reportId:String!, $values:Object!) {
	covenantIsInBreach(clientId:$clientId, reportId:$reportId, values:$values) {
		success
		error {
			code
			message
		}
	}
}`;
