import {useDebounce, useDebounceCallback} from '@react-hook/debounce'
import RCTable from 'rc-table';
import classNames from 'classnames';
import React, { useRef, useEffect, useMemo, useCallback,useState } from 'react';
import Loader from '../loader/spinner';
import Filter from '../grids/custom/filter';

import Pagination from 'rc-pagination'
import "rc-pagination/assets/index.css";

import { Table } from './Table';
import { useQuery } from '@apollo/client';

// !! needs revisement

export const GraphTableLocal = ({qry, options={},vars={},tableDataMapper=(d)=>d,dataSelector,onRowClick, ...props})=> {
    const { loading, error, data,fetchMore,refetch,previousData } = useQuery(qry,{
        ...options,
        notifyOnNetworkStatusChange: true,
        variables:{
            ...vars
        }
    }); 

    var rowClick = useCallback((rawTableRecord)=> {
        if(onRowClick) {
            console.log('GraphTableLocal rowclick', rawTableRecord,rawTableRecord.props.data.__original)
            onRowClick(rawTableRecord,rawTableRecord.props.data.__original);
        }
	
	},[onRowClick]);


    return <Table {...props} onRowClick={onRowClick ? rowClick : null} data={tableDataMapper(dataSelector(data))} loading={loading}  />
   
}

// !! needs revisement (double load, state management, opt for reducer)

export const GraphTableServer = ({qry,options={},vars=null,counter=0,tableDataMapper=(d)=>d,dataSelector,totalSelector, ...props})=> {

  //  console.log("GRAPH TABLE",vars);
  
    const [myVars,setMyVars] = useState({ limit:props.resultsPerPage || 10, skip:0, ...(vars || {})});
   
   // const [filter, setfilter] = useState(null);
    const [filterDebounced,setfilter] = useDebounce(null, 500);
    const [page,setPage] = useState(1);
    const [total,setTotal] = useState(0);
    const [pageSize,setPageSize] = useState(props.resultsPerPage || 10);
    const pageRef = useRef();
    const filterRef = useRef();
    const { loading, error, data,fetchMore,refetch,previousData } = useQuery(qry,{
        ...options,
        notifyOnNetworkStatusChange: true,
        variables:myVars,
        fetchPolicy: 'network-only'
    }); 
   //  console.log("GRAPH TABLE - loading",loading);
  //   console.log("GRAPH TABLE - DATA",data);

     useEffect(()=> {
        if (counter>0) refetch();
     },[counter,refetch])

  
     useEffect(()=> {
     //  console.log('GRAPH TABLE - SET VARS',{...myVars,...vars},vars)
        setMyVars({...myVars,...vars});
      
     },[vars]);
    // console.log('GRAPH TABLE - VARS',vars)
   
    useEffect(() => {
        pageRef.current = page;
        filterRef.current=null;
       // console.log('GRAPH TABLE - RESET PAGE AND FILTER')
    },[]);
 
    useEffect(() => {
       if( filterRef.current != filterDebounced) {
      //  console.log('GRAPH TABLE - REFETCH FILTER')
        filterRef.current=filterDebounced;
        refetch({
            skip:0,
            limit:pageSize,
            filter:filterDebounced    
        })
       }
        
        
    },[filterDebounced]);


    useEffect(() => {
        if(page != pageRef.current){
          // console.log('GRAPH TABLE - REFETCH PAGE')
            pageRef.current=page;
            refetch({
                skip:(page-1) * pageSize,
                limit:pageSize,
                filter:filterDebounced
                });
        }
     
       
    },[page]);

    useEffect(()=> {
       // console.log('GRAPH TABLE - REFETCH VARS')
        refetch({
            skip:0,
            filter:filterDebounced,
            ...myVars
        })
    },[myVars])

    useEffect(()=> {
       // console.log('GRAPH TABLE - SET TOTAL ')
     
        setTotal(data ? totalSelector(data) : 0)
    },[data,loading]);

    const pagingState =  useMemo(()=>({
        page,
        pageSize,
        total,
        local:false
    }),[page,pageSize,total]);


    const dataSet = useMemo(()=>{
        if(loading && previousData && !data) {
            return tableDataMapper(dataSelector(previousData));
        } else if(loading && !previousData) {
            return [];
        } else if(data){
            return tableDataMapper(dataSelector(data));
       
        }else if(previousData){
            return tableDataMapper(dataSelector(previousData));
       
        }

    },[data,loading,previousData,tableDataMapper,dataSelector])
   
    return <>
    <Table {...props} data={dataSet} loading={loading && dataSet.length===0} pagingInfo={pagingState} onFilter={setfilter} onPageChange={setPage} />
    </>
}

//skip: 0, limit: 25