import { useQuery } from '@apollo/client'
import Select from 'react-select';


const GraphSelect = ({query,optionMapping, ...opts})=> {
    const {data,loading} = useQuery(query);

    return <Select {...opts} isLoading={loading} options={loading ? [] : optionMapping(data)}/>

}

export default GraphSelect;