import { push as _push } from '../../../router';
import {  } from "react-router-dom";
import AuthHelper from '../../../auth/AuthHelper';
import Meteor from '../../../Meteor';

export const NAVIGATION_MENU_SET = 'NAVIGATION/MENU/SET';
export const NAVIGATION_MENU_RESET = 'NAVIGATION/MENU/RESET';


export function resetNavigation() {
    return {
        type:NAVIGATION_MENU_RESET
    }
}

const push = (loc)=> {
	return ()=>_push(loc)
}

export function setClientNavigation(clientId,clType) {

	
    return function (dispatch, getState) {

			const {
				reportConfigs,
				categorisedForms
			} = getState().data;
			const basePath = `/client/${clientId}`;

			let myReportConfigs = reportConfigs.filter(r=>r.clientTypes.includes(clType))
			let formsMenus = categorisedForms.find(c=>c.clientType==clType)?.menu || [];

			let menu = [
				{
					messageId: 'navigation.menu.dashboard',
					path: basePath,
					action: push(`${basePath}/`),
					iconName: 'icon-dash'
				},
				{
					messageId: 'navigation.menu.investments',
					path: `${basePath}/investments`,
					action: push(`${basePath}/investments`),
					iconName: 'icon-investments'
				},
				{
					messageId: 'navigation.menu.reporting',
					iconName: 'icon-reports',
					path: `${basePath}/reports/`,
					action: push(`${basePath}/reports/`),
					children: myReportConfigs.map((it) => {
						return {
							title: it.name.value,
							path: `${basePath}/reports/${it.key}`,
							action: push(`${basePath}/reports/${it.key}`)
						};
					})
				},
				{
					messageId: 'navigation.menu.export',
					iconName: 'icon-export',
					path: `${basePath}/export/`,
					action: push(`${basePath}/export/`),
					children: [
						{
							messageId: 'navigation.menu.export.factsheet',
							path: `${basePath}/export/factsheet`,
							action: push(`${basePath}/export/factsheet`)
						},
						{
							messageId: 'navigation.menu.reports',
							path: `${basePath}/export/reports`,
							action: push(`${basePath}/export/reports`)
						},
						{
							messageId: 'navigation.menu.export.investments',
							path: `${basePath}/export/investments`,
							action: push(`${basePath}/export/investments`)
						}
					]
				}
			];

			if (formsMenus) {
				formsMenus.forEach((gcf) => {
					var men_children = gcf.forms.map((it) => {
						return {
							title: it.name,
							path: `${basePath}/data/${gcf.key}/${it.key}`,
							action: push(`${basePath}/data/${gcf.key}/${it.key}`)
						};
					});

					if(gcf.key=="organisation" || gcf.key=="financial") {
						men_children.splice(0, 0, {
							title:{ en: 'Financial Periods'},
							path: `${basePath}/data/${gcf.key}/financial_periods`,
							action: push(`${basePath}/data/${gcf.key}/financial_periods`)
						});
					}

					

					menu.push({
						messageId: `navigation.menu.forms.${gcf.key}`,
						iconName: `icon-${gcf.key}`,
						path: `${basePath}/data/${gcf.key}/`,
						action: push(`${basePath}/data/${gcf.key}/${gcf.forms[0].key}`),
						children: men_children
					});

					
				});
			}

			dispatch({
				type: NAVIGATION_MENU_SET,
				menu,
				navigationType: 'CLIENT'
			});
    };
}



export function setIncofinNavigation() {
    return function (dispatch, getState) {

        const isAdmin = AuthHelper.isGranted('level','ADMIN');
        const {
            reportConfigs,
            categorisedForms
        } = getState().data;

        const basePath = `/incofin`;


        let menu= [
            {
                messageId: 'navigation.menu.dashboard',
                path: basePath,
                action: push(`${basePath}/`),
                iconName: 'icon-dash'
            },
						{
							messageId: 'navigation.menu.investments',
							path: `${basePath}/investments`,
							action: push(`${basePath}/investments`),
							iconName: 'icon-investments',
							children: [{
								title: 'MFI/Bank',
								path: `${basePath}/investments/mfi`,
								action: push(`${basePath}/investments/mfi`)
							},
							{
								title: 'Agro',
								path: `${basePath}/investments/agro`,
								action: push(`${basePath}/investments/agro`)
								}		
						]
						},
            {
                messageId: 'navigation.menu.reports',
                iconName: 'icon-reports',
                path: `${basePath}/reports/`,
								action: push(`${basePath}/reports/`),
                children: reportConfigs.map((it) => {
                    return {
                        title: it.name?.value || it.key,
                        path: `${basePath}/reports/${it.key}`,
                        action: push(`${basePath}/reports/${it.key}`)
                    };
                })
            },
            {
                messageId: 'navigation.menu.clients',
                action: push(`${basePath}/clients/`),
                path: `${basePath}/clients/`,
                iconName: 'icon-organisation'
            },
            {
                messageId: 'navigation.menu.export',
                iconName: 'icon-export',
				path: `${basePath}/export/`,
                action: push(`${basePath}/export/`),
								children: [
									{
										messageId: 'navigation.menu.export.factsheet',
										path: `${basePath}/export/factsheet`,
										action: push(`${basePath}/export/factsheet`)
									},
									{
										messageId: 'navigation.menu.reports',
										path: `${basePath}/export/reports`,
										action: push(`${basePath}/export/reports`)
									},
									{
										messageId: 'navigation.menu.export.investments',
										path: `${basePath}/export/investments`,
										action: push(`${basePath}/export/investments`)
									},
									{
										messageId: 'navigation.menu.export.covenants',
										path: `${basePath}/export/covenants`,
										action: push(`${basePath}/export/covenants`)
									}
								]
            }
        ];

        if (isAdmin) {
            const baseAdmin = `${basePath}/admin`;
            menu.push({
                messageId: 'navigation.menu.administration',
                iconName: 'icon-admin',
                action: push(`${baseAdmin}/clients`),
                path: `${baseAdmin}`,
                children: [
                    {
                        messageId: 'navigation.menu.administration.clients',
                        path: `${baseAdmin}/clients/`,
                        action: push(`${baseAdmin}/clients/`)
                    },
                    {
                        messageId: 'navigation.menu.administration.users',
                        path: `${baseAdmin}/users`,
                        action: push(`${baseAdmin}/users`)
                    },
                    {
                        messageId: 'navigation.menu.administration.audits',
                        path: `${baseAdmin}/audits`,
                        action: push(`${baseAdmin}/audits`)
                    },
                    {
                        messageId: 'navigation.menu.administration.exchangerates',
                        path: `${baseAdmin}/exchangerates`,
                        action: push(`${baseAdmin}/exchangerates`)
                    },
					{
							messageId: 'navigation.menu.reports',
							path: `${baseAdmin}/reports`,
							action: push(`${baseAdmin}/reports`)
					},
					{
							messageId: 'navigation.menu.administration.covenants',
							path: `${baseAdmin}/covenants`,
							action: push(`${baseAdmin}/covenants`),
							children: [{
									title: 'MFI/Bank',
									path: `${baseAdmin}/covenants/mfi`,
									action: push(`${baseAdmin}/covenants/mfi`)
								},
								{
									title: 'Agro',
									path: `${baseAdmin}/covenants/agro`,
									action: push(`${baseAdmin}/covenants/agro`)
									}		
							]
					}
                ]
            });
        }

        dispatch({
            type: NAVIGATION_MENU_SET,
            menu,
            navigationType:'INCOFIN'
        });
    };
}
