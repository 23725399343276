import { FormulaScope } from "./FormulaScope";



export class ReportingScope extends FormulaScope {

    constructor(currentNumeric,dataObject={},reportedScale=1) {
        super(currentNumeric,dataObject,reportedScale);
    }

    

    #parseReportedValue(value,fieldType) {
        if(!value) return value;
        switch(fieldType) {
            case "currency":
            case "numeric":
            case "percentual":
                return value *100
            
        }
        return value;
    }

    #formatReportedValue(value,fieldType) {
        if(!value) return value;
        switch(fieldType) {
            case "currency":
            case "numeric":
            case "percentual":
                return value / 100
            
        }
        return value;
    }

    // expects path "config.field.field..."
    reportValue(path,value,fieldType) {
        let pathParts = this.pathToParts(path);
        let isCurrency = fieldType==="currency";
        let val = this.#parseReportedValue(value,fieldType);
        let obj = this.get(pathParts);
        if(isCurrency) {
            this.set([...pathParts,"reportedValue"],val,true);
            this.set([...pathParts,"value"],val ? val * this.reportedScale : val,true);
       
        } else {
            this.set([...pathParts,"value"],val,true);
        }

    }

    getFieldValue(path,fieldType,period="current") {
      //  if(period!=="current" && path.indexOf("currentAssets")>-1) debugger;

        let pathParts = this.pathToParts(path,period);
        let isCurrency = fieldType==="currency";
        let isCurrent = period=="current";
        let obj = this.tryGet(pathParts);
        if(obj) {
            if(isCurrency) {
                let val = null;
                if(isCurrent && obj.reportedValue!=undefined) {
                    val = this.#formatReportedValue(obj.reportedValue,fieldType);
                    
                } else {
                    val = this.#formatReportedValue(obj.value,fieldType);
                    if(val) {
                        val = val / this.reportedScale
                    }
                }
                return val;

            } else {
                return this.#formatReportedValue(obj.value,fieldType);
            }
        }
       //throw new Error("Field not found at " + path);
        return null;

    }


   // expects path "config.field.field..."
    getField(path) {
        if(path.endsWith("value")) {
            throw new Error("Use getFieldValue to get a value")
        }
        let pathParts = this.pathToParts(path);
        return this.tryGet(pathParts);
    }

    // for covenants, set entire field?
    // or handle in field self

    subscribeField(path,callback) {
        return this.onChange((c)=>c.fieldPath==path,callback);
      
    }

    unsubscribe(listener) {
        this.offChange(listener)
    }

}