import enTrans from './en';
import frTrans from './fr';
import esTrans from './es';
import ptTrans from './pt';
import ruTrans from './ru';
import {IntlProvider} from "react-intl";
import { connect } from 'react-redux';

export const en = enTrans;
export const fr = frTrans;
export const es = esTrans;
export const pt = ptTrans;
export const ru = ruTrans;

const langs = ["en","fr","es","pt","ru"];

const messages = {
    en,fr,es,pt,ru
}

let defaultLanguage = navigator.language.split(/[-_]/)[0];
if(langs.indexOf(defaultLanguage)==-1) {
    defaultLanguage="en";
}

function mapStateToProps(state) {
    return {
       language: state.session.language
        
    };
}

export const MyIntlProvider = connect(mapStateToProps)(({children,language=defaultLanguage})=> {

   
    const locale = language;
    const message = messages[locale] ? messages[locale] : messages.en;

    console.log('INTL',locale,message);

    return <IntlProvider messages={message} locale={locale} key={locale} defaultLocale="en">
        {children}
        </IntlProvider>;

});
