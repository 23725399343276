import './covenant-list.scss';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';

import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import CovenantItem from './covenant-item';
import SelectCovenant from '../fields/select/covenant';
import Filter from '../grids/custom/filter';
import CovenantForm from '../InvestmentForm/CovenantForm';
import _ from 'underscore';

class CovenantList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			filter: null,
			editing: false,
			saving: false,
			covenant: {},
			creating: false
		};

		this.centralCovenantId = null;
		this.covenantForm = null;

		this.addCovenant = this.addCovenant.bind(this);
		this.editCovenant = this.editCovenant.bind(this);
		this.saveCovenant = this.saveCovenant.bind(this);
		this.cancel = this.cancel.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
		this.onCovenantChange = this.onCovenantChange.bind(this);
	}

	addCovenant() {
		if(this.props.addCovenant && this.centralCovenantId) {
			this.props.addCovenant(this.centralCovenantId);

			this.setState(() => ({
				creating: true
			}));
		}
	}

	editCovenant(covenantId) {
		this.setState(() => ({
			editing: true,
			covenant: _.findWhere(this.props.covenants, {_id: covenantId})
		}));
		this.centralCovenantId = null;
	}

	saveCovenant() {
		this.setState(() => ({
			saving: true
		}));

		if(this.covenantForm.state.valid && this.props.saveCovenant) {
			this.props.saveCovenant(this.covenantForm.state.values);

			this.setState(() => ({
				editing: false,
				creating: false,
				saving: false,
				covenant: {}
			}));

			this.centralCovenantId = null;
		} else if(this.refs.container){
			this.refs.container.error('Invalid covenant form! Please fill in all the required values!');
		}
	}

	cancel() {
		if(this.state.creating && this.props.deleteCovenant && this.centralCovenantId) {
			let covenant = this.props.covenants.find((covenant) => covenant.centralCovenantId._id === this.centralCovenantId);
			if(covenant) {
				this.props.deleteCovenant(covenant._id, this.centralCovenantId);
			}
		}

		this.centralCovenantId = null;
		this.setState(() => ({
			editing: false,
			saving: false,
			creating: false,
			covenant: {}
		}));
	}

	onFilterChange(filterValue) {
		if(this.changeTimeout) clearTimeout(this.changeTimeout);
		this.changeTimeout = setTimeout(() => {
			this.setState(() => ({
				filter: filterValue
			}));
		}, 300);
	}

	onCovenantChange(field, value, previousValue, valid) {
		if(value && valid) this.centralCovenantId = value;
	}

	renderCovenantItems(covenants) {
		let filteredCovenants = [...covenants];

		const {
			filter
		} = this.state;

		const {
			permissions,
			investmentStartDate,
			investmentEndDate,
			intl,
			deleteCovenant,
			deleteWaiver,
			saveWaiver
		} = this.props;

		if(filter && filter.length > 0) {
			filteredCovenants = filteredCovenants.filter((covenant) => {
				return covenant.centralCovenantId.name.includes(filter)
					|| covenant.centralCovenantId.name.toLowerCase().includes(filter);
			});
		}

		let sortedCovenants =
			filteredCovenants.sort((a, b) => {
				return a.centralCovenantId.name.toUpperCase() !== b.centralCovenantId.name ?
					(a.centralCovenantId.name.toUpperCase() > b.centralCovenantId.name.toUpperCase() ? 1 : -1): 0
			});

		return sortedCovenants && sortedCovenants.length > 0 && Array.isArray(sortedCovenants) ?
			sortedCovenants.map((covenant, idx) => {
				return (
					<CovenantItem key={idx} {...covenant} permissions={permissions} intl={intl}
												investmentStartDate={investmentStartDate} investmentEndDate={investmentEndDate}
												editCovenant={this.editCovenant} deleteCovenant={deleteCovenant}
												deleteWaiver={deleteWaiver} saveWaiver={saveWaiver} />
				);
			}) : <div>No covenants</div>;
	}

	render() {
		const {
			covenants,
			permissions,
			error
		} = this.props;

		const {
			editing,
			covenant,
			saving,
			creating
		} = this.state;

		let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

		let covenantToEdit = null;
		if(this.centralCovenantId) {
			covenantToEdit = covenants.find((covenant) => covenant.centralCovenantId._id === this.centralCovenantId);
		}

		return (
			<div className="covenant-list">
				<ToastContainer ref="container" toastMessageFactory={ToastMessageFactory} className="toast-top-right" />

				{
					editing || (creating && !error && covenantToEdit)?
						<div className="covenant-form">
							<CovenantForm values={editing ? covenant : covenants.find((covenant) => covenant.centralCovenantId._id === this.centralCovenantId)}
														saving={saving} ref={(covenantForm) => this.covenantForm = covenantForm} />
							<ReactTooltip delayHide={0} id="tooltip" class="guide-container" scrollHide={true} html={true} />

							<div style={{height: '3em', margin: '2em 0'}}>
								<div className="form-actions">
									<button onClick={this.saveCovenant}>
										<FormattedMessage id="app.buttons.save"/>
									</button>
									<button onClick={this.cancel} className="cancel" style={{margin: '0 1em'}}>
										<FormattedMessage id="app.buttons.cancel"/>
									</button>
								</div>
							</div>
						</div>
						:
						<div>
							<div className="covenant-list-heading">
								<ReactTooltip delayHide={0} id="tooltip" class="guide-container" scrollHide={true} html={true} />
								<Filter onFilterChange={this.onFilterChange} />

								{
									permissions.canAddCovenants ?
									<div className="covenant-select">
                    {/* feature/issue-126 */}
										<SelectCovenant clientId={this.props.clientId} onChange={this.onCovenantChange} />
										<button onClick={this.addCovenant}>
											<FormattedMessage id="app.buttons.add" />
										</button>
									</div>
									: null
								}
							</div>

							<div className="covenant-list-items">
								{
									permissions.canReadCovenants ?
									this.renderCovenantItems(covenants)
									: null
								}
							</div>
						</div>
				}
			</div>
		);
	}
}


export default injectIntl(CovenantList);