import React, { Component  } from 'react';

export default class CustomTick extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			height,
			width,
			fill,
			x,
			y,
			stroke,
			payload,
			textAnchor
		} = this.props;

		let addendum = '';
		let interpol = 1;
		if(payload.value >= 1000000 || payload.value <= -1000000) {
			addendum = 'M';
			interpol = 1000000;
		} else if(payload.value >= 1000 || payload.value <= -1000) {
			addendum = 'K';
			interpol = 1000;
		}

		let val = (payload.value/interpol).toLocaleString() + addendum;

		return (
			<g>
				<text fill={fill} x={x} y={y} dx={textAnchor === 'end' ? -5 : 5} height={height}
							width={width} textAnchor={textAnchor} stroke={stroke}>
					{val}
				</text>
			</g>
		)
	}
}