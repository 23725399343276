import {math} from "../mathjs"

var rePeriodRange = ()=> /^((?<from>\d{1,})-(?<to>\d{1,})(?<unit>[MY]|(FY)){1})$/gi;

export function parseRangeText(rangeText) {
    let res = rePeriodRange().exec(rangeText);
    
    let unit = res.groups?.unit;
    let from = res.groups?.from;
    let to = res.groups?.to;

    let range = {
        from:parseInt(from),
        to:parseInt(to)
    }
    return {
        range,unit
    }
}

function rangeTextToPeriods(rangeText) {
    let {
        range,
        unit
    } = parseRangeText(rangeText)
    let reverse=false;
    // possibly sequence matters (from old to new)
    if(range.from>range.to) {
        reverse=true;
        range = {
            from:range.to,
            to:range.from
        };
    }
    let list = Array.from(
        { length: range.to-range.from+1 },
        (x, i) => {
            let amount = range.from + i;
            return `${amount}${unit}`
        }
      );
    if(reverse) {
        list.reverse()
    }
    return list;
}

export function transformMultiPeriod(node) {
    let range=node.args[0].value;
    let fn = node.args[1];
    let content = node.args[2];

    let periods = rangeTextToPeriods(range); 

    function transformer(child,path,parent) {
        if(child instanceof math.FunctionNode) {
            switch(child.name) {
                case "field":{
                    let cl = child.clone();
                   
                    cl.args[2] = math.parse(`"${transformer.period}"`);
                    return cl;
                    break;   
                }
               
            }
        }
        return child;
    }


    let orginalPeriod = transformer.period;
    let newArgs = []
    for(let p of periods) {
        transformer.period=p;
        newArgs.push(content.cloneDeep().transform(transformer))
    }
    transformer.period=orginalPeriod;
    fn = fn.clone();
    fn.args = newArgs;
    
    return fn;
    //return {fn,periods,};
    
}