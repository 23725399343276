import React from 'react';
import {connect} from 'react-redux';
import { setIncofinNavigation } from '../../redux/actions/navigation';
import { clearClient } from '../../redux/actions/client';
import { FormattedMessage } from 'react-intl';


function mapDispatchToProps(dispatch) {
    return {

        setNavigation: ()=> {
            dispatch(setIncofinNavigation());
        },
        clearClient: ()=> {
            dispatch(clearClient());
        }
    };
}

class IncofinContainer extends React.Component {


    componentWillMount() {
        // load navigation...
        //console.log("will mount incofin container")
      this.props.clearClient();
		this.props.setNavigation();

    }

    componentDidMount() {
        //console.log("mount incofin container")
    }

    componentWillUnmount() {
        //console.log("unmount incofin container")
    }

	render() {

		const  {
			children,
            setNavigation,
			...props
		} = this.props;

       // return <div>TEST</div>

		return children && React.cloneElement(children, { ...props, headerName: <FormattedMessage id="header.client-list.my-dashboard"/> });
	}
}


export default connect(null,mapDispatchToProps)(IncofinContainer)
