import './login.scss';
import React, { useCallback, useRef, useState } from 'react';
import Meteor from '../../../Meteor';
import {connect} from 'react-redux';
import {Link, useHistory,useLocation} from 'react-router-dom';

import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import LoginField from '../../components/login/field/index.jsx';
import LoginSelect from '../../components/languageSelector/login';
import {fetchProfile, loggingIn, loggedOut, switchLanguage,login} from '../../../redux/actions/session';
import Loader from '../../components/loader/spinner.jsx';


/**
 * DESCRIPTION: login page
 * ROUTE: /login OR /
 * ACCESSIBLE BY: everyone
 * DATA: N/A
 */



function mapDispatchToProps(dispatch) {
	return {
		
		switchLanguage: (language) => {
			dispatch(switchLanguage(language));
		}
	};
}

function mapStateToProps(state) {
	return {
		language: state.session.language
	};
}

const LoginPage = ({
	language,
	switchLanguage})=> {


	const iamRef = useRef()
	const langRef =useRef()
	const inmybagRef = useRef()
	const containerRef =useRef()
	const location = useLocation();
	const history = useHistory();
	const [authenticating,setAuthentication] = useState(false);

	let { from } = location.state || { from: { pathname: "/" } };
	console.log('FROM',from)
	if(typeof(from)==='string') {
		from = { pathname:from};
	}
	if(from.pathname.indexOf('login')>-1) {
		from.pathname='/';
	} 

	const onLogon = useCallback((e)=>{
		e.preventDefault();

		Meteor.logout();

		if (iamRef.current.validate() && inmybagRef.current.validate()) {
			setAuthentication(true);
			const data = new URLSearchParams();
			data.append('username', iamRef.current.getValue());
			data.append('password', inmybagRef.current.getValue());
		
		  
		
			fetch('/auth/login', {
				method: 'post',
				body: data,
			}).then(response => response.json()).then((data)=> {
				const { token } = data;
				localStorage.setItem('token',token);
				setTimeout(()=> {
					//setAuthentication(false);
			
					window.location = from.pathname;
				},200)
			
			}).catch((e)=> {
				containerRef.current.error("Failed to authenticate");
				setAuthentication(false);
			})
			
		}

		return false
	},[from,iamRef,inmybagRef,containerRef,history]);

	let ToastMessageFactory = React.createFactory(ToastMessageAnimated);
	

	return (
		<div className="logon-wrapper">
			<ToastContainer ref={containerRef} toastMessageFactory={ToastMessageFactory} className="toast-top-right"/>

			<section className="logon fadeInUp">
				<div className="logo">
					<span>reporting</span>
				</div>

				<div className="logon-body">
					<form onSubmit={onLogon}>
						<LoginSelect disabled={authenticating} fieldName="language" ref={langRef} label="language" value={language}
												 action={authenticating ? ()=>{} : switchLanguage}>
						</LoginSelect>
						<LoginField disabled={authenticating} fieldName="iam" ref={iamRef} label="email/username"
												placeHolder="type your username here" autoFocus={true}
												inputType="text"
												validations={
													{
														required: true
													}
												}
						>
						</LoginField>
						<LoginField disabled={authenticating} fieldName="inmybag" ref={inmybagRef} label="password"
												placeHolder="type your password here"
												inputType="password"
												validations={
													{
														required: true,
														length: {min: 6}
													}
												}
						>
						</LoginField>
						<div className="action-wrapper">
							{ authenticating ? <Loader fadeIn={true} label="authenticating"/>
							: <><input type="submit" value="Login" className="large-button"/>
							<Link to="/login/resetpassword">forgot your password?</Link></>}
							
						</div>
					</form>
				</div>
			</section>
		</div>
	);
}


export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)