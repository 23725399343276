import { create, all } from 'mathjs'
import { createResolve } from './resolve';


export function patchMathJsKeyScoping(math)  {
    let origAccess =math.AccessorNode.prototype._compile;
    let origSymbol = math.SymbolNode.prototype._compile;
    let origAssignment = math.AssignmentNode.prototype._compile;
    let origResolve = math.resolve;

    function resolveAccessor(node) {
        let accessor = [node.name];
        let part = node.object;
        while(part) {
            part.compiled=true;
            accessor.unshift(part.name);
            if(part.isSymbolNode) {
                part.scoped = accessor;
            }
            part = part.object;
        }
        return accessor;
    }

    math.import(createResolve(resolveAccessor),{override:true})
   

    // Overruling the accessor compile in order to 
    // be able to call the scope with the full path at onces
    math.AccessorNode.prototype._compile = function (math, argNames) {
        var node=this;
        
        // to test:
     //   let normalFn = origAccess(math,argNames)

        let accessor =resolveAccessor(this)

        this.compiled= true;
        this.accessor = accessor;


        return function(scope,args,context) {
            
            return scope.tryGet ? scope.tryGet(accessor) : scope.get(accessor)
        }
        
        
    }


    math.AssignmentNode.prototype._compile = function(math,argNames) {
        const evalObject = this.object._compile(math, argNames)
        const evalIndex = this.index ? this.index._compile(math, argNames) : null
        const evalValue = this.value._compile(math, argNames)
        const name = this.object.name
    // debugger;

        if(this.object && this.object.compiled && this.index && this.index.isObjectProperty()) {
            let parts = [...this.object.accessor,this.index.getObjectProperty()]

            return function evalAssignment(scope,args,context) {
                const value = evalValue(scope,args,context);
                scope.set(parts,value);
                return value;
            }
        }

        return origAssignment.call(this,math,argNames);
    }
    return math;
}
