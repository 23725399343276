import { useEffect } from "react";

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (
  textAreaRef,
  value,
  minEm=1
) => {
  useEffect(() => {
    if (textAreaRef) {
        var content = textAreaRef.value;
        var height=minEm;
        if(content) {
            height += content.split(/\r*\n/).length;
        }
       textAreaRef.style.height = `${height}em`;
    }
  }, [textAreaRef, value]);
};

export default useAutosizeTextArea;
