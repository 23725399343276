import { useCallback, useState } from 'react'
import { GraphTableServer } from '../../components/table/GraphTable';
import investmentsQuery, { investmentsOverviewQuery } from '../../../graph/queries/incofin/investments';
import InvestmentGridItem from '../../components/grids/custom/investment-grid-item';
import { FormattedMessage,injectIntl } from 'react-intl';
import moment from 'moment'
import { DashboardContainer, SmartLayout } from '../../components/dashboard';
import Panel from '../../components/dashboard/panel/panel';
import { TabBar } from '../../components/Tabs/Bar';
import { Tiles } from '../../components/Tiles';
import { Titles } from '../admin/covenantConfigs/type/titles';

function convertNumericDate(numericDate) {
	if(!numericDate) return null;
	return moment(numericDate.toString(), 'YYYYMMDD').format('DD/MMM/YYYY')
}

function formatCurrency(intlProps,value,currency) {
	if(!value) return '';
	return `${intlProps.formatNumber(parseInt(value))} ${currency}`;
}


export const InvestmentTiles = ({history,match})=> {

   

    let baseUrl = match.url;
     if(baseUrl.endsWith("/")) baseUrl = baseUrl.slice(0,-1);


    return <Tiles history={history} tiles={[
        { title:"MFI/Bank Investments", url:`${baseUrl}/mfi`},
        { title:"Agro Investements", url:`${baseUrl}/agro`}
    ]}/>
}


const columnMeta = [
    {"columnName": 'importedId',sortable:true, "displayName": <span>TM1 id</span>, "customComponent": InvestmentGridItem},
    {"columnName": 'client', sortDataIndex:"client.name", sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.client"/>, "customComponent": InvestmentGridItem},
    
    {"columnName": 'country', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.country"/>, "customComponent": InvestmentGridItem},
    {"columnName": 'fund',sortDataIndex:"fund.name", sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.fund"/>, "customComponent": InvestmentGridItem},
    {"columnName": 'product',sortDataIndex:"product.name", sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.product"/>, "customComponent": InvestmentGridItem},
    {"columnName": 'currency', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.currency"/>, "customComponent": InvestmentGridItem},
    {"columnName": 'outstandingAmountString',sortable:true, "displayName": <FormattedMessage id="grid.investments.columns.outstandingAmount"/>, "customComponent": InvestmentGridItem},
    {"columnName": 'firstDisbursementDate',sortable:true, "displayName": <FormattedMessage id="grid.investments.columns.firstDisbursementDate"/>, "customComponent": InvestmentGridItem},
    {"columnName": 'lastReportedMonth', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.lastReportedMonth"/>, "customComponent": InvestmentGridItem},
    {"columnName": 'finalMaturityDate', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.finalMaturityDate"/>, "customComponent": InvestmentGridItem},
    {"columnName": 'endedAt', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.endedAt"/>, "customComponent": InvestmentGridItem},
    {"columnName": 'reportingPeriodicity', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.reportingPeriodicity"/>, "customComponent": InvestmentGridItem},
    {"columnName": 'covenantsNr', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.covenants"/>, "customComponent": InvestmentGridItem}
];

export const Investments = injectIntl(({history,match,intl,clientId})=> {

    const [search, setSearch] = useState(null);
    const [sorters,setSorters] = useState([]);

    let clientType = match.params?.clientType;

        const title = match.params?.clientType ? <span>: {Titles[match.params.clientType]}</span> : "";

    const [openOrClosed,setOpenOrClosed] = useState("OPEN");

    const gridDataMapper = (investments)=>{
        return   investments.map((investment) => {
            return {
                _id: investment._id,
                importedId: investment.importedId,
                client: investment.client.name ,
               product: investment.product.name,
                fund: investment.fund.name,
                firstDisbursementDate: convertNumericDate(investment.firstDisbursementDate),
                finalMaturityDate: convertNumericDate(investment.finalMaturityDate),
                endedAt: convertNumericDate(investment.endedAt),
                currency: investment.currency,
                country: investment.country,
                outstandingAmountString: formatCurrency(intl, investment.outstandingAmount, investment.currency),
                outstandingAmount: investment.outstandingAmount,
                lastReportedMonth: investment.lastReportedMonth ? moment(investment.lastReportedMonth, 'YYYYMMDD').format('MMM/YYYY') : null,
                covenants: investment.covenants,
                covenantsNr:investment.covenants ? investment.covenants.length : 0,
                reportingPeriodicity: investment.reportingPeriodicity
            };
        });
    };


    const onSortChange = useCallback((value)=> {

        console.log("SORT",value);
        if(value) {
            setSorters([{
                field:value.dataIndex,
                sort:value.direction
            }])
        } else {
            setSorters(null)
        }
    },[setSorters]);

    const onRowClick=useCallback((dbItem)=> {
        let url = match.url;
        if(url[url.length-1]=="/") {
            url = url.substr(0,url.length-1)
        }
		history.push(`${url}/${dbItem.props.data._id}`);
	},[history,match]);
    
    const onTabChange= useCallback(({key,index})=> {
        setOpenOrClosed(key);
    },[setOpenOrClosed]);

    return <DashboardContainer>
    <SmartLayout>
        <Panel title={<span><FormattedMessage id="grid.investments.title" />{title}</span>}>
            <TabBar active={openOrClosed} onChange={onTabChange} tabs={[
                { label:"Open Investments",
                itemKey:"OPEN"
                   },
                   { label:"Closed Investments",
                   itemKey:"CLOSED"
                   }
            ]}>

            </TabBar>
       
        <div>
        <GraphTableServer qry={investmentsOverviewQuery} 
        options={{
            fetchPolicy: "no-cache",
        }}
        onSortChange={onSortChange}
		vars={{
            sorting:sorters,
            clientId:clientId,
            clientType:clientType,
            state:openOrClosed
        }}
        tableDataMapper={gridDataMapper}
        dataSelector={(d)=> { return d && d.investmentsPaged && d.investmentsPaged.results ? d.investmentsPaged.results : []}}
        totalSelector={(d)=>d && d.investmentsPaged && d.investmentsPaged.pageInfo ? d.investmentsPaged.pageInfo.total :0}
         columnMetadata={columnMeta} showFilter={true} showPager={true} 
		 resultsPerPage={15}
         onRowClick={onRowClick}
					
         
		 enableSort={false}
        />
        </div>
    </Panel></SmartLayout></DashboardContainer>
});

//onRowClick={onRowClickCb} 