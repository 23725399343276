import { useApolloClient, useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { debounce } from "underscore";
import { Select, Spin } from "antd"


export const GraphSearchSelect = ({preSelected,graphOptions:{query,mapVariables,resultField,valueField,labelField},debounceTimeout=800,...props})=> {
    
   
    const graphClient = useApolloClient()
 
        
    const [loading,setLoading] = useState(false);
    const [options, setOptions] = useState(props.value ? [props.value] : []);

   /* useEffect(()=>{
      setOptions({
        label:preSelected[labelField],
                    value:preSelected[valueField],
                    obj:preSelected
      })
    },[preSelected])*/
    
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
      const loadOptions = (value) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setLoading(true)
        setOptions([]);
        const variables = mapVariables(value);
        graphClient.query({query,variables}).then(({data})=> {
            if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
              }
            if(data) {
                let arr = data[resultField] || []
                arr= arr.map(a=>({
                    label:a[labelField],
                    value:a[valueField],
                    obj:a
                }));
                setOptions(arr);

            }
        }).finally(()=> {
            setLoading(false);
        })
      };
      return debounce(loadOptions, debounceTimeout);
    }, [graphClient,query, debounceTimeout,mapVariables,resultField,valueField,labelField]);

    return <Select
    labelInValue
    showSearch={true}
    filterOption={false}
    onSearch={debounceFetcher}
   
    notFoundContent={loading ? <Spin size="small" /> : null}
    {...props}
    options={options}
  />
}