
import { getSafeProperty, hasSafeProperty, setSafeProperty } from "./utils"
import { math } from '../mathjs'
import moment from "moment";


function convertNumber(val) {
    return val/100;
}
function convertPercentage(val) {
    return val/10000
}

function setNumber(val) {
    return Math.round(val*100)
}

function setPercentage(val) {
    return Math.round(val*10000)
}





export class FormulaScope extends EventTarget  {

   // #eventEmitter = new EventEmitter();
    changes=[];
    newForms=[];
    wrappedObject={};
    currentNumeric;
   // exchangeRates;

    constructor (currentNumeric,dataObject={},reportedScale=1) {
        super();
        this.wrappedObject = JSON.parse(JSON.stringify(dataObject));
        this.currentNumeric=currentNumeric;
        this.reportedScale=reportedScale;
      //  this.exchangeRates=exchangeRates;
      
  
      //this[Symbol.iterator] = this.entries
    }

    traverse(fn,obj=this.wrappedObject) {
        for(let prop in obj) {
            if(prop!="_context") {
                fn(obj[prop],prop);
                if(typeof(obj[prop])=="object" && obj[prop]!=null) {
                    this.traverse(fn,obj[prop])
                }
            }
        }
    }
    

    // event listener (more for client side, if possible, signal)
    // https://www.npmjs.com/package/deepsignal
    // https://www.npmjs.com/package/@preact/signals-react
    onChange(filterFn,callback) {

       let filteredfn = ({detail})=>{
        if(detail && filterFn(detail)) {
            callback(detail);   
        }
       };
       
       this.addEventListener("change",filteredfn);
       return filteredfn;
    }

    offChange(callback) {
        this.removeEventListener("change",callback);
    }

    off(eventName,callback) {
        this.removeEventListener(eventName,callback)
    }

    #emitChange(change) {
        this.dispatchEvent(new CustomEvent("change",{
            detail:change
        }))
    }


    getServerChangesetOfConfig(key) {
        let changes = this.changes.filter(c=>c.config==key);
        let targetId = this.wrappedObject.current[key]._id;
        let configId = key;
        changes = changes.map(c=>({
            path:c.pathParts.slice(2).join("."),
            change:c.value
        }));

        return {
            targetId,
            configId,
            changes
        }

    }
    clearChangesOfConfig(key) {
        this.changes = this.changes.filter(c=>c.config==key);
    }

    getChangesByConfig() {
        let numeric = this.wrappedObject.current._context.numeric;
        let lastCalculated=  moment().unix();
        let updates = this.changes.reduce((res,item)=>{
            
            if(!res[item.config]) {
                    res[item.config] = {};
                }
                let internalPath = [...item.pathParts.slice(2)].join(".")
                res[item.config][internalPath]=item.value
                res[item.config].lastCalculated=lastCalculated
            
            return res;

        },{
        });

        return {
            numeric,
            updates
        };
    }

    #recordChange(key,value,originalValue){

        let path = Array.isArray(key) ? key : [key]
        let period = path[0]; // should only be current
        let config = path[1];
        let fieldPath = [config,...path.slice(3)];
        let lastPart =fieldPath[fieldPath.length-1];
        if(lastPart==="value" || lastPart==="reportedValue") {
            fieldPath.pop();
        }
        fieldPath = fieldPath.join(".");

        let change = null;

        let existingIdx = this.changes.findIndex(c=>c.path == path.join("."));
        if(existingIdx>-1) { 
            let existing = this.changes[existingIdx];
            if(existing.originalValue!=value) {
                existing.value = value;
                change=existing;
            } else {
                existing.value = value;
                change=existing;
                this.changes.splice(existingIdx,1);
            }
            // originalValue check
        } else if(originalValue!=value) {
            change = {
                path:path.join("."),
                fieldPath,
                period,
                config,
                pathParts:path,
                value:value,
                originalValue
            };
            this.changes.push(change)
        }
        if(change) this.#emitChange(change);
    }

    getNumeric(period) {

        if(period!="current" && !period.startsWith("_")) period = "_"+period;
        return this.tryGet([period,"_context","numeric"]);
    }
    /* INTERNAL SCOPE FUNCTIONS */

    default(val,defaultValue=0) {
        return val ? val : defaultValue;
    }

    pathToParts(path,period="current") {
        let parts = path.split(".");
        let config = parts.shift();
        if(period!="current" && !period.startsWith("_")) period = "_"+period;
        return [period,config,"values",...parts];
    }
    
    field(config,path,period="current",prop="value") {
        if(period!="current" && !period.startsWith("_")) period = "_"+period;
        let fullpath =[period,config,"values",...path.split("."),prop];
        if(!fullpath[fullpath.length-1]) fullpath.pop();
        if(!path||path=="") {
            fullpath = [period,config];
        }
        let result = this.tryGet(fullpath);
        return result;
    }

    annualize(val) {
        let res = val * this.wrappedObject.current._context.factorAnnualizeFY;
        return res;
    }

    period() {
        return this.wrappedObject.current._context.periodNum;
    }

    covenantRange(field,configKey,path,target) {
       // console.log("covenantRange",field,target);
        let targetField = this.field(configKey,path,"current",target)
        if(field) {
            const { investments=[],value } = field;
            if(value=== null || typeof(value) == "undefined" || isNaN(value)) return false;
           // console.log("covenantRange",investments,value);
            let invests = investments.filter((i)=> {
                const range = target==="breached" ? "breach" : "alert";
                return value < i[range].rangeMin || value > i[range].rangeMax;
            }).map(i=>i._id)

            let targetParts = this.pathToParts(`${configKey}.${path}.${target}.investments`)

            this.set(targetParts,invests)
           // targetField.investments = invests;
            
          //  console.log("covenantRange",invests);
            return invests.length!=0; // if any investments found
          
        }
        return true;
       // let fullpath =[period,config,"values",...path.split(".")];
       // let covenant = this.tryGet(fullpath);


    }

    stringLength(val) {
        if(!val) return 0;
        return val.toString().length;
    }


    keys () {
      return Object.keys(this.wrappedObject).values()
    }

    
    // the try get will fetch a path from scope,
    // the value handling though is specific to mathematical handling
    // so a percentage 20% os actually needed as 0,2
    // Storing and retrieving a percentage thus requires different handling
    // between within mathjs and outside of it. (as all are store as integers)
    // 
    // For value retrieval: This method should only by used within formula executions
    // not for visualisation / reporting
    tryGet(path) {
       
        let tree= [...path];

        let obj = this.wrappedObject;
        while(obj && tree.length>0) {
            let key =tree.shift();
            
            if(key==="value") {
                switch(obj.type) {
                    case "currency":
                    case "numeric":
                        obj = convertNumber(obj[key]);
                        break;
                    case "percentual":
                        obj = convertPercentage(obj[key]);
                        break;
                    default:
                        obj=obj[key];
                        break;
                }
            } else { 
                obj=obj[key];
            }
           
        }
       //console.log("Try GET",path,obj);
        return obj;
    }
  
    get (key) {
        
      if(this[key]) return this[key].bind(this);
      if(Array.isArray(key)) return this.tryGet(key);
      let res = getSafeProperty(this.wrappedObject, key);
      return res;
    }

    

    #createItem(parts) {
        let obj = this.wrappedObject;
        let part = parts.shift();
        while(part) {
            if(!obj[part]) {
                obj[part] = {};
            }
            obj=obj[part];
            part = parts.shift();
        }
        return obj;
    }
  
    // For value retrieval: This method should only by used within formula executions
    // not for visualisation / reporting
    set (key, value,ignoreTransformation=false) {
        let val = value;
        if(val == math.Infinity) {
            value=null;
            val=null;
        }
        let originalValue=null;
        if(Array.isArray(key)) {
            let parts = [...key];
            let prop = key[key.length-1];
            let isValueSet = prop === "value";
            
            parts.pop();

            let obj = this.tryGet(parts);
            if(!obj) {
                obj = this.#createItem(parts);
            }
            if(isValueSet) {

                originalValue = obj.value;
                // apply conversion to int base
                switch(obj.type) {
                    case "currency":
                       
                        if(!ignoreTransformation && typeof(obj.reportedValue)!="undefined") {
                            let originalReported = obj.reportedValue;
                            obj.reportedValue = value ? setNumber(value)/this.reportedScale : value;
                            this.#recordChange([...parts,"reportedValue"],obj.reportedValue,originalReported);
                        }
                    case "numeric":
                        obj.value = value && !ignoreTransformation ? setNumber(value) : value;
                        break;
                    case "percentual":
                        obj.value = value && !ignoreTransformation ? setPercentage(value) : value;
                        break;
                    default:
                        obj.value=value;
                        break;
                }
                val = obj.value;
                
            } else {
                
                obj[prop] = value;
                
                
            }
            
            
        } else {
            originalValue = getSafeProperty(this.wrappedObject, key);
            val = setSafeProperty(this.wrappedObject, key, value);
        }
        this.#recordChange(key,val,originalValue);
      return this
    }
  
    has (key) {
       // console.log("HAS",key);
      return this[key] ? this[key] : hasSafeProperty(this.wrappedObject, key)
    }
  
    entries () {
      //return mapIterator(this.keys(), key => [key, this.get(key)])
    }
  
    forEach (callback) {
     /* for (const key of this.keys()) {
        callback(this.get(key), key, this)
      }*/
    }
  
    delete (key) {
     //delete this.wrappedObject[key]
    }
  
    clear () {
     /* for (const key of this.keys()) {
        this.delete(key)
      }*/
    }
  
    get size () {
     // return Object.keys(this.wrappedObject).length
    }

    createSubScope (parentScope, args) {
        return this;
    }
  }

  /**
 * Create a new iterator that maps over the provided iterator, applying a mapping function to each item
 */
function mapIterator (it, callback) {
    return {
      next: () => {
        const n = it.next()
        return (n.done)
          ? n
          : {
              value: callback(n.value),
              done: false
            }
      }
    }
  }