import moment from 'moment';

function nth(n){return `${n}${["st","nd","rd"][((n+90)%100-10)%10-1]||"th"}`}

export default function(periodText,numeric) {
    if(periodText) {

        if(periodText.indexOf('Q')>-1) {
            try {

                let txt = `${periodText.substr(0,4)} - ${periodText.substr(6,1)}${nth(parseInt(periodText.substr(6,1)))} quarter`;
                if(numeric) {
                    let mom = moment(""+numeric,"YYYYMMDD").format("MMM YYYY");
                    txt = `${txt} (${mom})`;
                }
                return txt;
            } catch(e) {
                return periodText;
            }
        } else {
            return moment(periodText).format('MMM YYYY')
        }
    }
    return null;
}

export const contextToCalendar = (context) => {
    if(!context) return '';
    const { numeric, period } = context;
    let txt = moment(numeric.toString(),"YYYYMMDD").format('MMM YYYY');
    if(period && period.quarter) {
        txt = `${txt} | ${nth(period.quarter)} quarter`
    }
    return txt;
}

export const contextToFull = (context) => {
    console.log("contextToFull",context)
    if(!context) return '';
    const { numeric, period } = context;
    const { financialYear,financialYearPeriod } = period;
    let calendar = moment(numeric.toString(),"YYYYMMDD").format('MMM YYYY');
    
    let financial = financialYearPeriod ? ` - financial period: ${financialYearPeriod}/${financialYear}` : ''
    
    return `${calendar}${financial}`;
}