import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import GridView from '../dashboard/gridview/gridview.jsx';
import { Table } from '../table/Table'
import moment from 'moment';
import {singleExchangeRatesQuery} from '../../../graph/queries/admin/exchangeRates';
import Loader from '../../components/loader/spinner';
import { currencyMap } from '../../../data/currencies';
import { injectIntl, FormattedMessage } from 'react-intl';

/**
 * DESCRIPTION: show ALL exchange rates on the "/incofin/admin/exchangrates" route
 *   => only accessible by the Incofin-admin or admin user
 *
 *   <GridView results={gridData}
 initialSort={'quoteKey'}
 columnMetadata={columnMeta}
 resultsPerPage={this.props.itemsPerPage}
 />
 */


const DefaultProps = {
    itemsPerPage: 5
};

class AdminSingleRateGridView extends React.Component {
    constructor(props) {
        super(props);
    }

    //render method
    render() {
        if (this.props.data && this.props.data.loading) {
            return <Loader/>;
        }
        const rates = this.props.data.singleExchangeRate || [];

        const { date } = this.props;

        let gridData = [];
        for (let key in rates.quotes) {
            const cur = key.substr(3);
            let pair = {
                currency: currencyMap.has(cur) ? `${currencyMap.get(cur) } (${cur})`: cur,
                quote: this.props.intl.formatNumber(rates.quotes[key], {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4
                })
            };
            gridData.push(pair);
        }

        const columnMeta = [
            { columnName: 'quote', displayName: <><FormattedMessage id="grid.exchange-rates.columns.quota"/> - {date}</> },
            { columnName: 'currency', displayName: <FormattedMessage id="grid.exchange-rates.columns.currency"/> }
        
        ];

        return (
            <div className="quota-grid">
                <Table data={gridData}
                showPager={true}
                showFilter={true}
                          columnMetadata={columnMeta}
                          resultsPerPage={this.props.itemsPerPage}
                />
            </div>
        );
    }
}

export default injectIntl(graphql(singleExchangeRatesQuery, { options: ({rateId}) => ({
    variables: {
        exchangeId: rateId
    }
})
})(AdminSingleRateGridView));
