
import React, { Fragment, useCallback, useContext, useState } from "react";
import { FormTraverser } from "../../Form/FormTree/traverser";
import classNames from "classnames";
import { useWizardField, useWizardValue } from "../hooks/useWizardField";
import { Alert, InputNumber,Input, Space, Typography } from "antd";
import { useIntl } from "react-intl";
import { ReportingWizardContext } from "../hooks/context";
import moment from "moment";
import { FormulaIconLink, FormulaLink } from "../../NewFormula/components/Links";

const { TextArea} = Input

export const FormFieldLabel = ({children,configKey,name,path,formula})=> {

    const { scope, info } = useContext(ReportingWizardContext);
//debugger
    const cls = classNames("field-label", {
        "field-label-has-fx":!!formula
    })

	return <div className={cls}>
            <div className="field-label-fx">
                {!!formula ? <FormulaIconLink scope={scope} numeric={info.numeric}  fullPath={path} name={name} formula={formula} /> : null}
            </div>
        <div className="field-label-text">
        {!!formula ? <FormulaLink scope={scope}  name={name}  fullPath={path} formula={formula}>{children}</FormulaLink> : children}
        </div>
	</div>;
}


export const renderFormFieldLabel = (configKey,item,path)=> {

    return <FormFieldLabel configKey={configKey} path={path} formula={item.formula} name={item.name?.value}>{item.name?.value}</FormFieldLabel>

}

/*
formatter={transforms.formatter}
    parser={transforms.parser}
*/

const currencyParser = (intl)=>(val) => {
    try {
      // for when the input gets clears
      if (typeof val === "string" && !val.length) {
        val = "0.0";
      }
  
      // detecting and parsing between comma and dot
      var group = new Intl.NumberFormat(intl.locale).format(1111).replace(/1/g, "");
      var decimal = new Intl.NumberFormat(intl.locale).format(1.1).replace(/1/g, "");
      var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
      reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
      //  => 1232.21 €
  
      // removing everything except the digits and dot
      reversedVal = reversedVal.replace(/[^0-9.]/g, "");
      //  => 1232.21
  
      // appending digits properly
      const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;
  
      if (needsDigitsAppended) {
        reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
      }
  
      return Number.isNaN(reversedVal) ? null : reversedVal;
    } catch (error) {
      console.error(error);
    }
  };

  const currencyFormatter = intl => value => {

    return intl.formatNumber(value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
   /* return new intl.NumberFormat(locale, {
      style: "currency"
    }).format(value);*/
  };

const CurrencyField = ({currency,...props})=> {
    const intl = useIntl();

    return <NumberField {...props} formatter={currencyFormatter(intl)}
    parser={currencyParser} addonAfter={currency} />
}

const PercentageField = ({currency,...props})=> {
    const intl = useIntl();

    return <NumberField {...props} 
    formatter={currencyFormatter(intl)}
    parser={currencyParser} addonAfter="%"/>
    /*formatter={(value) => `${value}%`}
    parser={(value) => value?.replace('%', '')} />*/
}

const NumberField = ({value,onChange,currency,...props})=> {

    const onBlur = useCallback((evt)=> {
        
        let v = evt.target.value;
  
        if(v==""||v==null) {
            onChange(null);
            return;
        }
        // remove all text (keep "." as decimal separator)
        // (move decimal separator to intl?)
        v = v?.replace(/\$\s?|(,*)/g, '');
        if(v && !isNaN(v)) {
            v=parseFloat(v);
        } else {
            v=null;
        }
        if(v!=value) onChange(v);


    },[onChange,value])

    return <InputNumber
    
    size="small"
    width={150}
    variant="filled"
    changeOnWheel={false}
    controls={false}
    {...props}
    value={value}
    onBlur={onBlur}
    onFocus={(e)=>e.target.select()}
    stringMode
    
  />
}

function getFieldFormatter(fieldType) {
    let formatter = (v)=>v;
    let formatOrReturn = (value,formatMe)=> {
       // if(isNaN(value) ) debugger;
       return value===null || typeof(value)=="undefined" || value==="" ? "-" : formatMe(value)
    } ;


    switch(fieldType) {
        case "numeric": {
            return (v,intl)=>formatOrReturn(v,(value)=>{
                return intl.formatNumber(value, {
					minimumFractionDigits: 0,
					maximumFractionDigits: 2
				});
            })
        }
        case "currency": {
            return (v,intl,currency)=>formatOrReturn(v,(value)=>{
               // return "T"+value;
                return `${intl.formatNumber(value, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				})} ${currency}` ;
            })
        }
        case "percentual": {
            return (v,intl)=>formatOrReturn(v,(value)=>{
                return `${intl.formatNumber(value, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				})}%` ;
            })
        }
        case 'date':
            return (v,intl)=>formatOrReturn(v,(value)=>{
                return intl.formatDate(value, {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric'
					});
                });
				break;
    }

    return formatter;
}


const renderFormCovenant = (configKey,item,path,fieldType)=> {

    // get field type
    let it = {...item, fieldType};
    
    return <>{renderFormFieldEntry(configKey,it,path,fieldType)}</>;
}


const renderFormFieldDisplay = (configKey,item,path,fieldType,period="current")=> {

    let formatter = getFieldFormatter(fieldType);
   // console.log("render disp",period,path)

    let DisplayComponent = ()=> {
        const { scope } = useContext(ReportingWizardContext)
        const { value,currency } = useWizardField(configKey,item,path,true,period);
        const intl = useIntl();
       
       // console.log("render comp disp",period,path)
        return <div className="field-value">
             {!!item.formula ? <FormulaLink definition={item} scope={period=="current" ? scope : null} numeric={period=="current" ? null : scope.getNumeric(period)} name={item.name?.value}  fullPath={path} formula={item.formula}> {formatter(value,intl,currency)}</FormulaLink>
             :  formatter(value,intl,currency)}

		   
	    </div>
    }
    return <DisplayComponent/>
}

const GetFieldComponent = (fieldType)=> {
    switch(fieldType) {
        case "numeric":
            return NumberField;
        case "currency":
            return CurrencyField;
        case "percentual":
            return PercentageField;
        default:
            return Input;
    }
}

const renderFormFieldEntry = (configKey,item,path,fieldType)=> {

    if(item.fieldType=="COVENANT") {
        return renderFormCovenant(configKey,item,path,fieldType);

    }

    if(!!item.formula) {
		return renderFormFieldDisplay(configKey,item,path,fieldType);
	}

   

    if(fieldType==="fieldset") {
        return <Space/>
    }

    const Field = GetFieldComponent(fieldType)
    
    let FieldEntryComponent = ()=> {
        const { value, setValue, validationState,currency,disabled} = useWizardField(configKey,item,path,true);
        //console.log("FIELD",path,value,validationState)

        return <Field
        currency={currency}
            disabled={disabled}
            value={value}
            onChange={setValue}
            status={validationState?.status || undefined}
        />
    }
    

    return <div className="field-entry">
		<FieldEntryComponent/>
	</div>
}

const motivationItem = {
    internalName:"breachMotivation",
    fieldType:"textarea"
}

const boolean = "boolean";
const renderCovenantItem = (extraPeriods=[])=> ({configKey,
    field,
    path,
    fieldType,
    depth})=> {

        let breachPath = path + ".breached";
        let alertPath = path + ".alerted";
        let motivationPath = path + ".breachMotivation";
        
        const MotivationField = ()=> {
            const { value, setValue, validationState,currency,disabled} = useWizardField(configKey,motivationItem,motivationPath,true);

            const onBlur = useCallback((evt)=> {
                
                let v = evt.target.value;
                if(v!=value) setValue(v);
        
        
            },[setValue,value])

            const [local,setLocal] = useState(value)

            return <TextArea placeholder="Motivate the breach" 
                    disabled={disabled}
                    value={local}
                    onChange={(e)=>setLocal(e.target.value)}
                    onBlur={onBlur}
                    status={validationState?.status || undefined}
                autoSize={{
                minRows: 2
              }}/> 
        }
      
        const LineComponent = field.visible == false 
            ? ()=><Space/>
            : ({children})=> {
            const { validationState } = useWizardField(configKey,field,path,false);
            const { value:breached} = useWizardValue(configKey,boolean,breachPath);
            const { value:alerted} = useWizardValue(configKey,boolean,alertPath);

            const cls = classNames("form-flat-field-line-group",
                "form-flat-level-" + depth    
                ,{
                    "field-breached":breached,
                    "field-alerted":alerted && !breached,
                "field-has-errors":validationState?.status=="error",
                "field-has-warnings":validationState?.status=="warn",
            })

            return <>
            <div key={path+"_line"} className={cls}>{children}</div>
            { breached ? 
          
            <div className={cls}>
            
                <div style={{ paddingBottom:"20px",paddingLeft: "5em", paddingRight: "1em"}} className="form-flat-cell form-flat-label-column">
                    <div className="field-label"> <div className="field-label-text">Motivate the breach</div></div>
                     <MotivationField/></div>

                {extraPeriods && extraPeriods.length>0 ?
            <>
                <div key={path+"_spacer"}  className="form-flat-cell form-flat-spacer-column"></div>
                {extraPeriods.map((p)=>{
                    return <div key={path+"_sp_p_" +p}  className="form-flat-cell form-flat-extra-period-column form-flat-value-column">
                       
                    </div>
                })}
                </>
                :null}
            
            </div> : null }

            {validationState?.status=="error" ? <div key={path+"_err"} className="form-flat-caption"> <Alert showIcon style={{padding:"6px 6px"}} message={validationState?.message || "Invalid"} type="error" /></div> : null}
            {validationState?.status=="warn" ? <div key={path+"_warn"} className="form-flat-caption"> <Alert showIcon message={validationState?.message || ""} type="warning" /></div> : null}
            </>
        }
       

        return <LineComponent key={path}>
            <div key={path+"_lbl_col"}  className="form-flat-cell form-flat-label-column">{renderFormFieldLabel(configKey,field,path)} </div>
            <div key={path+"_val_col"}  className="form-flat-cell form-flat-value-column">{renderFormFieldEntry(configKey,field,path,fieldType)}</div>

            {extraPeriods && extraPeriods.length>0 ?
                <>
                <div key={path+"_spacer"}  className="form-flat-cell form-flat-spacer-column"></div>

                {extraPeriods.map((p)=>{
                    return <div key={path+"_p_" +p}  className="form-flat-cell form-flat-extra-period-column form-flat-value-column">
                        {renderFormFieldDisplay(configKey,field,path,fieldType,p)}
                    </div>
                })}
                </>
            : null } 
        </LineComponent>

    }

const renderItem = (extraPeriods=[])=>  ({configKey,
    field,
    path,
    fieldType,
    depth})=> {

        if(field.fieldType=="COVENANT") {
            return renderCovenantItem(extraPeriods)({configKey,field,path,fieldType,depth:"last"})
        }
        

        const LineComponent = field.visible == false 
            ? ()=><Space/>
            : ({children})=> {
            const { validationState } = useWizardField(configKey,field,path,false);

            const cls = classNames("form-flat-field-line-group",
                "form-flat-level-" + depth    
                ,{
                "field-has-errors":validationState?.status=="error",
                "field-has-warnings":validationState?.status=="warn",
            })

            return <>
            <div key={path+"_line"} className={cls}>{children}</div>
            {validationState?.status=="error" ? <div key={path+"_err"} className="form-flat-caption"> <Alert showIcon message={validationState?.message || "Invalid"} type="error" /></div> : null}
            {validationState?.status=="warn" ? <div key={path+"_warn"} className="form-flat-caption"> <Alert showIcon message={validationState?.message || ""} type="warning" /></div> : null}
            </>
        }
       

        return <LineComponent key={path}>
            <div key={path+"_lbl_col"}  className="form-flat-cell form-flat-label-column">{renderFormFieldLabel(configKey,field,path)} </div>
            <div key={path+"_val_col"}  className="form-flat-cell form-flat-value-column">{renderFormFieldEntry(configKey,field,path,fieldType)}</div>

            {extraPeriods && extraPeriods.length>0 ?
                <>
                <div key={path+"_spacer"}  className="form-flat-cell form-flat-spacer-column"></div>

                {extraPeriods.map((p)=>{
                    return <div key={path+"_p_" +p}  className="form-flat-cell form-flat-extra-period-column form-flat-value-column">
                        {renderFormFieldDisplay(configKey,field,path,fieldType,p)}
                    </div>
                })}
                </>
            : null } 
        </LineComponent>

    }

const renderGroupHeader = renderItem
/* ({
    configKey,
    field,
    path,
    fieldType,
    depth
}) => {

    return <div className="form-line ">

    </div>
}*/

const renderGroup = (extraPeriods=[])=>({
    configKey,
    field,
    path,
    fieldType,
    depth,
    header,
    children
}) => {

    return <Fragment key={path+"_group_wrap"}>
        {header}
        <div key={path+"_group_content"} className="form-flat-group-content">
            {children}
        </div>
    </Fragment>
}

const PeriodHeader = ({period})=> {
    const { scope } = useContext(ReportingWizardContext)

    let numeric = scope.get([period,"_context","numeric"]);
   
    if(!numeric) return "-"
    return moment(numeric.toString(),"YYYYMMDD").format("MMMM YYYY")
}

const periods = ['_1FY','_1M','_1Y']
export const ReportForm = ({config})=> {

    let header = periods && periods.length>0 ? <div className="form-flat-heading sticky">
            <div className="form-flat-cell form-flat-label-column">&nbsp;</div>
            <div className="form-flat-cell form-flat-value-column">Current reporting</div>
            <div className="form-flat-cell form-flat-spacer-column"></div>
        {periods.map((p)=> {
            return <div key={config.key+"_p_title_" +p}  className="form-flat-cell form-flat-extra-period-column form-flat-value-column"><PeriodHeader period={p}/></div>
        })}
    </div> : null

    return <div className="form-flat" key="test">
        {header}
        {FormTraverser(config, {
            renderItem:renderItem(periods),
            renderGroup:renderGroup(periods),
            renderGroupHeader:renderGroupHeader(periods)
        })}
        </div>
}