import {  FORMCONFIG_LOADING, FORMCONFIG_LOADED, FORMCONFIG_LOAD_ERROR} from '../actions/formConfig'


const initialState = {
    language:'en',
    _id:null,
    key:null,
    layout:null,
    guide:null,
    grid:null,
    type:null,
    context:{
        category:null,
        periodType:null,

    },
    name:null,
    fields:null,
    loading:false,
    error:null
};


export const formConfigReducer = (state = initialState, action) => {
   switch (action.type) {
        case FORMCONFIG_LOAD_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            };
       case FORMCONFIG_LOADING:
            return Object.assign({},initialState,{loading:true,...action.payload});
        case FORMCONFIG_LOADED:
            return {
                ...state,
                loading:false,
                error:null,
                ...action.payload
            };
    }

    return state;
};