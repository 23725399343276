import './startup.scss';
import React, { useEffect, useState,useRef,useCallback } from 'react';
import { connect} from 'react-redux';
import { redirLogin,fetchProfile } from '../../../redux/actions/session';
import { checkEssentials } from '../../../redux/actions/data';
import { useHistory } from 'react-router-dom';
//import { requireProfile } from './selectors';


function mapDispatchToProps(dispatch) {
    return {
        redirLogin: () => {
            redirLogin();
        },
        fetchProfile: ()=> {
            dispatch(fetchProfile());
        },
        checkEssentials: () => {
           dispatch(checkEssentials());
        }
    };
}

function mapStateToProps(state) {
    return {
        authenticated:state.session.authenticated,
        authenticating:state.session.authenticating,
        userLoading:state.session.loading,
        user:state.session.user,
        dataLoaded:state.data.loaded

    };
}
const useIsMounted = ()=> {
    const isMounted = useRef(false)
    useEffect(() => {
      isMounted.current = true
      return function cleanup() {
        isMounted.current = false
      }
    }, [])
    const checker = useCallback(() => {
      return isMounted.current
    }, [])
    return checker
  }



const SecuredWrapper = ({
    children,...props
})=> {

    /*
        let isAlreadyGoodToGo = this.props.authenticated === Meteor.userId()
            && !this.props.authenticating
            && !this.props.userLoading
            && this.props.user
            && this.props.user.userAccountId == this.props.authenticated
            && this.props.dataLoaded == 1*/
const {authenticated,authenticating,userLoading
    ,user,dataLoaded,checkEssentials,fetchProfile } = props;

    const readyToGo = !authenticating && !userLoading && user && dataLoaded==1
    const [fadeOut,setFadeOut] = useState(readyToGo);
    const [renderThrough,setRenderThrough] = useState(readyToGo);
    const [rehydrated,setRehydrated] = useState(true);
    const isMounted = useIsMounted();
    const history = useHistory();
    const redirLogin = useCallback(()=> {
        history.push('/login')
    },[history])
  //  const [renderThroughTimeout]

  console.log("SECURED")
  console.log("props",props)
    useEffect(()=>{
        console.log("secured effect")
        console.log("effect",{isMounted,fadeOut,renderThrough,readyToGo})
        if(fadeOut===false) {
            if(readyToGo) {
               
                let renderThroughTimeout = setTimeout(() => {
                    if(renderThroughTimeout) clearTimeout(renderThroughTimeout);
                    setFadeOut(true);
                    
                }, 600);
                //return () => ;
            }
        } else if (isMounted && fadeOut && renderThrough===false) {
            setRenderThrough(true);
        } else { 
            
            
            if(!renderThrough) {
                //this.props.redirLogin();
               // alert('REDIR')
               //
               redirLogin();
                
            }
        }
    },[readyToGo,fadeOut,setFadeOut,renderThrough,setRenderThrough,isMounted,redirLogin]);

    useEffect(()=>{
        if(rehydrated) {
            checkEssentials()
        }
    },[rehydrated,checkEssentials]);

    if(renderThrough) {
        console.log('secured render through');
        const childProps = Object.assign({}, props);
      
        return React.Children.only(children);
    } else {
        let classNames = ["startup-logo"];
        if(fadeOut) {
            classNames.push("fadeAway")
        }
        return <div className="startup"><div className={classNames.join(" ")}></div></div>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SecuredWrapper);

/*
export default class SecuredWrapper extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            fadeOut:isAlreadyGoodToGo, renderThrough:isAlreadyGoodToGo,
            rehydrated:true // to be used for redux-persist
        };
        this._isMounted=false;

    }

    componentWillReceiveProps(nextProps) {

        //console.log("secured will receive props",nextProps,this.props);
        if(this.state.fadeOut === false) {
            if(nextProps.authenticated === Meteor.userId()
                && !nextProps.authenticating
                && !nextProps.userLoading
                && nextProps.user
                && nextProps.user.userAccountId == nextProps.authenticated
                && nextProps.dataLoaded
            ) {
                this.setState((state,props)=> { return {
                    fadeOut:true
                }});

                let me = this;
                me.renderThroughTimeout = setTimeout(() => {
                    if( me.renderThroughTimeout) clearTimeout( me.renderThroughTimeout);
                    if (me && me._isMounted && me.state.fadeOut && me.state.renderThrough===false) {
                        me.setState({renderThrough: true});
                    }
                }, 600);
            }
        } else {
            if(this.state.renderThrough && nextProps.user==null && Meteor.userId()==null  && !Meteor.loggingIn() ) {
                this.props.redirLogin();
            }
        }


    }

    shouldComponentUpdate(nextProps,nextState) {
        return  nextState.fadeOut != this.state.fadeOut
            || nextState.renderThrough != this.state.renderThrough
            || (nextProps.dataLoaded != this.props.dataLoaded)
            || nextProps.location.pathname != this.props.location.pathname;


    }

    componentWillMount() {
        //console.log("secured will mount");
        if(Meteor.userId()==null && !Meteor.loggingIn()) {
            // trigger redir to login
            this.props.redirLogin();
        } else if(this.state.rehydrated) {
            // check essentials
            this.props.checkEssentials();
        }
    }

    componentDidMount() {
        this._isMounted=true;

    }

    componentWillUnmount() {
        this._isMounted=false;
        if( this.renderThroughTimeout) clearTimeout( this.renderThroughTimeout);
        //console.log("secured unmounted",this.props,this.state);
    }

    render() {

        //console.log("secured render");

        if(this.state.renderThrough) {
            //console.log('secured render through');
            const props = Object.assign({}, this.props);
            delete props.children;

            return React.Children.only(this.props.children);
        } else {
            let classNames = ["startup-logo"];
            if(this.state.fadeOut) {
                classNames.push("fadeAway")
            }
            return <div className="startup"><div className={classNames.join(" ")}></div></div>;
        }

    }
}
*/