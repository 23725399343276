import { gql } from '@apollo/client';

const countryQuery = gql`query { 
    countries {
        _id
        name
    }
  }  
`;

export default countryQuery;
