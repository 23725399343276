//////if(!Meteor.isTest) 
import React from 'react';
import { FormattedMessage } from 'react-intl';
import TimeslicerBody from './Body';
import TimeslicerSummary from './Summary';
import $ from 'jquery';
import './timeslicer.scss';

export default class Timeline extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			typeOfCalendar: props.onlyCalendarType ? 'C' : (this.props.default && this.props.default.periodType ? this.props.default.periodType : 'F'),
			selected: {},
			collapseBody: false
		};

		this.onTypeCalendarChange = this.onTypeCalendarChange.bind(this);
		this.onTimeblockSelect = this.onTimeblockSelect.bind(this);
		this.scrollLastYearIntoView = this.scrollLastYearIntoView.bind(this);
		this.collapseBody = this.collapseBody.bind(this);
	}

	componentWillUnmount() {
		if(this.changeTimeout) clearTimeout(this.changeTimeout);
	}

	getValue() {
		const {
			typeOfCalendar,
			selected
		} = this.state;

		let values = [];
		for(let selectedYear in selected) {
				if(selected[selectedYear] && selected[selectedYear].length > 0 && Array.isArray(selected[selectedYear])) {
						values = values.concat(selected[selectedYear]);
				}
		}
		values = values.sort((a,b)=>a-b);

		return {
			periodType: typeOfCalendar.toLowerCase(),
			periods: values
		}
	}

	onTypeCalendarChange(event) {
		let value = event.target.value;

		this.setState(() => ({
			typeOfCalendar: value,
			selected: {}
		}));

		if(this.props.onSelectionChange) {
			this.props.onSelectionChange({
				periodType: value,
				periods: []
			});
		}

		this.scrollLastYearIntoView();
	}

	scrollLastYearIntoView() {
		let slicerBody = $('.timeslicer-body .timeslicer-body-scroll');
		let slicer = $('.timeslicer')[0];
		let diffWidth = slicerBody[0] && slicer ? slicerBody[0].scrollWidth - slicer.scrollWidth : 0;

		if(diffWidth && diffWidth >= 0) {
			slicerBody.animate({
				scrollLeft: '+=' + diffWidth
			}, 300);
		}
	}

	onTimeblockSelect(year, selectedPeriods) {
		let selected = this.state.selected;

		if(!selected[year]) {
			selected[year] = {};
		}

		selected[year] = selectedPeriods;

		this.setState(() => ({
			selected: selected
		}));

		if(this.props.onSelectionChange) {
			let values = [];
			for(let selectedYear in selected) {
				if(selected[selectedYear] && selected[selectedYear].length > 0 && Array.isArray(selected[selectedYear])) {
					values = values.concat(selected[selectedYear]);
				}
			}

			let objectForParent = {
				periodType: this.state.typeOfCalendar,
				periods: values
			};

			if(this.props.withOnChangeTimeout) {
				if(this.changeTimeout) clearTimeout(this.changeTimeout);
				this.changeTimeout = setTimeout(() => this.props.onSelectionChange(objectForParent), 200);
			} else {
				this.props.onSelectionChange(objectForParent);
			}
		}
	}

	collapseBody() {
		this.setState((state, props) => ({
			collapseBody: !state.collapseBody
		}));
	}

	render() {
		const {
			typeOfCalendar,
			selected,
			collapseBody
		} = this.state;

		const {
			from,
			to,
			filter,
			onlyCalendarType
		} = this.props;

		return (
			<div className="timeslicer">
				<div className="timeslicer-calendar-type">
					{
						onlyCalendarType ?
							null :
							<div>
								<label>
									<input type="radio" name="typeOfCalendar" value={'C'}
												 onChange={this.onTypeCalendarChange} checked={typeOfCalendar === 'C'} />
									<FormattedMessage id="timeslicer.calendar-type.calendar" />
								</label>

								<label>
									<input type="radio" name="typeOfCalendar" value={'F'}
												 onChange={this.onTypeCalendarChange} checked={typeOfCalendar === 'F'} />
									<FormattedMessage id="timeslicer.calendar-type.financial-calendar" />
								</label>
							</div>
					}

				</div>

				<TimeslicerSummary periods={selected} typeOfCalendar={typeOfCalendar} onClick={this.collapseBody} collapsedBody={collapseBody} />

				<TimeslicerBody scrollLastYearIntoView={this.scrollLastYearIntoView} from={from} to={to} type={typeOfCalendar}
												filter={filter} default={this.props.default} onTimeblockSelect={this.onTimeblockSelect} viewable={!collapseBody}/>
			</div>
		);
	}
}