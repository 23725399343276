import React, { useCallback, useEffect, useState } from 'react';
import { connect} from 'react-redux';
import NavigationItem from './navigation-item.jsx';
import ContactList from './contactList.jsx';
import { injectIntl } from 'react-intl';
import './navigation.scss';
import { history} from '../../../router';
import { useLocation } from 'react-router-dom';

/* CONTAINER FOR RENDERING OF MENU AND CONTACTS */



function mapDispatchToProps(dispatch) {
    return {
        navigate: (action) => {
            dispatch(action);
        }
    };
}

function mapStateToProps(state) {
    return {
        ts: state.navigation.ts,
        menu:state.navigation.menu,
        contacts:state.navigation.contacts,
		language:state.session.language,
		currentpath : history.location.pathname
    };
}


const NavigationContainer = ({
	ts,menu,contacts,language,navigate,onContactsClicked,
		intl,
		collapsed,
})=> {
	let location = useLocation();
	const [currentPath,setCurrentPath] = useState(location.pathname);

	useEffect(()=>{
		if(location) {
			setCurrentPath(location.pathname);
		}
	

	},[location]);

	const onNavigationItemClicked = useCallback((action)=> {
		//console.log("CLICK",action)
		if(action) navigate(action);
	},[navigate]);

	

	//render all navigationItems together with its children
	const navigation = menu && menu instanceof Array && menu.length > 0 ?
		menu.map((item, idx) => {
			return (
				<NavigationItem key={idx} navId={idx} intl={intl} {...item} collapsed={collapsed} currentpath={currentPath} onClick={onNavigationItemClicked} />
			);
		}) : null;

	//render all contacts
	const contactList = contacts && contacts instanceof Array  && contacts.length > 0  ?
		(
			<div className="contact-list">
				<ContactList contacts={contacts} onContactTitleClicked={onContactsClicked} />
			</div>
		) : null;

	return (
		<section className="sidebar">
			<div className="logo"></div>

			<div className="navigation">
				{navigation}
			</div>

			{contactList}
		</section>
	);


}
/*
class NavigationContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentPath: this.props.currentPath || ''
		};

        this.onContactTitleClicked = this.onContactTitleClicked.bind(this);
        this.onNavigationItemClicked = this.onNavigationItemClicked.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextProps.ts != this.props.ts
			|| nextProps.language != this.props.language
			|| nextProps.currentpath != this.props.currentpath;
	}


	//if action is defined --> use Redux to execute action (change route)
	//set currentPath
	onNavigationItemClicked(action) {
		if(action && this.props.navigate) {
			this.props.navigate(action);
		}
	
	}

	//open navigation container when user clicked on contacts icon & container is collapsed
	onContactTitleClicked() {
		this.props.onContactsClicked();
	}

	render() {
		
	}
}*/

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NavigationContainer));
