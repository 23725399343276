import './display.scss';
import React from 'react';
import classNames from 'classnames';

/**
 * DESCRIPTION: used in the ViewLayout component, this will only render some text with additional css
 *   => for the moment only used in the financial overview pages
 */


class Display extends React.Component {
	constructor(props) {
		super(props);
	}

	//render method
	render() {
		let displayClassNames = classNames(
			'display-field',
			{
				'display-field--value': this.props.isValue,
				'display-field--has-children': this.props.hasChildren
			}
		);

		return (
			<div className={displayClassNames}>
				{this.props.text || "\u00a0"}
			</div>
		);
	}
}


export default Display;
