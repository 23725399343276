import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

export default class TimelineBlock extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: this.props.defaultSelected
		};

		this.quartersWithMonths = [
			{ quarter: 1, months: ['JAN', 'FEB', 'MAR'] },
			{ quarter: 2, months: ['APR', 'MAY', 'JUN'] },
			{ quarter: 3, months: ['JUL', 'AUG', 'SEP'] },
			{ quarter: 4, months: ['OCT', 'NOV', 'DEC'] }
		];

		this.onTimeblockSelect = this.onTimeblockSelect.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.type !== nextProps.type) {
			this.setState(() => ({
				selected: []
			}));
		}
	}

	componentDidMount() {
		if(this.props.onTimeblockSelect && this.props.defaultSelected && this.props.defaultSelected.length > 0) {
			this.props.onTimeblockSelect(this.props.year, this.props.defaultSelected);
		}
	}

	onTimeblockSelect(value) {
		let selected = this.state.selected;

		if(selected.indexOf(value) === -1) {
			selected.push(value);
		} else {
			selected.splice(selected.indexOf(value), 1);
		}

		selected = selected.sort((a, b) => {return a > b});

		this.setState(() => ({
			selected: selected
		}));

		if(this.props.onTimeblockSelect) this.props.onTimeblockSelect(this.props.year, selected);
	}

	renderChildren(year) {
		const {
			type,
			filter
		} = this.props;

		return this.quartersWithMonths.map((quarterWithMonths, idx) => {
			let quarter = (idx+1) * 3;
			let intQuarterValue = type === 'C' ?
				parseInt(moment().year(year).quarter(quarterWithMonths.quarter).endOf('quarter').format('YYYYMMDD'))
				: parseInt(`${year}${quarter < 10 ? '0'+quarter : quarter}`);
			let quarterIsAvailable = (filter && filter.length > 0 && filter.indexOf(intQuarterValue) > -1) || (typeof filter === 'boolean' && !filter);

			let classNamesQuarter = classNames(
				'timeslicer-block-title',
				this.state.selected && this.state.selected.indexOf(intQuarterValue) > -1 ? 'selected' : null,
				quarterIsAvailable ? null : 'unselectable'
			);

			return (
				<div className="timeslicer-q-block" key={idx}>
					<div className={classNamesQuarter} onClick={quarterIsAvailable ? () => this.onTimeblockSelect(intQuarterValue) : null}>
						{`Q${idx + 1}`}
					</div>
					<div className="timeslicer-q-block-body">
						{
							quarterWithMonths.months && quarterWithMonths.months.length > 0 && Array.isArray(quarterWithMonths.months) ?
								quarterWithMonths.months.map((month, mIdx) => {
									let endOfMonth = moment().year(year).month(month).endOf('month').format('YYYYMMDD');
									let monthCnt = (mIdx+1) + (idx*3);
									let intMonthValue = type === 'C' ? parseInt(endOfMonth) : parseInt(`${year}${monthCnt < 10 ? '0' + monthCnt : monthCnt}`);
									let monthIsAvailable = (filter && filter.length > 0 && filter.indexOf(intMonthValue) > -1) || (typeof filter === 'boolean' && !filter);

									let classNamesMonth = classNames(
										'timeslicer-month',
										this.state.selected && this.state.selected.indexOf(intMonthValue) > - 1 ? 'selected' : null,
										monthIsAvailable ? null : 'unselectable'
									);

									return (
										<div className={classNamesMonth} key={`${idx}-${monthCnt}`} onClick={monthIsAvailable ? () => this.onTimeblockSelect(intMonthValue) : null}>
											{type === 'C' ? month : monthCnt}
										</div>
									);
								}) : null
						}
					</div>
				</div>
			);
		});
	}

	render() {
		const {
			year,
			type,
			filter
		}  = this.props;

		const yearVal = type === 'C' ? parseInt(`${year}1231`) : parseInt(`${year}00`);
		let titleYear = year;
		let yearIsAvailable = (filter && filter.length > 0 && filter.indexOf(yearVal) > -1) || (typeof filter === 'boolean' && !filter);

		let classNamesYear = classNames(
			'timeslicer-block-title',
			this.state.selected && this.state.selected.indexOf(yearVal) > -1 ? 'selected' : null,
			yearIsAvailable ? null : 'unselectable'
		);

		return (
			<div className="timeslicer-block">
				<div className={classNamesYear} onClick={yearIsAvailable ? () => this.onTimeblockSelect(yearVal) : null}>
					{type === 'C' ? titleYear : `FY ${titleYear}`}
				</div>
				<div className="timeslicer-block-body">
					{this.renderChildren(year)}
				</div>
			</div>
		);
	}
}