import { gql } from '@apollo/client';

const clientsQuery = gql`query clients { 
    clients {
        _id
        importedId
        key
        name
        clientType
        profitProfile
        institutionalForm
        worldRegion
        yearOfOperationalStart
        officialName
        status
        address {
        	country
        	countryName
        	city
        	postalcode
        	street
        	nr
        	box
        	additional
        }
        settings {
        	financialPeriodEnd {
        		day
        		month
        	}
        	currency
        	reportingScale
        }
        contacts {
        	name
        	role
        	email
        }
        financialPeriods {
            financialYear
            financialYearId
            start
            end
            months
        }
    }
    regions {
        _id
        region
    }
  }
`;

export default clientsQuery;