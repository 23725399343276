import React, { useCallback, useEffect, useRef, useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import { FormattedMessage } from 'react-intl';
import Panel from '../../components/dashboard/panel/panel.jsx';
import Loader from '../../components/loader/spinner';
import { DashboardContainer, SmartLayout } from '../../components/dashboard';
import {exchangeRatesQuery} from '../../../graph/queries/admin/exchangeRates';
import AdminRatesGridView from '../../components/grids/admin-rates';
import AdminSingleRateGridView from '../../components/grids/admin-singlerate';
import Export from '../../components/AdminForms/ExportExchangeForm/index';
import './ExchangeRatePage.scss';
import { useQuery } from '@apollo/client';
import moment from 'moment';


/**
 * DESCRIPTION: Test exchange rates per currency per date period
 * ROUTE: /incofin/admin/exchangerates
 * ACCESSIBLE BY: Incofin-admin & admin
 * DATA: clientsQuery (all the exchange rates in the database without the quota's)
 */

 const substringSearch = (value, filter) => {
    if (!filter) {
      return true;
    }
    let parts = filter.split(' ');
    if(parts.length>1 ){
        return parts.every((p)=>substringSearch(value,p))
    } else {
        const filterToLower = filter.toLowerCase();
        return value && value.toString().toLowerCase().indexOf(filterToLower) > -1;
    }
  
    
};

const execFilter = (data,filter)=> {
    if(!filter) return data;
    return data.map((d)=>{
        return {
            ...d,
            search:moment(d.numericDate.toString(),"YYYYMMDD").format("DD MMM YYY")
        
        };
    }).filter((r)=> {
        return substringSearch(r.search,filter);
    });
}

const AdminExchangeRatesPage = ({loading, data=[]})=> {
    const [localData,setLocaleData] = useState([]);
    const [filter,setFilter] = useState(null);
    const [selectedRate, setSelectedRate] = useState(null);

    const [showExport,setShowExport] = useState(false);

    const toastRef = useRef();

    const onToggleExport = useCallback(()=> {
       
        setShowExport(!showExport)
    },[showExport])

    const onExportError = useCallback((error) => {
        if(toastRef.current) toastRef.current.error(error);
    },[]);


    const  onEndExport = useCallback(()=> {
        setShowExport(false);
    },[]);

    useEffect(()=> {
     
        let filterTimer = setTimeout(()=> {
           
            setLocaleData(execFilter(data,filter));
        },600);
       
        return () => {
            clearTimeout(filterTimer);
          };
    },[data,filter]);

    let ToastMessageFactory = React.createFactory(ToastMessageAnimated);


    return loading ? <Loader/> : <div className={"adminrates"}>
    <ToastContainer ref={toastRef} toastMessageFactory={ToastMessageFactory} className="toast-top-right"/>
            
  <DashboardContainer>
    
      <SmartLayout>
            
          <Panel style={{backgroundColor:'green'}} title={<FormattedMessage id="grid.exchange-rates.title" />} icon="icn-file-excel-o" iconAction={onToggleExport}>

          { showExport ?  <Export onEndExport={onEndExport} onError={onExportError}/> : null}
 
            <div className="grids">
              <AdminRatesGridView rates={localData} itemsPerPage={15} selected={selectedRate}
                                    onFilter={setFilter}
                                  onRowClick={setSelectedRate}/>
              {
                  (selectedRate) ?
                      <AdminSingleRateGridView date={selectedRate.numericDate} rateId={selectedRate._id} itemsPerPage={15} />
                      : null
              }
              </div>
          </Panel>
      </SmartLayout>
  </DashboardContainer>
</div>; 

}

const AdminExchangeRatesGraph = ({})=> {
    const { loading, error, data } = useQuery(exchangeRatesQuery,{
       
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    }); 

    return <AdminExchangeRatesPage 
    loading={loading}
    data={data && data.exchangeRate ? data.exchangeRate : []}
    />
}

export default AdminExchangeRatesGraph;

class _AdminExchangeRatesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details: false,
            exchangeRate: {},
            showExport:false
        };
        this.onEndExport = this.onEndExport.bind(this);
        this.toggleExport = this.toggleExport.bind(this);
    }

    onExportError(error) {
        this.container.error(error);
    }

    onEndExport() {
        this.hideExport();
    }

    hideExport() {
        this.setState(()=> {
            return {showExport:false};
        })
    }

    showExport() {
        this.setState(()=> {
            return {showExport:true};
        })
    }

    toggleExport() {
        this.setState(()=> {
            return {showExport:!this.state.showExport};
        })
    }

    //user clicked on a application user --> go to detailed mode with rate quotes
    onUserClick(exchangeRate) {
        this.setState({details: true, exchangeRate: exchangeRate._id,exchangeRateDate: exchangeRate.numericDate});
        return true;
    }

    //render method
    render() {
        if (this.props.data && this.props.data.loading) {
            return <Loader/>;
        }

        let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

        return(
            <div className={"adminrates"}>
                  <ToastContainer ref={(c) => this.container = c} toastMessageFactory={ToastMessageFactory} className="toast-top-right"/>
                          
                <DashboardContainer>
                    <SmartLayout>
                        <Panel style={{backgroundColor:'green'}} title={<FormattedMessage id="grid.exchange-rates.title" />} icon="icn-file-excel-o" iconAction={this.toggleExport}>
                            { this.state.showExport ?  <Export onEndExport={this.onEndExport} onError={this.onExportError}/> : null}
                            <AdminRatesGridView rates={this.props.data.exchangeRate} itemsPerPage={15} selected={this.state.exchangeRate}
                                                onRowClick={this.onUserClick.bind(this)}/>
                            {
                                (this.state.details) ?
                                    <AdminSingleRateGridView date={this.state.exchangeRateDate} rateId={this.state.exchangeRate} itemsPerPage={15} />
                                    : null
                            }
                        </Panel>
                    </SmartLayout>
                </DashboardContainer>
            </div>
        );
    }
}


//export default graphql(exchangeRatesQuery)(AdminExchangeRatesPage);