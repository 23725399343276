import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from '@apollo/client/react/hoc';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import Panel from '../../components/dashboard/panel/panel.jsx';
import Loader from '../../components/loader/index.jsx';
import AdminUsersGridView from '../../components/grids/admin-users';
import {DashboardContainer, SmartLayout} from '../../components/dashboard';
import UserForm from '../../components/AdminForms/UserForm/UserForm';
import usersQuery from '../../../graph/queries/admin/users';
import { createUser, updateUser, reinviteUser, deleteUser } from '../../../graph/mutations/user';

/**
 * DESCRIPTION: add/save/delete/invite users
 * ROUTE: /incofin/admin/users
 * ACCESSIBLE BY: Incofin-admin & admin
 * DATA: usersQuery (all the users in the database)
 * MUTATIONS: createUser & updateUser & reinviteUser & deleteUser
 */


class AdminUserFormPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			creating: false,
			editing: false,
			saving: false,
			user: {}
		};
	}

	//user clicked on a application user --> go to edit mode/reinvite user/delete user out of the system
	onUserClick(action, user, rowIndex) {
		if (action === 'edit') {
			this.setState({editing: true, user: user});
		} else if (action === 'reinvite') {
			// send invite mail
			this.props.reinviteUser({
					variables: {
							userId: user._id
					}
			}).then(({data}) => {
				if (data.reinviteUser.success && this.containerRef) {
					this.containerRef.success('A new invitation has been sent.');
				} else if(data.createUser.error && this.containerRef) {
					this.containerRef.error(data.createUser.error.code, data.createUser.error.message);
				}
			}).catch((error) => {
				if (this.containerRef) this.containerRef.error(error.message);
			});
		} else if(action === 'delete') {
			let confirmation = window.confirm(`Are you sure you want to delete this user?`);

			if(confirmation) {
				//'delete' user
				this.props.deleteUser({
					variables: {
						userToDelete: user._id
					}
				}).then(({data}) => {
					if(data.deleteUser.success && this.containerRef) {
						this.containerRef.success('User has been deleted');
						this.props.data.refetch();
					} else if(data.deleteUser.error && this.containerRef) {
						this.containerRef.error(data.deleteUser.error.code, data.deleteUser.error.message);
					}
				}).catch((error) => {
					if(this.containerRef) this.containerRef.error(error.message);
				});
			}
		}
	}

	//user clicked on "add +" --> go to create mode and fill in form with an empty value tree
	addUser() {
		this.setState({
			creating: true,
			user: {
				firstName: null,
				lastName: null,
				email: null,
				roles: [],
				clients: {
					regions: null,
					ids: null
				},
				address: {
					additional: null,
					box: null,
					city: null,
					country: null,
					nr: null,
					postalcode: null,
					state: null,
					street: null
				},				
				communication: []
			}
		});
	}

	//save/add user to the database using the above declared mutations
	// --> when successful, refetch (re-execute) graphql query for consistent data
	saveUser() {
		this.setState({saving: true});
		let userForm = this.userFormRef.wrappedInstance;

		if (userForm.state.valid) {
			if (this.state.creating) {
				//add user
				this.props.createUser({
					variables: {
						user: userForm.state.values,
					}
				}).then(({data}) => {
					if (data.createUser.success && this.containerRef) {
						this.containerRef.success('User was successfully created!');
						this.setState({creating: false, editing: false, saving: false, user: {}});
						this.props.data.refetch();
					} else if(data.createUser.error && this.containerRef) this.containerRef.error(data.createUser.error.code, data.createUser.error.message);
				}).catch((error) => {
					if (this.containerRef) this.containerRef.error(error.message);
				});
			} else if (this.state.editing) {
				//edit existing user
				this.props.updateUserAsAdmin({
					variables: {
						userId: userForm.state.values._id,
						user: userForm.state.values,
					}
				}).then(({data}) => {
					if (data.updateUserAsAdmin.success && this.containerRef) {
						this.containerRef.success('User was successfully updated!');
						this.setState({creating: false, editing: false, saving: false, user: {}});
						this.props.data.refetch();
					} else if(data.updateUserAsAdmin.error && this.containerRef) this.containerRef.error(data.updateUserAsAdmin.error.code, data.updateUserAsAdmin.error.message);
				}).catch((error) => {
					if (this.containerRef) this.containerRef.error(error.message);
				});
			}
		}
	}

	//user clicked on cancel --> go to view mode and show grid of all users
	cancel() {
		this.setState({creating: false, editing: false, saving: false, user: {}});
	}

	//render method
	render() {
		if (this.props.data.loading) {
			return <Loader/>;
		}

		
		let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

		return (
			<DashboardContainer>
				<SmartLayout>
					<Panel title={<FormattedMessage id="grid.users.title" />}>
						<ToastContainer ref={(r)=>this.containerRef=r} toastMessageFactory={ToastMessageFactory} className="toast-top-right"/>
						<div style={{ position:'relative',top:'0em'}}>
							{
								!this.state.creating && !this.state.editing ?
									<button onClick={this.addUser.bind(this)}><FormattedMessage id="app.buttons.add"/></button> :
									null
							}
							</div>
						{
							!this.state.creating && !this.state.editing ?
								<AdminUsersGridView users={this.props.data.users} itemsPerPage={25}
																		onAction={this.onUserClick.bind(this)} /> :
								<UserForm values={this.state.user} saving={this.state.saving} ref={(r)=>this.userFormRef = r}
													regions={this.props.data.regions} countries={this.props.data.countries} />
						}

						<div style={{height: '3em', marginTop: '2em'}}>
							{
								!this.state.creating && !this.state.editing ?
									<button onClick={this.addUser.bind(this)}><FormattedMessage id="app.buttons.add"/></button> :
									<div className="form-actions">
										{
											this.state.creating ? 
												<button onClick={this.saveUser.bind(this)}>
													<FormattedMessage id="app.buttons.send-invite"/>
												</button>
												:
												<button onClick={this.saveUser.bind(this)}>
													<FormattedMessage id="app.buttons.save-user"/>
												</button>
										}
										
										<button onClick={this.cancel.bind(this)} className="cancel" style={{margin: '0 1em'}}>
											<FormattedMessage id="app.buttons.cancel"/>
										</button>
									</div>
							}
						</div>
					</Panel>
				</SmartLayout>
			</DashboardContainer>
		);
	}
}

export default graphql(usersQuery)(
	graphql(createUser, { name: 'createUser' })(
	graphql(updateUser, { name: 'updateUserAsAdmin' })(
	graphql(reinviteUser, { name: 'reinviteUser' })(
	graphql(deleteUser, { name: 'deleteUser' })(AdminUserFormPage)))))