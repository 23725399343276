import React from 'react';
import { FormattedMessage } from 'react-intl';
import NumericField from '../fields/numeric/index';
import TextField from '../fields/text/index';
import { clone } from '../../../utils';
import RequiredLabel from '../RequiredLabel/index';


class CovenantForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			values: clone(this.props.values, true) || {},
			valid: true,
		};

		this._validations = new Map();

		this.onFieldChange = this.onFieldChange.bind(this);
		this.onValidationChange = this.onValidationChange.bind(this);
	}

	//set new value in received valueTree based on prop identifier
	iterateObject(values, value, identifier) {
		let foundValue = false;
		Object.keys(values).forEach((k) => {
			if (values[k] !== null && typeof values[k] === 'object' && k !== 'centralCovenantId') {
				foundValue = !foundValue ? this.iterateObject(values[k], value, identifier) : foundValue;
			}
			if (k === identifier) {
				values[k] = value;
				foundValue = true;
			}
		});

		return foundValue;
	}

	onFieldChange(field, value, previousValue, valid) {
		let values = this.state.values;
		let foundValue = this.iterateObject(values, value, field.props.identifier);
		if(!foundValue) values[field.props.identifier] = value;
		this.setState(() => ({
			values: values
		}));
	}

	onValidationChange(field, valid, value) {
		if(this._validations.has(field.props.identifier)) this._validations.delete(field.props.identifier);
		if(!valid) this._validations.set(field.props.identifier, valid);
		let myValid = this._validations.size === 0;
		this.setState(() => ({
			valid: myValid
		}));
	}

	render() {
		const {
			values
		} = this.state;

		const standardBindings = {
			validations: {required: true},
			activePageIsSubmitted: this.props.saving,
			onChange: this.onFieldChange,
			onValidationChange: this.onValidationChange
		};

		const fldType = values['centralCovenantId']['fieldType'];

        let fldLabel = '';
        switch(fldType) {
			case "percentual":
                fldLabel='%';
				break;
			case "currency":
                fldLabel='(currency)';
				break;

		}

		let tooltipBindings = values['centralCovenantId'] && values['centralCovenantId']['guide'] && values['centralCovenantId']['guide'].length > 0 ?
			{
				'data-tip': '<div>' + values['centralCovenantId']['guide'] + '</div>',
				'data-for': 'tooltip',
				'data-place': 'right',
				'data-effect': 'solid'
			} : {};

		return (
			<div className="admin-form">
				<div className="required-fields-label">
					<FormattedMessage id="required-fields.label"/>
				</div>

				<div className="form-group readOnly">

					{ Object.keys(tooltipBindings).length > 0 ?
						<span className="covenant-info-guide" {...tooltipBindings} /> : null }

					<label>
						<FormattedMessage id="admin.form.covenant.name"/>
					</label>
					<TextField value={values['centralCovenantId']['name']} identifier={'name'} readOnly={true} {...standardBindings} />
				</div>

				<div className="form-group">
					<div className="block">
						<label className="top-label">
							<FormattedMessage id="admin.form.covenant.range"/>
						</label>

						<div className="block-children">
							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.covenant.range.min"/>
									<RequiredLabel/>
								</label>
								<NumericField value={values['range']['min']} postText={fldLabel} max={values['range']['max']} identifier={'min'} {...standardBindings} precision={2} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.covenant.range.max"/>
									<RequiredLabel/>
								</label>
								<NumericField value={values['range']['max']} postText={fldLabel} min={values['range']['min']} identifier={'max'} {...standardBindings} precision={2} />
							</div>
						</div>
					</div>
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.covenant.alertThreshold"/>
						<RequiredLabel/>
					</label>
					<NumericField allowNegative={false} value={values['alertThreshold']} postText={fldLabel}  identifier={'alertThreshold'} {...standardBindings} precision={2} />
				</div>
			</div>
		);
	}
}


export default CovenantForm;