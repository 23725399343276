import React from 'react';
import { FormattedMessage } from 'react-intl';
import SelectField from '../fields/select/index';
import { clone } from '../../../utils';

const fieldTypeOptions = [
	{ value: 'currency', label: 'Currency' },
	{ value: 'percentual', label: 'Percentual'},
	{ value: 'numeric', label: 'Numeric'}
];


class ExportCovenantsForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			values: clone(props.values, true)
		};

		this.onFieldChange = this.onFieldChange.bind(this)
	}

	//set new value in received valueTree based on prop identifier
	iterateObject(values, value, identifier) {
		let foundValue = false;
		Object.keys(values).forEach((k) => {
			if (values[k] !== null && typeof values[k] === 'object') {
				foundValue = !foundValue ? this.iterateObject(values[k], value, identifier) : foundValue;
			}
			if (k === identifier) {
				values[k] = value;
				foundValue = true;
			}
		});

		return foundValue;
	}

	//method hooked up to all the fields --> detect changes
	onFieldChange(field, value, previousValue, valid) {
		let values = this.state.values;
		let foundValue = this.iterateObject(values, value, field.props.identifier);
		if(!foundValue) values[field.props.identifier] = value;

		this.setState(() => ({
			values: values
		}));
	}

	getValues() {
		return {
			fieldType: this.state.values.fieldType
		};
	}

	render() {
		const {
			values
		} = this.state;

		const standardBindings = {
			onChange: this.onFieldChange
		};

		return (
			<div className="admin-form">
				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.covenant.fieldType"/>
					</label>
					<SelectField multi={false} options={fieldTypeOptions} placeholder={'Select field type ... '}
											 {...standardBindings} identifier={'fieldType'} value={values['fieldType']} />
				</div>
			</div>
		);
	}
}

export default ExportCovenantsForm;