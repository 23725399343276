import {
	COVENANTS_LOAD_ERROR, COVENANTS_LOADED, COVENANTS_LOADING,
	COVENANTS_SET_FILTER, COVENANTS_SET_PAGE, COVENANTS_SET_CURRENT
} from '../actions/covenants';

const initialState = {
	loading: false,
	error: null,
	data: [],
	totalCovenants: 0,
	currentCovenant: null,
	curPage: 1,
	filter: null
};

export const covenantReducer = (state = initialState, action) => {
	switch (action.type) {
		case COVENANTS_LOAD_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		case COVENANTS_LOADING:
			return {
				...state,
				loading: true,
				error: null
			};
		case COVENANTS_LOADED:
			return {
				...state,
				loading: false,
				error: null,
				data: action.keepPreviousResult ? [...state.data, ...action.covenants] : action.covenants,
				totalCovenants: action.totalCovenants
			};
		case COVENANTS_SET_CURRENT:
			return {
				...state,
				currentCovenant: action.payload.covenantId
			};
		case COVENANTS_SET_PAGE:
			return {
				...state,
				curPage: action.payload.curPage
			};
		case COVENANTS_SET_FILTER:
			return {
				...state,
				curPage: 1,
				filter: action.payload.filter
			};
	}
	return state;
};
