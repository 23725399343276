import { useEffect, useState } from "react";
import { ReportingScope } from "../../NewFormula/scope/ReportingScope";




export const useReportScope = (numeric,values,reportedScale=1)=> {

    const [updatedScope,setUpdatedScope] = useState(0);
    const refreshScope = ()=> setUpdatedScope((a)=>a++);
    const [scope,setScope] = useState(new ReportingScope(numeric,values,reportedScale));

    useEffect(()=> {
        console.log("useReportScope","renew",{numeric,values,reportedScale});
        setScope(new ReportingScope(numeric,values,reportedScale))
    },[numeric,values,updatedScope]);

    /*
    useEffect(()=> {
        console.log("SCOPE UPDATE",reportedScale)
    },[reportedScale])*/

    return {
        scope,
        refreshScope,
       
    }
}