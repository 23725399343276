import React, { useCallback } from 'react';
import { push} from '../../../router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { injectIntl } from 'react-intl';

import Loader from '../../components/loader/spinner';
import Panel from '../../components/dashboard/panel/panel.jsx';
import Chart from '../../components/dashboard/chart/chart.jsx';
import SummaryBlock from '../../components/summary/block/summaryBlock.jsx';
import SummaryList from '../../components/summary/list/summaryList.jsx';
import ReportsGridView from '../../components/grids/reports/clients';
import { DashboardContainer, SmartLayout } from '../../components/dashboard';

import dashBoardQuery from '../../../graph/queries/clients/dashboard';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useHistory,useRouteMatch } from 'react-router-dom';

const InactiveClient = ({name})=> {

	return <div style={{
		textAlign:'center',
		color:'#FFFFFF',
		backgroundColor:'rgba(200,0,0,0.5)'
	}}>{name.toUpperCase()} IS CURRENTLY INACTIVE</div>
}


/**
 * DESCRIPTION: dashboard overview with summaries (total assets, total equity, ...), charts, report instances (status, category, period, ...)
 * ROUTE: /client/{clientId}
 * ACCESSIBLE BY: Client, Incofin-RD, Incofin-IM, Incofin-admin & admin
 * DATA: dashboardQuery
 *          => reports
 *          => clientSummaries (empty for the moment)
 *          => clientDashCharts (empty for the moment)
 */

function mapDispatchToProps(dispatch, ownProps) {
	return {
		...bindActionCreators({}, dispatch),
	/*	onReportClick: (report) => {
			dispatch(push(`/client/${ownProps.match.params.clientId}/reports/${report.key}/${report.period}`))
		}*/
	};
}


const Summaries = ({summaries,intl})=> {
	if(summaries) {
		const summaryList = summaries.map((item, idx) => {
			if(item && item.value && item.value !== null) {
				const keyName = `summary-${idx + 1}`;
				return <SummaryBlock key={keyName} iconName={item.icon} title={item.title} type={item.type} intl={intl}
														 value={item.value} valueIcon={item.valueIcon} subTitle={item.subtitle} />;
			}
		});

		if (summaryList.length > 0) {
			return <SummaryList scrollable={true}>{summaryList}</SummaryList>;
		}
	} 
	return null;
}

const RenderCharts = ({charts,currency,intl})=> {
	if(charts) {
		const colors = ['#12B6CF', '#93BB36', '#595959'];

		console.log("render charts",charts);

		return charts.map((chartProps, idx) => {
			return (
				<Panel key={`chartPanel-${idx}`} title={chartProps.title} subtitle={currency}>
					<Chart {...chartProps} colors={colors} currency={currency} intl={intl}/>
				</Panel>
			);
		});
	}

	return [];
}

const Dashboard = ({
	//data,
	language,
	client,
	intl,currency
})=> {

	const {data,loading} = useQuery(dashBoardQuery, {
		variables:{
			clientId: client._id,
			language,
			currency
		},
		refetchWritePolicy:'overwrite',
		fetchPolicy:'cache-and-network'
	})



	console.log("DASH DATA",data)
	
	const history = useHistory();
	const onReportClick = useCallback((report)=> {
		history.push(`/client/${report.clientKey}/reports/${report.key}/${report.period}`)
	},[history])
	if (!data || loading || data.loading) {
		return <DashboardContainer className="c-dashboard--client">
				<SmartLayout>
					<Loader/>
				</SmartLayout>

				<SmartLayout>
					<Loader/>
				</SmartLayout>

				<SmartLayout>
					<Loader/>
				</SmartLayout>
			</DashboardContainer>;
	}

	const {
		clientDashCharts,
		clientSummaries,
	} = data;

	return <DashboardContainer className="c-dashboard--client">
		{client && client.status==='inactive' ? <InactiveClient name={client.name}/> : null}
				<SmartLayout>
					<Summaries intl={intl} currency={currency} summaries={clientSummaries}/>
				</SmartLayout>

				<SmartLayout>
				<ReportsGridView clientId={client._id}  itemsPerPage={5} onRowClick={onReportClick}
		fullMode={false} showFilter={true} showCustomFilter={false} enableSort={true} showPager={true}/>
				</SmartLayout>

				<SmartLayout>
					{client && client.clientType!="AGRO" && RenderCharts({charts:clientDashCharts,currency,intl})}
				</SmartLayout>
			</DashboardContainer>

}



export default injectIntl(connect(function(state) {
	return {
		language: state.session.language,
		client: state.client,
		currency: state.session.currency
	}
}, mapDispatchToProps)(Dashboard));
/*
graphql(dashBoardQuery, {
	options: ({client, currency, language, params}) => ({
		variables: {
			clientId: client._id,
			language,
			currency
		}, forceFetch: true
	})
})(Dashboard)));*/