import { useIntl } from "react-intl"
import { FormTreeContent } from "../FormTree"
import { FormValuesContext } from "../hooks"
import { FormFieldDisplayRender } from "./FieldValue"
import moment from "moment"
import { StatusTag } from "../../../ui/components/StatusTag"



export const FormDataColumn = ({config,enableToggle,data,idx}) => {
	//const intl = useIntl();

	const dte = moment(data?.context?.numeric?.toString(),'YYYYMMDD').format('MMM YYYY')

	return <div key={idx} className="form-tree-column form-tree-data-column">
		<FormValuesContext.Provider value={{config,data}}>
			<div className="form-tree-column-header">
				<div>{dte}</div>
				<div><StatusTag status={data?.status}/></div>
				<div style={{fontSize:'0.7em',textDecoration:"underline"}}>{data?.settings?.currency} 1/{data?.settings?.reportingScale}</div>
			</div>
		{FormTreeContent({config,enableToggle,renderField:FormFieldDisplayRender})}
		</FormValuesContext.Provider>
	</div>
}
