import React,{useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
  } from "react-router-dom";
  import { useSelector, useDispatch } from 'react-redux'
import { Forbidden } from '../ui/components/400/Forbidden'  
import {  useLazyQuery, useQuery } from '@apollo/client';
import {profileQuery} from '../graph/queries/profileQuery';
import { loggingIn,loggedIn,setProfile } from '../redux/actions/session';
import AuthHelper from './AuthHelper';
import moment from 'moment';
import Meteor from '../Meteor';

export function PrivateRoute({ children,component, ...rest }) {

    const authenticated = useSelector((state) => state.session.authenticated);
   // const user = useSelector((state) => state.session.user);

   // console.log('PrivateRoute authenticated',authenticated)
    return (
      <Route
        {...rest}
        render={({ location,match }) => (
        authenticated ? (
          component ? React.createElement(component,{match}) : children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        )}
      />
    );
}

export function PrivateIncofinRoute({ children,component, ...rest }) {

    const authenticated = useSelector((state) => state.session.authenticated);
    const user = useSelector((state) => state.session.user);

    const authorized = user && (user.roles.indexOf('Incofin') > -1 || user.roles.indexOf('Incofin-admin') > -1)

    return (
      <Route
        {...rest}
        render={({ location,match }) =>
        authenticated ? authorized ? (
          component ? React.createElement(component,{match}) : children
          ) : (
              <Forbidden/>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
}

export function PrivateAdminRoute({ children,component, ...rest }) {

    const authenticated = useSelector((state) => state.session.authenticated);
    const user = useSelector((state) => state.session.user);

    const authorized = user && (user.roles.indexOf('Incofin-admin') > -1)

    return (
      <Route
        {...rest}
        render={({ location,match }) =>
        authenticated ? authorized ? (
          component ? React.createElement(component,{match}) : children
        
          ) : (
              <Forbidden/>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
}


export function useAuth() {
  const [fetchProfile,{ called,loading, error, data }]= useLazyQuery(profileQuery,{
    forceFetch: true
  });
  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const [busy,setBusy] = useState(true);
 
  // console.log('busy',busy,called,loading,session)
  useEffect(()=> {

    // console.log('busy effect',busy,called,loading,session.authenticated)
    if(session.authenticated) {
      setBusy(false);
    }
    if(busy && called && !loading) {
      
      if(error) {
        // console.error(error);
       // alert("ERROR");
      } else if(data) {
        const {
          whoAmI,
          clients,
          regions
        } = data;

        // console.log("busy DATA",data);
        if(whoAmI && whoAmI.person) {
          Meteor.setUserId(whoAmI._id);
          AuthHelper.decryptAndSave(whoAmI.permissions, whoAmI._id);

          const {
              __typename,
              ...user
          } = whoAmI.person;
          user.permissions = whoAmI.permissions;
          moment.locale(user.language);
          user.isIncofin = AuthHelper.isGranted('level','INCOFIN',whoAmI._id);
          user.isClient = AuthHelper.isGranted('level','CLIENT',whoAmI._id);
          user.defaultClient =  whoAmI.clientKey;
        
          user.home = user.isIncofin ? '/incofin/' : user.isClient ? `/client/${user.defaultClient}/` : '/login' ;


          dispatch(setProfile({user,clients:clients, regions:regions}));
        }

      }
      setBusy(false);
      dispatch(loggedIn());
     
      // console.log('set busy false')
    } else if(!called && !session.authenticated) {
    
      const token = localStorage.getItem('token');
      // console.log("has token?",token);
      if(token) {
        setBusy(true);
        dispatch(loggingIn());
        fetchProfile();
      } else {
        setBusy(false);
      }
    }

  },[session,called,loading])

  useEffect(()=> {
    // console.log('busy change',busy)
  },[busy]);

  // console.log("session",session)
  // console.log('>busy',busy || session.loading,busy,session.loading)


  return [busy || session.loading, session && session.user ? session.user : null,  error];
}