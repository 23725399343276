import { gql } from '@apollo/client';

const unregisteredUser = gql`query unregisteredUser($userId: String) {
	unregisteredUser(userId: $userId) {
		_id
		firstName
		lastName
		email
		address {
			country
			countryName
			state
			city
			postalcode
			street
			nr
			box
			additional
		}
		communication {
			type
			value
		}
		language
	}
	countries {
        _id
        name
    }
}
`;

export default unregisteredUser;