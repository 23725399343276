import {apolloClient, store} from '../../store';
import clientQry from '../../../graph/queries/clients/clientQry';
import { switchCurrency } from '../session';
import Meteor from '../../../Meteor';
import {
    CLIENT_CLEAR,CLIENT_LOADING,CLIENT_LOADED,LOAD_ERROR,CLIENT_SET_PERIOD_SELECTOR
} from './actions'

export function clearClient() {
	return {
		type: CLIENT_CLEAR
	};
}

export function setPeriods(selection) {
	return {
		type: CLIENT_SET_PERIOD_SELECTOR,
		payload: selection
	};
}

export function loadClient(id) {
    return function (dispatch) {
        dispatch({
            type:CLIENT_LOADING
        });

        apolloClient.query({
            query: clientQry,
            forceFetch: true,
            variables:{
                clientId:id
            }
        }).then((result) => {
            console.log('CLIENT LOAD APOLLO',result)
            if(result && result.data && result.data.client) {
                if(result.data.client.settings && result.data.client.settings.currency) {
                    dispatch(switchCurrency(result.data.client.settings.currency));
                }

                dispatch({
                    type:CLIENT_LOADED,
                    client:result.data.client
                });
            } else {
                dispatch({
                    type:LOAD_ERROR,
                    payload:"No client"
                });
            }
        }).catch((error)=> {
            // handle error;
            dispatch({
                type:LOAD_ERROR,
                payload:error.message
            });
            console.log("ERROR CLIENT LOAD",error);
        });
    };
}