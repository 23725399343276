import React from 'react';
import { injectIntl } from 'react-intl';


class Filter extends React.Component {
	constructor(props) {
		super(props);

		this.onFilterChange = this.onFilterChange.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return false;
	}

	onFilterChange() {
		if(this.props.onFilterChange) this.props.onFilterChange(this.filterRef.value);
	}

	render() {
		return(
			<div className="filter-wrapper">
				<input type="text" name="filter" placeholder={this.props.intl.formatMessage({id:"grid.search-placeholder"})} className="form-control"
							 onChange={this.onFilterChange} ref={(input) => this.filterRef = input} />
			</div>
		);
	}
}

export default injectIntl(Filter);