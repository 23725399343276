import { useCallback, useContext, useMemo, useState } from "react"
import { FormulaContext } from "../hooks/formulaContext"
import { Drawer } from "antd"
import { math } from "../mathjs"
import { FormulaViewer } from "./Viewer"
import { ClientContext } from "../../../ui/containers/client"
import { gql, useApolloClient, useLazyQuery } from "@apollo/client"
import { FormulaScope } from "../scope/FormulaScope"
import moment from "moment"
import { useSelector } from "react-redux"
import { useCurrency } from "../../../hooks/useCurrency"
import { useApp, useConfigCache } from "../../../hooks/useApp"
import dayjs from "dayjs"
import { FormulaViewerProvider } from "../hooks/useFormulaViewer"

// todo:
// config cache
// field to name
// nice view

const FormulaDrawerContent = ({context,onClose})=> {

// {context.formula ? math.parse(context.formula).toString() : null}
    let curMom = context?.numeric ? ` - ${moment(context.numeric.toString()).format("MMM YYYY")}` : "";

    
    return context ?  <Drawer mask={false} onClose={onClose} open={true} placement="bottom" title={context.name + curMom}>
           {context.formula ? <FormulaViewer formula={context.formula} scope={context?.scope||null}/> : null}
    </Drawer> : null
}




/*
export const _FormulaViewerProvider = ({children})=> {

    const client = useContext(ClientContext);
    const apolloClient = useApolloClient();
    const [data,setData] = useState(null);
    const [loading,setLoading] = useState(false);
    const currency = useSelector(s=>s.session?.currency||"USD")

    

    const show = useCallback((name,formula,configKey,path,numeric,scope=null,definition=null)=> {
        
        if(numeric && !scope) {
            // in a multiview, fetch when needed
            setLoading(true);
            
            setData({
                name,
                formula,
                configKey,
                path,
                numeric,
                scope:null,
                definition,
                error:null
            });
            apolloClient.query({
                query:qry,
               
                variables: {
                    clientId:client._id,
                    numeric,
                    currency,
                    fields:formula.dependencies
                }
            }).then(({data:result,error})=> {
                //debugger;
                setData({
                    name,
                    formula,
                    configKey,
                    path,
                    numeric,
                    definition,
                    scope:new FormulaScope(numeric,result?.fieldScope,1),
                    error:null
                });
                setLoading(false);
            }).catch((e)=>{
                //debugger;
                setLoading(false);
                setData({
                    name,
                    formula,
                    configKey,
                    path,
                    numeric,

                definition,
                    scope:null,
                    error:e.message
                });
            })
        } else {
            setData({
                name,
                formula,
                configKey,
                numeric,
                path,
                scope,

                definition,
                error:null
            });
        }
        
    },[client,currency])

    



    return <FormulaContext.Provider value={{
        show,
        data,
        loading
    }}>
        {children}
        <FormulaDrawerContent context={data} onClose={()=>setData(null)} />
    </FormulaContext.Provider>
}
*/

export const FormulaDrawer = ({children})=> {
/*
    const client = useContext(ClientContext);


    const [showFormula,setShowFormula] = useState(false);

*/



   
    return <FormulaViewerProvider>
    {children}
    </FormulaViewerProvider>
}