import { gql } from '@apollo/client';

export const reportConfigsSelect = gql`query  reportConfigs($clientType:String){ 
    reportConfigs(clientType:$clientType) { 
   
        _id
        name { value }
    }
  }  
`;
