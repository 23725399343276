import { useQuery } from "@apollo/client"
import { useCallback, useContext, useState } from "react"
import { ClientContext } from "../../ui/containers/client"
import { formConfigQuery } from "../../graph/queries/form/form"
import { FormDataColumn } from "./View/FormDataColumn"
import financeQuery from "../../graph/queries/form/finance"
import { FormLabelColumn } from "./View/FormLabelColumn"
import { Card, Divider, Spin } from "antd"
import Timeline from "../TimeSlicer"
import { useSelector } from "react-redux"
import { ConfigPeriodSelector, PeriodSelector } from "../../ui/components/PeriodSelector/periodSelector"
import { FormulaDrawer } from "../NewFormula/components/Drawer"


const FormWrapper = ({clientId,config,numerics})=> {
	
	
	const currency = useSelector(s=>s.session?.currency||"USD")
	const { data, loading } = useQuery(financeQuery,{
		variables:{
			clientId:clientId,
			formConfigId:config.key,
			range:numerics,
			currency

		}, fetchPolicy:"no-cache"
	})
	//if(loading) return <div>loading</div>
	let dataSet = data?.financeQuery || [];



	return <>
	{dataSet.map((f,idx)=>FormDataColumn({config,enableToggle:false,data:f,idx}))}
	</>
}


export const FormMultiView = ({configKey})=> {

	const [periods,setPeriods] = useState([]);

	

	const onPeriodChange = useCallback((selection)=> {
		setPeriods(selection);
	},[]);


	return <Card>
		<ConfigPeriodSelector configKey={configKey} defaultSelection={periods} onChange={onPeriodChange} />
		<Divider/>
		<FormMultiViewContent configKey={configKey} numerics={periods}/>
		
	</Card>
}

export const FormMultiViewContent = ({
	configKey, numerics
}) => {

	const clientContext = useContext(ClientContext)
	const language = useSelector(s=>s?.session?.language || "en")

	const {data,loading} = useQuery(formConfigQuery,{
		variables:{
			key:configKey,
			language
		},
		context: { headers: { "Incofin-Set-Language": language } }
		, fetchPolicy:"no-cache"
	})

	
	return <div className="form-tree">
		<div className="form-tree-fixed">
		{data?.formConfig ?<FormLabelColumn key={data.formConfig.key+"_labels"} enableToggle={false} config={data.formConfig} /> : null}
		</div>
		<div className="form-tree-data">
			{
			data?.formConfig && clientContext?._id ? <FormWrapper clientId={clientContext._id} config={data.formConfig} numerics={numerics}>

</FormWrapper>
: null}
			
		</div>
	</div>

}