import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import Timeline from '../../../../features/TimeSlicer/index';
import CurrencySelect from '../../fields/select/currency';
import { graphql } from '@apollo/client/react/hoc';
import Spinner from '../../loader/spinner';
import {createExportExchange} from '../../../../graph/mutations/export';
import RequiredLabel from '../../RequiredLabel/index';


class ExportExchangeForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currency:'USD',
            generating:false
        };

        this.periodSelector = null;

        this.onCurrencyChange = this.onCurrencyChange.bind(this);
        this.createExport = this.createExport.bind(this);

    }

    onCurrencyChange(field, val) {
        console.log('curr',val);
        this.setState(() => ({
            currency: val ? val.value ? val.value : val : "USD"
        }));
    }



    createExport() {

        let selectedPeriods = this.periodSelector.getValue();
        console.log(this.state.currency,selectedPeriods);
        if (this.state.currency && selectedPeriods && selectedPeriods.periods && selectedPeriods.periods.length>0) {

            this.setState(() => {
                return {generating: true}
            });

            this.props.createExportExchange({
                variables: {
                    currency: this.state.currency,
                    periods:selectedPeriods.periods
                }
            }).then(({data}) => {
                this.setState(() => {
                    return {generating: false}
                });

                if (data.createExportExchange) {
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = "/download/" + data.createExportExchange;
                    a.click();
                    document.body.removeChild(a);
                    if(this.props.onEndExport) {
                        this.props.onEndExport();
                    }
                }
            }).catch((e) => {
                if(this.props.onExportError) this.props.onExportError(e.message);
                this.setState(() => {
                    return {generating: false}
                });
            });
        } else {
						// if (this.props.onExportError) this.props.onExportError('Please fill in all the information.');
						if(this.refs.container) this.refs.container.error('Please fill in all the information.');
        }
    }

    getValues() {
        let periodSelectorValues = this.periodSelector.getValue();

        return {
            currency: this.state.currency,
            periods: periodSelectorValues ? periodSelectorValues.periods : []
        };
    }

    render() {
       /* const {

        } = this.props;
        */

        const {
            currency,
            generating
        } = this.state;


        if (generating) {
            return (
                <div style={{textAlign: 'center'}}>
                    <Spinner/>
                    GENERATING EXPORT
                </div>
            );
        }

				
				let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

        return (
            <div className="admin-form">
								<ToastContainer ref="container" toastMessageFactory={ToastMessageFactory} className="toast-top-right" />

								<div className="required-fields-label">
										<FormattedMessage id="required-fields.label"/>
								</div>

                <div className="form-group">
                    <label>
                        <FormattedMessage id="export.labels.period"/>
												<RequiredLabel/>
                    </label>
                    <div style={{width:'75%'}}>
                        <Timeline ref={(c) => this.periodSelector = c} from={2000} to={moment().year()}
                                  withOnChangeTimeout={false} onlyCalendarType={true} />
                    </div>
                </div>

                <div className="form-group">
                    <label>
                       Base currency
                    </label>
                    <CurrencySelect multi={false} onChange={this.onCurrencyChange} value={this.state.currency} placeholder={'Select currency(s) ... '}/>
                </div>
                <div className="submit-button" style={{marginTop:10}}>
                    <button onClick={this.createExport}>
                        <FormattedMessage id="export.labels.export-to-excel"/>
                    </button>
                </div>

            </div>
        );
    }
}

export default graphql(createExportExchange, {name: 'createExportExchange'})(ExportExchangeForm);