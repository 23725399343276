
import { clearWizardState } from '../../Wizard/redux/wizard.actions';

export const FORM_CHANGE_VALUE = 'FORMS/CHANGE_VALUE';
export const FORM_VALIDATION_TREE = 'FORMS/VALIDATION_TREE';
export const FORM_IS_VALID = 'FORMS/IS_VALID';
export const FORM_SET_VALUES = 'FORMS/SET_VALUES';
export const FORM_CLEAR_STATE = 'FORMS/CLEAR_STATE';

/*export function setFormValues(valueTree, key) {
	return {
		type: FORM_SET_VALUES,
		values: valueTree,
		key
	};
}

export function changeValue(value, invalid, pathInValueTree, internalName, formKey) {
	return (dispatch) => {
		dispatch(changeValueInTree(value, pathInValueTree, formKey));
		dispatch(changeValidationTree(internalName, invalid, formKey));
		dispatch(isFormValid(formKey));
	};
}

function changeValueInTree(value, path, formKey) {
	return {
		type: FORM_CHANGE_VALUE,
		payload: {
			value: value,
			path: path,
			key: formKey
		}
	};
}

function changeValidationTree(internalName, invalid, formKey) {
	return {
		type: FORM_VALIDATION_TREE,
		payload: {
			internalName: internalName,
			invalid: invalid,
			key: formKey
		}
	};
}

function isFormValid(formKey) {
	return {
		type: FORM_IS_VALID,
		key: formKey
	};
}*/

function clearFormState() {
	return {
		type: FORM_CLEAR_STATE
	};
}

export function clearState() {
	return (dispatch) => {
		dispatch(clearFormState());
		dispatch(clearWizardState());
	}
}