import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import InvestmentGridItem from './custom/investment-grid-item';
import { Table } from '../table/Table';


const DefaultProps = {
	itemsPerPage: 5
};

function convertNumericDate(numericDate) {
	if(!numericDate) return null;
	return moment(numericDate.toString(), 'YYYYMMDD').format('DD/MMM/YYYY')
}

function formatCurrency(intlProps,value,currency) {
	if(!value) return '';
	return `${intlProps.formatNumber(parseInt(value))} ${currency}`;
}

class InvestmentsGridView extends React.Component {
	constructor(props) {
		super(props);

		this.onRowClick = this.onRowClick.bind(this);
	}

	onRowClick(row) {
		if (this.props.onRowClick && typeof this.props.onRowClick === 'function') {
			this.props.onRowClick(row.props.data);
		}
	}

	//render method
	render() {
		const {
			itemsPerPage,
			investments,
			clientId,
			intl,
			
		} = this.props;

		let gridData = investments.map((investment) => {
			return {
				_id: investment._id,
                importedId: investment.importedId,
				client: investment.client.name ,
               product: investment.product.name,
				fund: investment.fund.name,
				firstDisbursementDate: convertNumericDate(investment.firstDisbursementDate),
				finalMaturityDate: convertNumericDate(investment.finalMaturityDate),
				endedAt: convertNumericDate(investment.endedAt),
				currency: investment.currency,
				country: investment.country,
				outstandingAmountString: formatCurrency(intl, investment.outstandingAmount, investment.currency),
				outstandingAmount: investment.outstandingAmount,
				lastReportedMonth: investment.lastReportedMonth ? moment(investment.lastReportedMonth, 'YYYYMMDD').format('MMM/YYYY') : null,
				covenants: investment.covenants,
				reportingPeriodicity: investment.reportingPeriodicity
			};
		});

		const columnMeta = [
			{"columnName": 'importedId',sortable:true, "displayName": <span>TM1 id</span>, "customComponent": InvestmentGridItem},
			{"columnName": 'country', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.country"/>, "customComponent": InvestmentGridItem},
			{"columnName": 'fund', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.fund"/>, "customComponent": InvestmentGridItem},
			{"columnName": 'product', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.product"/>, "customComponent": InvestmentGridItem},
			{"columnName": 'currency', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.currency"/>, "customComponent": InvestmentGridItem},
			{"columnName": 'outstandingAmountString',sortable:true, "displayName": <FormattedMessage id="grid.investments.columns.outstandingAmount"/>, "customComponent": InvestmentGridItem},
			{"columnName": 'firstDisbursementDate',sortable:true, "displayName": <FormattedMessage id="grid.investments.columns.firstDisbursementDate"/>, "customComponent": InvestmentGridItem},
			{"columnName": 'lastReportedMonth', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.lastReportedMonth"/>, "customComponent": InvestmentGridItem},
			{"columnName": 'finalMaturityDate', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.finalMaturityDate"/>, "customComponent": InvestmentGridItem},
			{"columnName": 'endedAt', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.endedAt"/>, "customComponent": InvestmentGridItem},
			{"columnName": 'reportingPeriodicity', sortable:true,"displayName": <FormattedMessage id="grid.investments.columns.reportingPeriodicity"/>, "customComponent": InvestmentGridItem}
		];

		let clientColumn = {"columnName": 'client',sortable:true, "displayName": <FormattedMessage id="grid.investments.columns.client"/>, "customComponent": InvestmentGridItem};
		if(!clientId) columnMeta.splice(1, 0, clientColumn);

		return (
			<div>
				<Table data={gridData} columnMetadata={columnMeta} showFilter={true} showPager={true} enableSort={true}
									resultsPerPage={20} onRowClick={this.onRowClick} />
			</div>
		);
	}
}

export default injectIntl(InvestmentsGridView);
