import './menu.scss';
import React from 'react';

/**
 * DESCRIPTION: component used in the layouts/portal/index.jsx for in the header of the app
 * 		=> show menu with certain menu items in it => dynamic (languages, user settings, currencies selector)
 */

export default class Menu extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
    	return this.props.menuClassname !== nextProps.menuClassname || this.props.title !== nextProps.title
				 || this.props.id !== nextProps.id || this.props.restrictWidth !== nextProps.restrictWidth;
		}

    //user clicked on a menu item --> handle action in parent component & hide menu
    onItemSelect(item) {
        if(this.timer) {
            clearTimeout(this.timer);
            this.timer=null;
        }
        this.hideMenu();
        if(this.props.onItemSelect) this.props.onItemSelect(item);
    }

    //if user leaves the Menu component with their mouse, hide menu after .5 seconds
    onLeaveSubmenu() {
        if(this.timer) {
            clearTimeout(this.timer);
            this.timer=null;
        }
       this.timer = setTimeout(()=> {
           this.hideMenu();
        },500);
    }

		//if user enters the Menu component with their mouse, clear out timer
    onEnterSubmenu() {
        if(this.timer) {
            clearTimeout(this.timer);
            this.timer=null;
        }
    }

    //hide/show menu (without leaving or entering menu)
    toggleMenu() {
        if(this.subMenu.style.display==="none") {
            this.calculateSub();
        } else {
            this.subMenu.style.display="none";
            this.subMenu.style.opacity=0;
        }
    }

    //hide menu
    hideMenu() {
      	this.subMenu.style.display="none";
    }

    getCoords(node) {
        return node.getBoundingClientRect();
    }

    getCoordsMenuItem() {
        return this.getCoords(this.menuItem);
    }

    getCoordsSubMenu() {
        return this.getCoords(this.subMenu);
    }

    calculateSub() {
        const menuCoords = this.getCoordsMenuItem();
        const docCoords = document.body.getBoundingClientRect();

        let substyle  = {
            display: "block",
            opacity: 1,
            top:menuCoords.bottom// ?: + (this.props.marginTop ? this.props.marginTop : 0 ) // NAAAH we leave it up to the useage => ul css
        };

        Object.assign(this.subMenu.style,substyle);
        const submenuCoords = this.getCoordsSubMenu();

        if(this.props.restrictWidth ||  menuCoords.left + submenuCoords.width < (docCoords.width-10 )) {
            // align left
            if(this.props.restrictWidth) {
                // submenu is fixed to menu width
                substyle.width = menuCoords.width+"px";
                substyle.maxWidth = menuCoords.width+"px";
                substyle.minWidth = menuCoords.width+"px";

            }
            substyle.left = menuCoords.left+"px";
            substyle.right=null;

        } else {
            substyle.right = Math.round(docCoords.width-menuCoords.right)+"px";
            substyle.left = null;
        }

        Object.assign(this.subMenu.style,substyle);
    }

    renderSubMenu() {
        let wrapperCls = ["menu-sub-wrapper"];
        if(this.props.subMenuClass) {
            wrapperCls = [...wrapperCls,...this.props.subMenuClassname.split(' ')]
        }

        const includeIcons = this.props.items.filter((item)=>item.icon);

        const items = this.props.items.map((item,idx)=> {
            let liClass= item.isDefault || this.props.defaultValue == item.value ? "menu-item-default" : "";
            let label = this.props.labelSelector ? item[this.props.labelSelector] : item.label;
            return <li className={liClass} onClick={this.onItemSelect.bind(this,item)} key={this.id+"submenu-item-"+idx}>
                {includeIcons.length>0 ? <span className="menu-sub-item-icon">{item.icon}</span> : null}
                <span>{label}</span>
            </li>;
        });

        return (
					<div style={{display:"none"}} className={wrapperCls.join(' ')} ref={(subMenu) => { this.subMenu = subMenu; }}
							 onMouseLeave={this.onLeaveSubmenu.bind(this)} onMouseEnter={this.onEnterSubmenu.bind(this)}>
							<ul>{items}</ul>
					</div>
        );
    }

    render() {
        this.id = this.props.id ? this.props.id : window.random.id();
        let menuCls = ["menu-wrapper"];
        if(this.props.menuClassname) {
            menuCls = [...menuCls,...this.props.menuClassname.split(' ')]
        }

        const subMenu = this.renderSubMenu();
        return (
        	<div className={menuCls.join(' ')}>
            <div className="menu-item" ref={(menuItem) => { this.menuItem = menuItem; }} onClick={this.toggleMenu.bind(this)}
								 onMouseLeave={this.onLeaveSubmenu.bind(this)} onMouseEnter={this.onEnterSubmenu.bind(this)}>
                <div className="menu-item-text"><span>{this.props.title}</span></div>
                <div className="menu-item-icon"></div>
            </div>

            {subMenu}
					</div>
				);
    }
}