import Select from './index';
import regionsQuery from '../../../../graph/queries/regions'


const optionMapping = (data)=> {
    if(data && data.regions) {
        return data.regions.map((r)=> {
            return {
                value: r.region,
                label: r.region
            };
        })
    }
    return [];
}


export const RegionSelect = ({...opts})=> {
    
    return <Select {...opts} query={regionsQuery} optionMapping={optionMapping}/>
}

export default RegionSelect