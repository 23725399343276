import React, { useCallback, useRef } from "react";
import { covenantConfigQuery } from "../../../../../graph/queries/admin/covenants";
import { useMutation, useQuery } from "@apollo/client";

import AdminCovenantForm from '../../../../components/AdminForms/CovenantForm/CovenantForm';
import { deleteCovenantConfig, updateCovenantConfig } from "../../../../../graph/mutations/covenants";
import { ToastContainer, ToastMessageAnimated } from "react-toastr";

import { DashboardContainer, SmartLayout } from '../../../../components/dashboard';
import Panel from "../../../../components/dashboard/panel/panel";
import { FormattedMessage } from "react-intl";



export const CovenantConfigEdit = ({match,history})=> {

    const id = match.params.id;

    const toastRef= useRef();
  


    const { loading, error, data } = useQuery(covenantConfigQuery,{
       variables:{
        id
       },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    }); 

    const [_saveCovenant,{loading:saving}] = useMutation(updateCovenantConfig,{});
    const [_archiveCovenant,{loading:archiving}] = useMutation(deleteCovenantConfig,{
        variables:{
            covenantId:id
        }
    });

    const onCancel = useCallback(()=>{
        
        history.push(match.url.split("/").slice(0,-1).join("/"))
    },[history,match]);

    const onSave = useCallback((values,valid)=> {
        if(valid) {
            _saveCovenant({
                variables: {
                    covenantId: values._id,
                    covenant: values
                }
            }).then((result)=> {
                toastRef.current.success(`Covenant '${data.covenantConfig.name}' was saved succesfully.`);
                onCancel();
            }).catch((error)=> {
                toastRef.current.error(`Failed to save covenant: ${error.message}`);
                    
                console.error(error);
            });
        } else {
            toastRef.current.error('Invalid values, review form');
        }
    },[_saveCovenant,data,onCancel]);

    const onArchive = useCallback(()=> {
        _archiveCovenant({variables:{covenantId:id}}).then((result)=> {
            if(!window.confirm(`Are you sure you want to archive covenant ${data.covenantConfig.name}? Active usage in investments will not be disturbed, but the covenant will no longer be selectable as a new or waiver from this date onwards`)) {
				toastRef.current.warning(`Covenant '${data.covenantConfig.name}' was not archived.`);
				return;	
			}
        }).then((result)=> {
            toastRef.current.success(`Covenant '${data.covenantConfig.name}' was succesfully archived.`);

        }).catch((error)=> {
            toastRef.current.error(`Failed to archive covenant '${data.covenantConfig.name}'.`);
				
            console.error(error);
        });
    },[_archiveCovenant,data,id])


	let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

// language={language} 

    return <DashboardContainer>
        <SmartLayout>
        
            <Panel title={<FormattedMessage id="grid.covenants.title"/>}>
            <ToastContainer ref={toastRef} toastMessageFactory={ToastMessageFactory} className="toast-top-right" />
	
                {loading || archiving ? <span>loading</span> : 
                 <AdminCovenantForm saving={saving} values={data.covenantConfig}
                 clientType={match?.params?.type}
                 onSave={onSave}
                 onCancel={onCancel} onDelete={onArchive} />
 
                }
           
            </Panel>
        </SmartLayout>
    </DashboardContainer>
}