import React, { useCallback, useState,useRef, useEffect, Children } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from '@apollo/client/react/hoc';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import Panel from '../../components/dashboard/panel/panel.jsx';
import Loader from '../../components/loader/index.jsx';
import AdminClientsGridView from '../../components/grids/admin-clients';
import { DashboardContainer, SmartLayout } from '../../components/dashboard';
import ClientForm from '../../components/AdminForms/ClientForm';
import clientsQuery from '../../../graph/queries/incofin/clients';
import { createClient, updateClient, removeClient }from '../../../graph/mutations/clients';
import { useLazyQuery, useMutation } from '@apollo/client';
//import { Drawer } from '../../components/Drawer/index.jsx';
import classNames from 'classnames';
import Flag from 'react-world-flags'
import { Card } from '../../components/Card/index.js';
import { DescriptionItem } from '../../components/Description/DescriptionItem.jsx';
/**
 * DESCRIPTION: add/save (not delete) clients
 * ROUTE: /incofin/admin/clients
 * ACCESSIBLE BY: Incofin-admin & admin
 * DATA: clientsQuery (all the clients in the database)
 * MUTATIONS: createClient & updateClient
 */

class AdminClientFormPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			creating: false,
			editing: false,
			saving: false,
			client: {}
		};

		this.clientForm = null;

		this.onClientClick = this.onClientClick.bind(this);
		this.addClient = this.addClient.bind(this);
		this.saveClient = this.saveClient.bind(this);
		this.deleteClient = this.deleteClient.bind(this);
		this.cancel = this.cancel.bind(this);
	}

	//user clicked on a client --> go to edit mode and fill in form with correct values
	onClientClick(client) {
		console.log(arguments)
		console.log('CLIENT',client);
		this.setState({editing: true, client: client});
	}

	//user clicked on "add +" --> go to create mode and fill in form with an empty value tree
	addClient() {
		this.setState({creating: true, client: {
			key: null,
			name: null,
			status: 'active',
			clientType: null,
			officialName: null,
			worldRegion: null,
			yearOfOperationalStart: null,
			profitProfile: null,
			institutionalForm: null,
			address: {
				country: null,
				city: null,
				postalcode: null,
				street: null,
				nr: null,
				box: null,
				additional: null
			},
			settings: {
				financialPeriodEnd: {
					day: null,
					month: null
				},
				currency: null,
				reportingScale: null
			}
		}});
	}

	//save/add client to the database using the above declared mutations
	// --> when successful, refetch (re-execute) graphql query for consistent data
	saveClient() {
		this.setState({saving: true});

		if(this.clientForm.state.valid) {
			if(this.state.creating) {
				//add client
				this.props.createClient({
					variables: {
						client: this.clientForm.state.values,
					}
				}).then(({data}) => {
					if(data.createClient.success && this.refs.container) {
						this.refs.container.success('Client was successfully created!');
						this.setState({creating: false, editing: false, saving: false, client: {}});
						this.props.data.refetch();
					} else if(data.createClient.error && this.refs.container) this.refs.container.error(data.createClient.error.code, data.createClient.error.message);
				}).catch((error) => {
					if(this.refs.container) this.refs.container.error(error.message);
				});
			} else if(this.state.editing) {
				//edit existing client
				this.props.updateClient({
					variables: {
						clientId: this.clientForm.state.values._id,
						client: this.clientForm.state.values,
					}
				}).then(({data}) => {
					if(data.updateClient.success && this.refs.container) {
						this.refs.container.success('Client was successfully updated!');
						this.setState({creating: false, editing: false, saving: false, client: {}});
						this.props.data.refetch();
					} else if(data.updateClient.error && this.refs.container) this.refs.container.error(data.updateClient.error.code, data.updateClient.error.message);
				}).catch((error) => {
					if(this.refs.container) this.refs.container.error(error.message);
				});
			}
		} else {
			if(this.refs.container) this.refs.container.error('Invalid form');
		}
	}

	deleteClient() {
		let name = this.clientForm.state.values.name;

		let confirm = window.confirm(`Are you sure you want to delete ${name}?`);
		if (confirm) {
			console.log('remove',this.clientForm.state.values._id )
			this.props.removeClient({
				variables: {
					clientId: this.clientForm.state.values._id,
					clientName: name
				}
			}).then(({data}) => {
				if(data.removeClient.success && this.refs.container) {
					this.refs.container.success('Client was successfully deleted!');
					this.setState((state, props) => ({
						creating: false,
						editing: false,
						saving: false,
						client: {}
					}));
					this.props.data.refetch();
				} else if(data.removeClient.error && this.refs.container) {
					this.refs.container.error(data.removeClient.error.code, data.removeClient.error.message);
				}
			}).catch((error) => {
				if(this.refs.container) this.refs.container.error(error.message);
			});
		}
	}

	//user clicked on cancel --> go to view mode and show grid of all clients
	cancel() {
		this.setState({creating: false, editing: false, saving: false, client: {}});
	}

	//render method
	render() {
		
		const {
			creating,
			editing,
			client,
			saving
		} = this.state;

		
		let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

		
		return(
			<DashboardContainer>
				<SmartLayout>
					<Panel title={<FormattedMessage id="grid.clients.title" />}>
						<ToastContainer ref="container" toastMessageFactory={ToastMessageFactory} className="toast-top-right" />
						<AdminClientsGridView showFilter={true} showPager={true} itemsPerPage={20} onRowClick={this.onClientClick} /> 
						
									<ClientForm values={client} saving={saving} ref={(clientForm) => this.clientForm = clientForm} />
							
						{
							//!creating && !editing ?
								//:
								
								
								
						}

						<div style={{height: '3em', marginTop: '2em'}}>
							{
								!creating && !editing ?
									<button key={"add-client"} onClick={this.addClient}>
										<FormattedMessage id="app.buttons.add" />
									</button> :
									<div className="form-actions">
										<button key={"save-client"} onClick={this.saveClient}>
											<FormattedMessage id="app.buttons.save" />
										</button>
										<button key={"delete-client"} onClick={this.deleteClient} style={{margin: '0 1em'}}>
											<FormattedMessage id="app.buttons.delete" />
										</button>
										<button key={"cancel-client"} onClick={this.cancel} className="cancel" style={{margin: '0 1em'}}>
											<FormattedMessage id="app.buttons.cancel" />
										</button>
									</div>
							}
						</div>
					</Panel>
				</SmartLayout>
			</DashboardContainer>
		);
	}
}


// export default graphql(createClient, { name: 'createClient' })(
// 	graphql(updateClient, { name: 'updateClient' })(
// 	graphql(removeClient, { name: 'removeClient' })(AdminClientFormPage)));


const EmptyClient = {
	key: null,
	name: null,
	status: 'active',
	clientType: null,
	officialName: null,
	worldRegion: null,
	yearOfOperationalStart: null,
	profitProfile: null,
	institutionalForm: null,
	financialPeriods:[],
	address: {
		country: null,
		city: null,
		postalcode: null,
		street: null,
		nr: null,
		box: null,
		additional: null
	},
	settings: {
		financialPeriodEnd: {
			day: null,
			month: null
		},
		currency: null,
		reportingScale: null
	}
};


const ClientAdminPage = ()=> {

	const [fetchClients, {}] = useLazyQuery(clientsQuery)
	const [state,setState] = useState('GRID');
	const [client, setClient] = useState(null);
	const toastrRef = useRef();

	const onClientClick = useCallback((cl)=> {
		setClient(cl);
		setState('EDIT');
	},[client,state])

	const onAddClick = useCallback(()=> {
		setClient(EmptyClient)
		setState('NEW');

	},[state]);

	const onSave = useCallback((data,error,valid)=> {
		//debugger;
		if(!valid) {
			toastrRef.current.error('Invalid form, please review');
		} else {
			if(error) {
				
				toastrRef.current.error(error.message);
				
			} else if(data.success===false) {
				if(data.error) {
					toastrRef.current.error(data.error.message);
				} else {
					toastrRef.current.error('Failed to save');
				}
			} else if (data.success) {
				// if(state==="NEW") {
				// 	toastrRef.current.success(`Client was successfully created!`);
				// } else {
				// 	toastrRef.current.success(`Client ${client.name} was successfully updated!`);
				// }
				setClient(EmptyClient);
				fetchClients();
				setState('GRID');
			}
		}
		
	},[state,client]);

	const onRemove = useCallback((data,error)=> {
		
		if(error) {
			toastrRef.current.error(error.message);
		} else if(data.success===false) {
			 if(data.error) {
					toastrRef.current.error(data.error.message);
				} else {
					toastrRef.current.error(`Failed to remove ${client.name}`);
				}
		} else if (data.success) {
			toastrRef.current.success(`Client ${client.name} was successfully removed!`);
			setClient(null);
			fetchClients();
			setState('GRID');
		}
		
		
		
	},[state,client]);

	const onCancel = useCallback(()=> {
		setState("GRID");
		setClient(null);
	},[state,client])

	let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

	var title = state == "GRID" ? <FormattedMessage id="grid.clients.title" /> : client ? client.name : <FormattedMessage id="app.buttons.add" />;

	var actions = state == "GRID" ? [<button key={"add-new-client"} onClick={onAddClick}>
	<FormattedMessage id="app.buttons.add" /> 
</button>]: [<button className='cancel' key={"cancel-client"} onClick={onCancel}>
	<FormattedMessage id="app.buttons.cancel" /> 
</button>];
		return(
			<DashboardContainer>
				
				<SmartLayout>
					<Panel actions={actions} title={title}>
						<ToastContainer ref={toastrRef} toastMessageFactory={ToastMessageFactory} className="toast-top-right" />
								
						{ 
							state==="GRID" ? 
								<>
								<AdminClientsGridView showFilter={true} showPager={true} itemsPerPage={20} onRowClick={onClientClick} />
								<div style={{height: '3em', marginTop: '2em'}}>
									<button onClick={onAddClick}>
										<FormattedMessage id="app.buttons.add" />
									</button>
								</div>
								</> :
								<ClientAdminForm client={client} onRemove={onRemove}
									state={state} onSave={onSave} onCancel={onCancel}
														 />
						}
						
					</Panel>
				</SmartLayout>
			</DashboardContainer>
		);

}

const ClientAdminForm = ({onSave, onRemove, onCancel, client, state})=> {

	const [saveClient, { loading }] = useMutation(state==="NEW" ? createClient : updateClient);
	const [deleteClient, { loading:removing }] = useMutation(removeClient);
	const [showButtons,setShowButtons] = useState(true);

	const formRef = useRef();

	const onSaveClick = useCallback(()=> {
		if(formRef.current) {
			var frm = formRef.current;
			if(!frm.state.valid) {
				onSave(client,null,frm.state.valid)
			} else {
				let vars = {
					client:frm.state.values
				};
				if(state!="NEW") {
					vars.clientId=frm.state.values._id;
				}
				saveClient({variables:vars}).then(({data,loading,error})=> {
					if(!loading) {
						onSave(state==="NEW" ? data.createClient : data.updateClient ,error,frm.state.valid)
					}
				}).catch((e)=> {
					onSave(null,e,frm.state.valid)
				});
			}
			
		}
	},[onSave,client,state,saveClient]);

	const onRemoveClick = useCallback(()=> {
		const  {_id, name}  = client;
		let confirm = window.confirm(`Are you sure you want to delete ${name}?`);
		if (confirm) {
			console.log('remove',_id )
			deleteClient({
				variables: {
					clientId: _id,
					clientName: name
				}
			}).then(({data,loading,error}) => {
				onRemove(data.removeClient,error);
				
			}).catch((error) => {
				onRemove(null,error)
			});
		}

	},[onRemove,client,deleteClient])

	


	
	return <>
		<ClientForm values={client} saving={loading}
		ref={formRef}
		showButtons={setShowButtons}
		 />

{ showButtons ? 
	<div style={{height: '3em', marginTop: '2em'}}>
		{
			
				<div className="form-actions">
					<button onClick={onSaveClick} disabled={loading  || removing}>
						<FormattedMessage id="app.buttons.save" />
					</button>
					{
						state!="NEW" ? <button onClick={onRemoveClick} disabled={loading || removing} style={{margin: '0 1em'}}>
						<FormattedMessage id="app.buttons.delete" />
					</button> : null
					}
					<button onClick={onCancel} className="cancel" style={{margin: '0 1em'}}>
						<FormattedMessage id="app.buttons.cancel" />
					</button>
				</div>
		}
	</div> : null}
	</>
}


export default ClientAdminPage
const _PAGESTATE = {
	GRID:0,
	CLIENT:1,
	NEWCLIENT:"NEWCLIENT"
}

function FixedPageContainer({children,activeIndex=0}) {

	return <div className='fixed-page-container'>
		{Children.map(children, (child,index) => {
		

			if(index<activeIndex) {
				return <div className="fixed-page-item"
					style={{
						transform: `translateX(-${100*(index+1)}%)`
					}}>
				{child}
			  </div>
			} else if (index==activeIndex) {
				return <div className="fixed-page-item"
					style={{
						transform: `translateX(0%)`
					}}>
				
				{child}
			  </div>
			} else {
				return <div className="fixed-page-item"
						style={{
							transform: `translateX(${100}%)`
						}}
				>
					
			  </div>
			}

			
		}
        
      )}
	</div>
}
/*

const ClientAdminPage = ()=> {
	const [pagestate,setPageState] = useState(_PAGESTATE.GRID);
	const [client,setClient] = useState(null);

	const onClientClick = useCallback((client)=> {
		setClient(client)
		setPageState(_PAGESTATE.CLIENT);

	},{});

	const onAddClick = useCallback(()=> {
		setClient(null)
		setPageState(_PAGESTATE.CLIENT);
	},{onClientClick});


	return <FixedPageContainer activeIndex={pagestate}>
		<Panel actions={[<button key={"add-new-client"} onClick={onAddClick}>
				<FormattedMessage id="app.buttons.add" /> 
				</button>]} title={<FormattedMessage id="grid.clients.title" />}>
			<AdminClientsGridView showFilter={true} showPager={true} itemsPerPage={20} onRowClick={onClientClick} />
		</Panel>
		<>
		{ client ? <Card title={client.officialName}>
			<ClientInfo client={client}/>
			</Card>: <Card >
			</Card>
		}
			
			
			<button onClick={()=>setPageState(_PAGESTATE.GRID)}>BACK</button>
	</>
		
	
		
	</FixedPageContainer>
}


const ClientAddress = ({
	country,countryName,
	city,
	postalcode,
	street, nr, box, additional

})=> {
	return <div>
		<div>{street} {nr} {box}</div>
		{additional ? <div>{additional}</div> : null}
		<div>{postalcode} {city}</div>
		<div><Flag code={country} height={16}/> {countryName}</div>
		</div>
}

const ClientInfo = ({client,onChange})=> {
	if(!client) return <></>
	return <div>
			<DescriptionItem formattedLabelId="admin.form.client.officialName">
				{client.officialName}
			</DescriptionItem>
			<DescriptionItem formattedLabelId="admin.form.client.name">
				{client.name}
			</DescriptionItem>
			<DescriptionItem formattedLabelId="admin.form.client.clientType">
				{client.clientType}
			</DescriptionItem>
			
			<DescriptionItem formattedLabelId="admin.form.client.institutionalForm">
				{client.institutionalForm}
			</DescriptionItem>
			
			<DescriptionItem formattedLabelId="admin.form.client.profitProfile">
				{client.profitProfile}
			</DescriptionItem>
			
			<DescriptionItem formattedLabelId="admin.form.client.region">
				{client.region}
			</DescriptionItem>
			
			<DescriptionItem formattedLabelId="admin.form.client.address">
			<ClientAddress {...client.address}></ClientAddress>
			</DescriptionItem>
			
			
	
	</div>
}

const ClientMeta = ({client}) => {

}

const ClientFinancialSettings = ({client})=> {

	return <div>

	</div>

}


export default ClientAdminPage;*/