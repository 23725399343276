import {
	REPORTS_LOADED, REPORTS_LOAD_ERROR, REPORTS_LOADING,
	REPORTS_SET_FILTER, REPORTS_SET_PAGE, REPORTS_RESET_PAGE
} from '../actions/reports/actions';

const initialState = {
	loading: false,
	error: null,
	data: [],
	totalReports: 0,
	curPage: 1,
	filter: null
};

export const reportReducer = (state = initialState, action) => {
	switch (action.type) {
		case REPORTS_LOAD_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		case REPORTS_LOADING:
			return {
				...state,
				loading: true,
				error: null
			};
		case REPORTS_LOADED:
			return {
				...state,
				loading: false,
				error: null,
				data: action.keepPreviousResult ? [...state.data, ...action.reports] : action.reports,
				totalReports: action.totalReports
			};
		case REPORTS_SET_PAGE:
			return {
				...state,
				curPage: action.payload.curPage
			};
		case REPORTS_RESET_PAGE:
			return {
				...state,
				curPage: 1,
				data: [],
				totalReports: 0
			};
		case REPORTS_SET_FILTER:
			return {
				...state,
				curPage: 1,
				filter: action.payload.filter
			};
	}
	return state;
};