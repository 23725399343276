import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import GridView from '../dashboard/gridview/gridview.jsx';
import { Table } from '../table/Table'
import { covenantsConfigQuery } from '../../../graph/queries/admin/covenants.js';
import Panel from '../dashboard/panel/panel.jsx';
import { GraphTableServer } from '../table/GraphTable.js';
import { useMutation } from '@apollo/client';
import { deleteCovenantConfig } from '../../../graph/mutations/covenants.js';
import ActionsComponent from '../dashboard/gridview/components/actions.jsx';
import { ToastContainer, ToastMessageAnimated } from 'react-toastr';
import moment from 'moment';


const gridDataMapper = (intl,language)=>(covenants)=> {

 	return covenants && covenants.length > 0 && Array.isArray(covenants) ?
			covenants.map((covenant) => {
				return {
					_id: covenant._id,
					name: covenant.name[language] ? covenant.name[language] : covenant.name['en'],
					fieldType: covenant.fieldType,
					rangeMin: intl.formatNumber(covenant.range.min / 100, {maximumFractionDigits: 2}) + (covenant.fieldType=="percentual" ? "%" : ""),
					rangeMax: intl.formatNumber(covenant.range.max / 100, {maximumFractionDigits: 2})+ (covenant.fieldType=="percentual" ? "%" : ""),
					alertThreshold: intl.formatNumber(covenant.alertThreshold / 100, {maximumFractionDigits: 2}) + (covenant.fieldType=="percentual" ? "%" : ""),
					activeCount:covenant.statistics && covenant.statistics.count ? covenant.statistics.count : '-',
					clientType:covenant.clientType ? covenant.clientType : '-',
					archived: covenant.archived ? moment(covenant.archived).format("DD/MM/YYYY") : "-",
					__actions:["archive"]
				};
			}) :[];

		
	};

let columnMeta = [
	{columnName: 'name',sortDataIndex:"translated", sortable:true, displayName: <FormattedMessage id="grid.covenants.columns.name"/>},
	{columnName: 'fieldType',sortable:true, displayName: <FormattedMessage id="grid.covenants.columns.fieldType"/>},
	{columnName: 'rangeMin',sortDataIndex:"range.min", sortable:true,displayName: <FormattedMessage id="grid.covenants.columns.rangeMin"/>},
	{columnName: 'rangeMax',sortDataIndex:"range.max", sortable:true,displayName: <FormattedMessage id="grid.covenants.columns.rangeMax"/>},
	{columnName: 'alertThreshold',sortable:true, displayName: <FormattedMessage id="grid.covenants.columns.alertThreshold"/>},
	{columnName: 'activeCount', sortable:true,sortDataIndex:"statistics.count", displayName: <FormattedMessage id="grid.covenants.columns.activeCount"/>},
	{columnName: 'clientType', displayName: <FormattedMessage id="grid.covenants.columns.clientType"/>}
];

let archivedCol = {columnName: 'archived', sortable:true,sortDataIndex:"archived", displayName: <FormattedMessage id="grid.covenants.columns.archived"/>};


const CovenantsConfigGrid = injectIntl(({
	intl,
	language,
	onRowClick,
	archived=null,
	type="mfi",
	showPager,
	enableSort,
	showFilter,
	itemsPerPage=25,
	reports,
	onFilterChange,
	fullMode,
	showCustomFilter,
	reportId=null
})=> {

	const toastRef= useRef();

	const [sorters,setSorters] = useState([]);

	const [columns,setColumns] = useState(columnMeta);

	const [archiveCovenant,{loading}] = useMutation(deleteCovenantConfig,{});

	const onAction = useCallback(async (action,rowData)=> {
		if(action=="archive") {
			
			if(!window.confirm(`Are you sure you want to archive covenant ${rowData.name}? Active usage in investments will not be disturbed, but the covenant will no longer be selectable as a new or waiver from this date onwards`)) {
				toastRef.current.warning(`Covenant '${rowData.name}' was not archived.`);
				return;	
			}
			archiveCovenant({ variables: {
				covenantId:rowData._id,
				asFrom:new Date()
			}}).then((result)=> {
				toastRef.current.success(`Covenant '${rowData.name}' was succesfully archived.`);

			}).catch((err)=> {
				toastRef.current.error(`Failed to archive covenant '${rowData.name}': ${err.message}`);
				
				console.error(err);
			})
		}
	},[archiveCovenant]);

	

	const onRowClickCb = useCallback((row)=>{
		onRowClick(row.props.data);
	},[onRowClick]);

	const onSortChange = useCallback((value)=> {

        console.log("SORT",value);
        if(value) {
            setSorters([{
                field:value.dataIndex,
                sort:value.direction
            }])
        } else {
            setSorters(null)
        }
    },[setSorters]);

	useEffect(()=> {
		let archiveActionCol ={
			columnName: '__actions',
			displayName: <FormattedMessage id="grid.column.actions"/>,
			customComponent: ActionsComponent,
			customComponentProps: {
				onAction: onAction
			}
		}
		if(archived) {
			setColumns([...columnMeta,archivedCol])
		} else {
			setColumns([...columnMeta,archiveActionCol]);
		}
	},[archived]);

	
	let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

	

	return <>
	<ToastContainer ref={toastRef} toastMessageFactory={ToastMessageFactory} className="toast-top-right" />
			
			 <GraphTableServer qry={covenantsConfigQuery} 
        options={{
            fetchPolicy: "no-cache",
        }}
		vars={{
			sorting:sorters,
			archived:archived,
			clientType:type

		}}
		onSortChange={onSortChange}
        tableDataMapper={gridDataMapper(intl,language)}
        dataSelector={(d)=> { return d && d.covenantsConfig && d.covenantsConfig.results ? d.covenantsConfig.results : []}}
        totalSelector={(d)=>d && d.covenantsConfig && d.covenantsConfig.pageInfo ? d.covenantsConfig.pageInfo.total :0}
         columnMetadata={columns} showFilter={true} showPager={true} 
		 resultsPerPage={itemsPerPage}
								onRowClick={onRowClickCb} 
		 enableSort={true}
        />
		

	</>;

});

export default CovenantsConfigGrid;

class AdminCovenantsGridView extends React.Component {
	constructor(props) {
		super(props);

		this.onRowClick = this.onRowClick.bind(this);
	}

	onRowClick(row,index) {
		console.log('COV ROW CLICK',row,index,this.props.covenants[index])
		if (this.props.onRowClick && typeof this.props.onRowClick === 'function') {
			this.props.onRowClick(this.props.covenants[index]);
		}
	}

	//render method
	render() {
		const {
			covenants,
			itemsPerPage,
			language,
			intl
		} = this.props;

		let gridData = covenants && covenants.length > 0 && Array.isArray(covenants) ?
			covenants.map((covenant) => {
				return {
					_id: covenant._id,
					name: covenant.name[language] ? covenant.name[language] : covenant.name['en'],
					fieldType: covenant.fieldType,
					rangeMin: intl.formatNumber(covenant.range.min / 100, {maximumFractionDigits: 2}) + (covenant.fieldType=="percentual" ? "%" : ""),
					rangeMax: intl.formatNumber(covenant.range.max / 100, {maximumFractionDigits: 2})+ (covenant.fieldType=="percentual" ? "%" : ""),
					alertThreshold: intl.formatNumber(covenant.alertThreshold / 100, {maximumFractionDigits: 2}) + (covenant.fieldType=="percentual" ? "%" : ""),
					clientType: covenant.clientType ? covenant.clientType : "-",
				};
			}) :[];

		const columnMeta = [
			{columnName: 'name', displayName: <FormattedMessage id="grid.covenants.columns.name"/>},
			{columnName: 'fieldType', displayName: <FormattedMessage id="grid.covenants.columns.fieldType"/>},
			{columnName: 'rangeMin', displayName: <FormattedMessage id="grid.covenants.columns.rangeMin"/>},
			{columnName: 'rangeMax', displayName: <FormattedMessage id="grid.covenants.columns.rangeMax"/>},
			{columnName: 'alertThreshold', displayName: <FormattedMessage id="grid.covenants.columns.alertThreshold"/>},
			{columnName: 'clientType', displayName: <FormattedMessage id="grid.covenants.columns.clientType"/>},
		];

		return (
			<div>
				<Table data={gridData} columnMetadata={columnMeta} resultsPerPage={itemsPerPage}
									showPager={false} enableSort={false} showFilter={false} onRowClick={this.onRowClick} />
			</div>
		);
	}
}

//export default injectIntl(AdminCovenantsGridView);
