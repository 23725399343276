
import { Route,Switch} from 'react-router-dom';
import { FormPage } from '.';
import FinancialPeriods from '../clients/financialPeriods';
import { FormulaPanel } from '../../../features/NewFormula/components/FormulaPanel';


/*
<Button onClick={()=>{
    setPanelState(s=>({...s,direction:"vertical"}))
}}>Change dir</Button>
*/


export const FormWrapperPage = ({match})=> {

    
    const matchPath = match.path.endsWith('/') ? match.path.substring(0,match.path.length-1) : match.path;

   

    return <Switch>
            <Route path={`${matchPath}/organisation/financial_periods`} exact component={FinancialPeriods}/>
            <Route path={`${matchPath}/financial/financial_periods`} exact component={FinancialPeriods}/>
            <Route>
                <FormulaPanel pinnable={true} panelGroupId="form-data-panels">
                    <div >
                        <Switch>
                            <Route path={`${matchPath}/organisation/:key`} exact component={FormPage}/>
                            <Route path={`${matchPath}/social/:key`} exact component={FormPage}/>
                           
                            <Route path={`${matchPath}/financial/:key`} exact component={FormPage}/>
                            <Route path={`${matchPath}/statistics/:key`} exact component={FormPage}/>
                            <Route path={`${matchPath}/financial/:key`} exact component={FormPage}/>
                        </Switch> 
                    </div>
                </FormulaPanel>

            </Route>
    </Switch>
    
}


/*
 <Button onClick={()=>{
                setPanelState(s=>({...s,direction:"horizontal"}))
            }}>Change dir</Button>
*/