import { isEmptyObject } from '../utils';

//Roles: [ { name, permissions: [ { context, action, value } ] } ]
const GRANT = 1;
const DENY = 0;
const EXPLICIT_DENY = -1;

function flattenRoles(roles) {
    if (roles === undefined) {
        return [];
    }
    // console.log(`Roles: ${JSON.stringify(roles)}`);

    let flatPermissions = Array.from(roles)
        .reduce((aggregate, item) => {
            if (item.permissions) {
                aggregate.push(...Array.from(item.permissions));
            }
            return aggregate;
        }, []);
    // console.log(`Permissions: ${JSON.stringify(flatPermissions)}`);

    return flatPermissions;
}

// true  = grant
// false = explicit deny
// undefined = deny or unknown
function mapValue(value) {
    if (value === EXPLICIT_DENY) {
        return false;
    }

    if (value === GRANT) {
        return true;
    }

    return undefined;
}

export default function reduceRoles(roles) {

    if (roles === undefined) {
        return {};
    }

    let flatPermissions = flattenRoles(roles);

    let reducedPermissionsSet = flatPermissions.reduce((aggregate, item) => {
        let { context, action, value } = item;


        let permission = mapValue(value);
        //console.log(`MappedPermission: ${permission}`);

        if (isEmptyObject(aggregate[context])) {
            // add new context to set ('grant' or 'explicit deny')
            if (permission !== undefined) {

                aggregate[context] = {
                    [action]: permission
                };
            }
        }
        else {
            if (permission === false || (aggregate[context][action] === undefined && permission !== undefined)) {
                // set permission for context.action (respecting 'explicit deny')

                aggregate[context][action] = permission;
            }
        }

        return aggregate;
    }, {});



    return reducedPermissionsSet;
}
