import {useLocation} from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Button, Card } from 'antd';
import {
    PushpinOutlined,
    PushpinFilled,CloseOutlined
  } from '@ant-design/icons';
import { useFormulaViewer } from '../hooks/useFormulaViewer';
import { FormulaViewer } from './Viewer';

import './FormulaCard.scss'
import IconMathFunction from '../../Form/FunctionIcon';


export const FormulaCard = ({pinnable=false})=> {
    const viewer = useFormulaViewer();
    const location = useLocation();
    const [isFormulaPinned,setFormulaPinned] = useState(true);
    const previousLocation = useRef(location.pathname);


    useEffect(()=>{
        try {
            if(previousLocation.current!=location.pathname) {
                previousLocation.current=location.pathname;
                if(!isFormulaPinned) {
                    viewer.clear();
                }
            }
        } catch(e) {
            
        }

    },[isFormulaPinned,location.pathname])
    
    if(!viewer || !viewer.settings) {
        return <></>
    }

    

return <Card className='flex formula-card' title={
    <><IconMathFunction/> {viewer?.settings?.title}</>
}
extra={<Button.Group>
   { pinnable ? <Button autoFocus={false} onClick={()=>setFormulaPinned(s=>!s)} icon={isFormulaPinned ? <PushpinFilled rotate={-45}/> : <PushpinOutlined/>}/>
   : null }
   <Button onClick={()=>viewer.clear()}><CloseOutlined/></Button>
</Button.Group>}
>
    <FormulaViewer
        formula={viewer?.settings?.formula}
        scope={viewer?.settings?.scope}
    ></FormulaViewer>

</Card>

}
