
import {apolloClient, store} from '../../store';
import essentialsQuery from '../../../graph/queries/essentials';
import Meteor from '../../../Meteor';

export const ESSENTIALS_LOADING = 'ESSENTIALS/LOADING';
export const ESSENTIALS_LOADED = 'ESSENTIALS/LOADED';
export const DATA_FAILURE = 'ESSENTIALS/FAILED';




export function checkEssentials() {
    return function (dispatch,getState) {

        const state = getState();
        if(state.data.loaded==-1) {

            dispatch({
                type:ESSENTIALS_LOADING
            });


            apolloClient.query({
                query: essentialsQuery
            }).then((result) => {


                dispatch({
                    type:ESSENTIALS_LOADED,
                    payload:{
                        reportConfigs:result.data.reportConfigs,
                        categorisedForms:result.data.categorisedForms
                    }
                });


            }).catch((error)=> {
                // handle error;
                dispatch({
                    type:DATA_FAILURE,
                    payload:error.message
                });
                console.log("ERROR PROFILE",error);
            });
        }

    };
}