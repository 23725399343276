
let userId = null;

export default {
    setUserId:(uid)=>{  userId=uid },
    userId: ()=>userId,
    settings:{},
    user:()=>({}),
    logout:(fn)=>{
        localStorage.removeItem("token");
        if(fn) {
            fn();
        }
    },
    loggedIn:()=>!!localStorage.getItem("token"),
    loggingIn:()=>false,
    autorun:(fn)=>{},
    loginWithPassword:(user,pass,cb)=> {
        //alert("no more login")
        //console.log('LOGIN',user,pass)
        cb(null)

    }
}