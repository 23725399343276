
import RCTable from 'rc-table';
import classNames from 'classnames';
import React, { createContext, useEffect, useMemo, useCallback,useState, useContext } from 'react';
import Loader from '../loader/spinner';
import Filter from '../grids/custom/filter';
import moment from 'moment';
import Pagination from 'rc-pagination'
import "rc-pagination/assets/index.css";
import "./table.scss"


const SortingContext = createContext();

const SortableHeader = ({onSortChange,title,dataIndex,defaultSort=null,sortDataIndex=null})=> {
   
    const sorter = useContext(SortingContext);

    const _dataIndex = sortDataIndex || dataIndex;

    const sortState = sorter && sorter.dataIndex===_dataIndex ? sorter.direction : null;

    const onToggleSort = useCallback(()=> {
      
       let newState = !sortState ? 'DESC' : sortState==='DESC' ? 'ASC' : null;
      // alert("SORT " + dataIndex + newState);
      // setSortState(newState);
      //debugger;
       if(onSortChange) {
           onSortChange(_dataIndex,newState);
       }
    },[_dataIndex,onSortChange,sortState])


    return <div className={classNames("column-sortable",
    {
        "column-sortable-active":sortState!=null,
        "column-sortable-asc":sortState==="ASC",
        "column-sortable-desc":sortState==="DESC"
    })} onClick={onToggleSort}>
        <div className="column-title">{title}</div>
        <div className="column-sort-icon"></div>
    </div>
}

function mapCols(cols,onSortChange) {
    if(!cols || cols.length===0) return [];
    return cols.map((x,idx) => {
		let c= {
			dataIndex:x["columnName"],
            sortDataIndex:x.sortDataIndex,
			key:x.columnName+idx,
			title:x.displayName
		};
        if(x.sortable) {
            c.title = React.createElement(SortableHeader,{ onSortChange:onSortChange,dataIndex:c.dataIndex,title:x.displayName,defaultSort:x.defaultSort,sortDataIndex:c.sortDataIndex});
        }
       
		if(x.customComponent) {
			c.render = (value, row, index)=>React.createElement(x.customComponent,{metadata:x, rowData:row, data:value})
		}
		return c;
	}) ;
}



const substringSearch = (value, filter) => {
    if (!filter) {
      return true;
    }
  
    const filterToLower = filter.toLowerCase();
    return value && value.toString().toLowerCase().indexOf(filterToLower) > -1;
  };

const execFilter = (data,filter)=> {
    console.log("EXEC FILTER",filter,data);
    return data.filter((r)=> {
        return Object.keys(r).some((k)=>substringSearch(r[k],filter));
    })
}

const execSort = (data,sorter)=> {
    if(!sorter) return data;
    console.log("SORT",sorter)
    return data.sort((objA,objB)=> {
              
        var direction = sorter.direction;
        var a = objA[sorter.dataIndex];
        var b = objB[sorter.dataIndex];
      
        if(moment.isMoment(a)) {
            return direction==="ASC" ? a.valueOf() - b.valueOf() : b.valueOf() - a.valueOf() 
        } else if(typeof(a)=== 'number') {
            return direction==="ASC" ? a - b : b - a;
      
        } else {
            var a = a===null || a===undefined ? '' : a.toString();
            var b = b===null || b===undefined ? '' : b.toString();
            return direction==="ASC" ?  a.localeCompare(b) :  b.localeCompare(a)
        }

       
    });
}

// TODO: debounce local filter

const useTable = (columnsMeta,initialData,
    filtering={enabled:false,scope:'local'},
    sorting={enabled:false,scope:'local'},
    paging={enabled:false,scope:'local',limit:10}
) => {

    let defaultSorter = columnsMeta.find(c=>c.defaultSort);
    defaultSorter = defaultSorter ? {dataIndex:defaultSorter.columnName,direction:defaultSorter.defaultSort} : null;

    const [data,setData] = useState(initialData);
    const [filter,setFilter] = useState(null);
    const [sorter,setSorter] = useState(defaultSorter);

    const sortBy = useCallback((dataIndex,direction)=> {
       
        if(dataIndex && direction) {
            setSorter({dataIndex,direction});
        } else {
            setSorter(null);
        }
        
    },[]);

    const columns = useMemo(()=>mapCols(columnsMeta,sortBy),[columnsMeta,setSorter]);
   

    const [page,setPage] = useState(1);

    const rows = useMemo(() => {
        console.log("MEMO ROWS",filter,data,sorter,sorting,filtering)
        let _data = [...data];
        if(filtering.enabled) {
            if(filter && filtering.scope==="local") {
                _data=execFilter(_data,filter);
            } 
        }
        if(sorting.enabled) {
            if(sorter && sorting.scope==="local") {
                console.log("sort",sorter,defaultSorter)
                _data=execSort(_data,sorter);
            } 
        }
        return _data;
       
    },[data,filter,sorter,sorting,filtering]);

    const pagedRows = useMemo(()=>{
        if(paging.enabled) {
            if(paging.scope==="local") {
                return rows.slice((page * paging.limit)-paging.limit,paging.limit*page);
            } 
        }
        return rows;
    },[rows,page,paging])

   
   
   

    const pageState = useMemo(()=> {
        return paging.enabled && paging.scope==="local" ? {
            page:page,
            pageSize:paging.limit,
            total:rows.length,
            local:true
        } : null;
    },[paging,pagedRows,page])

    return [columns,pagedRows,pageState,sorter
        , {updateData:setData,sortBy,filterBy:setFilter,setPage}]
}

export const Table = ({data,loading,columnMetadata,resultsPerPage,onFilter,onRowClick,onPageChange,showFilter=false,showPager=false,pagingInfo=null,enableSort=false,onSortChange,compact=false,...tableProps})=> {

    const [columns,localData,pageState,sorter,{ updateData,sortBy,filterBy,setPage } ] = useTable(columnMetadata,data,{
        enabled:showFilter && !onFilter,
        scope:!onFilter ? 'local' : 'remote'
    }, {
        enabled:enableSort && !onSortChange,
        scope:!onSortChange ? 'local' : 'remote'
    },{
        enabled:showPager && !onPageChange,
        scope:!onPageChange ? 'local' : 'remote',
        limit:resultsPerPage
    })

    useEffect(()=> {
        console.log("update data")
        updateData(data ? data : [])
    },[data])

 
    const myClassNames = useMemo(()=>{
        return classNames(
            'rc-table-inco',
            {
                'rc-table-inco--clickable': typeof onRowClick === 'function',
                'rc-table-inco--sortable': enableSort
            });
    },[onRowClick,enableSort]);




    const pagingState = useMemo(()=> {
        return pagingInfo ? pagingInfo : pageState;
    },[pagingInfo,pageState])


	var rowClick = useCallback((record,index)=> {
		return {
			onClick:()=>onRowClick ? onRowClick({props:{data:record}},index) : ()=>{}
		}
	},[onRowClick]);

    var onFilterChange = useCallback((val)=> {
        filterBy(val);
        if(onFilter) onFilter(val);

    },[onFilter,filterBy])

    useEffect(()=> {
        if(onSortChange) {
            onSortChange(sorter);
        }
    },[sorter,onSortChange])

   

    var onSetPage = useCallback((pg)=> {
        
        if(showPager && !onPageChange && pagingState && pagingState.local) {
            setPage(pg);
        } else if(showPager && onPageChange) {
            onPageChange(pg);
        }

    },[onPageChange,setPage])


   const renderEmpty = loading ? <Loader/> : undefined;

   const cls = classNames('inco-table',{
       'inco-table-compact': compact
   })


    return <div className={cls}>
        {
					!showFilter ?
						null :
						<Filter onFilterChange={onFilterChange}/>
				}
                <SortingContext.Provider value={sorter}>
    <RCTable {...(tableProps||{})}  
    emptyText={renderEmpty} rowKey="_id" className={myClassNames} columns={columns} data={localData} onRow={rowClick} >
  
        </RCTable></SortingContext.Provider>
        {showPager && pagingState && pagingState.total > pagingState.pageSize ? <Pagination
                        pageSize={pagingState.pageSize}
                        onChange={onSetPage}
                        current={pagingState.page}
                        total={pagingState.total}
                    /> : null
        }
    </div>
    
}
// internalHooks="rc-table-internal-hook" transformColumns={transformColumns} 