import deepClone from 'clone-deep';
import _ from 'underscore';
import moment from 'moment';

export function dateToNumeric(date) {
    let dte = date;
    if(date===null) {
        return null;
    } else if(typeof date === "float" || typeof date==="number") {
        return date;

    } else if(date instanceof Date) {
        dte = moment(date);
    } else if(moment.isMoment(date)) {
        // all ok
    } else {
        return null;
    }

    return parseInt(dte.format("YYYYMMDD"));
}

export function isEmptyObject(obj) {
    if (!obj) return true;
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function clone(source, deep = true) {
    if (deep) {
        return deepClone(source);
    }
    else {
        return Object.assign({}, source);
    }
}

export function flatten(arrayOfArrays) {
    return [].concat.apply([], arrayOfArrays || []);
}

function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function deepMerge(target, source) {
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, { [key]: source[key] });
                else
                    output[key] = deepMerge(target[key], source[key]);
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}

export function stringInterpolate(text,params) {
    const names = _.keys(params);
    const vals = _.values(params);
    return new Function(...names, `return \`${text}\`;`)(...vals);
}


export function extractPeriod(date,finYearEndMonth=12) {
    let dte = date;
    if(date===null) {
        return null;
    } else if(typeof date === "float" || typeof date==="number") {
        dte = moment(""+date,"YYYYMMDD");

    } else if(date instanceof Date) {
        dte = moment(date);
    } else if(moment.isMoment(date)) {
        // all ok
    } else {
        return null;
    }
    dte.startOf('date');

    const finYear = dte.clone().add(1,'days').year();
    // construct financial year moment:
    let endFy = moment([finYear,finYearEndMonth-1,1]).endOf("month");
    let startFy = endFy.clone().subtract(1,"years").add(1,"days");

    const financialPeriod = dte.diff(startFy,'months')+1;
    const monthLengthFy = endFy.diff(startFy,'months')+1;
    const quarterLength = monthLengthFy/4;
    const financialQuarter = Math.ceil(financialPeriod/quarterLength);

    return {
        day:dte.date(),
        month:dte.month()+1,
        year:dte.year(),
        quarter:dte.quarter(),
        financialYear:finYear,
        financialPeriod,
        financialQuarter
    };
}