import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';

import InvestmentForm from '../../components/InvestmentForm/index';
import Loader from '../../components/loader/index.jsx';
import InvestmentsGridView from '../../components/grids/investments';
import { DashboardContainer, SmartLayout } from '../../components/dashboard';
import Panel from '../../components/dashboard/panel/panel';
import _, { result } from 'underscore';
import {
	loadInvestments, addCovenant, removeCovenant, saveCovenant,
	addWaiver, removeWaiver, saveWaiver, changeInvestmentPeriodicity
} from '../../../redux/actions/investments';
import { useLazyQuery, useQuery,useMutation } from '@apollo/client';
import { investmentQuery } from '../../../graph/queries/incofin/investments';
import { createCovenant, deleteCovenant, updateCovenant, updateInvestmentPeriodicity } from '../../../graph/mutations/investment.covenants';
import { createWaiver, deleteWaiver as deleteWaiverQql, updateWaiver } from '../../../graph/mutations/waiver';
import { Link } from 'react-router-dom'

export const InvestmentDetail = ({match,history})=> {

    const investmentId = match.params.investId;
    
    const {data,loading} = useQuery(investmentQuery, {
		variables:{
			id: investmentId
		},
		refetchWritePolicy:'overwrite',
		fetchPolicy:'network-only'
	})
    const [investmentState,setInvestmentState] = useState(null);

    const [saving,setSaving] = useState(false);
    const [error,setError] = useState(null);

    const [_updatePeriodicity,{loading:updatingPeriodicity}] = useMutation(updateInvestmentPeriodicity, {
        variables:{
            investmentId
        }
    });
    const [_addCovenant] = useMutation(createCovenant,{
        variables:{
            investmentId
        }
    });
    const [_removeCovenant] = useMutation(deleteCovenant,{
        variables:{
            investmentId
        }
    });
    const [_saveCovenant] = useMutation(updateCovenant,{
        variables:{
            investmentId
        }
    });

    const [_addWaiver] = useMutation(createWaiver,{
        variables:{
            investmentId
        }
    });

    const [_removeWaiver] = useMutation(deleteWaiverQql,{
        variables:{
            investmentId
        }
    });

    const [_saveWaiver] = useMutation(updateWaiver,{
        variables:{
            investmentId
        }
    });

    const changeInvestmentPeriodicity = useCallback((periodicity)=> {
        _updatePeriodicity({
            variables:{
                investmentId:investmentId,
                periodicity
            }
        }).then((result)=> {
            let response = result.data.updateInvestmentPeriodicity;
			debugger;
            if(response && response.success) {
                setInvestmentState({
                    ...investmentState,
                    reportingPeriodicity:periodicity
                })
				
			} else if(response && response.error) {
                setError(response.error.message);
				
			}

        }).catch((error)=> {
            setError(error.message);
        });
    },[investmentId,investmentState,_updatePeriodicity]);

    const addCovenant = useCallback((covenantId)=> {
        _addCovenant({
            variables:{
                investmentId:investmentId,
                covenantId
            }
        }).then((result)=> {
            let response = result.data.createCovenant.response;
			debugger;
            if(response && response.success) {
                
                setInvestmentState({
                    ...investmentState,
                    covenants:result.data.createCovenant.covenants
                });
				
			} else if(response && response.error) {
                setError(response.error.message);
				
			}

        }).catch((error)=> {
            setError(error.message);
        });
    },[investmentId,investmentState,_addCovenant]);

    const removeCovenant = useCallback((investmentCovenantId, centralCovenantId)=> {
        let confirm = window.confirm('Are you sure you want to delete this covenant?');
        if (confirm) {
            _removeCovenant({
                variables:{
                    investmentId:investmentId,
                    covenantId: investmentCovenantId,
				    centralCovenantId
                }
            }).then((result)=> {
                let response = result.data.deleteCovenant;
                debugger;
                if(response && response.success) {
                    setInvestmentState({
                        ...investmentState,
                        covenants: [...investmentState.covenants.filter((covenant) => {
                            return covenant._id !== investmentCovenantId;
                        })]
                    })
                    
                } else if(response && response.error) {
                    setError(response.error.message);
                    
                }
    
            }).catch((error)=> {
                setError(error.message);
            });
        }
    },[investmentId,investmentState,_removeCovenant]);

    const saveCovenant = useCallback((covenant)=> {
        _saveCovenant({
            variables:{
                investmentId:investmentId,
                covenant,
				covenantId: covenant._id
            }
        }).then((result)=> {
            let response = result.data.updateCovenant;
			debugger;
            if(response && response.success) {
                setInvestmentState({
                    ...investmentState,
                    covenants: investmentState.covenants.map((origcovenant) => {
                        if(origcovenant._id === covenant._id) {
                            return Object.assign({}, origcovenant, {
                                range: covenant.range,
                                alertThreshold: covenant.alertThreshold
                            });
                        }

                        return origcovenant;
                    })
                })
				
			} else if(response && response.error) {
                setError(response.error.message);
				
			}

        }).catch((error)=> {
            setError(error.message);
        });
    },[investmentId,investmentState,_saveCovenant]);

    const deleteWaiver = useCallback((covenantId,waiverId)=> {
        _removeWaiver({
            variables:{
                investmentId:investmentId,
                covenantId,
				waiverId
            }
        }).then((result)=> {
            let response = result.data.deleteWaiver;
			debugger;
            if(response && response.success) {
                setInvestmentState({
                    ...investmentState,
                    covenants:investmentState.covenants.map((covenant)=> {
                        if(covenant._id===covenantId) {
                            return {
                                ...covenant,
                                waivers: [
                                    ...covenant.waivers.filter((waiver) => {
                                        return waiver._id !== waiverId;
                                    })
                                ]
                            }
                        }
                        return covenant;
                    })
                })
				
			} else if(response && response.error) {
                setError(response.error.message);
				
			}

        }).catch((error)=> {
            setError(error.message);
        });
    },[investmentId,investmentState,_removeWaiver]);

    const saveWaiver = useCallback((covenantId,waiver,addOrEdit)=> {
        if(addOrEdit==='ADD') {
            _addWaiver({
                variables:{
                    investmentId:investmentId,
                    covenantId,
				    waiver
                }
            }).then((result)=> {
                let response = result.data.createWaiver.response;
               
                if(response && response.success) {
                    let waivers = result.data.createWaiver.waivers
                    setInvestmentState({
                        ...investmentState,
                        covenants:investmentState.covenants.map((covenant)=> {
                            if(covenant._id===covenantId) {
                                return {
                                    ...covenant,
                                    waivers: [
                                        ...waivers
                                    ]
                                }
                            }
                            return covenant;
                        })
                    })
                    
                } else if(response && response.error) {
                    setError(response.error.message);
                    
                }
    
            }).catch((error)=> {
                setError(error.message);
            });
        } else if(addOrEdit==='EDIT') {
            _saveWaiver({
                variables:{
                    investmentId:investmentId,
                    covenantId,
				waiver,
				waiverId: waiver._id
                }
            }).then((result)=> {
                let response = result.data.updateWaiver.response;
			
                debugger;
                if(response && response.success) {
                    let waiverCovenant = waiver.waiverType === 'REPLACE' ? result.data.updateWaiver.waiver.centralCovenant : null
                    setInvestmentState({
                        ...investmentState,
                        covenants: investmentState.covenants.map((covenant) => {
                            if(covenant._id === covenantId) {
                                return Object.assign({}, covenant, {
                                    waivers: covenant.waivers.map((origwaiver) => {
                                        if(origwaiver._id === waiver._id) {
                                            return Object.assign({}, origwaiver, {
                                                waiverType: waiver.waiverType,
                                                alertThreshold: waiver.alertThreshold,
                                                range: waiver.range,
                                                startDate: waiver.startDate,
                                                endDate: waiver.endDate,
                                                centralCovenant: waiverCovenant
                                            });
                                        }

                                        return origwaiver;
                                    })
                                });
                            }

                            return covenant;
                        })
                    })
                    
                } else if(response && response.error) {
                    setError(response.error.message);
                    
                }
    
            }).catch((error)=> {
                setError(error.message);
            });
        }
    },[investmentId,investmentState,_saveWaiver,_addWaiver]);

    const cancel = useCallback(()=> {
        console.log(match.path);
        history.push(`${match.url}/..`)
       // history.push(`/client/${report.clientKey}/reports/${report.key}/${report.period}`);
       
    },[history,match]);

   
    useEffect(()=> {
        console.log("INVESTMENT EFFECT",data,loading)
        if(!loading && data && data.investment) {

            setInvestmentState(data.investment);
        }
    },[investmentId,loading,data]);

    
    
    if(loading || !investmentState) {
        return <>
        <div>loading</div>
        </>
    }

    //return <div>OK {JSON.stringify(investmentState)}</div>

    return <>
    <InvestmentForm values={investmentState} error={error} changeInvestmentPeriodicity={changeInvestmentPeriodicity}
								                  deleteWaiver={deleteWaiver} saveWaiver={saveWaiver}
								                  addCovenant={addCovenant} deleteCovenant={removeCovenant} saveCovenant={saveCovenant} />

        <div style={{height: '3em', marginTop: '2em'}}>
                                            <div className="form-actions">
                                            <Link to="./" relative="path">
                                                <button  className="cancel" style={{margin: '0 1em'}}>
                                                    <FormattedMessage id="app.buttons.close" />
                                                </button>
                                                </Link>
                                            </div>
                                        </div>
    </>


}