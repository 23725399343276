import React from 'react';
import moment from 'moment';

export default class DateFormatComponent extends React.Component {
	constructor(props) {
		super(props);
	}

	//render method
	render() {
		return (
			<div>
				{moment(this.props.data).format('DD/MM/YYYY HH:mm:ss')}
			</div>
		);
	}
}