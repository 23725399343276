import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ClientList from '../../components/ClientList/index';
import Menu from '../../components/menu/index';
import {logOut, switchLanguage } from '../../../redux/actions/session';
import {switchCurrency } from '../../../redux/actions/session';
import {updateLanguage} from '../../../graph/mutations/user';
import moment from 'moment';
import currencies from '../../../data/currencies';
import Meteor from '../../../Meteor'
import { asOptions as languages } from '../../components/languageSelector/languages';


function mapStateToProps(state) {
    return {

        fullName: state.session.user ? state.session.user.fullName : null,
        language: state.session.language,
        client: state.client,
        currency: state.session.currency,
        clients:state.session.clients
    };
}

function mapDispatchToProps(dispatch) {
    return {
        switchLanguage:(lang)=> {
            dispatch(switchLanguage(lang));
        },
        switchCurrency:(lang)=> {
            dispatch(switchCurrency(lang));
        },
        logout:()=> {
            
            dispatch(logOut())
        }
    };
}


class Header extends React.Component
{
    constructor(props) {
        super(props);

        this.onLanguageSelect = this.onLanguageSelect.bind(this);
        this.onSelectCurrency = this.onSelectCurrency.bind(this);
        this.onUserInfo = this.onUserInfo.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);


    }

    shouldComponentUpdate(nextProps) {
        return nextProps.client.currency != this.props.client.currency 
        || this.props.currency != nextProps.currency
        || this.props.language != nextProps.language;
    }

    onSelectCurrency(currency) {
        this.props.switchCurrency(currency.value);
    }

    //user clicked on a language in the MenuItem component --> set language in localForage for further use & reload window
    onLanguageSelect(item) {
        this.props.switchLanguage(item.value);

        this.props.updateLanguage({
            variables : {
                language: item.value
            }
        });
    }

    onUserInfo(item) {
        
        if (item.value == "logout") {
            this.props.logout();
           
        }
    }

    toggleCollapse() {
        if(this.props.toggleCollapse) this.props.toggleCollapse();
    }

    render() {
        const {
            fullName,
            language,
            client,
            clients,
            currency,
            title,
            switchLanguage
        } = this.props;

        const headerName = client ? client.name : title ;
        const diffCurrency = client && client.currency!=currency;

        console.log('HEADER',language,currency)

        return <section className="header">
            <div className="navigation-toggle fa fa-bars" onClick={this.toggleCollapse}></div>
            <div className="header-content">

                <ClientList defaultTitle={title} />

            </div>

            {
                client && client._id ?
                    <Menu id="currency-menu" key="currency" items={[{value:client.currency},...currencies]} title={currency} defaultValue={client.currency} labelSelector="value"
                          menuClassname={diffCurrency ? 'currency differs' : 'currency'}
                          onItemSelect={this.onSelectCurrency} restrictWidth={true} />
                    : null
            }

            <Menu id="language-menu" key="language" items={languages} menuClassname="language"
                  title={this.props.language} onItemSelect={this.onLanguageSelect} />

            <Menu id="userinfo-menu" key="userinfo" items={[{value: "logout", label: <FormattedMessage id="header.menu-item.logout"/>, icon: '\uf08b'}]}
                  onItemSelect={this.onUserInfo} menuClassname="user-info" restrictWidth={false} title={fullName} />
        </section>;

    }
}
export default graphql(updateLanguage, { name: 'updateLanguage' })(
    connect(mapStateToProps, mapDispatchToProps)(Header))
/*
 {
 Clients.length > 1 ?
 <ClientList Clients={Clients} routeParams={this.props.params} closeOther={this.closeOther.bind(this, 'CLIENTS')}
 activeClient={client !== undefined ? client : null}
 showClientOptions={showClientOptions} navigate={this.props.navigate} />
 : <h2>{headerName}</h2>
 }
 */