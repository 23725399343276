import { NAVIGATION_MENU_SET, NAVIGATION_MENU_RESET } from '../actions/navigation';


const clientMenu = [];

const incofinMenu = [];

const incofinAdminMenu = [];



const initialState = {
    loading:false,
    navigationType:null,
    ts:null,
    menu:[],
    contacts:[]
};


export const navigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case NAVIGATION_MENU_SET: {
            return {
                ...state,
                menu:[...action.menu],
                navigationType:action.navigationType,
                contacts:action.contacts ? [...action.contacts ] : [],
                ts:(new Date()).getTime()
            };
        }
        case NAVIGATION_MENU_RESET: {
            return {
                loading:false,
                navigationType:null,
                menu:[],
                contacts:[],
                ts:null
            };
        }
    }

    return state;
};




