import '../AdminForm.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from '@apollo/client/react/hoc';

import { Table } from '../../table/Table'
import classNames from 'classnames';
import ActionsComponent from '../../dashboard/gridview/components/actions';
import Loader from '../../../components/loader/index.jsx';
import TextField from '../../fields/text/index';
import SelectField from '../../fields/select/index';
import clientsQuery from '../../../../graph/queries/incofin/clients';
import { clone } from '../../../../utils';
import RequiredLabel from '../../RequiredLabel/index';
import _ from 'underscore';


class UserForm extends React.Component {
	constructor(props) {
		super(props);

		//sorry for the large state declaration?
		this.state = {
			values: clone(this.props.values, true) || {},
			valid: true,
			communicationCreating: false,
			communicationEditing: false,
			communicationSaving: false,
			communicationValid: true,
			communicationRowIndex: null,
			selectedCommunication: {}
		};

		//3 validation maps = 1 for the general form, 1 for the communication form, 1 for the background form
		this._validations = new Map();
		this._communicationValidations = new Map();

		this.gridClassName = classNames(
			'o-gridView',
		);

		this.tableClassName = classNames(
			'o-gridView__table',
			'o-gridView__table-no-margin-top',
			'o-gridView__table--sortable',
			'o-gridView__table--paged',
		);
	}

	//set new value in received valueTree based on prop identifier
	iterateObject(values, value, identifier) {
		let foundValue = false;
		Object.keys(values).forEach((k) => {
			if(value instanceof Array && k === identifier) {
				values[k] = _.pluck(value, 'value');
				foundValue = true;
				//return;
			} else if (values[k] !== null && typeof values[k] === 'object') {
				foundValue = !foundValue ? this.iterateObject(values[k], value, identifier) : foundValue;
				//return;
			} else if(k === identifier) {
				values[k] = value;
				foundValue = true;
			}
		});

		return foundValue;
	}

	//method hooked up to all the fields --> detect changes
	//if a field of the communication form is adjusted, immediately adjust value in state.selectedCommunication
	//if a field of the background form is adjusted, immediately adjust value in state.selectedBackground
	onFieldChange(field, value, previousValue, valid) {
		let values = this.state.values;
		if(field.props.identifier.split('.')[0] === 'communication') {
			this.state.selectedCommunication[field.props.identifier.split('.')[1]] = value;
		} else if(field.props.identifier === 'roles') {
			values['clients']['ids'] = [];
			values['clients']['regions'] = [];
		}

		let foundValue = this.iterateObject(values, value, field.props.identifier);
		if(!foundValue) values[field.props.identifier] = value;

		if(values.roles && values.roles.indexOf("CLIENT-ADMIN")>-1) {
			if(values.roles.indexOf("CLIENT")==-1) {
				values.roles.push("CLIENT");
			}
		}

		this.onValidationChange(field, valid, value);
		this.setState({values: values});
	}

	//method hookup to all the fields --> detect validation changes (valid/invalid)
	//if invalid --> store identifier in map, if map.length > 0 => specified form is invalid
	//if a field of the communication/background form is changed, store it in a different map
	onValidationChange(field, valid, value) {
		let validationMapToUse = field.props.identifier.split('.')[0] === 'communication' ? this._communicationValidations : this._validations;
		if(field.props.identifier === 'roles') {
			if(validationMapToUse.has('ids') && value.findIndex(item=>item.value === "CLIENT") === -1) {
				validationMapToUse.delete('ids');
			}
			if(validationMapToUse.has('regions') && value.findIndex(item=>item.value === "Incofin-RD"||item.value === "Incofin-IM") === -1) {
				validationMapToUse.delete('regions');
			}
		}
		if(validationMapToUse.has(field.props.identifier)) validationMapToUse.delete(field.props.identifier);
		if(!valid) validationMapToUse.set(field.props.identifier, valid);
		let myValid = validationMapToUse.size === 0;

		if(field.props.identifier.split('.')[0] === 'communication') this.setState({communicationValid: myValid});
		else this.setState({valid: myValid});
	}

	//user clicked on one of the action buttons in the background/communication GridView
	// => edit/delete background/communication --> no call needed to the db, will happen in the higher component
	onAction(action, rowData) {
		if(action === 'edit') {
			this.setState({
				selectedCommunication: {
					type: rowData.type,
					value: rowData.value
				},
				communicationEditing: true,
				communicationRowIndex: rowData.__index
			});
		} else if(action === 'delete') {
			let values = this.state.values;
			values.communication.splice(rowData.__index, 1);
			this.setState({values: values});
		}
	}

	//user clicked on "add new communication/background item" --> show background/communication form with empty values
	addItem() {
		this.setState({communicationCreating: true});
	}

	//user clicked on cancel in the "communication" or "background" part of the form --> show background/communication GridView & reset their part of the state
	cancel() {
		this.setState({communicationCreating: false, communicationEditing: false, communicationSaving: false, selectedCommunication: {}, communicationValid: true, communicationRowIndex: null});
	}

	//user clicked on "save communication item" --> save new/existing communication item to the state (no call needed to the db, will happen in the higher component)
	saveCommunication() {
		this.setState({communicationSaving: true});

		if(this.state.communicationValid) {
			let values = this.state.values;
			if(this.state.communicationEditing) {
				values.communication[this.state.communicationRowIndex] = this.state.selectedCommunication;
			} else if(this.state.communicationCreating) {
				if(!values.communication) values.communication = [];
				values.communication.push(this.state.selectedCommunication);
			}
			this.setState({
				values: values,
				communicationEditing: false,
				communicationCreating: false,
				communicationSaving: false,
				communicationValid: true,
				selectedCommunication: {},
				communicationRowIndex: null
			});
		}
	}

	//separate method to render a GridView with communication items in it (specific columns & data)
	renderCommunication(communication) {
		let values = communication && communication.length > 0 ? communication.map((dataEntry, idx) => {
			return {
				type: dataEntry.type,
				value: dataEntry.value,
				__actions: ['edit', 'delete'],
				__index: idx
			};
		}) : [];

		const columns = ['type', 'value', '__actions'];
		const columnMeta = [
			{ columnName: 'type', displayName: <FormattedMessage id="admin.form.user.communication.type"/> },
			{ columnName: 'value', displayName: <FormattedMessage id="admin.form.user.communication.value"/> },
			{
				columnName: '__actions',
				displayName: <FormattedMessage id="grid.column.actions"/>,
				customComponent: ActionsComponent,
				customComponentProps: {
					onAction: this.onAction.bind(this)
				}
			},
			{ columnName: '__index', visible: true }
		];

		return this.renderGriddle(values, columnMeta, columns);
	}

	//render the GridView itself (used in above methods)
	renderGriddle(values, columnMeta, columns) {
		return (
			<div className="grid-container">
				<Table data={values} columns={columns} columnMetadata={columnMeta} useGriddleStyles={false}
								 noDataMessage={'No data could be found.'} gridClassName={this.gridClassName}
								 tableClassName={this.tableClassName}/>
			</div>
		);
	}

	//render method (render a form --> NOT using FormGenerator here, only available field components)
	render() {
		if (this.props.data.loading) {
			return <Loader/>;
		}

		const {
			values
		} = this.state;

		const {
			regions,
			countries
		} = this.props;

		const clients = [];

		if(values['roles'] && values['roles'].indexOf('CLIENT') >= 0 && this.props.data && this.props.data.clients && this.props.data.clients.length > 0) {
			this.props.data.clients.forEach((client) => {
				clients.push({
					value: client._id,
					label: client.name
				});				
			});
		}

		const roles = [
			{ value: 'CLIENT', label: 'CLIENT' },
			{ value: 'CLIENT-ADMIN', label: 'Client Admin'},
			{ value: 'Incofin-RD', label: 'Incofin Regional Director'},
			{ value: 'Incofin-admin', label: 'Incofin Admin' },
			{ value: 'Incofin-IM', label: 'Incofin Investment Manager'}
		];

		const languages = [
			{value:"en", "name":"English", label:"English", "active":true, "sort":1,},
			{value:"ar", "name":"Arabic", label:"العربية", "active":true, "sort":-1},
			{value:"es", "name":"Spanish; Castilian", label:"español", "active":true, "sort":-1},
			{value:"fr", "name":"French", label:"français", "active":true, "sort":-1},
			{value:"km", "name":"Khmer", label:"ភាសាខ្មែរ", "active":true, "sort":-1},
			{value:"lo", "name":"Lao", label:"ພາສາລາວ", "active":true, "sort":-1},
			{value:"nl", "name":"Dutch", label:"Nederlands", "active":true, "sort":-1},
			{value:"pt", "name":"Portuguese", label:"Português", "active":true, "sort":-1},
			{value:"ru", "name":"Russian", label:"русский язык", "active":true, "sort":-1},
			{value:"vi", "name":"Vietnamese", label:"Tiếng Việt", "active":true, "sort":-1}
		];

		const regionOptions = regions && regions.length > 0 && regions instanceof Array ?
			regions.map((region) => {
				return {
					value: region._id,
					label: region.region
				};
			}) : [];

		const countriesOptions = countries && countries.length > 0 && countries instanceof Array ?
			countries.map((country) => {
				return {
					label: country.name,
					value: country.name
				};
			}) : [];

		const standardBindings = {
			validations: {required: true},
			activePageIsSubmitted: this.props.saving,
			onChange: this.onFieldChange.bind(this),
			onValidationChange: this.onValidationChange.bind(this)
		};

		let notRequiredBindings = { ...standardBindings };
		delete notRequiredBindings.validations;

		let communicationStandardBindings = {...standardBindings};
		communicationStandardBindings.activePageIsSubmitted = this.state.communicationSaving;

		return (
			<div className="admin-form">
				<div className="required-fields-label">
					<FormattedMessage id="required-fields.label"/>
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.user.firstName"/>
						<RequiredLabel />
					</label>
					<TextField value={values['firstName']} identifier={'firstName'} {...standardBindings} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.user.lastName"/>
						<RequiredLabel />
					</label>
					<TextField value={values['lastName']} identifier={'lastName'} {...standardBindings} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.user.email"/>
						<RequiredLabel />
					</label>
					<TextField value={values['email']} identifier={'email'} {...standardBindings} />				
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.user.roles"/>
						<RequiredLabel />
					</label>
					<SelectField value={values['roles']} multi={true} identifier={'roles'} options={roles} {...standardBindings} />
				</div>

				{
					(values['roles'] && values['roles'].indexOf('Incofin-RD') > -1) || (values['roles'] && values['roles'].indexOf('Incofin-IM') > -1) ?
						<div className="form-group">
							<label>
								<FormattedMessage id="admin.form.user.region"/>
								<RequiredLabel />
							</label>
							<SelectField value={values['clients']['regions']} identifier={'regions'} multi={true} options={regionOptions}
													 {...standardBindings} placeholder={'Select region(s) ... '} />
						</div> : null
				}

				{
					values['roles'] && values['roles'].indexOf('CLIENT') > -1 ?
						<div className="form-group">
							<label>
								<FormattedMessage id="admin.form.user.clients"/>
								<RequiredLabel />
							</label>
							<SelectField value={values['clients']['ids']} identifier={'ids'} options={clients} {...standardBindings}
													 multi={true} placeholder={'Select one or more Client\'s ... '} />
						</div> : null
				}

				<div className="form-group">
					<label>
						<FormattedMessage id="admin.form.user.language"/>
						<RequiredLabel />
					</label>
					<SelectField value={values['language']} multi={false} identifier={'language'} options={languages} {...standardBindings} />
				</div>

				<div className="form-group">
					<div className="block">
						<label className="top-label">
							<FormattedMessage id="admin.form.client.address"/>
						</label>

						<div className="block-children">
							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.user.address.country"/>
								</label>
								<SelectField value={values['address']['country']} identifier={'country'} options={countriesOptions} {...notRequiredBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.user.address.city"/>
								</label>
								<TextField value={values['address']['city']} identifier={'city'} {...notRequiredBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.user.address.postalCode"/>
								</label>
								<TextField value={values['address']['postalcode']} identifier={'postalcode'} {...notRequiredBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.user.address.street"/>
								</label>
								<TextField value={values['address']['street']} identifier={'street'} {...notRequiredBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.user.address.nr"/>
								</label>
								<TextField value={values['address']['nr']} identifier={'nr'} {...notRequiredBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.user.address.additional"/>
								</label>
								<TextField value={values['address']['additional']} identifier={'additional'} {...notRequiredBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.user.address.box"/>
								</label>
								<TextField value={values['address']['box']} identifier={'box'} {...notRequiredBindings} />
							</div>

							<div className="form-group">
								<label>
									<FormattedMessage id="admin.form.user.address.state"/>
								</label>
								<TextField value={values['address']['state']} identifier={'state'} {...notRequiredBindings} />
							</div>
						</div>
					</div>
				</div>
				<div className="form-group">
					<div className="block">
						<label className="top-label">
							<FormattedMessage id="admin.form.user.communication" />
						</label>
						{
							!this.state.communicationCreating && !this.state.communicationEditing ?
								this.renderCommunication(this.state.values['communication']) :
								<div className="block-children">
									<div className="form-group">
										<label>
											<FormattedMessage id="admin.form.user.communication.type"/>
											<RequiredLabel />
										</label>
										<TextField value={this.state.selectedCommunication['type']} identifier={'communication.type'} {...communicationStandardBindings} />
									</div>

									<div className="form-group">
										<label>
											<FormattedMessage id="admin.form.user.communication.value"/>
											<RequiredLabel />
										</label>
										<TextField value={this.state.selectedCommunication['value']} identifier={'communication.value'} {...communicationStandardBindings} />
									</div>
								</div>
						}

						{
							!this.state.communicationCreating && !this.state.communicationEditing ?
								<button onClick={this.addItem.bind(this, 'communication')}>
									<FormattedMessage id="app.buttons.add-new-communication-item" />
								</button>
								:
								<div className="form-actions">
									<button onClick={this.saveCommunication.bind(this)}>
										<FormattedMessage id="app.buttons.save-communication-item" />
									</button>
									<button onClick={this.cancel.bind(this, 'communication')} className="cancel">
										<FormattedMessage id="app.buttons.cancel"/>
									</button>
								</div>
						}
					</div>
				</div>
			</div>
		);
	}
}


export default graphql(clientsQuery, { withRef: true })(UserForm);
