import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import ActionsComponent from '../dashboard/gridview/components/actions';
import RolesComponent from '../dashboard/gridview/components/roles';

import { GraphTableServer } from '../table/GraphTable';
import { Table } from '../table/Table';
import usersQuery from '../../../graph/queries/admin/users';
import moment from 'moment';

/**
 * DESCRIPTION: show ALL users on the "/incofin/admin/users" route
 *   => only accessible by the Incofin-admin or admin user
 */


const DefaultProps = {
	itemsPerPage: 5
};

class AdminUsersGridView extends React.Component {
	constructor(props) {
		super(props);
	}
	calculateUser(user) {
		let actions = ['edit'];

		if(user.status !== 'DELETED') actions.push('delete');
		if(!user.userAccountId || user.userAccountId === null) actions.push('reinvite');

		return actions;
	}

	//render method
	render() {
		const users = this.props.users || [];
		let gridData = users.map((user) => {
			return {
				_id: user._id,
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				roles: user.roles,
				language: user.language,
				fullName: user.fullName,
				hasAccount: user.userAccountId ? 'Yes' : 'No',
				lastLogin:user.lastLogin ? moment(new Date(user.lastLogin)) : moment('0001-01-01T00:00:00Z'),
				lastOnline: user.lastLogin ? new Date(user.lastLogin).toUTCString() : 'Not known',
				status: user.status,
				clients: user.clients,
				clientnames: user.clients.nameList,
				address: user.address,
				communication: user.communication,
				__actions: this.calculateUser(user)
			};
		});

		const columnMeta = [
			{ columnName: 'fullName',sortable:true,  displayName: <FormattedMessage id="grid.users.columns.fullName" /> },
			{ columnName: 'clientnames',sortable:true,  displayName: <FormattedMessage id="export.labels.clients"/> },
			{ columnName: 'email', sortable:true, displayName: <FormattedMessage id="grid.users.columns.email" /> },
			{ columnName: 'hasAccount',sortable:true,  displayName: <FormattedMessage id="grid.users.columns.hasAccount" /> },
			{ columnName: 'lastOnline',sortable:true,sortDataIndex:'lastLogin',  displayName: <FormattedMessage id="grid.users.columns.lastOnline" /> },
			{ columnName: 'status',sortable:true,  displayName: <FormattedMessage id="grid.users.columns.status" /> },
			{ columnName: 'roles',sortable:true,  displayName: <FormattedMessage id="grid.users.columns.roles" />, customComponent: RolesComponent },
			{ 
				columnName: '__actions',
				displayName: <FormattedMessage id="grid.column.actions"/>,
				customComponent: ActionsComponent,
				customComponentProps: {
					onAction: this.props.onAction
				} 
			}
		];

		return (
			<div>
				
		
				<Table data={gridData} showFilter={true} showPager={true}
									initialSort={'fullName'}
									enableSort={true}
									columnMetadata={columnMeta}
									resultsPerPage={this.props.itemsPerPage} />
			</div>
		);
	}
}

export default AdminUsersGridView;
//graphql(usersQuery)(
	