import React from 'react';
import moment from 'moment';
import classNames from 'classnames';



class WaiverItem extends React.Component {
	constructor(props) {
		super(props);

		this.editWaiver = this.editWaiver.bind(this);
		this.deleteWaiver = this.deleteWaiver.bind(this);
	}

	editWaiver() {
		if(this.props.editWaiver) this.props.editWaiver(this.props._id);
	}

	deleteWaiver() {
		if(this.props.deleteWaiver) this.props.deleteWaiver(this.props._id);
	}

	render() {
		const {
			startDate,
			endDate,
			waiverType,
			centralCovenant,
			range: {
				min,
				max
			},
			alertThreshold,
			intl,
			permissions,
			waiverInEffect
		} = this.props;

		let classNamesItem = classNames(
			'covenant-waiver-list-item',
			waiverInEffect ? 'waiver-in-effect' : null
		);

		return (
			<div className={classNamesItem}>
				<div className="covenant-waiver-list-item-header">
					<div className="covenant-waiver-list-item-title">
						id:{this.props._id}
						{moment(startDate, 'YYYYMMDD').format('DD/MMM/YYYY')} &#062; {moment(endDate, 'YYYYMMDD').format('DD/MMM/YYYY')}
						<span>&#058;</span>
						<span className={`waiver-type ${waiverType}`}>{waiverType}</span>
					</div>
					{
						permissions.canEditWaivers || permissions.canDeleteWaivers ?
							<div className="covenant-waiver-list-item-actions">
								{
									permissions.canEditWaivers ?
										<span className="edit" onClick={this.editWaiver} /> : null
								}

								{
									permissions.canDeleteWaivers ?
										<span className="delete" onClick={this.deleteWaiver} /> : null
								}
							</div>
							: null
					}
				</div>

				<div className="covenant-waiver-list-item-details">
					<div className="covenant-waiver-list-item-details-replace">
						{
							waiverType === 'REPLACE' ? centralCovenant.name : null
						}
					</div>

					{
						waiverType === 'ALTER' || waiverType === 'REPLACE' ?
							<div className="covenant-waiver-list-item-details-range-alert">
								<span className="covenant-details-range">
									{intl.formatNumber(min/100)} &#060; &#062; {intl.formatNumber(max/100)}
								</span>
										<span className="covenant-details-alert-threshold">
									{intl.formatNumber(alertThreshold/100)}
								</span>
							</div>
							: null
					}
				</div>
			</div>
		);
	}
}

export default WaiverItem;