import { gql } from '@apollo/client';

const reportsQuery = gql`query reports($clientId:String,$reportId:String, $language:String, $skip:Int, $limit:Int,$filter:String,$sort:[InputSorting]) { 
    reports(clientId: $clientId, configId:$reportId,skip:$skip, limit:$limit,language:$language,filter:$filter,sort:$sort) {
    	results {
        _id
        configId
        client {
            _id
            key
            officialName
					  name
        }
        config {
        	_id
        	name {
            value
          }
        	key
        }
        schedule {
            due
        }
        settings {
            currency
            reportingScale
        }
        context {
            category
            text
            numeric
            period {
              month
              quarter
              year
              financialYear
              financialYearPeriod
            }
        }
        status
        overdue
        lastUpdated
        reportedBy {
            _id
            fullName
        }
        auditedBy {
            _id
            fullName
        }
      }
      pageInfo {
        total
        limit
        offset
      }
    }
  }
`;

export default reportsQuery;