import SummaryBlock from './ui/components/summary/block/summaryBlock';
import SummaryList from './ui/components/summary/list/summaryList'
import { Table } from './ui/components/table/Table';
import { FormattedMessage } from 'react-intl';
import {useState} from 'react'
import { gql} from '@apollo/client'
import reportsQuery from './graph/queries/incofin/reports';

import SpanStatusComponent from './ui/components/dashboard/gridview/components/span-status.jsx';
import { GraphTableServer } from './ui/components/table/GraphTable'
import moment from 'moment';

import periodNaming from "./ui/components/periodToText";
/*
variables: {
    clientId,
    reportId,
    skip,
    limit,
    language: state.session.language,
    filter
}*/


const gridDataMapper = (reports)=>{return reports.map((report) => {
    let overdue = moment().isAfter(moment(report.schedule.due));

    return {
        id: report._id,
        _id: report._id,
        reportId: report.reportId,
        clientId: report.client ? report.client._id : null,
        status: report.status === 'created' && overdue ? 'overdue' : report.status,
        client: report.client ? report.client.officialName : '',
        clientKey: report.client ? report.client.key : null,
        key: report.report.key,
        category: report.report.name,
        periodName: periodNaming(report.context.text,report.context.numeric),
        period: report.context.text,
        dueDate: moment(report.schedule.due).format('dddd MMMM DD YYYY'),
        overdue,
        reporter: report.reporter ? report.reporter.fullName : null,
    };
}); };

const columnMeta = [
    {
        "columnName": 'status',
        "displayName": <FormattedMessage id="grid.reports.columns.status"/>,
        "customComponent": SpanStatusComponent
    },
    {"columnName": 'client', "displayName": <FormattedMessage id="grid.reports.columns.client"/>},
    {"columnName": 'category', "displayName": <FormattedMessage id="grid.reports.columns.category"/>},
    {"columnName": 'periodName', "displayName": <FormattedMessage id="grid.reports.columns.period"/>},
    {"columnName": 'dueDate', "displayName": <FormattedMessage id="grid.reports.columns.due-date"/>},
    {"columnName": 'reporter', "displayName": <FormattedMessage id="grid.reports.columns.reporter"/>},
];

const rowMetadata = {
    'bodyCssClassName': function (rowData) {
        if (rowData.status === 'rejected' || (rowData.overdue && rowData.status !== 'approved')) return 'error';
    }
};

const data = [{_id:"345345",client:"Client", status:"OVERDUE",periodName:"periodname", dueDate:new Date(),reporter:"Ikke"}]

export default ()=> {


    const [loading,setLoading] = useState(true);

    return <div>
        <GraphTableServer qry={reportsQuery} 
        options={{
            fetchPolicy: "no-cache",
        }}
        tableDataMapper={gridDataMapper}
        dataSelector={(d)=>d && d.reports ? d.reports.results : []}
        totalSelector={(d)=>d && d.reports ? d.reports.total :0}
         columnMetadata={columnMeta} showFilter={true} showPager={true} 
        />
       <button onClick={()=>setLoading(!loading)}>Loading</button>

         </div>
}
//   <Table loading={loading} data={data} columnMetadata={columnMeta} showFilter={true} showPager={true} />
