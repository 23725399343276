import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { push } from '../../../router';
import Meteor from '../../../Meteor';
import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import { FormattedMessage } from 'react-intl';
import Loader from '../loader/index.jsx';
import { Table } from '../table/Table';
import classNames from 'classnames';
import TextField from '../fields/text/index';
import SelectField from '../fields/select/index';
import LoginField from '../login/field/index';
import unregisteredUser from '../../../graph/queries/registration/user';
import {updateRegistration} from '../../../graph/mutations/user';
import ActionsComponent from '../dashboard/gridview/components/actions';
import { clone } from '../../../utils';
import { fetchProfile, loggingIn, switchLanguage } from '../../../redux/actions/session';
import RequiredLabel from '../RequiredLabel/index';
import _ from 'underscore';

function mapDispatchToProps(dispatch) {
     return {
         fetchProfile: () => {
            dispatch(fetchProfile(true));
        },
         navigateTo: (action) => {
             dispatch(push(action));
         }
     }
 }

 
class RegisterForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            passwordsMatch: true,
            saving: false,
            valid: true,
            communicationCreating: false,
            communicationEditing: false,
            communicationSaving: false,
            communicationValid: true,
            communicationRowIndex: null,
            selectedCommunication: {}
        };

        this.values = {};
        this._validations = new Map();
        this._communicationValidations = new Map();

        this.gridClassName = classNames(
            'o-gridView',
        );

        this.tableClassName = classNames(
            'o-gridView__table',
            'o-gridView__table-no-margin-top',
            'o-gridView__table--sortable',
            'o-gridView__table--paged',
        );

        this.submitForm = this.submitForm.bind(this);
    }

    //method hooked up to all the fields --> detect changes
    //if a field of the communication form is adjusted, immediately adjust value in state.selectedCommunication
    //if a field of the background form is adjusted, immediately adjust value in state.selectedBackground
    onFieldChange(field, value, previousValue, valid) {
        let values = this.values;
        if(field.props.identifier.split('.')[0] === 'communication') {
            this.state.selectedCommunication[field.props.identifier.split('.')[1]] = value;
        }

        let foundValue = this.iterateObject(values, value, field.props.identifier);
        if(!foundValue) values[field.props.identifier] = value;

        this.onValidationChange(field, valid, value);
        this.values = values;
    }

    iterateObject(values, value, identifier) {
        let foundValue = false;
        Object.keys(values).forEach((k) => {
            if(value instanceof Array && k === identifier) {
                values[k] = _.pluck(value, 'value');
                foundValue = true;
                //return;
            } else if (values[k] !== null && typeof values[k] === 'object') {
                foundValue = !foundValue ? this.iterateObject(values[k], value, identifier) : foundValue;
                //return;
            } else if(k === identifier) {
                values[k] = value;
                foundValue = true;
            }
        });

        return foundValue;
    }

    submitForm() {
        this.setState({saving: true});

        //console.log('valid? ', this.state.valid);
        if(this.refs.password1.getValue() === this.refs.password2.getValue()) {
            this.setState({passwordsMatch: true});
            if (this.state.valid && this.refs.password1.validate() && this.refs.password2.validate()) {
                this.values.password = this.refs.password1.getValue().trim();

                this.props.updateUserFromRegistration({
                    variables: {
                        userId: this.props.user._id,
                        user: this.values
                    }
                }).then(({data}) => {
                    if (data.updateUserFromRegistration.success && this.refs.container) {
                        this.setState({saving: false});
                        this.refs.container.success('Success');

                        Meteor.loginWithPassword(this.props.user.email, this.values.password , (error) => {
                            if(error) {
                                //this.refs.container.error(error.message);
                                this.props.navigateTo('/login');
                            }
                            else {
                                document.location="/";
/*
                                this.props.fetchProfile();
                                this.props.navigateTo('/');*/
                            }
                        });

                    } else if(data.updateUserFromRegistration.error && this.refs.container) this.refs.container.error(data.updateUserFromRegistration.error.code, data.updateUserFromRegistration.error.message);
                }).catch((error) => {
                    if (this.refs.container) this.refs.container.error(error.message);
                });

            } else {
                this.refs.container.error('Please fill in all required fields');
            }
        } else {
            this.setState({passwordsMatch: false});
            this.refs.container.error('Passwords don\'t match');
        }
    }

    getValues() {
        return this.values;
    }

    //method hookup to all the fields --> detect validation changes (valid/invalid)
    //if invalid --> store identifier in map, if map.length > 0 => specified form is invalid
    //if a field of the communication/background form is changed, store it in a different map
    onValidationChange(field, valid, value) {
        let validationMapToUse = field.props.identifier.split('.')[0] === 'communication' ? this._communicationValidations : this._validations;

        if(validationMapToUse.has(field.props.identifier)) validationMapToUse.delete(field.props.identifier);
        if(!valid) validationMapToUse.set(field.props.identifier, valid);
        let myValid = validationMapToUse.size === 0;

        if(field.props.identifier.split('.')[0] === 'communication') this.setState({communicationValid: myValid});
        else this.setState({valid: myValid});
    }

    //user clicked on one of the action buttons in the background/communication GridView
    // => edit/delete background/communication --> no call needed to the db, will happen in the higher component
    onAction(action, rowData) {
        if(action === 'edit') {
            this.setState({
                selectedCommunication: {
                    type: rowData.type,
                    value: rowData.value
                },
                communicationEditing: true,
                communicationRowIndex: rowData.__index
            });
        } else if(action === 'delete') {
            let values = this.values;
            values.communication.splice(rowData.__index, 1);
            this.setState({values: values});
        }
    }

    //user clicked on "add new communication/background item" --> show background/communication form with empty values
    addItem(table) {
        this.setState({communicationCreating: true});
    }

    //user clicked on cancel in the "communication" or "background" part of the form --> show background/communication GridView & reset their part of the state
    cancel(table) {
        this.setState({communicationCreating: false, communicationEditing: false, communicationSaving: false, selectedCommunication: {}, communicationValid: true, communicationRowIndex: null});
    }

    //user clicked on "save communication item" --> save new/existing communication item to the state (no call needed to the db, will happen in the higher component)
    saveCommunication() {
        this.setState({communicationSaving: true});

        if(this.state.communicationValid) {
            let values = this.values;
            if(this.state.communicationEditing) {
                values.communication[this.state.communicationRowIndex] = this.state.selectedCommunication;
            } else if(this.state.communicationCreating) {
                if(!values.communication) values.communication = [];
                values.communication.push(this.state.selectedCommunication);
            }
            this.setState({
                values: values,
                communicationEditing: false,
                communicationCreating: false,
                communicationSaving: false,
                communicationValid: true,
                selectedCommunication: {},
                communicationRowIndex: null
            });
        }
    }

    //separate method to render a GridView with communication items in it (specific columns & data)
    renderCommunication(communication) {
        let values = communication && communication.length > 0 ? communication.map((dataEntry, idx) => {
            return {
                type: dataEntry.type,
                value: dataEntry.value,
                __actions: ['edit', 'delete'],
                __index: idx
            };
        }) : [];

        const columns = ['type', 'value', '__actions'];
        const columnMeta = [
            { columnName: 'type', displayName: 'Type' },
            { columnName: 'value', displayName: 'Value' },
            {
                columnName: '__actions',
                displayName: 'Actions',
                customComponent: ActionsComponent,
                customComponentProps: {
                    onAction: this.onAction.bind(this)
                }
            },
            { columnName: '__index', visible: true }
        ];

        return this.renderGriddle(values, columnMeta, columns);
    }

    //render the GridView itself (used in above methods)
    renderGriddle(values, columnMeta, columns) {
        return (
            <div className="grid-container">
                <Table data={values} columns={columns} columnMetadata={columnMeta} useGriddleStyles={false}
                         noDataMessage={'No data could be found.'} gridClassName={this.gridClassName}
                         tableClassName={this.tableClassName}/>
            </div>
        );
    }

    render() {
        if (this.props.data.loading) {
            return <Loader/>;
        }

        if (_.isEmpty(this.values)) this.values = clone(this.props.data.unregisteredUser, true);

        const {
            unregisteredUser,
            countries
        } = this.props.data;

        let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

        const languages = [
            {value:"en", "name":"English", label:"English", "active":true, "sort":1,},
            {value:"ar", "name":"Arabic", label:"العربية", "active":true, "sort":-1},
            {value:"es", "name":"Spanish; Castilian", label:"español", "active":true, "sort":-1},
            {value:"fr", "name":"French", label:"français", "active":true, "sort":-1},
            {value:"km", "name":"Khmer", label:"ភាសាខ្មែរ", "active":true, "sort":-1},
            {value:"lo", "name":"Lao", label:"ພາສາລາວ", "active":true, "sort":-1},
            {value:"nl", "name":"Dutch", label:"Nederlands", "active":true, "sort":-1},
            {value:"pt", "name":"Portuguese", label:"Português", "active":true, "sort":-1},
            {value:"ru", "name":"Russian", label:"русский язык", "active":true, "sort":-1},
            {value:"vi", "name":"Vietnamese", label:"Tiếng Việt", "active":true, "sort":-1}
        ];

        const countriesOptions = countries && countries.length > 0 && countries instanceof Array ?
            countries.map((country) => {
                return {
                    label: country.name,
                    value: country.name
                };
            }) : [];

        const standardBindings = {
            validations: {required: true},
            activePageIsSubmitted: this.state.saving,
            onChange: this.onFieldChange.bind(this),
            onValidationChange: this.onValidationChange.bind(this)
        };

        let notRequiredBindings = { ...standardBindings };
        delete notRequiredBindings.validations;

        let communicationStandardBindings = {...standardBindings};
        communicationStandardBindings.activePageIsSubmitted = this.state.communicationSaving;

        return (
            (!!unregisteredUser) ?
                <div className="register-form admin-form">
                    <ToastContainer ref="container" toastMessageFactory={ToastMessageFactory} className="toast-top-right" />

										<div className="required-fields-label">
												<FormattedMessage id="required-fields.label"/>
										</div>

                    <div className="form-group">
                        <label>
													First name
													<RequiredLabel/>
												</label>
                        <TextField value={unregisteredUser.firstName} identifier={'firstName'} {...standardBindings} />
                    </div>
                    <div className="form-group">
                        <label>
													Last name
													<RequiredLabel/>
												</label>
                        <TextField value={unregisteredUser.lastName} identifier={'lastName'} {...standardBindings} />
                    </div>
                    <div className="form-group">
                        <label>
													Password
													<RequiredLabel/>
												</label>
                        <LoginField value={''} inputType={'password'} identifier={'password1'}
                                    ref="password1"
                                    placeHolder="type your password here"
                                    validations={
                                        {
                                            required: true,
                                            length: {min: 6},
                                            strength: {min: 2}
                                        }
                                    }
                                    passwordsMatch={this.state.passwordsMatch}
                        />
                    </div>
                    <div className="form-group">
                        <label>
													Re-type password
													<RequiredLabel/>
												</label>
                        <LoginField value={''} inputType={'password'} identifier={'password2'}
                                    ref="password2"
                                    placeHolder="type your password again"
                                    validations={
                                        {
                                            required: true,
                                            length: {min: 6},
                                            strength: {min: 2}
                                        }
                                    }
                                    passwordsMatch={this.state.passwordsMatch}
                        />
                    </div>
                    <div className="form-group">
                        <label>
													Language
													<RequiredLabel/>
												</label>
                        <SelectField value={unregisteredUser.language} multi={false} identifier={'language'} options={languages} {...standardBindings} />
                    </div>

                    <div className="form-group">
                        <div className="block">
                            <label className="top-label">Address</label>
                            <div className="block-children">
                                <div className="form-group">
                                    <label>
																			Country
																			<RequiredLabel/>
																		</label>
                                    <SelectField value={unregisteredUser['address']['country']} identifier={'country'} options={countriesOptions} {...standardBindings} />
                                </div>

                                <div className="form-group">
                                    <label>
																			City
																			<RequiredLabel/>
																		</label>
                                    <TextField value={unregisteredUser['address']['city']} identifier={'city'} {...standardBindings} />
                                </div>

                                <div className="form-group">
                                    <label>
																			Postal Code
																			<RequiredLabel/>
																		</label>
                                    <TextField value={unregisteredUser['address']['postalcode']} identifier={'postalcode'} {...standardBindings} />
                                </div>

                                <div className="form-group">
                                    <label>
																			Street
																			<RequiredLabel/>
																		</label>
                                    <TextField value={unregisteredUser['address']['street']} identifier={'street'} {...standardBindings} />
                                </div>

                                <div className="form-group">
                                    <label>
																			Nr
																			<RequiredLabel/>
																		</label>
                                    <TextField value={unregisteredUser['address']['nr']} identifier={'nr'} {...standardBindings} />
                                </div>

                                <div className="form-group">
                                    <label>Additional</label>
                                    <TextField value={unregisteredUser['address']['additional']} identifier={'additional'} {...notRequiredBindings} />
                                </div>

                                <div className="form-group">
                                    <label>Box</label>
                                    <TextField value={unregisteredUser['address']['box']} identifier={'box'} {...notRequiredBindings} />
                                </div>

                                <div className="form-group">
                                    <label>State</label>
                                    <TextField value={unregisteredUser['address']['state']} identifier={'state'} {...notRequiredBindings} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="block">
                            <label className="top-label">Communication</label>
                            {
                                !this.state.communicationCreating && !this.state.communicationEditing ?
                                    this.renderCommunication(this.values['communication']) :
                                    <div className="block-children">
                                        <div className="form-group">
                                            <label>
																							Type
																							<RequiredLabel/>
																						</label>
                                            <TextField value={this.state.selectedCommunication['type']} identifier={'communication.type'} {...communicationStandardBindings} />
                                        </div>

                                        <div className="form-group">
                                            <label>
																							Value
																							<RequiredLabel/>
																						</label>
                                            <TextField value={this.state.selectedCommunication['value']} identifier={'communication.value'} {...communicationStandardBindings} />
                                        </div>
                                    </div>
                            }

                            {
                                !this.state.communicationCreating && !this.state.communicationEditing ?
                                    <button onClick={this.addItem.bind(this, 'communication')}>Add new communication item</button> :
                                    <div className="form-actions">
                                        <button onClick={this.saveCommunication.bind(this)}>Save communication item</button>
                                        <button onClick={this.cancel.bind(this, 'communication')} className="cancel">Cancel</button>
                                    </div>
                            }
                        </div>
                    </div>

                    <button className="confirm" onClick={this.submitForm}>Complete your registration</button>
                </div> :
                <div>
                    No user found.
                </div>
        )
    }
}

export default graphql(unregisteredUser, {
    options: ({user}) => {
        return { variables: {
            userId: user._id
        }
        }}
}, {name: 'unregisteredUser'})(
graphql(updateRegistration, { name: 'updateUserFromRegistration' })(
connect(null, mapDispatchToProps)(RegisterForm)))