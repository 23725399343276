import * as React from "react";
import Icon from '@ant-design/icons';

const ReportSvg = ()=><svg
fill="none"
stroke="currentColor"
strokeLinecap="round"
strokeLinejoin="round"
strokeWidth={2}
viewBox="0 0 24 24"
height="1em"
width="1em"
>
<path stroke="none" d="M0 0h24v24H0z" />
<path d="M21 17 A4 4 0 0 1 17 21 A4 4 0 0 1 13 17 A4 4 0 0 1 21 17 z" />
<path d="M17 13v4h4M12 3v4a1 1 0 001 1h4" />
<path d="M11.5 21H5a2 2 0 01-2-2V5a2 2 0 012-2h7l5 5v2m0 3v4" />
</svg>

export const IconReport = (props) => (
    <Icon component={ReportSvg} {...props} />
  );
  


