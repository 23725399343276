/* Session data: userinfo, language, */
import { fetchProfile
} from '../actions/session';
import { LOGIN_BUSY,METEOR_LOGGED_IN, LOADING_COMPLETE,LOADING_USER, LOGGED_OUT,SET_USER,SWITCH_LANGUAGE, SWITCH_CURRENCY
    } from '../actions/session/actions';
import Meteor from '../../Meteor';

const initialState = {
    authenticating:false,
    authenticated:false,
    loading:false,
    error: null,
    user: null,
    language:'en',
    currency:'USD',
    clients:[]
};


export const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SWITCH_LANGUAGE:
            return {
                ...state,
                language:action.payload
            };
        case SWITCH_CURRENCY:
            return {
                ...state,
                currency:action.payload
            };
        case SET_USER:
            return {
                ...state,
                authenticating:false,
                authenticated:true,
                user:action.payload.user,
                language:action.payload.user ? action.payload.user.language : "en",
                clients:action.payload.clients,
                regions:action.payload.regions
            };

        case LOADING_USER:

            return {
                ...state,
                loading:true
            };
        case LOADING_COMPLETE:

            return {
                ...state,
                loading:false
            };
        case LOGIN_BUSY:
            return {
                ...state,
                authenticating:true
            };
        case METEOR_LOGGED_IN:
            return {
                ...state,
                authenticating:false,
                authenticated:action.payload
            };
        case LOGGED_OUT:
            return {
                authenticated:false,
                error:null,
                user:null,
                language:state.language,
                currency:state.currency
            };
        default:
            return state;
    }

};

let startup = true;

export function listenToLogin(store) {
    Meteor.autorun(() => {
        let userId = Meteor.userId();
        if(userId) {
            store.dispatch({
                type:METEOR_LOGGED_IN,
                payload:userId
            });
            const state = store.getState();

            if(state.session.user ===null || state.session.user.userAccountId != userId) {
                store.dispatch(fetchProfile());
            }
            startup=false;
        } else {
            store.dispatch({
                type:LOGGED_OUT
            });
            startup=false;
        }

    });
};



