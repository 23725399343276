import React from 'react';
import { FormattedMessage } from 'react-intl';
import Contact from './contact.jsx';


/* DISPLAY CONTAINER FOR ALL THE CONTACTS */
class ContactList extends React.Component {
	constructor(props) {
		super(props);
	}

	//let navigation.jsx know that the user clicked on "Contacts"-icon
	onContactsClicked() {
		this.props.onContactTitleClicked();
	}

	render() {
		const contacts = this.props.contacts && this.props.contacts.length > 0 && this.props.contacts instanceof Array ?
			this.props.contacts.map((contact, idx) => {
				return (<Contact {...contact} key={idx}/>);
			}) : null;

		return (
			<div>
				<div className="title" onClick={this.onContactsClicked.bind(this)}>
					<span>
						<FormattedMessage id="navigation.menu.contacts-incofin"/>
					</span>
				</div>
				{contacts}
			</div>
		);
	}
}

export default ContactList;