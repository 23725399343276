import React from 'react';
import classNames from 'classnames';


const isValidColumnProp = x => Number.isInteger(x) && x > 0 && x <= 12;

class GridItem extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
    	//console.log('GRID ITEM', nextProps, nextState);
    	return true;
		}

    render() {
        if (!this.props.children) return false;

        const { xs, sm, md, lg } = this.props;
        const itemClasses = classNames(
            'o-grid__item',
            {
                [`o-grid__item--xs-${xs}`]: isValidColumnProp(xs),
                [`o-grid__item--sm-${sm}`]: isValidColumnProp(sm),
                [`o-grid__item--md-${md}`]: isValidColumnProp(md),
                [`o-grid__item--lg-${lg}`]: isValidColumnProp(lg)
            }
        );

        return (
            <div className={itemClasses}>
                {this.props.children}
            </div>
        );
    }
}


export default GridItem;