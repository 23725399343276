import './loader.scss';
import React from 'react';
/**
 * DESCRIPTION: used intensively in the application
 *   => show a loading spinner/screen when Apollo is still busy fetching data (loading = true) from the db
 */

export default class Loader extends React.Component {
    render(){
        return <div className="load">
            <div className="dot"></div>
            <div className="outline">
							<span/>
						</div>
        </div>;
    }
}
