
import react, { useCallback,useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import RequiredLabel from '../../../RequiredLabel';
import { useEffect } from 'react';
import './financialPeriodItem.scss'
import classNames from 'classnames';


function validatePeriod(data) {
    if(!data) return { valid:false,errors:["start is required", "end is required"]};
    
    
    if(!data.start) {

    }
    
}

export const FinancialPeriodItem = ({data={}
    ,loading
    ,idx
    , onChange

})=> {

    const [isValid,setValid] = useState(true);
  
    const {
        start,end,
        financialYear, financialYearId,months,
        errors
    } = data;

    const validateChange = useCallback((newState)=> {
        
        if(onChange) {
            onChange(newState,isValid,idx)
        }
       
    },[onChange]);

   



    const onStartChange = useCallback((value)=> {
        if(!value) {
            validateChange({
                ...data,
                start:null
            });
        } else {
            var startAt = moment(value).startOf("month");
            var ending = data.end ? moment(""+data.end,"YYYYMMDD").endOf("month"): null
            validateChange({
                ...data,
                months:ending ? ending.diff(startAt,'months')+1 : null,
                start:parseInt(startAt.format("YYYYMMDD"))
            });
           
        }

    },[data,validateChange]);

    const onEndChange = useCallback((value)=> {
        if(!value) {
            validateChange({
                ...data,
                financialYear:"-",
                financialYearId:null,
                end:null
            });
        } else {
            var ending = moment(value).endOf("month");
            var startAt = data.start ? moment(""+data.start,"YYYYMMDD"): null
            validateChange({
                ...data,
                financialYear:ending.year(),
                financialYearId:parseInt(`${ending.year()}00`),
                months:startAt ? ending.diff(startAt.startOf("month"),'months')+1 : null,
                end:parseInt(ending.format("YYYYMMDD"))
            });

        }
       

      


    },[data,validateChange]);



//  <button className='cancel' onClick={onDeleteClick}>X</button>
    
        return <div className={classNames('fperiod-item',{
            'fperiod-item-invalid':errors && errors.length>0
        })}>
          
            <div className='fperiod-item-title'>Financial Year: {financialYear} (#{months} Months)
            </div>
            { errors && errors.length>0 ? <div className='fperiod-item-errors'> 
                <ul>{errors.map(([field,msg],eidx)=> <li key={idx.toString()+eidx}>{msg}</li>)}</ul>
                </div> : null}
            <div className='fperiod-item-body'>
            <div className="form-group">
					<label> Start date
						<RequiredLabel />
					</label>
					<DatePicker
      selected={moment(""+start,"YYYYMMDD").toDate()}
      onChange={onStartChange}
      dateFormat="MMMM yyyy"
      showMonthYearPicker
      showFullMonthYearPicker
      showTwoColumnMonthYearPicker
    />
				</div><div> - </div>
                <div className="form-group">
					<label> End date
						<RequiredLabel />
					</label>
					<DatePicker
      selected={moment(""+end,"YYYYMMDD").toDate()}
      onChange={onEndChange}
      dateFormat="MMMM yyyy"
      showMonthYearPicker
      showFullMonthYearPicker
      showTwoColumnMonthYearPicker
    />
				</div>
                </div>
        </div>
}