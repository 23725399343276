import { gql } from '@apollo/client';

const invitationQuery = gql`query invitation($inviteToken: String, $verifyToken: String) { 
    invitedUser(inviteToken: $inviteToken, verifyToken: $verifyToken) {
        _id
        fullName
        email
        invitation {
            _id
            mailId
            lastSendOfInvite
            expirationDate
            fulfilled
            invitationToken
            validationToken
            creator
            sents
            bounced
        }
    }
  }
`;

export default invitationQuery;