import {useRef} from "react";
import useAutosizeTextArea from "../../hooks/useAutosizeTextarea";


export const TextAreaAuto =({
    value,
    onChange
}) => {

    const textAreaRef = useRef(null);

    useAutosizeTextArea(textAreaRef.current, value,3);


    return <textarea ref={textAreaRef}
    value={value}
    onChange={onChange}
    ></textarea>
  

}


export default TextAreaAuto;