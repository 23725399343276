import React from 'react';


/* DISPLAY SIMPLE CONTACT INFORMATION (NAME AND ROLE) */
class Contact extends React.Component {
	constructor(props) {
		super(props)
	}

	shouldComponentUpdate(nextProps) {
		return this.props.name !== nextProps.name || this.props.role !== nextProps.role || this.props.email !== nextProps.email;
	}

	render() {
		const {
			name,
			role,
			email
		} = this.props;

		return (
			<div className="contact">
				<a className="name" href={'mailto:' + email}>{name}</a>
				<span className="role">{role}</span>
			</div>
		);
	}
}

export default Contact;