import { useQuery } from "@apollo/client"
import { useContext } from "react"
import { ClientContext } from "../../ui/containers/client"
import { formConfigQuery } from "../../graph/queries/form/form"
import { FormDataColumn } from "./View/FormDataColumn"
import financeQuery from "../../graph/queries/form/finance"
import { FormLabelColumn } from "./View/FormLabelColumn"
import { FormFieldColumn } from "./Edit/FormFieldColumn"
import { FormFlatContent } from "./FormTree"


const FormWrapper = ({clientId,config,numerics})=> {
	
	
	const { data, loading } = useQuery(financeQuery,{
		variables:{
			clientId:clientId,
			formConfigId:config.key,
			range:numerics,
			currency:"USD"

		}
	})

	if(loading) return <div>loading</div>
	let dataSet = data?.financeQuery || [];
	return <>
	{dataSet.map((f,idx)=>FormDataColumn({config,enableToggle:false,data:f,idx}))}
	</>
}

export const FormDataEntry = ({
	config, numerics
}) => {

	if(!config) return <div></div>;

	return <div className="form-tree">
		<div className="form-tree-editor">

            <FormLabelColumn key={config.key+"_entry_labels"} enableToggle={false} config={config} />
        <FormFieldColumn key={config.key+"_fields"} enableToggle={false} config={config} />
       
		</div>
	</div>

}

export const FormReportEntry = ({config,renderField})=> {
    if(!config) return <div></div>;



	return <div className="form-tree">
		<div className="form-tree-editor">

            <FormLabelColumn key={config.key+"_entry_labels"} enableToggle={false} config={config} />
        <FormFieldColumn key={config.key+"_fields"} enableToggle={false} config={config} renderField={renderField} />
       
		</div>
	</div>
}


export const FormDataEntryLoad = ({
	configKey, numerics
}) => {

	const clientContext = useContext(ClientContext)

	const {data,loading} = useQuery(formConfigQuery,{
		variables:{
			key:configKey
		}
	})

	if(loading) return <div>loading</div>
	if(!data) return <div className="form-tree"></div>
	
	return data?.formConfig  ? <FormDataEntry config={data.formConfig} /> : null;

}