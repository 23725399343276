import { gql } from '@apollo/client';

const regionsQuery = gql`query regions { 
    
    regions {
        _id
        region
    }
  }
`;

export default regionsQuery;