import './InvestmentForm.scss';
import moment from 'moment';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import AuthHelper from '../../../auth/AuthHelper';
import CovenantList from '../CovenantList/covenant-list';
import Display from '../display/index';
import InvestmentPeriodicityField from './InvestmentPeriodicityField';


class InvestmentForm extends React.Component {
	constructor(props) {
		super(props);

		this.permissions = this.setPermissions();
	}

	setPermissions() {
		const investmentIsActive = !moment().isAfter(moment(this.props.values['firstDisbursementDate'], 'DD/MMM/YYYY'));
		const investmentIsFinal = moment().isAfter(moment(this.props.values['finalMaturityDate'], 'DD/MMM/YYYY'));
		let contextString = investmentIsFinal ? 'investment.inactive.covenants' : (investmentIsActive ? 'investment.active.covenants' : 'investment.covenants');
		let waiverContextString = investmentIsFinal ? 'investment.inactive.covenants.waivers' : (investmentIsActive ? 'investment.active.covenants.waivers' : 'investment.covenants.waivers');

		return {
			isAdmin: AuthHelper.isGranted('level', 'ADMIN'),
			isClient: AuthHelper.isGranted('level', 'CLIENT'),
			isIncofin: AuthHelper.isGranted('level', 'INCOFIN'),
			canReadCovenants: AuthHelper.isGranted(contextString, 'READ'),
			canReadWaivers: AuthHelper.isGranted(waiverContextString, 'READ'),
			canAddCovenants: AuthHelper.isGranted(contextString, 'ADD'),
			canAddWaivers: AuthHelper.isGranted(waiverContextString, 'ADD'),
			canEditCovenants: AuthHelper.isGranted(contextString, 'EDIT'),
			canEditWaivers: AuthHelper.isGranted(waiverContextString, 'EDIT'),
			canDeleteCovenants: AuthHelper.isGranted(contextString, 'DELETE'),
			canDeleteWaivers: AuthHelper.isGranted(waiverContextString, 'DELETE')
		};
	}

	render() {
		const {
			values,
			error,
			changeInvestmentPeriodicity,
			addCovenant,
			deleteCovenant,
			saveCovenant,
			deleteWaiver,
			saveWaiver
		} = this.props;

		return (
			<div className="investment-form">
				<div className="investment-detail">
					<div className="investment-detail-item">
						<label>
							<FormattedMessage id="grid.investments.columns.country"/>
						</label>
						<Display text={values['country']}/>
					</div>

					<div className="investment-detail-item">
						<label>
							<FormattedMessage id="grid.investments.columns.client"/>
						</label>
						<Display text={values['client'].name}/>
					</div>

					<div className="investment-detail-item">
						<label>
							<FormattedMessage id="grid.investments.columns.fund"/>
						</label>
						<Display text={values['fund'].name}/>
					</div>

					<div className="investment-detail-item">
						<label>
							<FormattedMessage id="grid.investments.columns.product"/>
						</label>
						<Display text={values['product'].name}/>
					</div>


					<div className="investment-detail-item">
						<label>
							<FormattedMessage id="grid.investments.columns.currency"/>
						</label>
						<Display text={values['currency']}/>
					</div>

					<div className="investment-detail-item">
						<label>
							<FormattedMessage id="grid.investments.columns.outstandingAmount"/>
						</label>
						<Display text={values['outstandingAmountString']}/>
					</div>

					<div className="investment-detail-item">
						<label>
							<FormattedMessage id="grid.investments.columns.firstDisbursementDate"/>
						</label>
						<Display text={values['firstDisbursementDate']}/>
					</div>

					<div className="investment-detail-item">
						<label>
							<FormattedMessage id="grid.investments.columns.finalMaturityDate"/>
						</label>
						<Display text={values['finalMaturityDate']}/>
					</div>

					<div className="investment-detail-item">
						<label>
							<FormattedMessage id="grid.investments.columns.lastReportedMonth"/>
						</label>
						<Display text={values['lastReportedMonth']}/>
					</div>
					<InvestmentPeriodicityField periodicity={values['reportingPeriodicity']}
					                            canEdit={this.permissions.isIncofin}
					                            changeInvestmentPeriodicity={changeInvestmentPeriodicity}/>
					{
						values['endedAt'] ?
							<div className="investment-detail-item">
								<label>
									<FormattedMessage id="grid.investments.columns.endedAt"/>
								</label>
								<Display text={values['endedAt']}/>
							</div> : null
					}
				</div>

				<div className="investment-covenants">
					<label>
						<FormattedMessage id="navigation.menu.administration.covenants"/>
					</label>
          {/* feature/issue-126 */}
					<CovenantList covenants={values['covenants']} permissions={this.permissions} error={error}
					              addCovenant={addCovenant} deleteCovenant={deleteCovenant} saveCovenant={saveCovenant}
					              deleteWaiver={deleteWaiver} saveWaiver={saveWaiver}
					              investmentStartDate={parseInt(moment(values['firstDisbursementDate'], 'DD/MMM/YYYY').format('YYYYMMDD'))}
					              investmentEndDate={parseInt(moment(values['finalMaturityDate'], 'DD/MMM/YYYY').format('YYYYMMDD'))} clientId={values['client']._id}/>
				</div>
			</div>
		);
	}
}

export default InvestmentForm;
