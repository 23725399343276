import AdminClientFormPage from './ClientFormPage';
import AdminUserFormPage from './UserFormPage';
import AdminExchangeRatePage from './ExchangeRatePage';
import AdminReportsPage from './reports';
import AdminAuditsPage from './audits';
import {AdminsCovenantTypes} from './covenantConfigs';
import { UserIsAdmin} from '../../../auth/AuthWrappers';
import { Route,Switch } from 'react-router-dom';
import { CovenantConfigEdit } from './covenantConfigs/type/edit';
import { CovenantConfigAdd } from './covenantConfigs/type/add';
import AdminCovenantsList from './covenantConfigs/type'

export const AdminRoute = ({match})=> {

    return  <UserIsAdmin>
    <Switch>
    <Route path={`${match.path}/clients`} exact  component={AdminClientFormPage} />
                <Route path={`${match.path}/users`} exact component={AdminUserFormPage} />
                <Route path={`${match.path}/audits`} exact component={AdminAuditsPage} />
                <Route path={`${match.path}/exchangerates`} exact component={AdminExchangeRatePage} />
                <Route path={`${match.path}/reports`} exact component={AdminReportsPage} />
                <Route path={`${match.path}/covenants`} exact component={AdminsCovenantTypes} />
                <Route path={`${match.path}/covenants/:type`} exact component={AdminCovenantsList} />
                <Route path={`${match.path}/covenants/:type/add`} exact component={CovenantConfigAdd} />
                <Route path={`${match.path}/covenants/:type/:id`} exact component={CovenantConfigEdit} />
              
    </Switch>
</UserIsAdmin>
}

/*
  <Route path={`${match.path}/covenants/:type`} exact component={AdminConvenantConfigs} />
                
*/