import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { math } from "../../mathjs"
import { Alert, Tag } from "antd";
import classNames from "classnames";
import { useConfigCache } from "../../../../hooks/useApp";
import uniqolor from "uniqolor";
import "./viewer.scss"
import IconMathFunction from "../../../Form/FunctionIcon";
import { ReportingScope } from "../../scope/ReportingScope";
import { FormulaScope } from "../../scope/FormulaScope";
import { FormulaContext } from "../../hooks/formulaContext";
import { useIntl } from "react-intl";
import { PauseOutlined } from "@ant-design/icons";
import { parseRangeText, transformMultiPeriod } from "../../transform/multiPeriod";
import { transformDefinitionToExec } from "../../transform/transformFormulaDefinition";
import { useCurrency } from "../../../../hooks/useCurrency";


const FormulaViewerDetailContext = createContext({showDetail:true});


const useFormulaViewerDetailProvider = (defaultShow=false)=> {

    const [showDetails,setShowDetails] = useState(defaultShow);

    const toggle = useCallback(()=> {
       
            setShowDetails((s)=>!s);
       
    },[])

    return {
        Provider:({children})=><FormulaViewerDetailContext.Provider value={showDetails}>{children}</FormulaViewerDetailContext.Provider>,
        showDetails,
        toggle
    }
}

const useFormulaViewerDetail = ()=> {
    const show = useContext(FormulaViewerDetailContext);
    return show;
}

const useFormulaRawResult = (node)=> {

    const intl = useIntl();
    const {settings:data} = useContext(FormulaContext) || {};

    const [result,setResult] = useState(null);

    useEffect(()=> {
        if(!!data?.scope) {
           
            let val = node.evaluate(data?.scope);
            
            if(val==null || typeof(val)=="undefined") {
                setResult("-n.a.-")
            } else {
                if(typeof val === 'number') {
                    val = intl.formatNumber(val, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })
                } 
                setResult(val)
                               
            }
        } else {
            setResult(null)
        }
    },[node,data]);

    return {
        hasScope:!!data?.scope,
        result
    }
}


const useViewerScope = (node,fieldConfig,existsCheck=false)=> {
    const intl = useIntl();
    const currency = useCurrency();
    const {settings:data} = useContext(FormulaContext) || {};

    const [calc,setCalc] = useState(null);

    const fieldType = useMemo(()=> {
       // debugger;
        if(fieldConfig && data?.fields) {
            let fld = data?.fields.get(`${fieldConfig.config}|${fieldConfig.path}`)
        
            if(fld) {
                return fld.settings?.fieldType || fld.fieldType;
            }
        } 
        return null;
    },[fieldConfig,data?.fields])

    useEffect(()=> {
        if(!!data?.scope) {
           
            let val = node.evaluate(data?.scope);
            //console.log("viewerScope",{fieldType,fieldConfig,val});
           
            if(typeof(val)==="number") {
                if(fieldType=="percentual") {
                    // change to percentage (saved in calculation mode in 0-1)
                    val = val * 100
                } else {
                  //  val= val/100
                }
                val = intl.formatNumber(val, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				})
                if(fieldType=="currency") {
                    if(fieldConfig?.config) {
                       // debugger;
                        let cur = currency;
                        //data.scope.get(["current",fieldConfig.config,"settings","currency"]);
                        val= `${val} ${cur}`
                    }
                } else if(fieldType=="percentual") {
                    val= `${val}%`
                } else {
                   
                    val = val;
                    
                }

            }
            if(val==null || typeof(val)=="undefined") {
                setCalc("-n.a.-")
            } else {
                if(typeof(val) === 'object') {
                    val = val != null && val !=undefined
                }
                setCalc(val)
            }
        } else {
            setCalc(null);
        }
    },[data?.scope,node,fieldType,fieldConfig,currency])
    //debugger;
    return {
        hasScope:!!data?.scope,
        calc
    }
}

const useViewerScopeResult = (node) => {
    const {settings = {}} = useContext(FormulaContext) || {};
    //const { config,path } = settings?.target;

    const fieldConfig = settings?.target;
    /*useMemo(()=> {
       

        if(settings?.target) {
            let p = path.startsWith(configKey+".") ? path.substring(configKey.length+1) : path;
            //debugger
            return {
                config:configKey,
                path:p
            }
        }
        return null;
    },[settings?.target])*/

    const transformed = useMemo(()=>transformDefinitionToExec(node),[node])
   
    return useViewerScope(transformed,fieldConfig)
}

const PeriodSelectNode = ({period})=> {

    return <span className="fn-period">-{period}</span>
}

const PeriodAverageNode = ({node})=> {
    const showDetail = useFormulaViewerDetail();
    const { hasScope,result } = useFormulaRawResult(node)
    const { Provider,toggle } = useFormulaViewerDetailProvider(false);


    const body = node.args[0];
    const amount = node.args[1]?.value;
    let periodType = node.args[2]?.value;
    switch(periodType) {
        case "M":{
            periodType="Month(s)";
            break;
        }
        case "Y":{
            periodType="Calendar year(s)";
            break;
        }
        case "FY":{
            periodType="Financial year(s)";
            break;
        }
    }

    return <span className="fn-item fn-function">
        <span className="fn-item-body"><IconMathFunction/><span className="fn-function-name">{node.name} <span className="fn-highlight-info"> over the past {amount} {periodType}</span></span><span className="fn-function-body"><Parenthesis><Provider>{formulaToReact(body)}</Provider></Parenthesis></span>
        </span> 
        {hasScope && showDetail ?  <span className="fn-item-value">{result}</span>  : null}
    </span>

}



//multiPeriod(\"1-12M\", meanLoose(), field(\"agroProfitLoss\", \"ebitda\")
const MultiPeriodNode = ({node})=> {

    
    const rangeText = node.args[0]?.value;
    const { range, unit } = parseRangeText(rangeText);
    const fn = node.args[1];
    const fnbody = node.args[2];
   
    let periodType="-"
    switch(unit) {
        case "M":{
            periodType="Months";
            break;
        }
        case "Y":{
            periodType="Calendar years";
            break;
        }
        case "FY":{
            periodType="Financial years";
            break;
        }
    }

    const transFormed = useMemo(()=>transformMultiPeriod(node),[node]);

    const showDetail = useFormulaViewerDetail();
    const { hasScope,result } = useFormulaRawResult(transFormed)
    const { Provider,toggle,showDetails } = useFormulaViewerDetailProvider(false);

   // const {hasScope,calc} = useViewerScope(transFormed,vwConfig);
    

   
  

    return <span className="fn-item fn-function fn-function-multiperiod">
         <span className="fn-item-body"><IconMathFunction/><span onClick={toggle} className="fn-function-name">{node.name} <span className="fn-highlight-info"> from -{range?.to} to -{range?.from} {periodType}</span></span>
    <span className="fn-function-body">
    <Provider> { showDetails ?
        <Parenthesis>{formulaToReact(transFormed)}</Parenthesis>
        : <span className="fn-multiperiod-short" >[ {formulaToReact(fn)},
        <Parenthesis>{formulaToReact(fnbody)}</Parenthesis> ]</span>
        }
        </Provider>
        </span>
        </span>
        {hasScope && showDetail ?  <span className="fn-item-value">{result}</span>  : null}
        </span>

}

const VariableNode = ({node})=> {

    const {color,translator} = useConfigCache();
    const [showFull,setShowFull] = useState(false);

    const toggle = useCallback(()=> {
        setShowFull(!showFull);
    },[showFull]);
    
    const config = node.args[0]?.value;
    const path = node.args[1]?.value;
    const period = node.args[2]?.value;// || "current";

    const vwConfig = useMemo(()=> {
        return { config,path}
    },[ config,path])

    const {hasScope,calc} = useViewerScope(node,vwConfig);
    // deconstruct arguments
    const showDetail = useFormulaViewerDetail();

    const transPath = translator.field(config,path);
    const transConfig = translator.config(config);
    const configColor = color(config);
    // move to central
    //debugger;
   console.log("variableNode",{transPath,transConfig,configColor,path,calc})
    


    return <span onClick={toggle} className="fn-item fn-variable" style={{
        color:configColor.color,
       // color:configColor.isLight ? "#000" : "#fff"
    }}>
            <span className="fn-item-body fn-variable-formula">
            {period ? <PeriodSelectNode 
            style={{
                color:configColor.color
            }}
            period={period}/> : null}
            <span className="fn-var-config"
                style={{
                    backgroundColor:configColor.color,
                    color:configColor.isLight ? "#000" : "#fff"
                }}
            >
                {showFull ? transConfig : transConfig.slice(0,2) }
            </span>
            <span style={{
                 color:configColor.color,
            }}  className="fn-var-path">
                {showFull ? transPath.join(" > ") : transPath[transPath.length-1]}
            </span>
            </span>
           
            {hasScope && showDetail ?  <span className="fn-item-value">{calc}</span>  : null} 
        </span> 
}

const OperatorNode = ({node})=> {

    const childs = node.args.map(formulaToReact);

    const op = <span className={"fn-operator-char fn-operator-" + node.fn}> {node.op} </span>

    return <span className="fn-operator">{childs[0]} {op} {childs[1]}</span>
}
const ConstantNode = ({node})=> {

    const intl = useIntl();
    const ct = typeof(node.value) == "string" ? <span className="fn-constant-string">&quot;{node.value}&quot;</span> : <span className="fn-constant-number">{node.value ?  intl.formatNumber(node.value, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }):node.value}</span>


    return <span className="fn-constant"> {ct} </span>
}

function jsxJoin(children,separator) {
    return children.map((child, index) => (
        <React.Fragment key={index}>
            { !!index && separator }
            { child }

        </React.Fragment>
    ))
}


const FunctionNode = ({node})=> {

//showDetail

    const showDetail = useFormulaViewerDetail();
    const { hasScope,result } = useFormulaRawResult(node)
    const { Provider,toggle } = useFormulaViewerDetailProvider(false);

    const childs = node.args.map(formulaToReact);

    return <span className="fn-item fn-function">
        <span className="fn-item-body "><IconMathFunction/><span onClick={toggle} className="fn-function-name fn-item-toggle">{node.name}</span><span className="fn-function-body"><Provider><Parenthesis>{jsxJoin(childs,<span>, </span>)}</Parenthesis></Provider></span></span>
        {hasScope && showDetail ?  <span className="fn-item-value">{result}</span>  : null}
        </span>
}

const ConditionalNode = ({node})=> {
    
    const {hasScope,result: conditionResult} = useFormulaRawResult(node.condition)
    const cond = formulaToReact(node.condition);
    const yes = formulaToReact(node.trueExpr);
    const no = formulaToReact(node.falseExpr);

    const cls = classNames("fn-conditional",{
        "fn-conditional-result-true" : hasScope && conditionResult,
        "fn-conditional-result-false" : hasScope && !conditionResult,
        
    })

    const resultAddendum = hasScope ? conditionResult ? <span className="fn-condition-value"> = TRUE</span> : <span className="fn-condition-value"> = FALSE</span> : null

    return <span className={cls}>
        <span className="fn-condition"><span className="fn-condition-title">IF</span> <span>{cond}</span> {resultAddendum}</span>
        
        <span className="fn-condition-true"><span className="fn-condition-title">THEN </span> <span className="fn-condition-case">{yes}</span></span>
        <span className="fn-condition-false"><span className="fn-condition-title">ELSE </span> <span className="fn-condition-case">{no}</span></span>
        </span>
}


const ExistsFunctionNode = ({node})=> {

    //formulaToReact.excludeValues=true;
    const childs = node.args.map(formulaToReact);
    const showDetail = useFormulaViewerDetail();
    const { hasScope,result } = useFormulaRawResult(node)
    //formulaToReact.excludeValues=false;

    return <span className="fn-item"><span className="fn-item-body fn-function"><IconMathFunction/><span className="fn-function-name">{node.name}</span><span className="fn-function-body"><Parenthesis>{jsxJoin(childs,<span>, </span>)}</Parenthesis></span></span>
        {hasScope && showDetail ?  <span className="fn-item-value">{result?.toString()}</span>  : null}
    </span>
}

function functionToReact(node,idx) {
    console.log("FN NODE",node.name)
    switch(node.name) {
        case "field": {
            return <VariableNode node={node} excludeValue={formulaToReact.excludeValues===true}/>
        }
        case "periodAverage":{
            return <PeriodAverageNode node={node}/>
        }
        case "multiPeriod":{
            return <MultiPeriodNode node={node}/>;
            
        }
        case "exists":{
            
            
            return <ExistsFunctionNode node={node}/>;
        }
        default: {
            return <FunctionNode node={node}/>
        }
    }
}

const Parenthesis = ({children})=> {
   
    const style = {
        color:uniqolor.random().color
    }

    return <span className="fn-parenthesis-block"><span style={style} className="fn-parenthesis fn-p-open">(</span><span className="fn-parenthesis-body">{children}</span><span style={style} className="fn-parenthesis fn-p-close">)</span></span>
}

const ParenthesisNode = ({node})=> {
   
    return <Parenthesis>{formulaToReact(node.content)}</Parenthesis>
}

function formulaToReact(node,idx=0) {

    switch (node.type) {
        case 'OperatorNode':
            return <OperatorNode key={idx} node={node}/>
          break
        case 'ConstantNode':
            return <ConstantNode node={node}/>
          break
        case 'FunctionNode':
            return functionToReact(node,idx);
            break
        case 'ParenthesisNode':
            return <ParenthesisNode node={node}/>
            break
        case 'ConditionalNode': 
            return <ConditionalNode node={node}/>;

        case 'SymbolNode':
            return <span>symbol type {node.name} {node.value}</span>
          break
        default:
          return <span>Missing type {node.type}</span>
      }
}

const ResultNode = ({node}) => {

    const {hasScope,calc} = useViewerScopeResult(node);
   
    return hasScope ? <div className="fn-formula-result"><span>=</span> {calc}</div> : null;
}


export const FormulaViewer = ({formula,scope})=> {
    
    const { Provider } = useFormulaViewerDetailProvider(true);

    if(!formula) return <></>

    let parsed = math.parse(typeof(formula)==="object" ? formula.formula : formula);   
  

    return <Alert.ErrorBoundary> <div className="fn-formula">
            <Provider>
            {formulaToReact(parsed)}
            </Provider>
            <ResultNode node={parsed}/>
            
    </div></Alert.ErrorBoundary>;

}