import { compose, createStore, combineReducers, applyMiddleware } from 'redux';
import { browserHistory } from 'react-router';
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql
  } from "@apollo/client";

import * as reducers from './reducers';
import { createApolloClient} from '../graph/client'
//import { listenToLogin } from './reducers/session';




const middleware = [thunk];


let devtools;

if (process.env.NODE_ENV === 'development') {
    // Add any middleware for dev environment only
    if (window.devToolsExtension) {
        devtools = window.devToolsExtension();
    } else {
        middleware.push(createLogger());
        devtools = f => f;
    }
} else {
    devtools = f => f;
}


  



export const store = createStore(
    combineReducers(reducers),
    compose(applyMiddleware(...middleware),
    devtools)
);

export const apolloClient =createApolloClient(store);




