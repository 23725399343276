import './field.scss';
import React from 'react';
import validator from 'validator';
var zxcvbn = require('zxcvbn');

/**
 * DESCRIPTION: this field is only used in the login page
 * 		=> why not use existing fields? very specific HTML & CSS & custom methods/props
 *  	=> input type can be "text" or "password"
 */
export default class LoginField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focus:this.props.autoFocus===true,
            invalid:false,
            invalidText:null
        };
        this.inputRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        //if(this.props.passwordsMatch !== nextProps.passwordsMatch) this.setState({passwordsMatch: nextProps.passwordsMatch});
    }

    //onFocus binding of the input element
    onFocus() {
        this.setState({focus:true});
    }

    //onBlur binding of the input element
    onBlur() {
        this.setState({focus:false});
        this.validate();
    }

    //validate this field, return valid --> if invalid, set specific invalidText
    validate() {
        let valid = true;
        let txt = [];
        let val = this.getValue();

        if(this.props.validations) {
            let vlds = this.props.validations;

            if(vlds.required) {
                if(validator.isEmpty(val)) {
                    valid=false;
                    txt.push('field is required');
                }
            }
            if(vlds.length) {
                if(!validator.isLength(val,vlds.length)) {
                    valid = false;
                    txt.push('minimal length is ' + vlds.length.min);
                }
            }
            if(vlds.strength) {
                let result = zxcvbn(val);
                let strength = {
                    0: "Bad",
                    1: "Weak",
                    2: "Ok",
                    3: "Good",
                    4: "Strong"
                }

                if (result.score < vlds.strength.min) {
                    valid = false;
                    txt.push(strength[result.score]);
                    txt.push(result.feedback.suggestions[0]);
                } else {
                    valid = true;
                }
            }
            /*if(!this.props.passwordsMatch) {
                txt.push(`Passwords don't match`);
                valid = false;
            }*/
        }

        this.setState({invalid:!valid,invalidText:txt.join(', ')});
        return valid;
    }

    //return current value of the input element
    getValue() {
        return this.inputRef.current.value;
    }

    //render method
    render() {
        const {
            fieldName,
            autoFocus,
            label,
            placeHolder,
            inputType,
            disabled=false
        } = this.props;

        const {
            focus,
            invalidText
        } = this.state;

     

        const fieldInput = inputType==="password" ?
            ( <div className="pw-wrapper"> <input disabled={disabled} ref={this.inputRef} type={inputType} name={fieldName} placeholder={placeHolder} autoFocus={autoFocus} onBlur={this.onBlur.bind(this)} onFocus={this.onFocus.bind(this)} maxLength="33"/></div>)
            : (<input ref={this.inputRef} disabled={disabled}  type={inputType} name={fieldName} placeholder={placeHolder} autoFocus={autoFocus} onBlur={this.onBlur.bind(this)} onFocus={this.onFocus.bind(this)}/>);


        let classNames = 'field-wrapper';
        if(focus) classNames += ' hasfocus';
        if(invalidText && invalidText!=="") {
            classNames += ' invalid';
        }

        return (
            <div className={classNames}>
                <div className="field-container">
                    { label != null ?  <label htmlFor={fieldName}>{label}</label> : null }
                    {fieldInput}
                </div>
                <div className="invalid-text">{invalidText}</div>
            </div>
        );
    }
}