import store from './store';

export let latestClients = new store('latest-visited-clients');

export let userSettings = new store('user-settings');

export const createStores = async function() {
    //latestClients= await store.create('latest-visited-clients');
    //userSettings = await store.create('user-settings');
    
}


/*
localForage.ready().then(function() {
    // This code runs once localforage
    // has fully initialized the selected driver.
    latestClients.setReady();
    userSettings.setReady();
    console.log(localForage.driver()); // LocalStorage
}).catch(function (e) {
    console.log(e); // `No available storage method found.`
    // One of the cases that `ready()` rejects,
    // is when no usable storage driver is found
});
*/

