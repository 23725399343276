import React, { useCallback } from "react";
import './tiles.scss'


export const Tiles = ({history,tiles=[]})=> {


    const onTile = useCallback((idx)=> {
       if(tiles[idx] && tiles[idx].url) {
        history.push(tiles[idx].url)
       }
    },[tiles,history])

    return <div className="tiles">
        {tiles.map((t,idx)=> <div key={"tile-"+idx} onClick={()=>onTile(idx)}>{t.title}</div>)}
    </div>
}

