import { gql } from '@apollo/client';

export const createWaiver = gql`mutation createWaiver($investmentId: String!, $covenantId: String!, $waiver: Object!) {
	createWaiver(investmentId:$investmentId, covenantId:$covenantId, waiver:$waiver) {
		waivers {
			_id
			waiverType
			centralCovenant {
				_id
				name
				guide
				links {
					uri
					name
					guide
				}
				fieldType
				range {
					min
					max
				}
				alertThreshold 
			}
			range {
				min
				max
			}
			alertThreshold
			startDate
			endDate
		}
		response {
			success
			error {
				code
				message
			}
		}
	}
}
`;

export const updateWaiver = gql`mutation updateWaiver($investmentId: String!, $covenantId: String!, $waiverId: String!, $waiver: Object!) {
	updateWaiver(investmentId:$investmentId, covenantId:$covenantId, waiverId:$waiverId, waiver:$waiver) {
		response {
			success
			error {
				code
				message
			}
		}
		waiver {
			centralCovenant {
				_id
				name
				guide
				fieldType
				range {
					min
					max
				}
				alertThreshold 
			}
		}
	}
}
`;

export const deleteWaiver = gql`mutation deleteWaiver($investmentId: String!, $covenantId: String!, $waiverId: String!) {
	deleteWaiver(investmentId:$investmentId, covenantId:$covenantId, waiverId:$waiverId) {
		success
		error {
			code
			message
		}
	}
}
`;

export const validateWaiver = gql`mutation validateWaiver($investmentId: String!, $covenantId: String!, $waiverId: String!) {
	validateWaiver(investmentId:$investmentId, covenantId:$covenantId, waiverId:$waiverId) {
		success
		error {
			code
			message
		}
	}
}
`;
