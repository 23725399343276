import React from 'react';

export default class CustomLabel extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			x,
			width,
			height,
			y,
			fill,
			stroke,
			label,
			orientation
		} = this.props;

		return (
			<g className="recharts-cartesian-axis-label">
				<text width={width} height={height} x={orientation === 'left' ? x + 25 : x} y={y} stroke={stroke} fill={fill} className="recharts-text">
					<tspan dy="-1.5em">{label}</tspan>
				</text>
			</g>
		);
	}
}