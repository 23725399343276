import react, { useCallback,useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FinancialPeriodItem } from './FinancialPeriodItem';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { updateFinancialPeriods } from '../../../../../graph/mutations/clients';
import { FormattedMessage } from 'react-intl';
import Info from '../../../Info/info';


function toMoment(numDate) {
    return moment(""+numDate,"YYYYMMDD")
}

function toNumeric(momDate) {
    return parseInt(momDate.format("YYYYMMDD"));
}

function validatePeriods(periods=[],defaultSettings ={
    month:12,
    day:31
}) {
    if(!periods) periods = [];
    var isValid = true;
    var sortedPeriods = [...periods].sort((a,b)=>a.end-b.end);
    for(let i=0;i<sortedPeriods.length;i++) {
        var period = sortedPeriods[i];
        period.errors = [];
        if(!period.start) {
            
            period.errors.push(['start','Start of period is required']);
        }
        if(!period.end) {
            period.errors.push(['end','End of period is required']);
        }
        if(period.end<=period.start) {
            period.errors.push(['start','Start of period must come before end']);
            period.errors.push(['end','End of period must come after start']);
        }
        if(i>0) {
            var prevPeriod = sortedPeriods[i-1];
            
            var expectedStart = toNumeric(toMoment(prevPeriod.end).add(1,"day"));
            if(period.start!=expectedStart) {
                period.errors.push(['start',`Start of period ${toMoment(period.start).format("DD/MM/YYYY")} does not follow on previous end of period ${toMoment(prevPeriod.end).format("DD/MM/YYYY")} `]);
            }
           
        }

        if(period.errors.length>0) {
            isValid=false
        }
        
    }
    console.log("period validations",isValid)

    return [isValid,periods];

}


export const FinancialPeriodsEditor = ({clientId,data=[],defaultSettings ={
    month:12,
    day:31
}, onClose
})=> {

    const [periods,setPeriodsState] = useState([]);
    const [isValid,setValidation] = useState(true);
    const [autoCorrect,setAutoCorrect] = useState(true);

    useEffect(()=> {
        const [validResult,cleanedData] = validatePeriods(data);
        setPeriodsState(cleanedData);
        setValidation(validResult);

    },[data,setPeriodsState,setValidation,validatePeriods]);

    const setPeriods = useCallback((newperiods,changedIdx)=> {
        let savePeriods =newperiods;
        if(autoCorrect && changedIdx!=null && changedIdx!=undefined) {
            let periodsAltered = [...newperiods.slice(0,changedIdx+1)];
            let prevPeriod = newperiods[changedIdx];
            for(let i=changedIdx+1;i<newperiods.length;i++) {
                let curPeriod = newperiods[i];
               
                let momStart = moment(prevPeriod.end.toString(),"YYYYMMDD").add(1,"day").startOf("month");
                let momEnd = moment(momStart).add(curPeriod.months-1,"months").endOf("month");
                let nwPeriod ={
                    start:parseInt(momStart.format("YYYYMMDD")),
                    end:parseInt(momEnd.format("YYYYMMDD")),
                    financialYear:momEnd.year(),
                    financialYearId:parseInt(`${momEnd.year()}00`),
                    months:curPeriod.months
                }
                periodsAltered.push(nwPeriod);
                prevPeriod = nwPeriod;
            }
          
            savePeriods=periodsAltered;
        } 
        const [validResult,cleanedData] = validatePeriods(savePeriods);
        setPeriodsState(cleanedData);
        setValidation(validResult)
    },[autoCorrect,periods,setPeriodsState.validatePeriods,setValidation]);
    

    const onAddClick = useCallback(()=> {
        var newperiods = [...periods];
        newperiods.sort((a,b)=> a.end-b.end);
        var end = moment([(new Date()).getFullYear(),defaultSettings.month-1,defaultSettings.day]).endOf("month");
            
        debugger;
        var maxPeriod = newperiods.length>0 ? newperiods[newperiods.length-1].end : null;

        if(!maxPeriod && end.isBefore(moment())) {
            end = end.add(1,"year")
        }
        var start = end.subtract(11,"months").startOf("month")
        if(maxPeriod) {
            start = moment(""+maxPeriod,"YYYYMMDD").add(1,"day");
            end = moment([start.year(),defaultSettings.month-1,defaultSettings.day]).endOf("month");
            if(end.isBefore(start)) {
                end = end.add(1,"year");
            }
            
        }

           
            setPeriods([
               ...newperiods,
               {
                start:parseInt(start.format("YYYYMMDD")),
                end:parseInt(end.format("YYYYMMDD")),
                financialYear:end.year(),
                financialYearId:parseInt(`${end.year()}00`),
                months:end.diff(start,"months")+1
            }
            ],newperiods.length)
        
            
       
    },[periods]);

    const onItemChange = useCallback((data,valid,idx)=> {
        var newPeriods = [
            ...periods.slice(0,idx),
            {
                ...data
            },
            ...periods.slice(idx+1)
        ]
       // const [validResult,cleanedData] = validatePeriods(newPeriods);
        setPeriods(newPeriods,idx);
       // setValidation(validResult);

    },[periods,setPeriods]);


    const [savePeriods, { loading:saving }] = useMutation(updateFinancialPeriods);
	
   // const save = useMutation();

    const onSaveClick = useCallback(()=> {
        if(isValid) {
            savePeriods({variables:{
                clientId,
                financialPeriods:periods.map(({errors,__typename,...rest})=>({...rest}))
            }}).then((result)=> {
                if(onClose) {
                    onClose(result.data.updateFinancialPeriods);
                }
            }).catch((err)=>{
                console.error(err);
                alert("Failed to save")
            })
           
        } 
    },[isValid,periods,onClose,clientId]);

    const onCancelClick = useCallback(()=> {
        if(onClose) {
            onClose();
        }
    },[onClose]);

   


    return <>
    {saving ? <div>Saving...</div> :  <div><button style={{
        float:'none'
    }} onClick={onAddClick}>ADD +</button></div>}
        <div> 
           <i className='fa fa-cog'></i> Enable Auto correct changes? <input type='checkbox' checked={autoCorrect} onChange={(evt)=>setAutoCorrect(!autoCorrect)}></input>
           <Info>
            { autoCorrect ? <span><b>Autocorrect enabled</b>: changing a period will recalculate all preceding periods based on their current duration of months</span>
            : <span><b>Autocorrect disabled</b>: regardless of change to one period, any other period remains untouched and is to be altered manually.</span>
            }  </Info> 
        </div>
        <div>
        {
            periods.map((p,idx)=> {
                return <FinancialPeriodItem locked={saving} key={idx} idx={idx} data={p} onChange={onItemChange}/>
            })
        }
    </div>
    <div className="form-actions">
					<button onClick={onSaveClick}  disabled={saving || !isValid}>
						<FormattedMessage id="app.buttons.save" />
					</button>
				
					<button onClick={onCancelClick}  disabled={saving} className="cancel" style={{margin: '0 1em'}}>
						<FormattedMessage id="app.buttons.cancel" />
					</button>
				</div>
    </>
}