import { gql } from '@apollo/client';

export const authCheckQuery = gql`query { 
    whoAmI {
        _id
    }
}`;

export const profileQuery = gql`query { 
    whoAmI {
        _id
        person {
            _id
            fullName
            email
            userAccountId
            language
            roles
        }
        roles
        permissions
        context
        clientKey
    } 
    clients {
        _id
        key
        name
        officialName
        status
        settings {
            financialPeriodEnd {
                day
                month
            }
            currency
            reportingScale
        }
        address {
            country
        }
        clientType
    }
    regions {
        _id
        region
        countries
    }
  }  
`;

export default profileQuery;
