import React, {useState, useCallback,useEffect} from 'react';
import { connect } from 'react-redux';
import Navigation from '../../components/navigation';
import Header from './header';

import {history} from '../../../router';
import { useHistory, useLocation } from 'react-router-dom';
import { FormulaDrawer } from '../../../features/NewFormula/components/Drawer';



// TODO split out header, nav and body !! (redux connect diffs)
//import LogLifeCycle from 'react-log-lifecycle';
/*
import ReactPerfTool from 'react-perf-tool';
import Perf from 'react-addons-perf';
*/
// TODO move to i18n

function mapStateToProps(state) {
	return {
		Clients: [],//state.session.user.clients,
		fullName: state.session.user ? state.session.user.fullName : null,
		language: state.session.language
	};
}


//graphql(auditLogOut, {name: 'auditLogOut'})
/*
class PortalLayout extends React.Component {  //LogLifeCycle { //
	constructor(props) {
		super(props);
		this.routePath = history.location.pathname;
		this.state = {
			collapsed: false,

			showClientOptions: false,
		};

		//if user already selected a language in previous sessions (localForage), show this in MenuItem component



		this.toggleCollapse = this.toggleCollapse.bind(this);
	}

	//only applicable for incofin users --> switching between clients should also update currency in MenuItem component
	componentWillReceiveProps(props) {
		const clientId = this.props.client ? this.props.client._id : null;
		if (this.state.clientId != clientId) {
			this.setState({selectedCurrency: props.client ? props.client.settings.currency : ''})
		}
	}

	//try to minimize component re-rendering
	shouldComponentUpdate(nextProps, nextState) {
		const clientId = nextProps.client ? nextProps.client._id : null;
		return nextProps.language != this.props.language || this.routePath != nextProps.location.pathname || this.state.clientId != clientId || nextState.selectedCurrency != this.state.selectedCurrency
			|| this.state.showClientOptions != nextState.showClientOptions || this.state.collapsed != nextState.collapsed;
	}

	//collapse navigation
	toggleCollapse() {
		this.adjustMenu();
	}

	//open up navigation
	openMenu() {
		if (this.state.collapsed) this.adjustMenu();
	}

	//adjust collapsed state
	adjustMenu() {
		this.setState({collapsed: !this.state.collapsed});
	}



	//close currencies, clientList or user info when clicking on one of these (doesn't work for the moment)
	closeOther(containerToShow) {
		switch (containerToShow) {
			case 'CLIENTS':
				this.setState({showCurrencies: false, showClientOptions: !this.state.showClientOptions, showUserInfoOptions: false});
				break;
			case 'CURRENCIES':
				if (this.state.showCurrencies) this.setState({showCurrencies: false});
				else this.setState({showCurrencies: true, showClientOptions: false, showUserInfoOptions: false});
				break;
			case 'USERINFO':
				this.setState({showCurrencies: false, showClientOptions: false, showUserInfoOptions: true});
				break;
		}
	}

	//render method (render navigation and menu, render header, render app body + alerts container)
	render() {
		const {
			collapsed,
			showClientOptions,
			selectedCurrency
		} = this.state;

		this.routePath = history.location.pathname;

		const { client, navigation, headerName, Clients, currencies } = this.props;

		
		

		//add this below to activate performance testing tool: <ReactPerfTool perf={Perf} />

		
	}
}
*/
const PortalLayout = ({
	children, ...props
})=> {
	const {client, navigation, headerName, Clients, currencies} = props;

	
	const [collapsed, setCollapsed ] = useState(false);
	const [ showClientOptions, setShowClientOptions] = useState(false);
	const [ selectedCurrency, setSelectedCurrency] = useState();

	let location = useLocation();
	let history = useHistory();

	const toggleCollapse = useCallback(
		() => {
			setCollapsed(!collapsed);
		},
		[collapsed,setCollapsed],
	  );
	  const openMenu = useCallback(
		() => {
			setCollapsed(true);
		},
		[collapsed,setCollapsed],
	  );

	const navigate = useCallback((action)=> {
		history.push(action);
	},[history])


	let wrapper = 'app-wrapper';
		if (collapsed) wrapper += ' collapsed';

		const navigationComponent = <Navigation onContactsClicked={openMenu}
		collapsed={collapsed} navigate={navigate}
		{...navigation} currentPath={location ? location.pathname : ""}/>;


		const childprops = Object.assign({}, props, {currency: selectedCurrency});


	return (
		<div className={wrapper}>
			{navigationComponent}
			<FormulaDrawer>
			<div className="app-content">
				<Header toggleCollapse={toggleCollapse} title={headerName}/>

				<section className="body">
					{ children }
				</section>
			</div>

		</FormulaDrawer>
		</div>
	);

}

export const PortalBodyWrap = (Component) => (props)=> {
	return <Component {...props}/>
}
//&& React.cloneElement(children, {...childprops}) 

export default connect(mapStateToProps, null)(PortalLayout)