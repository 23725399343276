
import React from 'react';
import classNames from 'classnames';
import SummaryBlock from '../block/summaryBlock.jsx';
import './summaryList.scss';
/**
 * DESCRIPTION: used in the client's dashboard
 *    => render multiple SummaryBlock components
 *    => scrollable if needed (fancy arrows & fancy animation)
 *    => contains statistics about the client
 */


class SummaryList extends React.Component {
    constructor(props) {
        super(props);

        this.rqf = null;
        this.state = {
            leftPosition: 0,
            showButtons: false,
            prevBtn: false,
            nextBtn: false
        };

        this.onResize = this.onResize.bind(this);
    }

    onPrevBtnClick() {
        const bounds = this.calculateBounds();
        let newPos = this.state.leftPosition + bounds.step;
        this.updateButtons(newPos, bounds);
    }

    onNextBtnClick() {
        const bounds = this.calculateBounds();

        let newPos = this.state.leftPosition - bounds.step;
        this.updateButtons(newPos, bounds);
    }

    onResize (){
        if (this.rqf) return;
        if (typeof window !== 'undefined') {
            this.rqf = window.requestAnimationFrame(() => {
                this.rqf = null;

                const bounds = this.calculateBounds();
                this.updateButtons(this.state.leftPosition, bounds);
            });
        }
    }

    updateButtons(left, bounds) {
        let normalizedLeft = this.normalizeLeft(left, bounds);

        this.setState({
            leftPosition: normalizedLeft,
            showButtons: bounds.scrollable,
            prevBtn: bounds.scrollable && normalizedLeft < bounds.max,
            nextBtn: bounds.scrollable && normalizedLeft >= bounds.max
        });
    }

    normalizeLeft(left, bounds) {
        if (!bounds.scrollable) return bounds.max;
        if (left === undefined) return bounds.max;
        if (left >= bounds.max) return bounds.max;
        if (left <= bounds.min) return bounds.min;
        return left;
    }

    calculateBounds() {
    		if(this.container) {
					let containerWidth = this.container.offsetWidth;
					let items = Array.from(this.container.children);
					let stepSize = Math.round((containerWidth / items.length) * 2);

					let itemsWidth = 0;
					items.forEach(x => itemsWidth += x.offsetWidth);
					let delta = itemsWidth - containerWidth;

					return {
						itemsCount: items.length,
						step: stepSize,
						scrollable: delta > 0,
						min: delta > 0 ? -(delta) : 0,
						max: 0
					};
				}
    }

    componentDidMount() {
        const bounds = this.calculateBounds();
        if(bounds) this.updateButtons(0, bounds);

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', this.onResize, false);
        }
    }

    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize);
        }
    }

    renderScrollable(children) {
        const style = {
            'left': this.state.leftPosition + 'px'
        };

        let prevButton = this.state.showButtons && (
            <button className="o-btn o-btn--prev" disabled={!this.state.prevBtn}
                    onClick={this.onPrevBtnClick.bind(this)}>
                <i className="fa fa-chevron-left" />
            </button>
        );

        let nextButton = this.state.showButtons && (
            <button className="o-btn o-btn--next" disabled={!this.state.nextBtn}
                    onClick={this.onNextBtnClick.bind(this)}>
                <i className="fa fa-chevron-right" />
            </button>
        );

        const componentClasses = classNames({
            'c-summary-list': true,
            'c-summary-list--scrollable': true,
            'c-summary-list--has-buttons': this.state.showButtons
        });

        return (
            <div className={componentClasses}>
                <div className="o-scrollable-wrapper">
                    <div className="c-summary-list__items-container" style={style} ref={(ref) => this.container = ref}>
                        {children}
                    </div>
                </div>
                {prevButton}
                {nextButton}
            </div>
        );
    }

    render() {
        const {
            scrollable
        } = this.props;

        let validChildren = 0;
        const children = React.Children.map(this.props.children, (child) => {
            if (child && child.type === SummaryBlock) {
                validChildren++;

                return (
                    <div className="c-summary-list__item">{child}</div>
                );
            }
            return false;
        });

        if (!children || validChildren === 0) return false;
        if (scrollable) return this.renderScrollable(children);

        return (
            <div className="c-summary-list">
                <div className="c-summary-list__items-container">
                    {children}
                </div>
            </div>
        );
    }
}

export default SummaryList;
