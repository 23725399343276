import { createContext } from "react";


export const ReportingWizardContext = createContext({});


export const createWizardContext = ()=> {


    return {
        
    }
}