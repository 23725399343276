import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { push} from '../../../router';

import Loader from '../../components/loader/index.jsx';
import ClientsGridView from '../../components/grids/clients';
import { DashboardContainer, SmartLayout } from '../../components/dashboard';

import clientsQuery from '../../../graph/queries/incofin/clients';

/**
 * DESCRIPTION: overview of all clients that are under your supervision (based on role, Incofin-RD will have less clients than an Incofin-admin)
 * ROUTE: /incofin/clients
 * ACCESSIBLE BY:  Incofin-RD, Incofin-IM, Incofin-admin & admin
 * DATA: clientsQuery
 *          => clients with ALL their information (refactor so graphql returns less data? gridview only displays name, type and region)
 */



const Clients = ({history})=> {
    const onClientClick = useCallback((client)=> {
        history.push(`/client/${client.key}/`)
    });

    return (
        <DashboardContainer>
            <SmartLayout>
                <ClientsGridView itemsPerPage={25} onRowClick={onClientClick} />
            </SmartLayout>
        </DashboardContainer>
    );
}


export default Clients