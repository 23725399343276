import { gql } from '@apollo/client';

export const exchangeRatesQuery = gql`query {
	exchangeRate {
		_id
		numericDate
        source
	}
}
`;

export const singleExchangeRatesQuery = gql`query($exchangeId:String!) {
	singleExchangeRate(exchangeId:$exchangeId) {
		_id
		numericDate
        source
        quotes
	}
}
`;