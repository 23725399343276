import classNames from "classnames";
import IconMathFunction from "../FunctionIcon";
import { useCallback } from "react";
import { FormulaIconLink, FormulaLink } from "../../NewFormula/components/Links";




export const FormFieldLabel = (item,path)=> {

    const cls = classNames("field-label", {
        "field-label-has-fx":!!item.formula
    })

	return <div className={cls}>
            <div className="field-label-fx">
                {!!item.formula ? <FormulaIconLink name={item?.name?.value}  path={path} formula={item.formula.formula}/> : null}
            </div>
        <div className="field-label-text">
        {!!item.formula ? <FormulaLink name={item?.name?.value} fullPath={path} formula={item.formula}>{item.name.value}</FormulaLink> : item.name.value}
        </div>
	</div>;
}