import React from 'react';
import TextBlock from '../../text/block/textBlock.jsx';
import {useIntl, FormattedDate} from 'react-intl'
import './summaryBlock.scss'

/**
 * DESCRIPTION: render a display block with the following parts
 *   => header => TextBlock component
 *   => content => TextBlock component
 *   => footer => TextBlock component
 */

export default ({
	iconName,title,subTitle,valueIcon,type,value
}) => {
	const intl = useIntl()
	if (type === 'currency') {
		value = intl.formatNumber(value, {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
	} else if(type === 'numeric') {
		value = intl.formatNumber(value);
	}

	const vIcon = valueIcon ? valueIcon : null;

	const header = title && (
			<div className="summary-block__heading">
				<TextBlock icon={iconName} text={title}/>
			</div>
		);

	const content = value && (
			<div className="summary-block__content">
				<TextBlock postText={vIcon} text={value}/>
			</div>
		);

	const footer = subTitle && (
			<div className="summary-block__footer">
				<TextBlock text={subTitle}/>
			</div>
		);

	return (
		<div className="summary-block">
			{header}
			{content}
			{footer}
		</div>
	);
}
	

