import { useQuery } from "@apollo/client";
import classNames from "classnames"
import React,{ createContext, useCallback, useContext, useMemo, useState } from "react"
import { formConfigQuery } from "../../../graph/queries/form/form";
import './form.scss';
import { ClientContext } from "../../../ui/containers/client";
import financeQuery from "../../../graph/queries/form/finance";
import { useIntl } from "react-intl";
import IconMathFunction from "../FunctionIcon";
import { math } from "../../NewFormula/mathjs";





const FormulaWrapper = ({formula,children})=>{
	const [show,setShow] = useState(false)

	const formulaString = useMemo(()=> {
		return math.parse(formula).toString();
	},[formula])

	const cls = classNames("form-flat-field-line-group",{
		"show-formula":show
	})

	return <div className={cls}>
		{children}
		<div className="form-flat-field-line form-flat-field-formula">
			<div colSpan={2} className="form-flat-field-formula-content">
			{formulaString}
			</div>
		</div>
	</div>

}

const FormFlatItemFieldHead = ({field,renderField,renderLine=RenderFlatLine,path,cls="form-flat-field-line"})=> {
	//console.log(path,renderField)
	let compField =renderField(field,path);

	
	let compLine = renderLine(field,path,compField,{key:path,className:cls});
	
	return compLine;	
}

const RenderFlatLine = (field,path,children,props)=> {
	return <div {...props}>{children}</div>
}

const FormFlatItemField = ({field,renderField,renderLine=RenderFlatLine,path,depth})=> {
	//console.log(path,renderField)
	let compField =renderField(field,path);
//	let formula = !!field.formula ? <IconMathFunction width={12} height={12}/> : null ;
	let levelDepth = depth < 4 && depth > 1 ? "last" : depth;
	let cls = "form-flat-field-line form-flat-level-"+levelDepth;

	let compLine = renderLine(field,path,compField,{key:path,className:cls});
	return compLine;	
}

const FormTreeItemField = ({field,renderField,path,cls="form-tree-field-line"})=> {
	//console.log(path,renderField)
	let compField =renderField(field,path);
//	let formula = !!field.formula ? <IconMathFunction width={12} height={12}/> : null ;
		
	let compLine = <div key={path} className={cls}>{compField}</div>;
	return compLine;	
}

const FormTreeItemGroup  = ({field,renderField,path,renderTree})=> {
	
	return <div key={"group"+path} className="form-tree-field-group">
	{FormTreeItemField({field,renderField,path})}
	<div className="form-tree-field-group-content">
		{field.children.map(f=>renderTree(f,renderField,path,renderTree))}
	</div>
</div>
}

const FormFlatItemGroup  = ({field,renderField,renderLine=RenderFlatLine,path,renderTree,depth=1})=> {
	
	let depthCls = "form-flat-field-line form-flat-level-"+depth;

	let childDepth = depth+1;
	//let anydeeper = field.children.some(c=>c.children && c.children.length>0);
	

	return <>
		{FormFlatItemFieldHead({field,renderField,renderLine,path,cls:depthCls})}
		{field.children.map(f=>renderTree(f,renderField,path,childDepth))}
	</>
	
}

const FormTreeItemGroupToggle = ({field,renderField,path,renderTree})=> {
	const [isOpen,setOpen] = useState(true);
	const toggle = useCallback(()=> {
		setOpen(!isOpen)
	},[isOpen])

	const clsGroup = classNames("form-tree-field-group-content",{
		isClosed:!isOpen
	});

	return <div key={"group"+path} className="from-tree-field-group" onClick={toggle}>
	{FormTreeItemField({field,renderField,path})}
	<div className={clsGroup}>
	{field.children.map(f=>renderTree(f,renderField,path,renderTree))}

	</div>
</div>
}



export const FormTreeContent = ({config,renderField,renderLine=RenderFlatLine,enableToggle=false,showFormula=true})=> {

	let { fields=[] } = config;
	let path = config.key;

	let compFn = (field,renderField,parentPath)=> {
		let path = `${parentPath}.${field.internalName}`
		
		
		if(field.fieldType=="FIELDSET") {

		}
		if(field.children && field.children.length>0) {
			if(enableToggle) {
				return FormTreeItemGroupToggle({field,renderField,renderLine,path,renderTree:compFn})
			} else {
				return FormTreeItemGroup({field,renderField,renderLine,path,renderTree:compFn})
			
			}
		} else {
			return FormTreeItemField({field,renderField,renderLine,path,renderTree:compFn});
		}
	}

	return fields.map(f=>compFn(f,renderField,path))
}




export const FormFlatContent = ({config,renderField,
	renderLine,
	enableToggle=false,showFormula=true})=> {

	let { fields=[] } = config;
	let path = config.key;

	let compFn = (field,renderField,parentPath,depth=1)=> {
		let path = `${parentPath}.${field.internalName}`
		//debugger;
		
		if(field.fieldType=="FIELDSET") {

		}
		if(field.children && field.children.length>0 && field.fieldType!="COVENANT") {
			return FormFlatItemGroup({field,renderField,renderLine,path,renderTree:compFn,depth})
			
		} else {
			
			return FormFlatItemField({field,renderField,renderLine,path,renderTree:compFn,depth});
		}
	}

	return fields.map(f=>compFn(f,renderField,path))
}







//20231231,20211231,20201231,20191231]