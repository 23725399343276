import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import GridView from '../dashboard/gridview/gridview.jsx';
import { GraphTableServer } from '../table/GraphTable';
import DateFormat from '../dashboard/gridview/components/date';
import auditsQuery from '../../../graph/queries/admin/audits';

import DateRange from './custom/range-date';

/**
 * DESCRIPTION: show server-side paged audits on the "/incofin/admin/audits" route
 *   => only accessible by the Incofin-admin or admin user
 */

 const gridDataMapper = (audits)=>{
	return  audits && audits.length > 0 && Array.isArray(audits) ?
		audits.map((audit) => {
			return {
				date: audit.date,
				text: audit.text,
				user: audit.user && audit.user.fullName ? audit.user.fullName : 'System'
			};
		}) 
		: [];
  };
  const columnMeta = [
	{'columnName': 'date', 'displayName': <FormattedMessage id="grid.audits.columns.date"/>, 'customComponent': DateFormat},
	{'columnName': 'text', 'displayName': <FormattedMessage id="grid.audits.columns.text"/>},
	{'columnName': 'user', 'displayName': <FormattedMessage id="grid.audits.columns.user"/>}
];

const AdminAuditsGridView = ({itemsPerPage})=> {

	const [vars,setVars] = useState({range:{}});
	//<DateRange range={this.range} onRangeChange={this.onRangeChange} />
	
	const onSetRange = useCallback((rangeType, dateField, value)=> {
		
		if(value) {
			vars.range[rangeType] = value;
		} else if(vars.range[rangeType] && value === null) {
			delete vars.range[rangeType];
		}
		
		console.log('set range vars',vars)
		setVars({...vars});
	},[vars])

	return 	<div>
		<DateRange range={vars.range} onRangeChange={onSetRange} />
		<GraphTableServer qry={auditsQuery} 
	options={{
		fetchPolicy: "no-cache",
	}}
	vars={vars}
	tableDataMapper={gridDataMapper}
	dataSelector={(d)=>d && d.audits ? d.audits.results : []}
	totalSelector={(d)=>d && d.audits ? d.audits.pageInfo.total :0}
	 columnMetadata={columnMeta} showFilter={true} showPager={true} 
	 resultsPerPage={itemsPerPage}
	/>
	</div> 
}

/*
class AdminAuditsGridView extends React.Component {
	constructor(props) {
		super(props);
	}

	//render method
	render() {
		const {
		
			itemsPerPage
		} = this.props;

		

	

		return (
			<div>
				<Table data={gridData} columnMetadata={columnMeta} resultsPerPage={itemsPerPage}
									showPager={false} enableSort={false} showFilter={false} />
			</div>
		);
	}
}
*/

export default AdminAuditsGridView;
