import React from 'react';


export const Info = ({children})=> {

    return <div style={{padding:"0.5em",margin:0,
    backgroundColor:'#e6f4ff',
    borderRadius:'5px',
    border:'1px solid #91caff'
    }}>
        <span className='fa fa-info-circle' style={{
            color:"#1677ff", marginRight:'0.5em'
        }}></span>
       {children}
    </div>
   
}

export default Info;