import React,{useCallback} from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import GridView from '../dashboard/gridview/gridview.jsx';
import ActionsComponent from '../dashboard/gridview/components/actions';
import SpanStatusComponent from '../dashboard/gridview/components/span-status';
import {contextToCalendar} from '../periodToText';
import { GraphTableServer } from '../table/GraphTable'
import { useHistory } from 'react-router-dom';
import adminReportsQuery from '../../../graph/queries/admin/reports';
import Panel from '../dashboard/panel/panel';


/**
 * DESCRIPTION: show ALL report instances on the "/incofin/admin/reports" route
 *   => only accessible by the Incofin-admin or admin user
 */


const DefaultProps = {
	itemsPerPage: 5
};

const gridDataMapper = (reports)=>{return reports.map((report) => {
	let overdue = moment().isAfter(moment(report.schedule.due));
	return {
		id: report._id,
		_id: report._id,
		reportId: report.config?.id,
		clientId: report.client?._id,
		status: report.status === 'created' && overdue ? 'overdue' : report.status,
		client: report.client ? `${report.client.officialName} (${report.client.name})` : '',
		clientKey : report.client?.key,
		key: report.config?.key,
		category: report.config?.name?.value,
		periodName: contextToCalendar(report.context),
		period: report.context.text,
		numericContext: report.context.numeric,
		dueDate: moment(report.schedule.due).format('dddd MMMM DD YYYY'),
		overdue,
		reporter: report.reportedBy?.fullName ,
		__actions: report.status === 'created' ? ['delete'] : ['reopen', 'delete']
	};
});
};



const rowMetadata = {
	'bodyCssClassName': function (rowData) {
		if (rowData.status === 'rejected' || (rowData.overdue && rowData.status !== 'approved')) return 'error';
	}
};

const AdminReportsGridView =({
	onRowClick,
	showPager,
	enableSort,
	showFilter,
	itemsPerPage=DefaultProps.itemsPerPage,
	reports,
	onFilterChange,
	fullMode,
	showCustomFilter,
	reportId=null,
	onAction,
	counter=0

})=> {
	const onRowClickCb = useCallback((row)=>{
		onRowClick(row.props.data);
	},[onRowClick]);
	const history = useHistory();
	const goToReports = useCallback(()=>{
		history.push('/incofin/reports')
	},[history]);

	const onActionCb = useCallback((action,row)=>{
		onAction(action,row);
	},[onAction]);

	const columnMeta = [
		{"columnName": 'status', "displayName": <FormattedMessage id="grid.reports.columns.status"/>, "customComponent": SpanStatusComponent},
		{"columnName": 'client', "displayName": <FormattedMessage id="grid.reports.columns.client"/>},
		{"columnName": 'category', "displayName": <FormattedMessage id="grid.reports.columns.category"/>},
		{"columnName": 'periodName', "displayName": <FormattedMessage id="grid.reports.columns.period"/>},
		{"columnName": 'dueDate', "displayName": <FormattedMessage id="grid.reports.columns.due-date"/>},
		{"columnName": 'reporter', "displayName": <FormattedMessage id="grid.reports.columns.reporter"/>},
		{
			columnName: '__actions',
			displayName: <FormattedMessage id="grid.column.actions"/>,
			customComponent: ActionsComponent,
			customComponentProps: {
				onAction: onAction
			}
		}
	];


	return (
			 <GraphTableServer qry={adminReportsQuery} 
        options={{
            fetchPolicy: "no-cache",
        }}
		counter={counter}
		vars={{reportId,
			sort:{field: "context.numeric", sort: "DESC"}}}
        tableDataMapper={gridDataMapper}
        dataSelector={(d)=> { return d && d.adminReports && d.adminReports.results ? d.adminReports.results : []}}
        totalSelector={(d)=>d && d.adminReports && d.adminReports.pageInfo ? d.adminReports.pageInfo.total :0}
         columnMetadata={columnMeta} showFilter={true} showPager={fullMode} 
		 resultsPerPage={itemsPerPage}
		onRowClick={onRowClickCb} 
		 enableSort={true}
		

        />
				
		
	);

}


export default AdminReportsGridView;
