import { gql } from '@apollo/client';
import { formConfigFields, getFieldFragment } from '../form/form';

export const wizardStepConfig = `
      type
      required
      ...on ReportingInstanceConfigFormStep {
        config {
          key
          _id
          name { value }
          grid
          guide  {
            value
          }
          context {
            category
          }
          type
          ${formConfigFields}
          validations {
            validationType
            validationMessage { value }
            formula
            dependencies
          }
        }
      }
`

const openReportInfo = `
_id
			config {
                _id
                key
                name {
                    value
                }
			}
			configId
			schedule {
					due
			}
			
			context {
					category
					text
					numeric
					period {
						month
						quarter
						year
						financialYear
						financialYearPeriod
					  }
			}
			status
			overdue
			reportedBy { 
				_id
				fullName 
			}
			auditedBy { 
				_id
				fullName 
			}
			lastUpdated
      `

export const wizardFullQuery = gql`query wizard($clientId: String!, $reportConfigKey: String!, $reportInstanceKey: String!, $language: String) {
  
  wizard(clientId: $clientId, reportConfigKey: $reportConfigKey, reportInstanceKey: $reportInstanceKey,language:$language) {
    _id
    config {
      _id
      name { value }
    }
    reportedBy { 
      _id
      fullName 
    }
    auditedBy { 
        _id
        fullName 
    }
    
    status
    overdue
    context {
      numeric
      text
      period {
        month
        quarter
        year
        financialYear
        financialYearPeriod
      }
     
    }
    settings {
    	currency
    	reportingScale
    }
    steps {
      ${wizardStepConfig}
    }
    scopedData
    formulaSet {
      target
      formula
    }
    previousOpenReports {
      ${openReportInfo}
    }
   
  
  }
}
${getFieldFragment(true)}
`

export const wizardAndClientFullQuery = gql`query wizard($clientId: String!, $reportConfigKey: String!, $reportInstanceKey: String!, $language: String) {
  
  wizard(clientId: $clientId, reportConfigKey: $reportConfigKey, reportInstanceKey: $reportInstanceKey,language:$language) {
    _id
    client {
      _id
      name
      officialName
      key
      status
      contacts {
          name
          role
          email
      }
      settings {
          financialPeriodEnd {
              day
              month
          }
          currency
          reportingScale
      }
      clientType
      bottomDate
      topDate
    }
    
    config {
      _id
      name { value }
    }
    reportedBy { 
      _id
      fullName 
    }
    auditedBy { 
        _id
        fullName 
    }
    
    status
    overdue
    context {
      numeric
      text
      period {
        month
        quarter
        year
        financialYear
        financialYearPeriod
      }
     
    }
    settings {
    	currency
    	reportingScale
    }
    steps {
      ${wizardStepConfig}
    }
    scopedData
    formulaSet {
      target
      formula
    }
    previousOpenReports {
      ${openReportInfo}
    }
  
  }
}
${getFieldFragment(true)}
`

