import React, { createContext } from 'react';
import Loader from '../components/loader/spinner';
import { connect, useDispatch,useSelector } from 'react-redux';
import { loadClient } from '../../redux/actions/client';
import { setClientNavigation } from '../../redux/actions/navigation';
import clientQuery from '../../graph/queries/clients/clientQry';
import { useEffect } from 'react';
import { id } from 'date-fns/locale';
import { useState } from 'react';
import { Spin } from 'antd';
import { FormulaDrawer } from '../../features/NewFormula/components/Drawer';
import { useQuery } from '@apollo/client';
import { switchCurrency } from '../../redux/actions/session';
import { CLIENT_LOADED, LOAD_ERROR } from '../../redux/actions/client/actions';


export const ClientContext = createContext({_id:null})


function mapDispatchToProps(dispatch) {
    return {
        fetchClient: (clientIdOrKey) => {
            dispatch(loadClient(clientIdOrKey));
        },
        setNavigation: (clientIdOrKey)=> {
            dispatch(setClientNavigation(clientIdOrKey));
        }
    };
}

/*
graphql(clientQuery, {
	options:({params}) => ({variables: {clientId: params.clientId}})
})*/


const ClientContainer = ({match,children,...props})=> {

    const dispatch = useDispatch();
    const { loading, data,called } = useQuery(clientQuery,{
        variables:{
            clientId:match.params.clientId
        }
    })

    useEffect(()=> {
        if(called && !loading) {
        if(data?.client) {
            if(data?.client?.settings?.currency) {
                dispatch(switchCurrency(data.client.settings.currency));
            }

            dispatch({
                type:CLIENT_LOADED,
                client:data.client
            });
            dispatch(setClientNavigation(data.client.key,data.client.clientType));
        } else {
            dispatch({
                type:LOAD_ERROR,
                payload:"No client"
            });
        }
    }

    },[loading,called, data]);

    
    if(loading) {
        return <Spin tip="Loading" fullscreen></Spin>;
    }
    return <ClientContext.Provider value={data.client}>
        <FormulaDrawer client={data.client}>
        { children && React.cloneElement(children, {
        ...props
        //headerName: client.name,
        //currencies,
        //navigation,
        //client,
    })}</FormulaDrawer></ClientContext.Provider>;

   // return <div>OK</div>
}

/*
class _ClientContainer extends React.Component {

    componentWillReceiveProps(props) {
        //console.log('client container will receive props',props,this.loading);
        // load client ?
        this.loading=false;
        console.log("ClientContainer",props)
        if(props.clientKey==props.match.params.clientId) {
            this.loading=false;
        } else {
            this.props.setNavigation(this.props.match.params.clientId);
            if(!this.loading && !props.loading) {
                //console.log("FETCH CLIENT");
                
                this.loading=true;
               
               this.props.fetchClient(props.match.params.clientId);
            }
        }
        //console.log('client container will receive props - END',props,this.loading);
    }

	componentWillMount() {
        console.log('client container will mount',this.props.match.params.clientId);
        // load navigation...
        this.props.setNavigation(this.props.match.params.clientId);
        if(this.props.clientKey!=this.props.match.params.clientId && !this.props.loading) {
            this.loading=true;
            //console.log("FETCH CLIENT");
            this.props.fetchClient(this.props.match.params.clientId);
        }


	}

	componentDidMount() {

	}

    componentWillUnmount() {
        //console.log("unmount client container")
    }


	render() {
        
        const  {
            children,
            setNavigation,
            fetchClient,
            clientKey,
            loading,
            ...props
        } = this.props;

        console.log("render client container",this.props.match.params.clientId,clientKey)

        if(clientKey!=this.props.match.params.clientId) {
            return <Loader/>;
        }


		return children && React.cloneElement(children, {
                ...props
				//headerName: client.name,
				//currencies,
				//navigation,
				//client,
		});
	}
}

export default connect(function(state) {
    return {clientKey:state.client.key,clientType:state.client.clientType,loading:state.client.loading}
},mapDispatchToProps)(ClientContainer)*/

export default ClientContainer
