import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { MyIntlProvider } from './i18n'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from "@apollo/client";
import { Provider as ReduxProvider } from 'react-redux'
import {store,apolloClient} from './redux/store'
import './index.scss'
import './random'
import Demo from './Demo';
import localForage from 'localforage';
import { createStores } from './localForage';
import { Tooltip} from 'redux-tooltip';
import ReduxToastr from 'react-redux-toastr';
import { history } from './router';
import { Router } from 'react-router-dom';
// ADD AUTH CHECK
// ADD INTL
// ADD GRAPH
// ADD REDUX
// ADD BASE ROUTING
// <React.StrictMode>

function renderApp() {
  
ReactDOM.render(
 
    <ReduxProvider store={store}>
    <MyIntlProvider>
    
    <Router history={history}>
    <ApolloProvider client={apolloClient}><App/>
    </ApolloProvider>
    </Router>
  
    <Tooltip name="general"/>
						<Tooltip name="error-tooltip" className="error-tooltip"/>

						<ReduxToastr
							timeOut={4000}
							newestOnTop={false}
							preventDuplicates
							position="top-left"
							transitionIn='bounceIn'
							transitionOut='bounceOut'
							progressBar={false}/>
    
    </MyIntlProvider>
    </ReduxProvider>,
  document.getElementById('app')
);
}
//localForage.ready().then(createStores).then(renderApp).catch(renderApp);
renderApp();
//
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
