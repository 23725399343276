import { gql } from '@apollo/client';

const auditsQuery = gql`query audits($skip: Int, $limit: Int, $filter: String, $range: Object) {
	audits(skip: $skip, limit: $limit, filter: $filter, range: $range) {
		results {
			_id
			user
			description
			text
			date
			__typename
		  }
		  pageInfo {
			total
			limit
			offset
		  }
	}
}
`;

export default auditsQuery;