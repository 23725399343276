import React from 'react';

export default class RolesComponent extends React.Component {
	constructor(props) {
		super(props);
	}

	//render method
	render() {
		return (
			<div>
				{
					this.props.data.map((role, idx) => {
						let mappedRole;

						switch(role) {
							case 'CLIENT':
								mappedRole = 'Client';
								break;
							case 'Incofin-admin':
								mappedRole = 'Incofin Admin';
								break;
							case 'Incofin-RD':
								mappedRole = 'Incofin Regional Director';
								break;
							case 'Incofin-IM':
								mappedRole = 'Incofin Investment Manager';
								break;
							case 'CLIENT-ADMIN':
								mappedRole = 'Client Admin';
								break;
							default:
								mappedRole = role;
								break;
						}
						return (
							<div key={idx}>
								{mappedRole}
							</div>
						);
					}, this)
				}
			</div>
		);
	}
}