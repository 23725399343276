import {default as Dashboard} from './dashboard';
import {default as Reports} from './reports';
import {default as ExportFactsheet} from '../export/index';
import {default as ExportReports} from '../export/exportReports';
import {default as ExportInvestments} from '../export/exportInvestments';

import {FormPage} from '../form/index.jsx';
import Secured from '../../containers/Secured';
import PortalLayout from '../../layouts/portal';
import { UserIsIncofinMember} from '../../../auth/AuthWrappers';
import ClientDataContainer from '../../containers/client';
import {Investments } from '../investments/tabs';
import { Router,Route,Switch } from 'react-router-dom';
import AuthHelper from '../../../auth/AuthHelper';
import {ReportPage } from '../reportingWizard/index.jsx';

import { connect } from 'react-redux';
import { InvestmentDetail } from '../investments/investment';
import { FormWrapperPage } from '../form/FormsWrapperPage';
export {default as Dashboard} from './dashboard';
export {default as Reports} from './reports';
export {default as ExportFactsheet} from '../export/index';
export {default as ExportReports} from '../export/exportReports';
export {default as ExportInvestments} from '../export/exportInvestments';




const InvestmentsPage = connect(function(state) {
	return {
		clientId: state.client._id
	}
},{})(Investments)


export const ClientRoute = ({ match })=> {

    //const IncofinMember= UserIsIncofinMember(IncofinDataContainer);

    const matchPath = match.path.endsWith('/') ? match.path.substring(0,match.path.length-1) : match.path;

    return  <ClientDataContainer match={match}>
            <Switch>
                <Route path={`${matchPath}/reports/:reportId/:period`} component={ReportPage}/>
                <Route>
                <PortalLayout>
            <Switch>
            <Route path={`${matchPath}`} exact component={Dashboard}/>
            <Route path={`${matchPath}/`} exact component={Dashboard}/>
            <Route path={`${matchPath}/dashboard`} exact component={Dashboard}/>
            <Route path={`${matchPath}/investments`} exact component={InvestmentsPage} />
            <Route path={`${matchPath}/investments/:investId`} exact component={InvestmentDetail}/>
           
            
            <Route path={`${matchPath}/data`} component={FormWrapperPage}/>
            
            <Route path={`${matchPath}/export/`} exact component={ExportFactsheet}/>
           <Route path={`${matchPath}/export/factsheet`} exact component={ExportFactsheet}/>
            <Route path={`${matchPath}/export/reports`} exact component={ExportReports}/>
            <Route path={`${matchPath}/export/investments`} exact component={ExportInvestments}/>
            <Route path={`${matchPath}/reports`} exact component={Reports}/>
            <Route path={`${matchPath}/reports/`} exact component={Reports}/>
           
            <Route path={`${matchPath}/reports/:reportId`} exact component={Reports}/>
            <Route></Route>
            </Switch>
            </PortalLayout>

                </Route>
          </Switch>

            </ClientDataContainer>
      ;

}


export default {
    Dashboard,Reports,ExportFactsheet,ExportInvestments,ExportReports
}
