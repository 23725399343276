import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import DateField from '../../fields/date/index';


class DateRange extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			to: this.props.range.to,
			from: this.props.range.from
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.to !== this.state.to || nextState.from !== this.state.from;
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.range && (this.props.to !== nextProps.range.to || this.props.from !== nextProps.range.from)) {
			this.setState({to: nextProps.range.to, from: nextProps.range.from});
		}
	}

	onRangeChange(rangeType, dateField, value) {
		if(this.props.onRangeChange) this.props.onRangeChange(rangeType, dateField, value);
	}

	render() {
		const {
			to,
			from
		} = this.state;

		return(
			<div className="range-wrapper">
				<span>
					<FormattedMessage id="grid.date-range.between"/>
				</span>
				<DateField onChange={this.onRangeChange.bind(this, 'from')} value={null} maxDate={to ? moment(to).add(1, 'days') : null}
									 isNullable={true} validations={{}} format={'YYYY-MM-DD'} />

				<span><FormattedMessage id="grid.date-range.and"/></span>
				<DateField onChange={this.onRangeChange.bind(this, 'to')} value={null} minDate={from ? moment(from).add(1, 'days') : null}
									 isNullable={true} validations={{}} format={'YYYY-MM-DD'} />
			</div>
		);
	}
}

export default DateRange;