import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import SelectField from '../fields/select/index';
import MonthSelect from '../fields/select/months';
import YearSelect from '../fields/select/year';
import AuthHelper from '../../../auth/AuthHelper';
import { clone } from '../../../utils';
import moment from 'moment';
import RequiredLabel from '../RequiredLabel/index';
import _ from 'underscore';
import { Button, DatePicker, Form, Modal, Select, Space } from 'antd';
import { ClientSelect } from '../Clients/Select';
import { GraphSelect } from '../GraphSelect';
import { reportConfigsSelect } from '../../../graph/queries/reportConfigSelect';
import { insertReportInstance } from '../../../graph/mutations/report';
import { useMutation } from '@apollo/client';
import { useApp } from '../../../hooks/useApp';
import dayjs from 'dayjs';
import { ClientContext } from '../../containers/client';

const layout = { labelCol: { span: 4 }, wrapperCol: { span: 14 } }
const graphOptions = {
    query:reportConfigsSelect,
    resultField:"reportConfigs",
    valueField:"_id",
    labelField:"name.value",
    mapVariables:(value)=> ({text:value})
}

const tailLayout = {
	wrapperCol: {
	  offset: 8,
	  span: 16,
	},
  };

const ReportTemplateSelect = ({clientType,...props})=> {


	return <GraphSelect graphOptions={graphOptions}
	variables={{clientType}}
	{...props}
	disabled={!clientType}
	/>
}

export const ReportInstanceForm = ({
	onDone=()=>{},onCancel=()=>{},open,
	client:fixedClient
	
})=>{
	
	const clientCtx = useContext(ClientContext);
	const [initialValues,setInitialValues] = useState(null)
	const [createReport,{loading}] = useMutation(insertReportInstance,{});
	const { notify,message } = useApp();
	
	const [form] = Form.useForm();

	const [client,setClient] = useState(clientCtx && clientCtx._id ? clientCtx : null);


	const onClientSelect = useCallback((val,option)=>{
		
		setClient(option?.obj);
		if(!option?.obj) form.setFieldsValue({configId:null})
	},[form])

	const resetFormDefaults = useCallback(()=> {
		if(clientCtx && clientCtx._id) {
			form.setFieldValue("clientId",{
				value:clientCtx._id,
				label:clientCtx.name,
				obj:clientCtx
			})
		} else {
			form.setFieldValue("clientId",null)
		}
	},[form,clientCtx])

	useEffect(()=> {
		resetFormDefaults();
	},[clientCtx,resetFormDefaults])

	const onFinish = useCallback(async (values)=> {

		try {
			const values = await form?.validateFields();
			
			const { clientId, configId, month } = values;
			let request = {
				clientId:clientId.value,
				configId:configId.value,
				numeric:parseInt(month.endOf('month').format('YYYYMMDD'))
			}
			createReport({variables:request}).then(({data:result})=> {
				
				const report = result["insertReportInstance"]
				message.success(`Created report ${report?.config?.name?.value} on ${report?.context?.text} for ${report?.client?.officialName} `);
				form?.resetFields();
				resetFormDefaults();
				if(onDone) { onDone(result) }
			}).catch((err)=> {
				notify.error({
					message: 'Failed to create report',
					description: err.message,
					duration:5
				})
			})
		  } catch (error) {
			console.log('Failed:', error);
		  }
		

		
	},[createReport,onDone,form,resetFormDefaults]);
	
	/*const onFinishFailed = (errorInfo) => {
	console.log('Failed:', errorInfo);
	};
	//onFinishFailed={onFinishFailed}
	*/
	//debugger;

//
	return <Modal
	title="Create Report"
	open={open}
	onOk={onFinish}
	confirmLoading={loading}
	onCancel={onCancel}
	width={"800px"}
	okText="Create Report"
    cancelText="Cancel"
	destroyOnClose={true}
	
  ><Form
		{...layout}
		form={form}
		layout="horizontal"
		onFinish={onFinish}
		disabled={loading}
		initialValues={initialValues}
	>
		<Form.Item name="clientId" label="Client" rules={[{ required: true }]}>
       
		<ClientSelect
		  disabled={clientCtx && clientCtx._id}
          placeholder="Select a client"
		  onSelect={onClientSelect}
		  onClear={onClientSelect}
          allowClear
        />
      </Form.Item>

		<Form.Item name="configId" label="Report Template" rules={[{ required: true }]}>
        <ReportTemplateSelect
          placeholder="Select a reporting template"
			clientType={client?.clientType || undefined}
          allowClear
        />
       
      </Form.Item>
	  <Form.Item name="month" label="Period" rules={[{ required: true }]}>
	  <DatePicker  picker="month" placeholder='Select period' />
	  </Form.Item>
	 
	</Form>
	</Modal>
}

/*
 <!--Form.Item {...tailLayout}>
        <Space>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
          <Button htmlType="button">
            Cancel
          </Button>
        </Space>
      </Form.Item-->
*/
class _ReportInstanceForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			values: clone(props.values, true),
			valid: true
		};

		this._validations = new Map();
		this.isYearlyReport = null;
		this.isIncofin = AuthHelper.isGranted('level','ADMIN');

		this.onFieldChange = this.onFieldChange.bind(this);
		this.onValidationChange = this.onValidationChange.bind(this);
	}

	//set new value in received valueTree based on prop identifier
	iterateObject(values, value, identifier) {
		let foundValue = false;
		Object.keys(values).forEach((k) => {
			if (values[k] !== null && typeof values[k] === 'object') {
				foundValue = !foundValue ? this.iterateObject(values[k], value, identifier) : foundValue;
			}
			if (k === identifier) {
				values[k] = value;
				foundValue = true;
			}
		});

		return foundValue;
	}

	//method hooked up to all the fields --> detect changes
	onFieldChange(field, value, previousValue, valid) {
		let values = this.state.values;
		let foundValue = this.iterateObject(values, value, field.props.identifier);
		if(!foundValue) values[field.props.identifier] = value;

		this.setState(() => ({
			values: values
		}));
	}

	//method hookup to all the fields --> detect validation changes (valid/invalid)
	//if invalid --> store identifier in map, if map.length > 0 => form is invalid
	onValidationChange(field, valid, value) {
		if(this._validations.has(field.props.identifier)) {
			this._validations.delete(field.props.identifier);
		}

		if(!valid) {
			this._validations.set(field.props.identifier, valid)
		}

		if(this.isYearlyReport && this._validations.has('month')) {
			this._validations.delete('month');
		}

		let myValid = this._validations.size === 0;
		this.setState(() => ({
			valid: myValid
		}));
	}

	render() {
		const {
			saving,
			reportConfigs,
			client,
			clients,
			language
		} = this.props;

		const {
			values
		} = this.state;

		const standardBindings = {
			validations: {required: true},
			activePageIsSubmitted: saving,
			onChange: this.onFieldChange,
			onValidationChange: this.onValidationChange
		};

		const clientOptions = [];
		let clientOption = client && client._id? {
			value: this.props.client._id,
			label: this.props.client.name
		} : null;

		if (this.isIncofin && clients && clients.length > 0 && Array.isArray(clients) && !client._id) {
			clients.forEach((client) => {
				clientOptions.push({
					value: client._id,
					label: client.name
				});
			});
		} else if(client && client._id) {
			clientOptions.push({
				value: client._id,
				label: client.name
			});
		}

		const reportOptions = [];
		if(reportConfigs && reportConfigs.length > 0 && Array.isArray(reportConfigs)) {
			reportConfigs.forEach((reportConfig) => {
				reportOptions.push({
					value: reportConfig._id,
					label: reportConfig.name?.value || reportConfig.key
				});
			});
		}

		if(values && values['configId']) {
			let reportConfig = _.findWhere(reportConfigs, {_id: values['configId']});
			if(!reportConfig) {
				reportConfig = _.findWhere(reportConfigs, {key: values['configId']});
				values['configId'] = reportConfig._id;
			} 
			//reportValue = reportConfig._id;
			this.isYearlyReport = reportConfig.scheduleType === 'FINANCIAL_YEARLY' || reportConfig.scheduleType === 'YEARLY';
		}

		return (
			<div className="admin-form">
				<div className="required-fields-label">
					<FormattedMessage id="required-fields.label"/>
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="form.reports.type-of-reporting"/>
						<RequiredLabel/>
					</label>
					<SelectField value={values['report']} identifier={'report'} options={reportOptions}
											 {...standardBindings} placeholder={'Select report ... '} />
				</div>

				<div className="form-group">
					<label>
						<FormattedMessage id="form.reports.client"/>
						<RequiredLabel/>
					</label>
					<SelectField value={client && client._id ? clientOption : values['client']} identifier={'client'} options={clientOptions}
											 {...standardBindings} placeholder={'Select client ... '} multi={false} readOnly={!!(this.isIncofin && client && client._id)}/>
				</div>

				<div className="form-group">
					<div className="block">
						<label className="top-label">
							<FormattedMessage id="form.reports.context"/>
						</label>

						<div className="block-children">

						
							{
								!this.isYearlyReport ?
									<div className="form-group">
										<label>
											<FormattedMessage id="form.reports.context.month"/>
											<RequiredLabel/>
										</label>
								
										<MonthSelect value={values['context']['month']} identifier={'month'}
																 {...standardBindings} />
									</div> : null
							}

							<div className="form-group">
								<label>
									<FormattedMessage id="form.reports.context.year"/>
									<RequiredLabel/>
								</label>
								<YearSelect value={values['context']['year']} identifier={'year'}
														minYear={2000} maxYear={moment().year()+1}
														{...standardBindings} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ReportInstanceForm;