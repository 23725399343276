import Actions from './wizard.actions.keys';

const initialStateWizard = {
    loading:true,
    currentStep: null,
    fieldFocusPath: null,
    elementInView: '',
    validations:[]
};

const initialStateSteps =[];

const initialStateData ={};
const initialStateNotes ={
    all:[],
    filtered:[]
};



export const wizardReducer = (state = initialStateWizard, action) => {

    switch(action.type) {
        case Actions.WIZARD_CLEAR:
            console.log("---CLEAR WIZARD--");
            return initialStateWizard;
            break;
        case Actions.WIZARD_LOADING:
            return {
                loading:true
            };
            break;
        case Actions.WIZARD_LOADED:

            // set steps
//            console.log(action);

            return {
                ...state,
                loading:false,
                ...action.data
            };
            break;
        case Actions.WIZARD_STEPS_SET:

            return {
                ...state,
                activeStep:action.payload
            }
            break;


    }
    return state;
};

export const wizardStepsReducer = (state = initialStateSteps, action) => {
    switch(action.type) {
        case Actions.WIZARD_LOADING:
            return [];
            break;
        case Actions.WIZARD_STEPS_LOAD:
            return [...action.steps];
            break;
        case Actions.WIZARD_DATA_SETVALIDATION:
            let states = [...state];
            states[action.step].validations =action.validations;
            return state;

            break;

    }
    return state;
};

export const wizardDataReducer = (state = initialStateData, action) => {
    switch(action.type) {
        case Actions.WIZARD_DATA_LOAD:
            return {...action.data};
            break;
        case Actions.WIZARD_DATA_CHANGE:
            let ste = {...state};
            ste['_'+action.context] = {...ste['_'+action.context],...action.changes};
            return ste;
            break;

        case Actions.WIZARD_DATA_REMOVE:
            let ster = {...state};
            ster['_'+action.context] = {...ster['_'+action.context]};
            action.paths.forEach((pth)=> {
               delete  ster['_'+action.context][pth];
            });
            return ster;
            break;

        case Actions.WIZARD_DATA_SETVALIDATION:

            /*
             add:validResult.add,
             remove:validResult.remove,
             step:stepIdx,
             configKey,
             context:contextNum
             */


            let nwState = {...state};
            nwState['_'+action.context] = { ...nwState['_'+action.context]};
            let validations =nwState['_'+action.context][action.configKey+'.__validations'] ?  {...nwState['_'+action.context][action.configKey+'.__validations']} : {
                fields: {},
                messages: {}
            };


            action.remove.forEach((rm)=> {
                if(validations.messages[rm.id]) {
                    delete validations.messages[rm.id]
                }
                rm.fields.forEach((fl)=> {
                    if(validations.fields[fl]) {
                        let msgIdx = validations.fields[fl].indexOf(rm.id);
                        if(msgIdx>-1) {
                            validations.fields[fl].splice(msgIdx,1);
                        }
                        if(validations.fields[fl].length==0) {
                            delete validations.fields[fl];
                        }


                    }
                })
            });

            action.add.forEach((ad)=> {
               if(!validations.messages[ad.id]) {
                   validations.messages[ad.id]= {type:ad.msgType,text:ad.msg};
               }
               ad.fields.forEach((fl)=> {
                   if(!validations.fields[fl]) {
                       validations.fields[fl]=[]
                   }
                   if(validations.fields[fl].indexOf(ad.id)===-1) validations.fields[fl].push(ad.id);

               });

            });

            
            validations.invalid = Object.keys(validations.messages).length>0;


            nwState['_'+action.context][action.configKey+'.__validations'] = validations;


            return nwState;

        case Actions.WIZARD_FIELDGRID_ADD:
            let steFgAd = {...state};
            steFgAd['_'+action.context] = {...steFgAd['_'+action.context]};

            steFgAd['_'+action.context][action.path] = {...steFgAd['_'+action.context][action.path],value:[...steFgAd['_'+action.context][action.path].value,{}] };
            return steFgAd;
            break;
        case Actions.WIZARD_FIELDGRID_REMOVE:
            let steFgRm = {...state};
            steFgRm['_'+action.context] = {...steFgRm['_'+action.context]};

            steFgRm['_'+action.context][action.path] = {...steFgRm['_'+action.context][action.path] };
            if(!Array.isArray(steFgRm['_'+action.context][action.path].value)) {
                steFgRm['_'+action.context][action.path].value = [];
            }
            steFgRm['_'+action.context][action.path].value = [...steFgRm['_'+action.context][action.path].value.slice(0,action.index),...steFgRm['_'+action.context][action.path].value.slice(action.index+1)]

            return steFgRm;
            break;

        case Actions.WIZARD_FIELDGRID_SAVE:
            let steFgSv = {...state};
            let steFgSvSource = steFgSv['_'+action.context];
            let basepath = `${action.path}.[${action.index}].`;
            let obj = action.fields.reduce((res,item,idx)=> {
                res[item] = {
                    ...steFgSvSource[`${basepath}${item}`]
                };
                return res;
            },{});
            steFgSv['_'+action.context] = {
                ...steFgSv['_'+action.context]
            };
            steFgSv['_'+action.context][action.path]= {
                ...steFgSv['_'+action.context][action.path],
                value:[...steFgSv['_'+action.context][action.path].value.slice(0,action.index),obj,...steFgSv['_'+action.context][action.path].value.slice(action.index+1)]
            };
            return steFgSv;

            break;
        case Actions.WIZARD_FIELDGRID_CANCEL:
            let steFgCn = {...state};
            let basepathSc = `${action.path}.[${action.index}].`;
            steFgCn['_'+action.context] = {
                ...steFgCn['_'+action.context]
            };
            let sourceItem = steFgCn['_'+action.context][action.path].value[action.index];

            action.fields.forEach((fldName)=> {
                steFgCn['_'+action.context][`${basepathSc}${fldName}`]={...sourceItem[fldName]};
            });


            return steFgCn;
            break;

    }
    return state;
};

export const wizardNotesReducer = (state = initialStateNotes, action) => {
    switch(action.type) {
        case Actions.WIZARD_DATA_LOAD:


            break;
        case Actions.WIZARD_STEPS_SET:


            break;

    }
    return state;
};