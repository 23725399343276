import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTable } from 'react-table';
import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import financialPeriodsQuery from '../../../graph/queries/clients/financialPeriods';
//import './table.scss';
import Panel from '../../components/dashboard/panel/panel';
import {DashboardContainer, SmartLayout} from '../../components/dashboard';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FinancialPeriodsTable } from '../../components/grids/financialPeriods/view'

function mapStateToProps(state) {
	return {
		language: state.session.user.language,
		//clients: state.session.clients,
		clientId: state.client ? state.client._id : null
	};
}


const FinancialPeriods = ({clientId,editable=false}) => {
    const { loading, error, data,fetchMore,refetch,previousData } = useQuery(financialPeriodsQuery,{
       // ...options,
        notifyOnNetworkStatusChange: true,
        variables:{
            clientId:clientId
        }
    }); 

    const dta = data ? data.financialPeriods : [];


    
    return <DashboardContainer>
    <SmartLayout>
    <Panel title={"Financial Periods"}>
                    <div style={{display: 'block'}}><FinancialPeriodsTable data={dta} 
    loading={loading}
    /></div></Panel></SmartLayout></DashboardContainer>
}

export default connect(mapStateToProps)(FinancialPeriods);