import './panel.scss';
import React from 'react';
import classNames from 'classnames';

/**
 * DESCRIPTION: used on several pages (standalone forms, dashboard, ...)
 *   => wraps children (=body) in a styled wrapper with a title
 */



const emptyFn = ()=> {};

const Panel = ({title,subtitle,icon, iconAction,children, extraClassNames,actions=[]}) => {
	let panelClasses = classNames(
		'panel-container',
		extraClassNames
	);
    return <div className={panelClasses}>
		<div className="panel-title">
			<div className="panel-title-txt">
            { title ? title : 'Panel' }
			{ icon ? <span className={'icon-button ' + icon} onClick={iconAction ? iconAction : emptyFn}>&nbsp;</span> : null}
            { subtitle ? <span className="subtitle">{subtitle}</span> : null }
			</div>
			<div className="panel-title-extras">
			{
				actions && actions.length> 0 ? actions : null
			}
			</div>
			

		</div>
		<div className="panel-component">
            {children}
		</div>
	</div>;
};

export default Panel;
