import { useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Select, Spin } from "antd"

function getNested(obj,path) {
    let parts = path.split(".");
     return parts.reduce((src,p)=>src ? src[p]:null,obj);
}

export const GraphSelect = ({variables,graphOptions:{query,resultField,valueField,labelField},debounceTimeout=800,...props})=> {
    
    const { data ,loading} = useQuery(query,{variables});
        
    const [options, setOptions] = useState([]);

    const mapData = useMemo(()=> {
        return (graphResult)=> {
            //debugger;
            if(!graphResult) return [];
            let arr = graphResult[resultField] || []
                arr= arr.map(a=>({
                    label:getNested(a,labelField),
                    value:getNested(a,valueField)
                }));
            return arr;
        }
    },[resultField,valueField,labelField])
    
    useEffect(()=> {

        setOptions(mapData(data));
    },[data,mapData])


    return <Select
    labelInValue
   
    filterOption={false}
    notFoundContent={loading ? <Spin size="small" /> : null}
    {...props}
    options={options}
  />
}