import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from '@apollo/client/react/hoc';
import { DashboardContainer, SmartLayout } from '../../components/dashboard';
import Panel from '../../components/dashboard/panel/panel.jsx';
import Loader from '../../components/loader/index.jsx';
import Pagination from '../../components/grids/custom/pagination';
import DateRange from '../../components/grids/custom/range-date';
import Filter from '../../components/grids/custom/filter';
import AdminAuditsGridView from '../../components/grids/admin-audits';
import auditsQuery from '../../../graph/queries/admin/audits';


const Audits = ()=> {

	return <DashboardContainer>
	<SmartLayout>
		<Panel title={<FormattedMessage id="grid.audits.title" />}>
			<AdminAuditsGridView itemsPerPage={20}/>
			</Panel>
			</SmartLayout>
			</DashboardContainer>
}

export default Audits;