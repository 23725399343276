import { gql } from '@apollo/client';

export const createUser = gql`mutation createUser($user: Object!) {
	createUser(user: $user) {
		success
		error {
			code
			message
		}
	}
}
`;

export const updateUser = gql`mutation updateUserAsAdmin($userId: String!, $user: Object!) {
	updateUserAsAdmin(userId: $userId, user: $user) {
		success
		error {
			code
			message
		}
	}
}
`;

export const updateRegistration = gql`mutation updateUserFromRegistration($userId: String!, $user: Object!) {
    updateUserFromRegistration(userId: $userId, user: $user) {
        success
        error {
            code
            message
        }
    }
}
`;

export const createValidationToken = gql`mutation createValidationToken($invitation: Object!) {
	createValidationToken(invitation: $invitation) {
		success
		error {
			code
			message
		}
	}
}
`;

export const updateDeclineReason = gql`mutation updateDeclineReason($inviteId: String!, $bounceReason: String!) {
    updateDeclineReason(inviteId: $inviteId, bounceReason: $bounceReason) {
        success
        error {
            code
            message
        }
    }
}
`;

export const reinviteUser = gql`mutation reinviteUser($userId: String!) {
    reinviteUser(userId: $userId) {
        success
        error {
            code
            message
        }
    }
}
`;

export const updateLanguage = gql`mutation updateLanguage($language: String!) {
    updateLanguage(language: $language) {
        success
        error {
            code
            message
        }
    }
}
`;

export const processEmailForReset = gql`mutation processEmailForReset($userEmail: String!) {
    processEmailForReset(userEmail: $userEmail) {
        success
        error {
            code
            message
        }
    }
}
`;

export const processTokenForReset = gql`mutation processTokenForReset($userEmail: String!, $token: String!) {
    processTokenForReset(userEmail: $userEmail, token: $token) {
         _id
         userAccountId
         status
         email
         firstName
         lastName
         language
    }
}
`;

export const updateUserPassword = gql`mutation updateUserPassword($user: Object!, $password: String!) {
    updateUserPassword(user: $user, password: $password) {
         success
         error {
            code
            message
         }
    }
}
`;

export const deleteUser = gql`mutation deleteUser($userToDelete: String!) {
		deleteUser(userToDelete: $userToDelete) {
				success
				error {
						code
						message
				}
		}
}
`;


