import './exports.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Panel from '../../components/dashboard/panel/panel.jsx';
import { DashboardContainer, SmartLayout } from '../../components/dashboard/index';
import ExportForm from '../../components/ExportForm/index';

export default class Exports extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
			return (
				<DashboardContainer>
					<SmartLayout>
						<Panel title={<FormattedMessage id="export-factsheet.title"/>}>
							<ExportForm/>
						</Panel>
					</SmartLayout>
				</DashboardContainer>
			);
    }
}
