import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTable,useSortBy } from 'react-table';
import { useQuery } from '@apollo/client';
import financialPeriodsQuery from '../../../../graph/queries/clients/financialPeriods';
import Loader from '../../loader/spinner';
import './table.scss';
import moment from 'moment';

function formatDate(val) {
    return moment(""+val,"YYYYMMDD").format("MMMM YYYY")
}

export const FinancialPeriodsTable = ({data,loading,editable=false})=> {
    
    const columns = React.useMemo(
        () => [
          {
            Header: 'Financial Year',
            accessor: 'financialYear',
          },
          {
            Header: 'Period Start',
            editable:editable,
            id:'start',
             accessor: row => formatDate(row.start)
              },
              {
                Header: 'Period End',
                accessor:  row => formatDate(row.end)
              },
          {
            Header: '# Months',
            accessor: 'months',
          }
        ],
        [editable]
      )
        console.log("FinancialPeriodsTable data",data)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows
        // Get the state from the instance
       
      } = useTable(
        {
          columns,
          data: data ? data :[]
        }, useSortBy
      );

      return <table className='inco-financialperiods inco-table' {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <span className='column-sorted column-sort-desc'>{column.render('Header')} </span>
                        : <span className='column-sorted column-sort-asc'>{column.render('Header')} </span>
                      : column.render('Header')}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
          <tr>
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <td colSpan="10000"><Loader label={"loading"}/></td>
            ) : (
              <td colSpan="10000">
               
              </td>
            )}
          </tr>
        </tbody>
      </table>
}


export const FinancialPeriodsGraph = ({clientId,editable=false}) => {
    const { loading, error, data,fetchMore,refetch,previousData } = useQuery(financialPeriodsQuery,{
       // ...options,
        notifyOnNetworkStatusChange: true,
        variables:{
            clientId
        }
    }); 

    const dta = data ? data.financialPeriods : [];

   
    return <FinancialPeriodsTable data={dta} 
    loading={loading}
    />
}