import crypto from 'crypto';

const CIPHER_ALGORITHM = 'aes256';

export function encrypt(data, secret) {
	let cipher = crypto.createCipher(CIPHER_ALGORITHM, secret);

	let rawText = JSON.stringify(data || {});
	let encryptedText = cipher.update(rawText, 'utf8', 'hex') + cipher.final('hex');

	return encryptedText;
}

export function decrypt(encryptedText, secret) {
	let decipher = crypto.createDecipher(CIPHER_ALGORITHM, secret);

	try {
		let decryptedText = decipher.update(encryptedText, 'hex', 'utf8') + decipher.final('utf8');

		return JSON.parse(decryptedText);
	} catch (err) {
		return encryptedText;
	}
}