import './spinner.scss';
import React from 'react';
import classNames from 'classnames';
// functional component, has no state.
const spinner = ({label,fadeIn=false})=> (<div className={classNames("spinner",{"fadeIn":fadeIn})}> 
    <div className="spinner-body">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
    </div>
    {label ? <div className="spinner-label"><h2>{label}</h2></div> : null}
</div>);

export default spinner;