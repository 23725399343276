import { math } from "../mathjs"



export const useMath = (formulas)=> {

    console.log("RENDER","useMath");
    const compiledSet = formulas.map(f=>{
        let formula = math.compile(f.formula);
        let target = f.target.split(".");
       
       return { target:f.target, formula:(scope)=> {
        let val = null;
        try {
            console.log("CALC",f.formula,target)
             val = formula.evaluate(scope);
        } catch(e) {
            // 
            console.error("CALC ERROR","${f.target}",e)
        }
        console.log("CALC","SET",target,val)
        scope.set(target,val);
        return val;
       }
    }
        
    });

    return {
        calculate:(scope)=> {
            let errors = [];
            for(var f of compiledSet) {
                try {
                    f.formula(scope);
                } catch (e) {
                    errors.push({target:f.target,error:e})
                    console.error(e);
                }
            }
        }
    }
}